import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { GpstoreBannerComponent } from './gpstore-banner/gpstore-banner.component';
 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'gpstore-web';
  popupClosed = false;
  showHeaderFooter = true;
  showFooter = true;
  constructor(public dialog: MatDialog, private authservice:AuthService,private router: Router) { }
 
  // ngOnInit(): void {
  //   this.router.events.subscribe(event => {
  //     if (event instanceof NavigationEnd) {
  //       const noFooterPages = ['/login', '/register', '/404-not-found'];
  //       this.showHeaderFooter = !['/login', '/register'].includes(event.urlAfterRedirects);
  //       this.showFooter = !noFooterPages.includes(event.urlAfterRedirects);
  //     }
  //   });
  //   const isPopupShown = sessionStorage.getItem('isPopupShown');
  //   if (!isPopupShown) {
  //     this.openDialog();
  //   }
  // }
 
  // openDialog(): void {
  //   const isMobile = window.innerWidth < 768;
  //   // let sizes = {
  //   //   height: '550px',
  //   //   maxWidth: 'unset',
  //   // };
  //   // if (isMobile) {
  //   //   sizes = {
  //   //     maxWidth: 'unset',
  //   //     height: '100%',
  //   //   }
  //   // }
  //   let sizes = {
  //     width:'70%',
  //     height: '552px',
  //     maxWidth: 'unset',
  //   };
  //   if (isMobile) {
  //     sizes = {
  //       width:'100%',
  //       maxWidth: 'unset',
  //       height: '100%',
  //     }
  //   }
 
 
  //   const dialogRef = this.dialog.open(GpstoreBannerComponent, {
  //     ...sizes,
  //     disableClose: true,
  //     panelClass: 'custom-dialog-container'
  //   });
 
  //    // Add class to body to prevent background scroll
  //    document.body.classList.add('dialog-open');
 
  //    dialogRef.afterClosed().subscribe(result => {
  //      // Remove class from body to allow background scroll
  //      document.body.classList.remove('dialog-open');
  //      this.popupClosed = true;
  //      sessionStorage.setItem('isPopupShown', 'true');
  //    });
  //  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const noFooterPages = ['/login', '/register', '/404-not-found'];
        const noPopupPages = ['/login', '/register','/stripe-order-success','/stripe-wallet-success']; // Define routes where popup shouldn't be shown
        this.showHeaderFooter = !noPopupPages.includes(event.urlAfterRedirects);
        this.showFooter = !noFooterPages.includes(event.urlAfterRedirects);
 
        const isPopupShown = sessionStorage.getItem('isPopupShown');
        const sessionExpired = localStorage.getItem('sessionExpired') === 'true';
        // Check if popup has already been shown and if the current route allows the popup
        if (!isPopupShown && !noPopupPages.includes(event.urlAfterRedirects) && !sessionExpired) {
          this.openDialog();
        }
      }
    });
  }
 
  openDialog(): void {
    const isMobile = window.innerWidth < 768;
    let sizes = {
      width: '70%',
      height: '552px',
      maxWidth: 'unset',
    };
    if (isMobile) {
      sizes = {
        width: '100%',
        maxWidth: 'unset',
        height: '100%',
      };
    }
 
    const dialogRef = this.dialog.open(GpstoreBannerComponent, {
      ...sizes,
      disableClose: true,
      panelClass: 'custom-dialog-container'
    });
 
    // Add class to body to prevent background scroll
    document.body.classList.add('dialog-open');
 
    dialogRef.afterClosed().subscribe(result => {
      // Remove class from body to allow background scroll
      document.body.classList.remove('dialog-open');
      this.popupClosed = true;
      sessionStorage.setItem('isPopupShown', 'true');
    });
  }
 
}
 
 