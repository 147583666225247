<div class="m-lg-5 m-md-4 m-4 contact-page">
    <div class="contact-us-img-container">
        <div class="bg-img">
            <img src="../../assets/images/contactus-bg-img.svg" alt="img" class="contact-us-img">
            <div class="img-overlays"></div>
            <div class="contact-img-content">
                <span class="contact-us-heading">Contact us</span>
                <div class="disposal-days">
                    <div>We are at your disposal</div>
                    <div>7 days a week!</div>
                </div>
            </div>
        </div>
    </div>
    <div class="container d-lg-block d-none">
        <div class="mt-5 address-container">
            <div class="row">
                <div class="col-4">
                    <ul>
                        <li class="address">
                            <h6>Address</h6>
                            <span>
                                Harlow
                                Edinburgh Way, Temple Fields,
                                Harlow CM20 2DG
                            </span>
                        </li>
                        <li class="phone">
                            <h6>Phone</h6>
                            <span>447375928153</span>
                        </li>
                        <li class="email">
                            <h6>Email</h6>
                            <span>maddmanlimitedgmail.com</span>
                        </li>
                        <li class="website">
                            <h6>Website</h6>
                            <span>Gpstore.com</span>
                        </li>
                        <li class="follow">
                            <h6>Follow:</h6>
                            <div class="social-media-icon-container">
                                <div class="row">
                                    <div class="col-3">
                                        <img src="../../assets/icon/contact-us-fb-icon.svg" alt="fb-icon">
                                    </div>
                                    <div class="col-3">
                                        <img src="../../assets/icon/contact-us-twtr-icon.svg" alt="tetr-icon">
                                    </div>
                                    <div class="col-3">
                                        <img src="../../assets/icon/contact-us-insta-icon.svg" alt="insta-icon">
                                    </div>
                                    <div class="col-3">
                                        <img src="../../assets/icon/contact-us-wtsp-icon.svg" alt="wtsp-icon">
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-8">
                    <div class="map-container">
                        <img src="../../assets/images/contact-us-map.svg" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-lg-none d-block">
        <ul>
            <li class="address">
                <h6>Address</h6>
                <span>
                    Harlow
                    Edinburgh Way, Temple Fields,
                    Harlow CM20 2DG
                </span>
            </li>
            <li class="phone">
                <h6>Phone</h6>
                <span>447375928153</span>
            </li>
            <li class="email">
                <h6>Email</h6>
                <span>maddmanlimitedgmail.com</span>
            </li>
            <li class="website">
                <h6>Website</h6>
                <span>Gpstore.com</span>
            </li>
            <li class="follow">
                <h6>Follow:</h6>
                <div class="social-media-icon-container">
                    <div class="row">
                        <div class="col-3">
                            <img src="../../assets/icon/contact-us-fb-icon.svg" alt="fb-icon">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/icon/contact-us-twtr-icon.svg" alt="tetr-icon">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/icon/contact-us-insta-icon.svg" alt="insta-icon">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/icon/contact-us-wtsp-icon.svg" alt="wtsp-icon">
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="map-container">
            <img src="../../assets/images/contact-us-map.svg" alt="img">
        </div>
    </div>
    <div class="container mt-lg-5 mt-4 contact-us-container">
        <div class="contact-heading">
            <h4>Contact us</h4>
        </div>
        <form class="m-lg-4 contact-us-content">
            <div class="row mt-lg-0 mt-3 mb-3">
                <div class="col-lg-12 col-12">
                    <div class="full-name">
                        <label for="" class="form-label">Full name</label>
                        <input type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-6 col-12">
                    <div class="phone-number">
                        <label for="" class="form-label">Phone number</label>
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="email-id">
                        <label for="" class="form-label">Email id</label>
                        <input type="email" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-12 col-12">
                    <div class="message">
                        <label for="" class="form-label">Message</label>
                        <textarea class="form-control" rows="4"></textarea>
 
                    </div>
                </div>
            </div>
        <div class="btn-container">
            <div class="d-flex justify-content-lg-end justify-content-center">
                <button class="btn send-btn">Send message</button>
            </div>
        </div>
    </form>
    </div>
</div>
 
 