import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ProductPopupDialogComponent } from '../product-popup-dialog/product-popup-dialog.component';
import { ProductService } from '../services/product.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';

declare var $: any;
@Component({
  selector: 'app-recently-viewed-products',
  templateUrl: './recently-viewed-products.component.html',
  styleUrls: ['./recently-viewed-products.component.css']
})
export class RecentlyViewedProductsComponent {
  dataFrom :any;
  responsiveOptions: any[] | undefined;
  productList: any[] = [];
  selectedVariation: any;
  numVisible: number = 4; // default visible items
  userId: string | null = null;
  add_cart: boolean = true;
  downArrow: boolean = false;
  upArrow: boolean = false;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
  count: number = 4; // Default number of products to show
  page: number = 1;
  wishlistIcon = false;
  isLoggedIn: boolean = false;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  loadMoreRecentlyViewed: boolean = false;
  isLoadingRecentlyViewed: boolean = false;
  rightArrow: boolean = false;
  isMobileView = false;
  productListCount:any
 

  @ViewChild('elemt') elemt!: ElementRef;

  constructor(public router: Router, private http: HttpClient, private productService: ProductService, private dialog: MatDialog, public auth: AuthService, private cartService: CartService) {
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {
      if (data) {
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      } else {
        this.isLoggedIn = false;
      }
    });

  }

  ngOnInit() {

    if (!this.isLoadingRecentlyViewed) {
      this.ProductListCall(this.count,"arrow", '', '')
    }
    this.updateVisibleItems();
    this.isMobileView = window.innerWidth < 993;
 
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItems();
    this.isMobileView = window.innerWidth < 993;
 
  }

  updateVisibleItems() {
    const width = window.innerWidth;
    if (width > 1199) {
      this.numVisible = 4;
    } else if (width > 991) {
      this.numVisible = 3;
    } else if (width > 767) {
      this.numVisible = 2;
    } else {
      this.numVisible = 1;
    }
  }

  openProductDialog(product: any, selectedVariationId: string): void {

    const bestSelling = "bestSelling";

    const variationId = this.selectedVariation || selectedVariationId;

    this.dialog.open(ProductPopupDialogComponent, {
      data: {
        id: product.id,
        datafrom: bestSelling,
        variationId: variationId
      },
      width: '100%',
      height: '100%',
      disableClose: true
    });
    this.selectedVariation = ''
  }

  get showPrevArrow(): boolean {
    return this.productList.length > this.numVisible;
  }

  get showNextArrow(): boolean {
    return this.productList.length > this.numVisible;
  }

  viewAll() {
    this.router.navigate(['/product-list-pages']);
  }

  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }



    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }

  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }

  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }


  loadMoreProducts(datafrom: any, parentElement: any, rightScroll: any): void {


    if (!this.isLoadingRecentlyViewed && this.loadMoreRecentlyViewed) {

      this.ProductListCall(1,"arrow", parentElement, rightScroll);
    }
  }
  // onScrollOrClickArrow(): void {
  //   this.page +=1;
  //   this.count =1;
  //   this.loadMoreProducts("arrow");

  // }
  onScrollOrClick() {
   
    // this.downArrow = true;
    this.upArrow = true;
    if (this.isLoadingRecentlyViewed) {
      return ; // Ignore further clicks while loading
    }
    if (this.page === 1) {
      this.page = 5; // Start from 5 on the first click after the initial load
    } else {
      this.page += 1; // Increment the page count on subsequent clicks
    }
    const container = this.elemt.nativeElement;
    setTimeout(() => {
      if (this.productListCount === this.productList.length) {
        this.downArrow = false;
        this.rightArrow = false;
      }
    }, 0);
    if (this.productList.length > 0 ) {
          this.downArrow = true;  // Enable down arrow
        }
       
        else{
          this.downArrow = false;
            // Enable down arrow
        }
    let parentElement = this.elemt.nativeElement.parentElement;
    let rightScroll =container.scrollWidth ;
 
    this.loadMoreProducts("scroll", parentElement, rightScroll);
    setTimeout(() => {
      $(parentElement)[0].scrollTo({
        left: rightScroll, // Desired scrollLeft value
        behavior: 'smooth' // Enables smooth scrolling
      });
    });
  }
 
  onLeftScrollOrClick(): void {
   
    this.downArrow = false;
    this.rightArrow = true;
    const leftBtn = document.querySelector('#left-button') as HTMLElement;
   
    const content = document.querySelector('#popular-spots-lists') as HTMLElement;
    const scrollLeft = (event: Event) => {
      const TOLERANCE = content.offsetLeft ;
      const scrollLeftMax = content.scrollWidth - content.clientWidth;
      if (content.scrollLeft <= 300) {
        this.downArrow = false; // No more space to scroll left
        this.rightArrow = true;
        this.upArrow = true;
      this.upArrow = false;
      }else {
        // this.upArrow = false;
        this.upArrow = content.scrollLeft > 300 - TOLERANCE;
 
      }
      content.scrollBy({
        left: -300,  
        behavior: 'smooth'  
    });
    event.preventDefault();
};
leftBtn.removeEventListener("click", scrollLeft);
leftBtn.addEventListener("click", scrollLeft);
scrollLeft(new MouseEvent('click'));
  }
  onRightScrollOrClick(): void {
   
    this.rightArrow = true;
    this.upArrow = true;
   
    const rightBtn = document.querySelector('#right-button') as HTMLElement;
    const content = document.querySelector('#popular-spots-lists') as HTMLElement;
    const maxScrollLeft = content.scrollWidth - content.clientWidth;
    setTimeout(() => {
      if (this.productListCount === this.productList.length) {
        this.downArrow = false;
       
      }
    }, 0);
     
    // Check if we can scroll further
    if (content.scrollLeft + 300 >= maxScrollLeft) {
      this.rightArrow = false;
      if (this.productList.length > 0) {
        // this.rightArrow =true;
            this.downArrow = true;  // Enable down arrow
          }
         
           else{
            this.downArrow = false;
// Enable down arrow
          }
    }
    const scrollRight = (event: Event) => {
      content.scrollBy({
        left: +300,
        behavior: 'smooth'
      });
      event.preventDefault();
    };
    rightBtn.removeEventListener("click", scrollRight);
    rightBtn.addEventListener("click", scrollRight);
    scrollRight(new MouseEvent('click'));
  }
 

  selectSize(id: any, variation: any): void {
    let productIndex = this.productList.findIndex((x: any) => x.id == id);

    if (productIndex != -1) {
      this.selectedVariation = variation.productvariationid;
      this.productList[productIndex].selectedVariation = variation.productvariationid;
      this.productList[productIndex].price = variation.priceToShow;
      this.productList[productIndex].originalPrice = variation.originalPrice;
    }

  }
  ProductListCall(count:any,dataFrom: any, parentElement: any, rightScroll: any): void {

    this.isLoadingRecentlyViewed = true;
    const requestBody: any = {
      status: 1,
      // isSale: true,
      page: this.page,
      count: count,
      userId: this.userId
    };
    if (this.userId) {
      requestBody.userId = this.userId;
    }
    this.http.post(environment.apiUrl + `/products/recently-viewed-list`, requestBody, { responseType: 'json' }).subscribe({
     next: (data: any) => {
        if (data.status === 'SUCCESS') {

          if (!this.userId) {
            const storedWishlist = JSON.parse(localStorage.getItem('wishlist') || '[]');
            data.products_output.forEach((product: any) => {
              product.wishlistIcon = storedWishlist.includes(product.id);
            });
          } else {
            // If the user is logged in, handle accordingly (e.g., make an API call if needed)s

          }

          //  const duplicateRemoveNewproduct= this.removeDuplicates(data.products_output, "activityid", "merchantid");





          data.products_output.forEach((product: any) => {
            if (product.productvariations && product.productvariations.length > 0) {
              product.variations = [];
              product.productvariations.forEach((variation: any) => {
                if (product.productvariations[0]) {
                  // Set the first variation as default
                  product.originalPrice = product.productvariations[0].mrpprice;
                  product.variationvalue = product.productvariations[0].variationvalue;
                  product.price = product.productvariations[0].sellingprice;
                  product.originalPrice = product.productvariations[0].mrpprice;
                  product.variationvalue = product.productvariations[0].variationvalue;
                  product.variationshortname = product.productvariations[0].variationshortname;
                  product.selectedVariation = product.productvariations[0].productvariationid
                  product.cartitemsquantity = product.productvariations[0].cartitemsquantity
                  product.cartid = product.productvariations[0].cartid
                  product.variations.push({
                    originalPrice: variation.mrpprice,
                    priceToShow: variation.sellingprice,
                    variationvalue: variation.variationvalue,
                    variationshortname: variation.variationshortname,
                    productvariationid: variation.productvariationid
                  });

                }
              });
            }
            //  this.wishlistIcon = this.isInWishlist || false;
          });

          data.products_output.forEach((x: any) => {
            x.src = [];
            
            // Parse the `imageinfo` JSON to extract `imageid`
            if (x.imageinfo) {
              try {
                const imageInfoArray = JSON.parse(x.imageinfo);
                if (imageInfoArray.length > 0) {
                  const imageId = imageInfoArray[0].imageid; // Assuming you want the first image
                  if (imageId) {
                    // Directly call the API to fetch the image
                    this.http.get(environment.apiUrl + `/uploads/get?id=${imageId}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        x.src.push(res.uploads.base64data);
                      }
                    });
                  }
                }
              } catch (err) {
                console.error("Error parsing imageinfo JSON:", err);
              }
            }
          });
          
          //   if (data.wishlistitems_output) {
          //     // Create a Set of wishlist items for quick lookup
          //     const wishlistSet = new Set<string>();
          //     data.wishlistitems_output.forEach((item: any) => {
          //         if (item.productid && item.productvariationid) {
          //             wishlistSet.add(`${item.productid}-${item.productvariationid}`);
          //         }
          //     });

          //     // Update productList with wishlistIcon
          //     this.productList.forEach((product: any) => {
          //         if (product.productid && product.productvariationid) {
          //             const key = `${product.productid}-${product.productvariationid}`;
          //             product.wishlistIcon = wishlistSet.has(key);
          //         }
          //     });
          // }
          if (parentElement && parentElement != "" && rightScroll && rightScroll != "") {

            setTimeout(() => {
              $(parentElement)[0].scrollTo({
                left: rightScroll, // Desired scrollLeft value
                behavior: 'smooth' // Enables smooth scrolling
              });
            });

          }

          if (dataFrom === "arrow" && this.productList.length > 0) {
            this.upArrow = true; // Show "See Less" after clicking "See More"
          }
          const filteredActivities = data.products_output.filter((activity: any) => {
            return this.productList != activity.id;
          });
          this.productListCount = data.count;
 
          this.productList = [...this.productList, ...data.products_output];

          if (this.productList.length < count * this.page) {
            this.loadMoreRecentlyViewed = false; // No more products to load
          } else {
            this.loadMoreRecentlyViewed = true; // Enable further loading
          }
 
          if (data.count > this.productList.length) {
            // this.loadMoreRecentlyViewed = true;
            // this.page += 1;
            // this.upArrow = true; // Hide both arrows if no new products
            this.downArrow = true;
          }
          else {
            // this.loadMoreRecentlyViewed = false;
            this.downArrow = false;
            this.upArrow = true;
          }
 
        
          this.isLoadingRecentlyViewed = false;
         
        }
      },
     error: (error) => {
        console.error("API Error:", error);
      }
  });
  }

  // removeDuplicates(arr: any[], prop1: string, prop2: string) {
  //   return arr.filter((obj, index, self) =>


  //     index === self.findIndex((o) =>
  //       o[prop1] === obj[prop1] && o[prop2] === obj[prop2]
  //     )
  //   );
  // }

  productList_page(productId: string, selectedVariation: string,type:any): void {
    if(type ==='is_sale'){
      this.dataFrom = "bestSelling";
    }else if(type ==='is_subscribe'){
      this.dataFrom = "subscribeProduct";
    }
    const variationId = this.selectedVariation || selectedVariation;
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: this.dataFrom, variationId: variationId } }
    });
  }

  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe({
      next:(data: any) => {
        if (data.status === 'SUCCESS') {
          const product = this.productList.find(item => item.id === productId);
          if (product) {
            product.cartitemsquantity = quantity;
          }

          this.addedToCart.set(productId, true);
          this.cartService.updateCartCount(data.newCartCount);

        }
      },
      error:(error: any) => {
        console.error("Error in cartList API call:", error);
      }
  });
  }


  getQuantity(productId: number): number {

    return this.quantities.get(productId) || 1;

  }
  increaseQuantity(productId: number, productVariationId: string) {
    const product = this.productList.find(item => item.id === productId);
    if (product) {
      product.cartitemsquantity += 1;
      this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
    }
  }


  decreaseQuantity(productId: number, productVariationId: string, cartId: any) {
    const product = this.productList.find(item => item.id === productId);

    if (product) {
      if (product.cartitemsquantity > 1) {
        // Decrease quantity and update the cart
        product.cartitemsquantity -= 1;
        this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
      } else if (product.cartitemsquantity === 1) {
        // If the quantity is 1, set it to 0 and call delete function
        product.cartitemsquantity = 0;
        this.deleteCartItem(cartId);  // Call delete function

      }
    }
  }

  deleteCartItem(cartId: any): void {
    const requestBody = {
      updatedBy: this.userId,
      cartIds: [cartId],
    };

    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe({
     next: (data: any) => {
        if (data.status === 'SUCCESS') {
          if (this.userId !== null && this.userId !== undefined) {
            this.cartService.updateCartCount(this.userId);
          } else {
            console.error('User ID is null or undefined. Cannot update cart count.');
          }
        }
      },
      error:(error: any) => {
        console.error('An error occurred:', error);
      }
  });
  }


  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const newStatus = !product.wishlistIcon;

    product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId
      };

      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
        .subscribe({
          next:(data: any) => {
            // Handle the success response here if needed
          },
          error:(error) => {
            console.error("API Error:", error);
          }
    });
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }

 


 
 
 

}

