<div mat-dialog-content>
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>
 
  <div class="row mt-4">
    <!-- Thumbnail Images -->
    <div class="col-md-1 px-0">
      <!-- <div class="d-block">
        <div class="img" *ngFor="let image of productImages.src; let i = index" (click)="selectImage(i)">
          <img [src]="image" alt="Product Image" class="border my-0"
            [ngClass]="{'active-thumbnail': selectedIndex === i}" />
        </div>
      </div> -->
      <div id="myCarousel" class="carousel slide position-relative" data-ride="carousel">
        <div class="carousel-inner" role="listbox">
          <!-- Loop through product images and display only 3 at a time -->
          <div class="img item m-0" *ngFor="let image of productImages.src.slice(startIndex, endIndex); let i = index"
            (click)="selectImage(startIndex + i)">
            <img [src]="image" alt="Product Image" class=" border mx-0 mb-2"
              [ngClass]="{'border': selectedIndex !== (startIndex + i), 'active-thumbnail': selectedIndex === (startIndex + i)}" />
          </div>
        </div>
        <!-- Carousel controls -->
        <div *ngIf="startIndex > 0" class="carousel-control-prev position-absolute">
          <img [src]="prevImageSrc" alt="Previous" class="m-0  carousel-img-scroll" (click)="prevImage()"
            (mouseover)="hoverImage('prev', true)" (mouseout)="hoverImage('prev', false)" width="35px" height="35px">
        </div>
        <div *ngIf="endIndex < productImages.src.length" class="carousel-control-next position-absolute">
          <img [src]="nextImageSrc" alt="Next" class="m-0  carousel-img-scroll" (click)="nextImage()"
            (mouseover)="hoverImage('next', true)" (mouseout)="hoverImage('next', false)" width="35px" height="35px">
        </div>
      </div>
    </div>
 
    <!-- Main Product Image -->
    <div class="col-md-5 p-0 border" style="border-radius: 10px; height: 465px;">
      <img [src]="mainImage" alt="Product Image" class="main-product-image" />
    </div>
 
    <!-- Product Details -->
    <div class="col-md-5 productDetails">
      <h4>{{ productDetails.title }}</h4>
 
      <div class="promotions mt-3 mb-3 d-flex align-items-center">
        <div *ngFor="let variation of productDetails?.productvariations ">
          <span class="promo_off p-1 " *ngIf="variation?.discountpercentage > 0 && variation?.productvariationid === productDetails?.selectedVariation && !isDeliverySchedule">
            {{variation?.discountpercentage}}% Off
          </span>
          <span class="promo_off me-1 p-1" *ngIf="variation?.scribecap && variation?.productvariationid === productDetails?.selectedVariation && isDeliverySchedule">
            {{variation?.scribecap }}
          </span>
        </div>
 
        <span class="combo_off mx-1" *ngIf="iscomboavailable && productDetails?.selectedVariation">Combo offer available</span>
        <span class="halal-offer mx-1 ps-1" *ngIf="ishalalfood">Halal Food
          <img src="../../assets/images/halal-food.svg" alt="">
        </span>    <!-- <span class="badge" [ngClass]="{ 'bg-success': productDetails.type === 'discount', 'bg-info': productDetails.type === 'offer' }">
        {{ productDetails.label }}
      </span> -->
      </div>
 
      <div class="description-container">
        <p class="description" [innerHTML]="sanitizedDescription"></p>
      </div>
      <!-- <a class="show-more mt-3" (click)="toggleShowMore()">Show more</a> -->
 
      <div class="pricing mt-3">
        <span class="new-price fs-3">{{ priceToShow | currency: 'GBP' }}</span>
        <span class="original-price ms-2 text-decoration-line-through">
          <del>{{ mrpPrice | currency: 'GBP' }}</del>
        </span>
        <span class="ms-2 tax">(inclusive of all taxes)</span>
      </div>
 
 
      <div class="size-selection mt-1">
        <div class="sizes p-1">
          <button *ngFor="let variation of productDetails.variations" (click)="selectSize( variation)"
            [ngClass]="{'selected-btn': variation === selectedVariation}" class="btn size-btn me-2">
            {{ variation.variationvalue }} {{ variation.variationshortname }}
          </button>
        </div>
      </div>
 
    
          <!-- delivery schedule -->
          <div class="delivery-selection mt-3" *ngIf="isDeliverySchedule  ">
            <div>
              <h6>Delivery Schedule</h6>
              <div>
                <select name="schedule" id="schedule" class="form-select" [(ngModel)]="selectedSchedule">
                  <option value="">Select Schedule</option>
                  <hr>
                  <option value="Daily">Daily</option>
                  <hr>
                  <option value="Alternate">Alternate</option>
                  <hr>
                  <option value="Every 3 days">Every 3 days</option>
                  <hr>
                  <option value="Weekly">Weekly</option>
                  <hr>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>
            </div>
          </div>
 
          <!-- schedule date -->
          <div class="date-selection mt-3 row" *ngIf="isDeliverySchedule">
            <div class="col">
              <h6>Starts on</h6>
              <div class="">
                <input type="date" name="startDate" id="startDate" class="form-control" [(ngModel)]="startDate">
              </div>
            </div>
            <div class="col">
              <h6>Ends on</h6>
              <div class="">
                <input type="date" name="endDate" id="endDate" class="form-control" [(ngModel)]="endDate">
              </div>
            </div>
          </div>
          <!-- only for food product -->
          <div>
            <div class="mt-2" *ngIf="productDetails.next_delivery_slot && !isDeliverySchedule">
              <span class="slot">*Next Delivery Slot will be:</span>
              <span class="slot-date">{{ productDetails.next_delivery_slot }}</span>
            </div>
 
            <div class="mt-2 mb-2" *ngIf="FoodDeliverySlot && flavorList !=null">
            <label for="" class="food-instruction">Flavor Instruction</label>
            <div class="mt-1" *ngIf="!iscomboavailable">
              <select name="flavor" id="flavor-select" class="flavor-add" [(ngModel)]="selectedFlavor"
                (change)="onFlavorChange($event,productDetails)">
                <option value="">Add food flavor instruction</option>
                <option *ngFor="let flavor of flavorList" [value]="flavor">{{ flavor }}</option>
              </select>
            </div>
          </div>
            <!-- </div> -->
          </div>
    
 
      <button class="btn add-card btn-lg"
      (click)="Add_to_cart(productDetails.id, productDetails.selectedVariation, 1,'is_sale')"
      *ngIf="(!productDetails.cartitemquantity || productDetails.cartitemquantity === 0) && !isDeliverySchedule ">
      <div>
        <img src="../../assets/images/bag-add.svg" alt="" class="mx-2">Add to cart
      </div>
    </button>
 
      <button class="btn add-card btn-lg mt-3" (click)="SubcribeClick(productDetails.id, productDetails.selectedVariation)" *ngIf="isDeliverySchedule">
        <div><img src="../../assets/images/bag-add.svg" alt="" class="mx-2">Subcribe</div>
      </button>
      <div class="d-flex align-items-center justify-content-around add-border cart"
        *ngIf="isLoggedIn &&productDetails.cartitemquantity > 0 && !isDeliverySchedule">
        <div class="text-center d-flex   justify-content-around" style="width: 240px;">
          <button class="btn p-0"
            (click)="decreaseQuantity(productDetails.id, productDetails.selectedVariation)"><img
              src="../../assets/images/decrease_img.svg" alt="Decrease" style="width: 50px; height: 25px;"></button>
          <span class="px-2" style="font-size: 16px; margin-top: 5px;">{{ productDetails.cartitemquantity }}</span>
          <button class="btn p-0" (click)="increaseQuantity(productDetails.id, productDetails.selectedVariation)"><img
              src="../../assets/images/increase_img.svg" alt="Increase" style="width: 50px; height: 25px;"></button>
        </div>
      </div>
    </div>
 
    <!-- Share and Like Icons -->
    <div class="col-md-1 p-0">
      <div class="d-flex justify-content-between align-items-center">
        <div class="">
          <img src="../../assets/icon/share.svg" alt="share" class="icon pointer"
            (click)="copyTextToClipboard(hostName+'/product-pages/'+productDetails.id);">
          <div *ngIf="showCopiedOptions" class="mt-3 d-block copyOptions" style="position: absolute;">
            <ul>
              <li>Copy link</li>
              <li>E-mail</li>
              <li>Whatsapp</li>
              <li>Facebook</li>
              <li>Twitter</li>
            </ul>
          </div>
          <div class="copy-success position-absolute" *ngIf="showCopiedMessage">Copied
            to clipboard!</div>

        </div>


        <div class="like-icon-div">
          <img
            *ngIf="isLoggedIn && productDetails.wishlistIcon ; else noWishListIcon"
            (click)="toggleWishListOrCombo($event,  productDetails)"
            src="../../assets/images/single-like-img.svg" alt="like" />
          <ng-template #noWishListIcon>
            <img
              (click)="toggleWishListOrCombo($event,productDetails)"
              src="../../assets/images/single-red-like-img.svg" alt="like">
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <img src="../../assets/images/animation-arrow.svg" alt="" class="d-block m-auto img-animation"
      (click)="navigateToProductPage(productDetails.selectedVariation
      )" style="cursor: pointer;">
  </div>
 
</div>
 
 
 
 
 