import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.css']
})
export class PrivacyPolicyPageComponent {
  pageDataType: any;
  pageType:any;
  constructor(private router: Router,private http: HttpClient,) {
    // Access the state passed from the previous component
    const navigation = this.router.getCurrentNavigation();
    this.pageDataType = navigation?.extras?.state?.['pageData']; // Extract the state data
  }
  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
          })
 
    if (this.pageDataType) {
      this.openPage()
    } else {
      console.error('No page data found');
    }
    const fullUrl = this.router.url; 
    this.pageDataType = fullUrl.startsWith('/') ? fullUrl.slice(1) : fullUrl;
  }

  openPage(){
   
    const requestBody = {
      pageType:this.pageDataType
    };
    
    this.http.post(environment.apiUrl + `/pages/list`, requestBody, { responseType: 'json' })
    .subscribe(
      (data: any) => {
        
        if (data.status === 'SUCCESS') {

          this.pageType = data.pages_output[0]; 
        }
      },
      (error) => {
        console.error("Wishlist API Error:", error);
      }
    );
  }

}
