<!-- for laptop-design -->
<div class="d-none d-lg-block">
  <!-- Upper Header -->
  <div id="upper-header" class="d-lg-flex justify-content-sm-between upper-header p-3 px-xl-5 px-2">
    <div class="d-flex gap-lg-2 gap-1">
      <li class="list-inline-item"><a href="" class="d-flex"><img src="../../assets/icon/secured-payment.svg"
            class="px-lg-2 me-1" alt="Secured Payment">Secured Payment</a></li>
      <li class="list-inline-item"><a href="" class="d-flex"><img src="../../assets/icon/star.svg" class="px-lg-2 me-1"
            alt="Star">100% Original Product</a></li>
    </div>
    <div class="d-flex gap-lg-2">
      <li class="list-inline-item"><a href="tel:+447375928153" class="d-flex"><img src="../../assets/icon/phone.svg" class="px-lg-2 me-1"
            alt="Phone" >+447375928153</a></li>
      <li class="list-inline-item"><a href="mailto:sajsv8@gmail.com.com" (click)="openEmail($event)" class="d-flex"><img src="../../assets/icon/email.svg" class="px-lg-2 me-1"
            alt="Email">{{mailId}}</a></li>
    </div>
  </div>

  <!-- Header Nav -->
  <nav id="header-nav" class="navbar navbar-expand navbar-light bg-white py-2">
    <div class="collapse navbar-collapse d-flex  mx-md-1  mx-xl-4  row gap-xl-2 gap-md-1 justify-content-around"
      id="fixedNavbarExample">
      <!-- Logo -->
      <div class="logo  col-lg-2 ">
        <img src="../../assets/gpstore-logo.svg" routerLink="/home" alt="logo" (click)="onChange($event)" >
      </div>
      <ul class="navbar-nav  col-lg-3 d-flex gap-2 ">

        <ul class="dropdown nav-item list-unstyled ul-style " *ngFor="let card of mainCategories; let i = index">
          <li (mouseenter)="toggleCategory(true, i)" (mouseleave)="toggleCategory(false, i)"
            class="dropdown nav-item parent" [class.active]="isCategoryActive(i)">



            <a class="btn d-flex nav-link menu-item mt-2 me-2 ms-1 " type="button" id="{{ card.id }}"
              aria-expanded="false" (click)="categorykey_click(card)">

              {{ card.name }}
              <img src="../../assets/icon/down-arrow.svg" alt="categories" class="ms-1 mt-1"
                *ngIf="!(isCategoryActive(i) && (groceryOpen || foodOpen))">
              <img src="../../assets/icon/up-arrow.svg" alt="categories" class="ms-1 mt-1"
                *ngIf="isCategoryActive(i) && (groceryOpen || foodOpen)">
            </a>

            <ul class="dropdown-menu child" [class.show]="isCategoryActive(i) && (groceryOpen || foodOpen)">
              <li *ngFor="let subcategory of subCategories"
                (click)="navigateToProductList(subcategory.id, subcategory.name, subcategory.level, subcategory.parentid,subcategory.categorykey)">
                <a class="dropdown-item grocery-color ">{{ subcategory.name }}</a>
              </li>
            </ul>

            <div *ngIf="isCategoryActive(i) && (groceryOpen || foodOpen)" class="triangle-grocery"></div>
          </li>
        </ul>
        <li class="nav-item   subscribe-products pt-2" (click)="subscribeList_page()">
          <a class="nav-link m-0 " style="font-weight: 600;">Subscribe Products</a>
        </li>

      </ul>
      <div class=" col-lg-4 mt-2 me-2">
        <div class="search-container">
          <input type="text" placeholder="Search Grocery Products & Foods" [(ngModel)]="searchQuery"
            (keyup.enter)="onSearch()">
          <button type="submit" (click)="onSearch()"><i class="fa fa-search"></i></button>
        </div>
      </div>
      <ul class="navbar-nav col-lg-2 col justify-content-around  ">
        <!-- map -->
        <li class="nav-item dropdown mt-1" (click)="mapDropdown($event)" (mouseleave)="closeDropdown('map')">
          <a class="nav-link " aria-current="page" id="mapDropdown">
            <img src="../../assets/images/map.svg" alt="map" class="img-color-change"
              [ngClass]="{'hidden-img': isAddCartPage}" [class.active]="mapDropdownOpen" style="width: 19px;">
          </a>
          <div class="dropdown-menu profile-menu p-3 " [ngClass]="{'hidden-img': isAddCartPage}"
            [class.active]="mapDropdownOpen" [class.show]="mapDropdownOpen" aria-labelledby="mapDropdown"
            (click)="$event.stopPropagation()">
            <div class="search-container map-search-container mx-auto">
              <input type="text" class="p-3" placeholder="Search for area,place name " [(ngModel)]="postalCode"
                (keyup.enter)="SearchAreaForDelivery(postalCode)">
              <button type="submit" (click)="SearchAreaForDelivery(postalCode)"><i class="fa fa-search"></i></button>
            </div>
            <div class="mb-2 mx-4 my-3 detect-address" (click)="detectLocation()">
              <img src="../../assets/icon/detect-address.svg" alt="" class="pe-2"> Detect my current location
            </div>
            <hr>
            <div class="detect-address mx-4 my-3 pointer" (click)="saveAddress('address')">
              <img src="../../assets/icon/add-address.svg" alt="" class="pe-2"> Add address
            </div>
            <hr>
            <div class="ms-4" *ngIf="Address">
              <small class="d-block address-save mb-3">Default Address</small>
              <div class="d-flex">
                <input class="mb-2 me-3 " type="radio" name="selectedAddress" [(ngModel)]="selectedLocationId"
                  [value]="Address.id">
                <div>
                  <span class="d-block main-address mb-2">{{Address.firstname}}</span>
                  <p class="address-save-details">{{Address.address}}</p>
                  <p class="address-save-details">{{Address.deliveryarea}}</p>
                </div>
              </div>
            </div>

          </div>
          <div *ngIf="mapDropdownOpen" [ngClass]="{'hidden-img': isAddCartPage}" [class.active]="mapDropdownOpen"
            class="triangle-corner" style="left: -2px;"></div>
        </li>
        <!-- add-bag -->
        <li class="dropdown nav-item mt-1" (mouseenter)="add_bag(true)" (mouseleave)="add_bag(false)"
          (click)="add_cart()">
          <a class="add_bag-btn btn nav-link " type="button" id="add_bag" aria-expanded="false">
            <img src="../../assets/images/add-bag.svg" alt="bag" class="img-color-change"
              [ngClass]="{'hidden-img': isAddCartPage}" [class.active]="add_bagOpen" style="width: 20px;">
            <span [ngClass]="{'hidden-img': isAddCartPage}" class="notification-bag" *ngIf="count>0">{{count}}</span>
          </a>
          <div class="dropdown-menu profile-menu" [class.show]="add_bagOpen" *ngIf="!isAddCartPage && add_bagOpen"
            aria-labelledby="add_bag">
            <div class="p-4">
              <div class="card-header row">
                <h6 class="col-sm-8 cart mb-0">Cart</h6>
                <span class="col-sm-4 product-count">{{count}} Products</span>
              </div>
              <hr class="mt-2">
              <div class="card-body">
                <div class="row" *ngFor="let item of cartItems | slice:0:3">
                  <div class="col-sm-8 product text-truncate">{{ item.title }}</div>
                  <div class="mt-2 col-sm-4 text-center price">{{ (item.price ||
                    item.variations?.[0]?.sellingprice || 0) | currency:'GBP' }}
                    <span class="multiples">X {{item.quantity}}</span>
                  </div>
                  <hr>

                </div>
                <span *ngIf="cartItems.length > 4" (click)="add_cart()" class="see-more mb-3 mt-0">+{{count-3}}
                  more</span>
                <div class="row">
                  <div class="col-sm-8 mt-1">Total Price</div>
                  <div class="col-sm-4 total">{{ getSubTotalPrice() | currency:'GBP' }}</div>
                </div>
                <button class="proceed-btn btn col-md-8 offset-md-2 my-3" (click)="add_cart()">Proceed to
                  checkout</button>
              </div>
            </div>
          </div>
          <div *ngIf="!isAddCartPage && add_bagOpen" class="triangle-corner" style="left: 2px;"></div>
        </li>
        <!-- profile -->


        <div class="nav-link d-flex profile  d-none d-lg-block signin-profile" id="profileDropdown" *ngIf="!name">
          <a (click)="loginpage()">Signin <img src="../../assets/icon/signin-profile.svg" alt="" class=""></a>
        </div>

        <li class="nav-item dropdown " (click)="toggleProfileDropdown($event)" *ngIf="name"
          (mouseleave)="closeDropdown('profile')">
          <a class="nav-link d-flex profile p-1" id="profileDropdown" [ngClass]="{'active': profileDropdownOpen}">
            <span class="nav-link profile-name">{{name}}</span>
            <img
              [src]="profileImageUrl  && profileImageUrl.trim() !== '' ? profileImageUrl : 'assets/images/profile-man.svg'"
              alt="profile" class="profile-img">
          </a>
          <div class="dropdown-menu p-1 profileMenu" [class.show]="profileDropdownOpen"
            aria-labelledby="profileDropdown">
            <a class="dropdown-item p-2" (click)="navigateToProfile('profile',$event)">
              <img src="../../assets/icon/profile-man.svg" alt="" class="me-3">Profile
            </a>
            <a class="dropdown-item p-2" (click)="navigateToProfile('wallet',$event)">
              <img src="../../assets/icon/wallet.svg" alt="" class="me-3">My Wallet
            </a>
            <a class="dropdown-item p-2" (click)="navigateToProfile('wishlist',$event)">
              <img src="../../assets/icon/whishlist.svg" alt="" class="me-3">Wishlist
            </a>
            <a class="dropdown-item p-2" (click)="navigateToProfile('orders',$event)">
              <img src="../../assets/icon/my-order.svg" alt="" class="me-3">My Orders
            </a>
            <a class="dropdown-item p-2" (click)="navigateToProfile('subscriptions',$event)">
              <img src="../../assets/icon/subscribe-products.svg" alt="" class="me-3">Subscribe Products
            </a>
            <a class="dropdown-item p-2" (click)="navigateToProfile('notifications',$event)">
              <img src="../../assets/icon/notification.svg" alt="" class="me-3">Notification
            </a>
            <a class="dropdown-item p-2" href="#" (click)="openSignOutModal($event)">
              <img src="../../assets/icon/signout.svg" alt="" class="me-3">Sign out
            </a>
            
          </div>

         

          <div *ngIf="profileDropdownOpen" class="triangle-profile-corner" style="left: 50px;"></div>
        </li>

      </ul>
    </div>
  </nav>

</div>
 <!-- Sign Out Confirmation Modal -->
 <div class="modal fade" id="signoutModal" data-bs-backdrop="false" tabindex="-1" aria-labelledby="signoutModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="signout-content">
        <div class="sign">
            <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
            <div class="popup-message">Do you want to logout your account</div>
        </div>
        <hr class="hr-line">
        <div class="popup-actions">
            <button class="btn no-confirm-btn"  data-bs-dismiss="modal">No</button>
            <div class="vr px-0 "></div>
            <button class="btn yes-confirm-btn" (click)="confirmSignOut()">Yes</button>
        </div>
    </div>
    </div>
  </div>
</div>

<!-- for mobile-design-->
<div class="mobile-header d-block d-lg-none ">
  <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #FFFF !important;">
    <div class="d-flex justify-content-between py-2 px-3  pointer w-100">
      <button class="navbar-toggler " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight" style="border:none; box-shadow:none;">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div>
        <a routerLink="/home"><img src="../../assets/gpstore-logo.svg"></a>
      </div>
      <div class="d-flex align-items-center location-cart gap-3 ">
        <!-- <a class="nav-link"><img src="../../assets/images/map.svg" alt="map" class="img-color-change" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="mapDropdown($event)"></a> -->
        <a class="nav-link"><img src="../../assets/images/map.svg" [ngClass]="{'hidden-img': isAddCartPage}"
            [class.active]="mapDropdownOpen" alt="map" class="img-color-change" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom" (click)="mapDropdown($event)"></a>

        <!-- offcanvas -->
        <!-- <div class="modal" id="exampleModal" tabindex="-1" aria-hidden="true"  [class.show]="mapDropdownOpen" aria-labelledby="mapDropdown">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Address</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="" [class.show]="mapDropdownOpen" aria-labelledby="mapDropdown"
        (click)="$event.stopPropagation()">
        <div class="search-container map-search-container mx-auto">
          <input type="text" class="p-3 seach-input" placeholder="Search for area,place name "  [(ngModel)]="postalCode"
          (keyup.enter)="SearchAreaForDelivery(postalCode)">
          <button type="submit" (click)="SearchAreaForDelivery(postalCode)"><i class="fa fa-search"></i></button>
        </div>
        <div class="mb-2 my-3 detect-address" (click)="detectLocation()">
          <img src="../../assets/icon/detect-address.svg" alt="" class="pe-2"> Detect my current location
        </div>
        <hr>
        <div class="detect-address my-3 pointer" (click)="saveAddress('address')">
          <img src="../../assets/icon/add-address.svg" alt="" class="pe-2"> Add address
        </div>
        <hr>
        <div class="" *ngIf="Address">
          <small class="d-block address-save mb-3">Default Address</small>
          <div class="d-flex">
          <input class="mb-2 me-3 " type="radio" name="selectAddress" [(ngModel)]="selectedLocationId"
          [value]="Address.id">
          <div>
          <span class="d-block main-address mb-2">{{Address.firstname}}</span>
          <p class="address-save">{{Address.address}}</p>
          <p class="address-save">{{Address.deliveryarea}}</p>
          </div>
          </div>
        </div>
 
      </div>
      </div>
    </div>
  </div>
</div> -->

        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom"
          aria-labelledby="offcanvasBottomLabel" data-bs-backdrop="static" data-bs-keyboard="false">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="exampleModalLabel">Address</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body small">
            <div class="" aria-labelledby="mapDropdown" (click)="$event.stopPropagation()">
              <div class="search-container map-search-container mx-auto">
                <input type="text" class="p-3 seach-input" placeholder="Search for area,place name "
                  [(ngModel)]="postalCode" (keyup.enter)="SearchAreaForDelivery(postalCode)">
                <button type="submit" (click)="SearchAreaForDelivery(postalCode)"><i class="fa fa-search"></i></button>
              </div>
              <div class="mb-2 my-3 detect-address" (click)="detectLocation()">
                <img src="../../assets/icon/detect-address.svg" alt="" class="pe-2"> Detect my current location
              </div>
              <hr>
              <div class="detect-address my-3 pointer" (click)="saveAddress('address')" data-bs-dismiss="offcanvas"
                aria-label="Close">
                <img src="../../assets/icon/add-address.svg" alt="" class="pe-2"> Add address
              </div>
              <hr>
              <div class="" *ngIf="Address">
                <small class="d-block address-save mb-3">Default Address</small>
                <div class="d-flex">
                  <input class="mb-2 me-3 " type="radio" name="selectAddress" [(ngModel)]="selectedLocationId"
                    [value]="Address.id">
                  <div>
                    <span class="d-block main-address mb-2">{{Address.firstname}}</span>
                    <p class="address-save">{{Address.address}}</p>
                    <p class="address-save">{{Address.deliveryarea}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a class="nav-link position-relative"><img src="../../assets/images/add-bag.svg" alt="bag"
            [ngClass]="{'hidden-img': isAddCartPage}" [class.active]="add_bagOpen" class="img-color-change"
            (click)="add_cart()">
          <span class="notification-bag" *ngIf="!isAddCartPage && count>0">{{count}}</span>
        </a>
      </div>
    </div>
    <div class=" w-100 d-block d-lg-none px-3">
      <div class="search-container w-100 px-3">
        <input type="text" placeholder="Search grocery product or food" id="mobile-search-input"
          [(ngModel)]="searchQuery" (keyup.enter)="onSearch()">
        <button type="submit" (click)="onSearch()"><i class="fa fa-search pe-5"></i></button>
      </div>

      <!-- toggle menu -->
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel"
        data-bs-backdrop="static" data-bs-keyboard="false">
        <!-- logo & close -->
        <div class="offcanvas-header">
          <a routerLink="/home"><img src="../../assets/logo.svg"></a>
          <button type="button" class="btn-close text-reset ms-auto d-block border-rounded" data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
        </div>
        <!-- profile -->

        <li class="list-unstyled border-bottom" (click)="profiledown($event)">
          <div class="profilename px-2 py-2" *ngIf="!name" (click)="loginpage()">
            <a class=" mt-lg-1  ms-xl-2" type="button" id="grocery" aria-expanded="false"
              [ngClass]="{'active': profiledownOpen}" class="d-flex justify-content-between mobile-signin">
              <a>Signin<img
                  [src]="profileImageUrl  && profileImageUrl.trim() !== '' ? profileImageUrl : 'assets/images/profile-man.svg'"
                  alt="profile" class="ms-2" style="width: 22px;"></a>

            </a>
          </div>
          <div class="profilename px-2 p-2 mobile-signin" *ngIf="name">
            <a class=" mt-lg-1  ms-xl-2 " type="button" id="grocery" aria-expanded="false"
              [ngClass]="{'active': profiledownOpen}" class="d-flex justify-content-between">
              <a (click)="navigateProfile($event)" data-bs-dismiss="offcanvas" aria-label="Close">{{name}} <img
                  [src]="profileImageUrl  && profileImageUrl.trim() !== '' ? profileImageUrl : 'assets/images/profile-man.svg'"
                  alt="profile" class="ms-2 profile-img"></a>
              <img src="../../assets/icon/profile-arow-right.svg" alt="categories" *ngIf="!profiledownOpen"
                style="width: 6px;">
              <img src="../../assets/icon/profile-arow-down.svg" alt="categories" *ngIf="profiledownOpen"
                style="width: 10px;">
            </a>
          </div>
          <ul class="" *ngIf="profiledownOpen" aria-labelledby="grocery" (click)="$event.stopPropagation()"
            class="list-unstyled px-3" data-bs-dismiss="offcanvas" aria-label="Close">
            <li (click)="navigateToProfile('profile',$event)"><a class="dropdown-item  py-2"
                href="javascript:void(0)"><img src="../../assets/icon/profile-man.svg" alt="" class="me-2"> Profile</a>
            </li>
            <li (click)="navigateToProfile('wallet',$event)"><a class="dropdown-item py-2"
                href="javascript:void(0)"><img src="../../assets/icon/wallet.svg" alt="" class="me-2"> My
                Wallet</a></li>
            <li (click)="navigateToProfile('wishlist',$event)"><a class="dropdown-item py-2"
                href="javascript:void(0)"><img src="../../assets/icon/whishlist.svg" alt="" class="me-2">Wishlist</a>
            </li>

            <li (click)="navigateToProfile('orders',$event)"><a class="dropdown-item  py-2"
                href="javascript:void(0)"><img src="../../assets/icon/my-order.svg" class="me-2" alt="">My
                Orders</a></li>
            <li (click)="navigateToProfile('subscriptions',$event)"><a class="dropdown-item  py-2"
                href="javascript:void(0)"><img src="../../assets/icon/subscribe-products.svg" alt=""
                  class="me-2">Subscribe Products</a></li>
            <li (click)="navigateToProfile('notifications',$event)"><a class="dropdown-item  py-2"
                href="javascript:void(0)"><img src="../../assets/icon/notification.svg" alt=""
                  class="me-2">Notification</a></li>
            <li><a (click)="openSignOutModal($event)"class="dropdown-item  py-2" href="#" data-bs-toggle="modal" data-bs-target="#signoutModal"><img src="../../assets/icon/signout.svg" alt=""
                  class="me-2">Sign out</a></li>

          </ul>

        </li>
        <!-- grocery -->
        <li class="list-unstyled border-bottom" *ngFor="let card of mainCategories; let i = index">
          <div class="px-2 p-2" [ngClass]="{'active-category': grocerydownOpen === i }"
            [class.active]="isCategoryActive(i)" (click)="categorykey_click(card)">
            <a class="mt-lg-1 ms-xl-2 d-flex justify-content-between" type="button" (click)="toggleDropdown(i)">
              <span [ngClass]="{'text-white': grocerydownOpen === i, 'text-muted': grocerydownOpen !== i}">
                {{ card.name }}
              </span>
              <img *ngIf="grocerydownOpen !== i" src="../../assets/icon/right-arrow.svg" alt="categories"
                style="width: 6px;">
              <img *ngIf="grocerydownOpen === i" src="../../assets/icon/down-white-arrow.svg" alt="categories"
                style="width: 10px;">
            </a>
          </div>


          <!-- Dropdown list for subcategories -->
          <ul *ngIf="grocerydownOpen === i" class="list-unstyled px-0" data-bs-dismiss="offcanvas" aria-label="Close">
            <li *ngFor="let subcategory of subCategories"
              (click)="navigateToProductListAndCloseOffcanvas(subcategory.id, subcategory.name, subcategory.level, subcategory.parentid,subcategory.categorykey)"
              [ngClass]="{'active-subcategory': subcategory.id === activeSubcategoryId}" data-bs-dismiss="offcanvas"
              aria-label="Close">
              <a class="dropdown-item py-2 px-2">
                {{ subcategory.name }}
              </a>
            </li>
          </ul>


        </li>


        <!-- food -->

        <li class="list-unstyled border-bottom" (click)="subscribeList_page()" data-bs-dismiss="offcanvas"
          aria-label="Close">
          <div class=" px-2 p-2">
            <a class=" mt-lg-1  ms-xl-2" type="button" id="grocery" aria-expanded="false"
              class="d-flex justify-content-between">
              <a>Subscribe Products</a>
            </a>
          </div>

        </li>

        <div class="mt-2">
          <small class="text-muted px-2 p-2">Our Informations</small>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 offcanvas-body">
            <!-- <li class="nav-item">
              <a [routerLink]="['/about-us']" class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas"
                aria-label="Close" aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                routerLink="/partnerdashboard">About us</a>
            </li> -->
            <div *ngFor="let page of pagesOutput">
              <li class="nav-item"><a (click)="click_pageType(page)" class="nav-link mobile-link px-3"
                  data-bs-dismiss="offcanvas" aria-label="Close" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-current="page" href="javascript:void(0)">{{page.title}}</a></li>
            </div>
            <li class="nav-item d-none">
              <a href="javascript:void(0)" class="nav-link mobile-link px-3 pointer" data-bs-dismiss="offcanvas"
                aria-label="Close" aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page">Find
                our stores</a>
            </li>
            <!-- <li class="nav-item" *ngIf="!name">
              <a [routerLink]="['/contact-us']" class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas"
                aria-label="Close" aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                href="javascript:void(0)">Contact us</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/privacy-policy']" class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas"
                aria-label="Close" aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                href="javascript:void(0)">Privacy policy</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/terms-and-conditions']" class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas"
                aria-label="Close" aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                href="javascript:void(0)">Terms & conditions</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/cancellation-policy']" class="nav-link mobile-link px-3" data-bs-dismiss="offcanvas"
                aria-label="Close" aria-controls="navbarSupportedContent" aria-expanded="false" aria-current="page"
                href="javascript:void(0)">Cancellation Policy</a>
            </li> -->
          </ul>

          <!-- <div class="d-flex justify-content-start mobile-footer ms-3">
            <a href="javascript:void(0)">
              <img src="/assets/fb.svg" width="38px" alt="fb" class="ms-0 mt-2"></a>
            <a href="javascript:void(0)">
              <img src="/assets/instagram.svg" width="38px" alt="instagram" class="ms-2 mt-2"></a>
            <a href="javascript:void(0)">
              <img src="/assets/linkedIn.svg" width="38px" alt="linkedIn" class="ms-2 mt-2"></a>
          </div> -->
        </div>
      </div>
    </div>
  </nav>
</div>