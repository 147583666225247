<div class="fixed-margin-top">
  <div class="m-4 ">
 
    <div class="row">
 
      <div class="col-lg-3 product-list d-none d-lg-block">
 
        <div class="btn-container d-flex">
          <div class="row p-4" *ngFor="let main_category of mainCategories">
            <button type="button" class="btn col-md-6 col-sm-6 btn-category"
              (click)="Category_Section_click(main_category.id,main_category.categorykey,main_category.name)"
              [ngClass]="{'active-category': main_category.id === activeCategoryId}">
              {{ main_category.name }}
            </button>
            <!-- <div>{{main_category.id}}</div>
            <hr>
            <div>{{activeCategoryId}}</div> -->
          </div>
        </div>
 
        <!-- Grocery Section -->
        <div class="category_list">
          <div class="category-color">
            <div class="m-2">
              <!-- <h5 (click)="Category_Section_click(mainCategories[0].id,mainCategories[0].categorykey)"
                class="cursor-point mb-0 main-category-name">Category</h5> -->
              <h5 class="cursor-point mb-0 main-category-name">Category</h5>
              <div *ngIf="subCategories.length > 0">
                <hr>
                <div *ngFor="let category of subCategories; let i = index" class="">
                  <div class="category-heading p-2 ps-lg-3 d-flex justify-content-between align-items-center"
                    (click)="toggleDropdownContent($event, i, category.id, category.categorykey)"
                    [ngClass]="{'active-category': category.id === activeSubCategoryId}">
                    <h6 class="font-weight-bold mb-0 category-name">{{ category.name }} </h6>
                    <img
                      [src]="'assets/icon/' + (isDropdownContentVisible[i] ?  'list-arrow-down.svg'  : 'list-arrow-side.svg' )"
                      class="ms-4 pointer" alt="arrow">
 
                  </div>
                  <div *ngIf="isDropdownContentVisible[i]">
                    <ul class="list-unstyled test">
                      <li *ngFor="let item of subCategories_list; let j = index">
                        <div class="category-heading ps-lg-3 d-flex align-items-center"
                          [ngClass]="{ 'bg-warning': item.isOpen, 'bg-outline-warning': !item.isOpen }" (click)="onCategoryClick(item)">
                          <input class="form-check-input m-0" type="checkbox" 
                            [id]="'checkbox-' + item.id + '-' + item.id" [(ngModel)]="item.isChecked">
                          <h6 class="font-weight-bold sub-item ps-lg-3 mb-0" style="font-weight: 200;" >{{ item.name }}
                          </h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="category-color mb-3" *ngIf="this.categoryname === 'Food' || this.categoryname === 'Food'">
            <div class="d-flex justify-content-between align-items-center" [ngClass]="{'active-category': isfoodType}"
              (click)="toggleFoodType()" style="height: 2.5rem;">
              <h5 class="main-category-name  m-3">Food Type</h5>
              <img [src]="'assets/icon/' + (isDropdownContentVisible? 'list-arrow-side.svg' : 'list-arrow-down.svg')"
                class="ms-4 pointer  m-3" alt="arrow">
            </div>
            <ul class="p-0" *ngIf="isfoodType">
              <li class="list-unstyled">
                <div class="category-heading ps-lg-3 d-flex align-items-center bg-outline-warning  food-items">
                  <input type="checkbox" (change)="foodType($event, 'veg')" class="me-3 form-check-input">Veg only
                </div>
              </li>
              <li class="list-unstyled">
                <div class="category-heading ps-lg-3 d-flex align-items-center bg-outline-warning  food-items">
                  <input type="checkbox" (change)="foodType($event, 'non-veg')" class="me-3 form-check-input">Non Veg
                  Only
 
                </div>
              </li>
              <!-- <li class="list-unstyled">
                <div class="category-heading ps-lg-3 d-flex align-items-center bg-outline-warning  food-items">
                  <input type="checkbox" (change)="foodType($event, 'both')" class="me-3 form-check-input">Both
                </div>
              </li>
              <li class="list-unstyled">
                <div class="category-heading ps-lg-3 d-flex align-items-center bg-outline-warning  food-items">
                  <input type="checkbox" (change)="foodType($event, 'halal-food')" class="me-3 form-check-input">Halal
                  Food only
                </div>
              </li> -->
            </ul>
          </div>
          <div class="category-color mb-3">
            <div class="d-flex justify-content-between align-items-center"
              [ngClass]="{'active-category': isPriceVisible}" (click)="togglePriceContent()" style="height: 2.5rem;">
              <h5 class=" m-3 main-category-name">Price</h5>
              <img [src]="'assets/icon/' + (isDropdownContentVisible ? 'list-arrow-side.svg' : 'list-arrow-down.svg')"
                class="ms-4 pointer  m-3" alt="arrow">
            </div>
            <div *ngIf="isPriceVisible" class="m-2">
              <div class="rangeslider">
                <!-- Min Range Slider -->
                <input class="min" type="range" [(ngModel)]="minPrice" [min]="1" [max]="5000"
                  (input)="onRangeInputChange( true)" />
 
                <!-- Max Range Slider -->
                <input class="max" type="range" [(ngModel)]="maxPrice" [min]="1" [max]="5000"
                  (input)="onRangeInputChange( false)" />
 
                <!-- Min Value Label -->
                <!-- <span class="range_min light left pt-4">{{ (minPrice )}} </span> -->
 
                <!-- Max Value Label -->
                <!-- <span class="range_max light right pt-4">{{ (maxPrice ) }} </span> -->
              </div>
 
              <div class="row">
                <div class="col pt-4">
                  <label for="" class="price-label">Min price</label>
                  <input type="text" class="form-control" [(ngModel)]="minPrice"
                  (input)="onInputPriceChange(true)"
          (blur)="validateInput('min', minPrice, maxPrice)" />
                </div>
                <div class="col pt-4">
                  <label for="" class="price-label">Max price</label>
                  <input type="text" class="form-control" [(ngModel)]="maxPrice"
                  (input)="onInputPriceChange(false)"
                  (blur)="validateInput('max', minPrice, maxPrice)" />
                </div>
              </div>
            </div>
 
 
 
          </div>
 
 
          <div class="category-color mb-3">
            <div class="d-flex justify-content-between align-items-center"
              [ngClass]="{'active-category': isAvailability}" (click)="toggleAvailability()" style="height: 2.5rem;">
              <h5 class="m-3 main-category-name">Availability</h5>
              <img [src]="'assets/icon/' + (isDropdownContentVisible? 'list-arrow-side.svg' : 'list-arrow-down.svg')"
                class="ms-4 pointer m-3" alt="arrow">
            </div>
            <ul *ngIf="isAvailability" class="sub-item mt-2">
              <input type="checkbox" (change)="onOutOfStockChange($event)" class="me-3 form-check-input">Include Out of
              stock
            </ul>
          </div>
          <div class="category-color">
            <div class="d-flex justify-content-between align-items-center" style="height: 2.5rem;" [ngClass]="{'active-category': isOffer}" (click)="toggleOffer()">
              <h5 class="m-3 main-category-name">Offers</h5>
              <img [src]="'assets/icon/' + (isDropdownContentVisible ? 'list-arrow-side.svg' : 'list-arrow-down.svg')"
                class="ms-4 pointer m-3" alt="arrow">
            </div>
            
            <ul *ngIf="isOffer" class="sub-item mt-2 list-styles">
              <li>
                <input type="radio" name="discountRange" class="me-3 form-check-input" (change)="onDiscountChange(0, 5, $event)">
                Below 5%
              </li>
              <li>
                <input type="radio" name="discountRange" class="me-3 form-check-input" (change)="onDiscountChange(5, 10, $event)">
                5% to 10%
              </li>
              <li>
                <input type="radio" name="discountRange" class="me-3 form-check-input" (change)="onDiscountChange(10, 25, $event)">
                10% to 25%
              </li>
              <li>
                <input type="radio" name="discountRange" class="me-3 form-check-input" (change)="onDiscountChange(25, 50, $event)">
                25% to 50%
              </li>
              <li>
                <input type="radio" name="discountRange" class="me-3 form-check-input" (change)="onDiscountChange(50, 75, $event)">
                50% to 75%
              </li>
            </ul>
          </div>
          
         
        </div>
      </div>
 
      <!-- card -->
 
      <div class="col-lg-9 col product-list-card mt-4">
        <div class="row result">
          <div class="col-lg-6 col-5 result-info">
            <small>Result Found: </small>
            <small>{{this.productList.length}}</small>
          </div>
          <!-- <button class="btn btn-border d-none d-lg-block" (click)="toggleSortMenu()"
            [ngClass]="{'menu-open': isSortMenuOpen}">
            Sort by <img src="../../assets/icon/down-arrow.svg" alt="">
          </button>
          <ul *ngIf="isSortMenuOpen" class="sort-menu p-1"  (mouseleave)="closeDropdown('sortMenu')">
            <li><a href="javascript:void(0)" (click)="sortProducts('minprice', 'ASC')">Price: Low to High</a></li>
            <li><a href="javascript:void(0)" (click)="sortProducts('minprice', 'DESC')">Price: High to Low</a></li>
            <li><a href="javascript:void(0)" (click)="sortProducts('','',true)">Most Popular</a></li>
       
          </ul>
  -->
  <div class="col d-lg-block d-none ">
    <select name="" id="" class="btn btn-border float-end" (click)="toggleSortMenu()"
    [ngClass]="{'menu-open': isSortMenuOpen}">
    <option value="" class="sort-menu p-1" (click)="sortProducts('', '',false)">Sort by</option>
    <option value="" class="sort-menu p-1" (click)="sortProducts('minprice', 'ASC',false)">Price: Low to High</option>
    <option value="" class="sort-menu p-1" (click)="sortProducts('minprice', 'DESC',false)">Price: High to Low</option>
    <option value="" class="sort-menu p-1" (click)="sortProducts('','',true)">Most Popular</option>
    <option value="" class="sort-menu p-1" (click)="Discount_high_low()">Discount : High to low</option>
  </select>
  </div>    
 
 
  <div class="col-7 d-block d-lg-none p-0">
    <!-- <button class="btn btn-border-mobile me-md-5 me-1 "><img src="../../assets/icon/sort-by.svg" alt=""> Sort
by</button> -->
<!-- offsetcanvas -->
<button (click)="toggleSortMenu()" class="p-1 btn btn-border col-6 me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottoms" aria-controls="offcanvasBottoms">Sort by</button>
<div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottoms" #offcanvasRef  aria-labelledby="offcanvasBottomLabel" data-bs-backdrop="static"
data-bs-keyboard="false">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasBottomLabel">Sort by</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
 
  </div>
  <div class="offcanvas-body small">
      <div class="d-flex justify-content-between m-2">
        <label>
          Price: Low to High
        </label>
        <input type="radio" name="sort" (click)="sortProducts('minprice', 'ASC', false)" data-bs-dismiss="offcanvas"
        aria-label="Close">
      </div>
      <div class="d-flex justify-content-between m-2">
        <label>
          Price: High to Low
        </label>
        <input type="radio" name="sort" (click)="sortProducts('minprice', 'DESC', false)" data-bs-dismiss="offcanvas"
        aria-label="Close">
      </div>
      <div class="d-flex justify-content-between m-2">
        <label>
          Most Popular
        </label>
        <input type="radio" name="sort" (click)="sortProducts('', '', true)" data-bs-dismiss="offcanvas"
        aria-label="Close">
      </div>
      <div class="d-flex justify-content-between m-2">
        <label>
          Discount: High to Low
        </label>
        <input type="radio" name="sort" (click)="Discount_high_low()" data-bs-dismiss="offcanvas"
        aria-label="Close">
      </div>
  </div>
</div>
 

 
    <!-- <select name="" id="" class=" btn btn-border col-6 me-2" (click)="toggleSortMenu()"
      [ngClass]="{'menu-open': isSortMenuOpen}">
      <option value="" class="sort-menu p-1">Sort by</option>
      <option value="" class="sort-menu p-1" (click)="sortProducts('minprice', 'ASC',false)">Price: Low to High</option>
      <option value="" class="sort-menu p-1" (click)="sortProducts('minprice', 'DESC',false)">Price: High to Low
      </option>
      <option value="" class="sort-menu p-1" (click)="sortProducts('','',true)">Most Popular</option>
    </select> -->
    <button class="p-1 btn btn-border-mobile  me-md-5 col-6" (click)="toggleFilterSection()"><img
        src="../../assets/icon/filter.svg" alt=""> Filter</button>
 
  </div>
 
 
        </div>
        <hr class="hr-color">
 
        <div class="row">
          <div *ngIf="activeButton === 1">
            <div class="row">
              <div *ngFor="let product of productList" class="col-md-4 d-lg-block d-none mb-4">
                <div class="card me-md-2 me-1"f>
                  <div class="d-flex">
                    <!-- <div class="offer mx-1 px-1">{{ product.offer }}</div> -->
                    <div  *ngFor="let variation of product?.productvariations  ">
                      <span class="offer  mx-1 px-2" *ngIf="variation?.discountpercentage > 0&& variation?.productvariationid === product?.selectedVariation">
                        {{variation?.discountpercentage}}% Off
                      </span>
                    </div>

                    <!-- <div class="offer mx-1 px-2">50% off</div> -->
                    <div class="halal-offer mx-1 px-2 d-block" *ngIf="product.ishalalfood">Halal Food <img
                        src="../../assets/images/halal-food.svg" alt=""></div>
                    <div class="hover-zoom" style="cursor: pointer;" (click)="grocery_click(product.id,product.selectedVariation)">
                      <img [src]="product.src?.[0]" class="card-img card-img-mobile" alt="product">
                    </div>
                    <div class="like-border me-5 d-none d-sm-block">
                      <!-- If product is in the wishlist (heart icon) -->
                      <span *ngIf="isLoggedIn && product.wishlistIcon; else noWishListIcon" style="cursor: pointer;"
                        (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                        <img src="../../assets/images/heart-icon.svg" class="like" alt="heart-icon">
                      </span>
 
                      <!-- If product is not in the wishlist (border heart icon) -->
                      <ng-template #noWishListIcon>
                        <span style="cursor: pointer;" (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                          <img src="../../assets/images/heart-border-web.png" class="like" alt="like">
                        </span>
                      </ng-template>
 
                      <img style="cursor: pointer;" src="../../assets/images/view-border.svg" class="view" alt="view"
                        (click)="openProductDialog(product)">
                    </div>
                    <!-- for mobile -->
                    <div class="mobile-like mt-2 d-sm-none d-block">
                      <img src="../../assets/images/heartborder.svg" class="heartlike" alt="like">
                    </div>
                  </div>
                  <div class="card-body px-sm-2">
                    <h6 class="card-title my-3 text-truncate" style="cursor: pointer;" (click)="grocery_click(product.id,product.selectedVariation)">{{ product.title }}</h6>
                    <div class="card-text d-flex my-3 mx-2">
                      <!-- <p class="mx-1" >10 pcs</p> -->
                      <button *ngFor="let variation of product.productvariations" (click)="selectSize(product.id, variation)"
                        class="btn size-btn me-1 d-flex align-items-center"
                        [ngClass]="{'selected-btn': variation.productvariationid === product.selectedVariation}">
                        {{ variation.variationvalue }} {{ variation.variationshortname }}
                      </button>
 
 
                      <!-- <p class="mx-1" *ngFor="let size of product.sizes">{{ size }}</p> -->
                    </div>
                    <div class="text-center d-md-flex justify-content-md-between mt-1 mt-md-0 mx-2 mobile-off">
                      <div class="mt-md-0 ">
                        <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                        <span class="amount2 m-0"><del>{{ product.originalPrice | currency:'GBP' }}</del></span>
                      </div>
                      <div class="add-btn text-center" (click)="Add_to_cart(product.id,product.selectedVariation,1)"
                        *ngIf="!isLoggedIn || product.cartitemquantity === null || product.cartitemquantity === 0 ">
                        <a class="btn mt-1"><img src="../../assets/images/cart.svg" alt="cart" class="me-2">Add to
                          Cart</a>
                      </div>
                      <div class="d-flex justify-content-between align-items-center add-border cart"
                        *ngIf="isLoggedIn && product.cartitemquantity > 0">
                        <button class="btn p-0" (click)="decreaseQuantity(product.id, product.selectedVariation)"><img
                            src="../../assets/images/decrease_img.svg" alt="Decrease"
                            style="width: 20px; height: 20px; margin-left: 20px;"></button>
                        <span class="px-2" style="font-size: 14px; ">{{ product.cartitemquantity }}</span>
                        <button class="btn p-0" (click)="increaseQuantity(product.id,product.selectedVariation)"><img
                            src="../../assets/images/increase_img.svg" alt="Increase"
                            style="width: 20px; height: 20px; margin-right: 20px;"></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- for mobile -->
              <div *ngFor="let product of productList" class="col-12 d-lg-none d-block ">
                <div class=" me-md-2" *ngIf="showProductList">
                  <div class="row productListmobile">
                    <!-- <div class="offer mx-1 px-1">{{ product.offer }}</div> -->
                    <!-- <div class="offer mx-1 px-1">50% off</div> -->
 
                    <!-- <div class="hover-zoom" > -->
                    <div class="col-4 col-md-6 px-0">
                      <img [src]="product.src?.[0]" style="cursor: pointer;" class="card-img card-img-mobile" alt="product"
                        (click)="grocery_click(product.id,product.selectedVariation)">
                    </div>
                    <!-- <div class="like-border me-md-3 me-0 d-none d-sm-block">
                              <img src="../../assets/images/like.svg" class="like" alt="like">
                              <img src="../../assets/images/view.svg" class="view" alt="view" (click)="openProductDialog(product)">
                            </div> -->
                    <div class="col-8 col-md-6 px-0 mt-0">
                      <div class="d-flex ">
                        <h5 class="card-title mb-0" (click)="grocery_click(product.id,product.selectedVariation)">{{ product.title }}</h5>
                        <span *ngIf="isLoggedIn &&  product.wishlistIcon; else noWishListIcon"
                          (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                          <img src="../../assets/images/single-like-img.svg"  alt="heart-icon">
 
                        </span>
 
                        <!-- If product is not in the wishlist (border heart icon) -->
                        <ng-template #noWishListIcon>
                          <span (click)="toggleWishList($event, product, product.id,product.selectedVariation)">
                            <img src="../../assets/images/single-red-like-img.svg" alt="like">
                          </span>
                        </ng-template>
                      </div>
                      <div class="d-flex align-items-center mt-2 tabOffer">
                        <div  *ngFor="let variation of product?.productvariations  ">
                          <span class="offer  mx-1 px-1" *ngIf="variation?.discountpercentage > 0&& variation?.productvariationid === product?.selectedVariation">
                            {{variation?.discountpercentage}}% Off
                          </span>
                        </div>
                        <!-- <div class="offer mx-1 px-1">50% off</div> -->
                        <div class="halal-mob-offer px-2" *ngIf="product.ishalalfood">Halal Food <img
                            src="../../assets/images/halal-food.svg" alt=""></div>
                      </div>
 
                      <div class="card-text d-flex my-2">
                        <button *ngFor="let variation of product.productvariations" (click)="selectSize(product.id, variation)"
                          class="btn size-btn me-1 pt-1"
                          [ngClass]="{'selected-btn': variation.productvariationid === product.selectedVariation}">
                          {{ variation.variationvalue }} {{ variation.variationshortname }}
                        </button>
                      </div>
                      <div class="card-body row mt-3">
 
                        <div class="col-5 text-center d-md-flex justify-content-md-between mt-1 mt-md-0 ms-2">
                          <div class="mt-md-0 d-flex mobile-off">
                            <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                            <span class="amount2 text-truncate"><del>{{ product.originalPrice | currency:'GBP' }}</del></span>
                          </div>
 
                        </div>
 
                        <div class="col-7 add-btn text-center" (click)="Add_to_cart(product.id,product.selectedVariation,1)"
                          *ngIf="!isLoggedIn || product.cartitemquantity === null || product.cartitemquantity === 0 ">
                          <a class="btn "><img src="../../assets/images/cart.svg" alt="cart" class="me-2">Add to
                            Cart</a>
                        </div>
                        <div class="col-7 d-flex justify-content-between align-items-center add-border cart"
                          *ngIf="isLoggedIn && product.cartitemquantity > 0">
                          <button class="btn p-0" (click)="decreaseQuantity(product.id, product.selectedVariation)"><img
                              src="../../assets/images/decrease_img.svg" alt="Decrease"
                              style="width: 20px; height: 20px;"></button>
                          <span class="px-2" style="font-size: 14px; ">{{ product.cartitemquantity }}</span>
                          <button class="btn p-0" (click)="increaseQuantity(product.id,product.selectedVariation)"><img
                              src="../../assets/images/increase_img.svg" alt="Increase"
                              style="width: 20px; height: 20px;"></button>
                        </div>
                      </div>
                    </div>
 
                    <!-- </div> -->
 
                  </div>
                  <!-- <div class="card-body px-sm-2"> -->
 
                  <!-- <div class="text-center d-md-flex justify-content-md-between mt-1 mt-md-0 mx-2">
                              <div class="mt-md-0 ">
                                <p class="amount m-0">{{ product.price | currency:'GBP' }}</p>
                                <span class="amount2 m-0"><del>{{ product.originalPrice | currency:'GBP' }}</del></span>
                              </div>
                             
                            </div> -->
                  <!-- </div> -->
                </div>
                <hr>
              </div>
            </div>
          </div>
        </div>
 
        <div class="row">
 
          <nav aria-label="Page navigation example">
            <div *ngIf="productList.length === 0">
              <div class="text-muted text-center fs-5 full-page-center">
                Items are not available
              </div>
 
            </div>
            <ul class="pagination justify-content-center" *ngIf="totalProducts > 9">
              <li class="page-item" [class.disabled]="page === 1">
                <a class="page-link" href="javascript:void(0)" (click)="previousPage()">Previous</a>
              </li>
              <li *ngFor="let p of [].constructor(totalPages); let i = index" class="page-item" [class.active]="i + 1 === page">
                <a class="page-link" href="javascript:void(0)" (click)="goToPage(i + 1)">{{ i + 1 }}</a>
              </li>
              <li class="page-item" [class.disabled]="page === totalPages">
                <a class="page-link" href="javascript:void(0)" (click)="nextPage()">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div *ngIf="searchQuery || (sortColumn && sort)">
      <app-subscribe-product [searchQuery]="searchQuery"  [sortColumn]="sortColumn" [sort]="sort" [isMostPopular]="triggeredByMostPopular"></app-subscribe-product>
    </div>
 
    <app-play-store></app-play-store>
 
  </div>
 
  <!-- for mobile filter click -->
  <div class="filter-container d-lg-none d-block" [ngClass]="{'show-filter': showFilterSection}">
    <div class="header">
      <div class="p-3 filter_header d-flex justify-content-between align-items-center">
        <img src="../../assets/images/left_arrow.svg" alt="" (click)="toggleFilterSection()" style="cursor: pointer;">
 
        <h5 class="mb-0">Filter</h5>
        <span style="cursor: pointer;" (click)="resetAllFilter()">Reset all</span>
      </div>
    </div>
 
    <div class="section">
      <div class="btn-container row p-3 mb-3">
        <div class=" col-6" *ngFor="let main_category of mainCategories">
          <button type="button" class="btn px-3"
            (click)="Category_Section_click(main_category.id,main_category.categorykey)" style="width: 100%"
            [ngClass]="{'active-category': main_category.id === activeCategoryId}">
            {{ main_category.name }}
          </button>
        </div>
      </div>
 
      <div *ngIf="activeButton === 1" class="mt-3">
        <div class="p-2">
          <div *ngFor="let category of subCategories; let i = index" class="category-color">
            <div class="category-heading p-2 ps-lg-3 d-flex justify-content-between align-items-center"
              (click)="toggleDropdownContent($event, i, category.id, category.categorykey)"
              [ngClass]="{'active-category': category.id === activeSubCategoryId}">
              <h5 class="font-weight-bold mb-0 category-name">{{ category.name }} </h5>
              <img
                src="../../assets/icon/{{ isDropdownContentVisible[i] ? 'list-arrow-side.svg' : 'list-arrow-down.svg' }}"
                class="ms-4 pointer" alt="arrow">
            </div>
            <div *ngIf="isDropdownContentVisible[i]">
              <ul class="list-unstyled test">
                <li *ngFor="let item of subCategories_list; let j = index">
                  <div class="category-heading ps-lg-3 d-flex align-items-center"
                  [ngClass]="{ 'bg-warning': item.isOpen, 'bg-outline-warning': !item.isOpen }" (click)="onCategoryClick(item)">
                    <input class="form-check-input form-check-box" type="checkbox"
                   
                    [id]="'checkbox-' + item.id + '-' + item.id" [(ngModel)]="item.isChecked">
                    <h5 class="font-weight-bold mob-sub-item ps-lg-3 mb-0" style="font-weight: 200;">{{ item.name }}
                    </h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="category-color mb-3" *ngIf="this.categoryname === 'Food' || this.categoryname === 'Food'">
            <div class="d-flex justify-content-between align-items-center" [ngClass]="{'active-category': isfoodType}"
              (click)="toggleFoodType()" style="height: 2.5rem;">
              <h5 class="main-category-name  m-3">Food Type</h5>
              <img [src]="'assets/icon/' + (isDropdownContentVisible? 'list-arrow-side.svg' : 'list-arrow-down.svg')"
                class="ms-4 pointer  m-3" alt="arrow">
            </div>
            <ul class="p-0" *ngIf="isfoodType">
              <li class="list-unstyled">
                <div class="category-heading ps-lg-3 d-flex align-items-center bg-outline-warning  food-items">
                  <input type="checkbox" (change)="foodType($event, 'veg')" class="me-3 form-check-input">Veg only
                </div>
              </li>
              <li class="list-unstyled">
                <div class="category-heading ps-lg-3 d-flex align-items-center bg-outline-warning  food-items">
                  <input type="checkbox" (change)="foodType($event, 'non-veg')" class="me-3 form-check-input">Non Veg
                  Only
 
                </div>
              </li>
              <!-- <li class="list-unstyled">
                <div class="category-heading ps-lg-3 d-flex align-items-center bg-outline-warning  food-items">
                  <input type="checkbox" (change)="foodType($event, 'both')" class="me-3 form-check-input">Both
                </div>
              </li>
              <li class="list-unstyled">
                <div class="category-heading ps-lg-3 d-flex align-items-center bg-outline-warning  food-items">
                  <input type="checkbox" (change)="foodType($event, 'halal-food')" class="me-3 form-check-input">Halal
                  Food only
                </div>
              </li> -->
            </ul>
          </div>
          <div class="category-color mb-3">
            <div class="d-flex justify-content-between align-items-center"
              [ngClass]="{'active-category': isPriceVisible}" (click)="togglePriceContent()" style="height: 2.5rem;">
              <h5 class="m-3 main-category-name">Price</h5>
              <img [src]="'assets/icon/' + (isPriceVisible ? 'list-arrow-down.svg' : 'list-arrow-side.svg')"
                class="ms-4 pointer m-3" alt="arrow">
            </div>
         
            <div *ngIf="isPriceVisible" class="m-2">
              <div class="rangeslider">
                <!-- Min Range Slider -->
                <input class="min" type="range" [(ngModel)]="minPrice" [min]="1" [max]="5000"
                  (input)="onRangeInputChange(true)" />
         
                <!-- Max Range Slider -->
                <input class="max" type="range" [(ngModel)]="maxPrice" [min]="1" [max]="5000"
                  (input)="onRangeInputChange(false)" />
              </div>
         
              <div class="row">
                <div class="col pt-4">
                  <label for="" class="price-label">Min price</label>
                  <input type="text" class="form-control" [(ngModel)]="minPrice"
                    (input)="onInputPriceChange(true)"
                    (blur)="validateInput('min', minPrice, maxPrice)"  />
                </div>
                <div class="col pt-4">
                  <label for="" class="price-label">Max price</label>
                  <input type="text" class="form-control" [(ngModel)]="maxPrice"
                    (input)="onInputPriceChange(false)"
                    (blur)="validateInput('max', minPrice, maxPrice)"/>
                </div>
              </div>
              
            </div>
          </div>
         
         
 
          <div class="category-color mb-3">
            <div class="d-flex justify-content-between align-items-center"
              [ngClass]="{'active-category': isAvailability}" (click)="toggleAvailability()" style="height: 2.5rem;">
              <h5 class="m-3 main-category-name">Availability</h5>
              <img [src]="'assets/icon/' + (isDropdownContentVisible? 'list-arrow-side.svg' : 'list-arrow-down.svg')"
                class="ms-4 pointer m-3" alt="arrow">
            </div>
            <ul *ngIf="isAvailability" class="sub-item mt-2">
              <input type="checkbox" (change)="onOutOfStockChange($event)" class="me-3 form-check-input">Include Out of
              stock
            </ul>
          </div>
          <div class="category-color">
            <div class="d-flex justify-content-between align-items-center" style="height: 2.5rem;"
              [ngClass]="{'active-category': isOffer}" (click)="toggleOffer()">
              <h5 class="m-3 main-category-name">Offers</h5>
              <img [src]="'assets/icon/' + (isDropdownContentVisible? 'list-arrow-side.svg' : 'list-arrow-down.svg')"
                class="ms-4 pointer m-3" alt="arrow">
            </div>
            <ul *ngIf="isOffer" class="sub-item mt-2 list-styles pb-5">
              <li>
                <input type="radio" name="discountRange" class="me-3 form-check-input" (change)="onDiscountChange(0, 5, $event)">
                Below 5%
              </li>
              <li>
                <input type="radio" name="discountRange" class="me-3 form-check-input" (change)="onDiscountChange(5, 10, $event)">
                5% to 10%
              </li>
              <li>
                <input type="radio" name="discountRange" class="me-3 form-check-input" (change)="onDiscountChange(10, 25, $event)">
                10% to 25%
              </li>
              <li>
                <input type="radio" name="discountRange" class="me-3 form-check-input" (change)="onDiscountChange(25, 50, $event)">
                25% to 50%
              </li>
              <li>
                <input type="radio" name="discountRange" class="me-3 form-check-input" (change)="onDiscountChange(50, 75, $event)">
                50% to 75%
              </li>
            </ul>
            
          </div>
 
        </div>
      </div>
     
    </div>
    <div class="footer sticky-footer" *ngIf="showApplyBtn">
      <button class="btn w-100 apply_btn" (click)="toggleFilterSection()">Apply</button>
    </div>
  </div>
 
</div>
 
 
 
 
 
 
 
 