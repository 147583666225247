<section class="container">
    <div class="m-auto">
        <div class="m-4">
            <div class="text-center">
                <h4 class="oops text-center">Oops!</h4>
                <p class="error">404</p>
                <p class="not-found">Page Not Found</p>
                <p class="error-find">We Could not find the page you were looking for</p>
                <button class="btn go-back-btn" (click)="goBack()">Go Back</button>
            </div>
        </div>
    </div>
</section>
 