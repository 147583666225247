import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.css']
})
export class ContactUsPageComponent {
 ngOnInit():void{
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
        })

 }
}
