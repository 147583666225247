<div class="mt-4 add-to-cart">
    <!-- tittle -->
    <div class="d-flex justify-content-start gap-4 tittle list-unstyled mb-0 heading_cart">
        <!-- Cart section (visible when not subscribed) -->
        <a href="javascript:void(0)" *ngIf="!issubscribeProduct && !orderEmpty">
            <img src="../../assets/images/cart-active.svg" [class.d-none]="false" alt="">
            <img src="../../assets/images/cart-inactive.svg" [class.d-none]="true" alt="">
            My Cart
        </a>

        <!-- Subscription section (visible when subscribed) -->
        <a href="javascript:void(0)" *ngIf="issubscribeProduct ">
            <img src="../../assets/images/subscription-active.svg" [class.d-none]="false" alt="">
            <img src="../../assets/images/subscription-inactive.svg" [class.d-none]="true" alt="">
            My Subscription
        </a>

    </div>
    <hr class=" hr-line" *ngIf="!orderEmpty">
    <!-- count -->
    <div class="d-flex justify-content-center align-items-center card-container" *ngIf="!orderPlaced && !orderEmpty">
        <div class="step-container">
            <small class="border-rounded" [ngClass]="{'active': currentStep >= 0}">1</small>
            <label class="d-lg-block d-none">Your Cart</label>
            <label class="d-lg-none d-block text-truncate">Your Cart</label>
        </div>
        <hr class="hr-color">
        <div class="step-container">
            <small class="border-rounded" [ngClass]="{'active': currentStep >= 1}">2</small>
            <label class="d-lg-block d-none">Address</label>
            <label class="d-lg-none d-block text-truncate">Address</label>
        </div>
        <hr class="hr-color">
        <div class="step-container">
            <small class="border-rounded" [ngClass]="{'active': currentStep >= 2}">3</small>
            <label class="d-lg-block d-none">Delivery Schedule</label>
            <label class="d-lg-none d-block text-truncate">Delivery Schedule</label>
        </div>
        <hr class="hr-color">
        <div class="step-container">
            <small class="border-rounded" [ngClass]="{'active': currentStep >= 3}">4</small>
            <label class="d-lg-block d-none">Payment Process</label>
            <label class="d-lg-none d-block text-truncate">Payment Process</label>
        </div>
    </div>
    <!-- table -->
    <div class="container">
        <!-- cart-page -->
        <div class="row ">
            <!-- start -->
            <!-- product page 1st page -->
            <div class="col-md-8 col-12 d-lg-block d-none"
                *ngIf="cartPage && productPage && !orderEmpty ||issubscribeProduct ">
                <div class="d-flex justify-content-between align-items-center cart mb-2">
                    <div class="">
                        <h5>Cart</h5>
                    </div>
                    <div class="d-flex cart align-items-center" *ngIf="!issubscribeProduct">
                        <p class="m-0">Total product: {{count}}</p>
                        <!-- <button (click)="pay()">pay with link</button> -->
                        <button class="btn clear_btn" (click)="clearAllItems()">Clear all</button>
                    </div>
                </div>
                <table class="table table-responsive">
                    <thead>
                        <tr>
                            <th class="sub-width"></th>
                            <th scope="col" class="col-product product-width">Product</th>
                            <th scope="col" class="col-variant  product-width">Variant</th>
                            <th scope="col" class="col-price sub-width">Price</th>
                            <th scope="col" class="col-quantity  product-width">Quantity</th>
                            <th scope="col" class="col-subtotal  product-width">Subtotal</th>
                            <th scope="col" *ngIf="!issubscribeProduct" class="col-allow ">Allow Substitutions</th>

                        </tr>
                    </thead>
                    <tbody *ngFor="let item of cartItems">
                        <tr [ngClass]="{'no-border': item.next_delivery_slot !== null}">
                            <td class="sub-width">
                                <div class="d-flex align-items-center p-2" *ngIf="!issubscribeProduct">
                                    <input type="checkbox" name="" id="" class="form-check-input"
                                        (change)="onCheckboxChange($event, item)" style="cursor: pointer;"
                                        [checked]="item.hasChecked">
                                </div>
                            </td>

                            <td scope="row" class="col-td-product pe-0 product-width">
                                <div class="d-flex align-items-center cart" style="cursor: pointer;"
                                    (click)="productList_page(issubscribeProduct  ? item.id : (item.type === 'is_sale' ? item.productid : (item.type === 'is_combo' ? item.comboid : null)),  issubscribeProduct  ? item.selectedVariationId  : item.selectedVariation)">
                                    <div class="card p-1 me-3" style="border: transparent;">
                                        <img [src]="item.src?.[0]" alt="img" class="image-src">
                                    </div>
                                    <p class="mb-0 item-title text-truncate" data-bs-toggle="tooltip"
                                        data-bs-placement="bottom" title={{item.title}}>{{ item.title }}</p>
                                    <!-- <p class="mb-0 item-title text-truncate">{{ item.title }}</p> -->
                                </div>

                            </td>

                            <td class="px-0 product-width text-center">
                                <div class="cart px-0">
                                    <!-- For cart items (when item.productvariationid is available) -->
                                    <!-- <select *ngIf="!issubscribeProduct" class="variations"
                                    [(ngModel)]="item.productvariationid" (change)="updatePrice($event, item)"
                                    [value]="item.productvariationid">
 
                                    <option *ngFor="let variation of item.variations" [value]="variation.id">
                                        {{variation.variationvalue }} {{variation.variationshortname}}
                                    </option>
 
                                </select> -->
                                    <!-- <div *ngIf="!issubscribeProduct"> -->
                                    <!-- Iterate through all variations -->
                                    <span class="variations">
                                        {{ issubscribeProduct
                                        ? (item.selectedVariationValue + ' ' + item.selectedVariationShortname)
                                        : item.variation }}
                                    </span>


                                    <!-- </div> -->

                                    <!-- <div *ngIf="issubscribeProduct">
                                    <span class="variations">
                                        {{ item.selectedVariation?.variationvalue }} {{ item.selectedVariation?.variationshortname }}
                                    </span>
                                  </div> -->

                                    <!-- For subscription products (when issubscribeProduct is true) -->
                                    <!-- <select *ngIf="issubscribeProduct" class="variations"
                                    [(ngModel)]="SubcribeProductVariationId" (change)="updatePrice($event, item)"
                                    [value]="SubcribeProductVariationId">
 
                                    <option *ngFor="let variation of item.variations" [value]="variation.id">
                                        {{variation.variationvalue }} {{variation.variationshortname}}
                                    </option>
 
                                </select> -->
                                </div>


                            </td>
                            <td style="font-size: 14px;" class="pe-0 sub-width text-center">
                                <div class="cart selling-price">
                                    {{ issubscribeProduct
                                    ? ((item.selectedPrice || item.variations?.[0]?.sellingprice || 0) | currency:'GBP')
                                    : ((item.price || item.variations?.[0]?.price || 0) | currency:'GBP') }}
                                </div>
                            </td>



                            <td class="product-width  text sub-width">
                                <div class="d-flex align-items-center justify-content-around  add-border cart">
                                    <button class="btn p-0 border-0 " (click)="decreaseQuantity(item)"
                                        [disabled]="item.quantity <= 1"><img src="../../assets/images/decrease_img.svg"
                                            alt="Decrease" style="width: 20px; height: 20px;"></button>
                                    <span class="px-2" style="font-size: 14px;">{{ item.quantity }}</span>
                                    <button class="btn p-0 border-0 " (click)="increaseQuantity(item)"><img
                                            src="../../assets/images/increase_img.svg" alt="Increase"
                                            style="width: 20px; height: 20px;"></button>
                                </div>
                            </td>

                            <!-- subtotal for car-list -->
                            <td style="font-size: 14px;" *ngIf="!issubscribeProduct"
                                class="product-width px-0 text-center">
                                <div class="cart total-price" *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                                    {{ (item.price ||
                                    item.variations?.[0]?.price
                                    || 0) * item.quantity | currency:'GBP' }}
                                </div>
                            </td>

                            <!-- subtotal for subscribe -->

                            <td style="font-size: 14px;" *ngIf="issubscribeProduct" class="product-width">
                                <div class="cart total-price">
                                    {{ getSubTotalSubscribePrice() | currency: 'GBP' }}
                                </div>
                            </td>
                            <td *ngIf="!issubscribeProduct" class="sub-width">

                                <div class="d-flex justify-content-around align-items-center cart">
                                    <input type="checkbox" class="form-check-input" name="substitute" id="substitute"
                                        [checked]="item.allowsubstitute" (change)="onSubstituteChange($event, item)">
                                    <button class="btn p-0 ms-2" (click)="deleteCartItem(item.cartitemid,item.cartid, item.comboid)
                                    "><img src="../../assets/images/remove_btn.svg" alt="Remove"
                                            style="width: 20px; height: 20px;"></button>

                                </div>
                            </td>
                        </tr>
                        <tr class="w-100" *ngIf="item.next_delivery_slot !== null && !issubscribeProduct">
                            <td class="w-100 p-0" colspan="7">
                                <div class="d-flex" style="margin-left: 75px;">
                                    <span class="slot-dates d-flex">*Delivery Slot :<p class="date-slot"> {{
                                            item.next_delivery_slot }}</p></span>
                                </div>
                            </td>
                        </tr>


                    </tbody>
                </table>


                <div class="d-flex justify-content-between align-items-center mt-4 cart" *ngIf="!issubscribeProduct">
                    <div class="form-check ">
                        <input class="form-check-input" type="checkbox" name="allowSubstitutes" id="allowSubstitutes"
                            (change)="onMasterCheckboxChange($event)">
                        <label class="form-check-label" for="allowSubstitutes">Allow substitutes for unavailable
                            products in this order*</label>
                    </div>
                    <button class="btn btn-next px-md-4" (click)="productPage_next()"
                        [disabled]="isNextButtonDisabled">Next <img src="../../assets/images/next_rightarrow.svg"
                            alt=""></button>
                </div>
                <button class="btn btn-next px-md-4" style="float: right;" *ngIf="issubscribeProduct"
                    (click)="productPage_next()" [disabled]="isNextButtonDisabled">Next <img
                        src="../../assets/images/next_rightarrow.svg" alt=""></button>

            </div>
            <!-- for mobile -->
            <div class="col-md-8 col-12  d-lg-none d-block"
                *ngIf="cartPage &&productPage && !orderEmpty ||issubscribeProduct ">
                <div class="d-flex justify-content-between align-items-center cart">
                    <div class="">
                        <h5>&nbsp; Cart</h5>
                    </div>
                    <div class="d-flex cart align-items-center" *ngIf="!issubscribeProduct">
                        <p class="m-0">Total product:{{count}}</p>

                        <button class="btn clear_btn" (click)="clearAllItems()">Clear all</button>
                    </div>
                </div>
                <hr>

                <div *ngFor="let item of cartItems">
                    <div class="card card-details-mobile mb-3">
                        <div class="m-2">

                            <div class="d-flex justify-content-between align-items-center cart">
                                <div class="d-flex align-items-center p-2">
                                    <input type="checkbox" class="form-check-input" name="" id=""
                                        (change)="onCheckboxChange($event, item)" style="cursor: pointer;"
                                        [checked]="item.hasChecked" *ngIf="!issubscribeProduct">
                                </div>
                                <div class="d-flex" style="width: 60%;cursor: pointer;"
                                    (click)="productList_page(issubscribeProduct  ? item.id : (item.type === 'is_sale' ? item.productid : (item.type === 'is_combo' ? item.comboid : null)),  issubscribeProduct  ? item.selectedVariationId  : item.selectedVariation)">
                                    <img [src]="item.src?.[0]" alt="img" class="me-2" style="width: 50px;
                height: 50px;
                border-radius: 5px;border: 1px solid #F2F2F2;
                ">
                                    <!-- <p class="mb-0 text-truncate" style="font-size: 14px;word-break: break-all;">{{ item.title }}</p> -->
                                    <p class="mb-0 text-truncate" data-bs-toggle="tooltip" data-bs-placement="bottom"
                                        title={{item.title}} style="font-size: 14px;word-break: break-all;">{{
                                        item.title }}</p>

                                </div>

                                <!-- <select class="variations" *ngIf="!issubscribeProduct" class="variations"
                                [(ngModel)]="item.productvariationid" (change)="updatePrice($event, item)"
                                [value]="item.productvariationid">
                                <option *ngFor="let variation of item.variations" [value]="variation.id">{{
                                    variation.variationvalue }} {{
                                    variation.variationshortname }}</option>
 
                            </select>
                            <select class="variations" *ngIf="issubscribeProduct" class="variations"
                                [(ngModel)]="SubcribeProductVariationId" (change)="updatePrice($event, item)"
                                [value]="SubcribeProductVariationId">
                                <option *ngFor="let variation of item.variations" [value]="variation.id">{{
                                    variation.variationvalue }} {{
                                    variation.variationshortname }}</option>
 
                            </select> -->

                                <!-- Iterate through all variations -->
                                <span class="variations">
                                    {{ issubscribeProduct
                                    ? (item.selectedVariationValue + ' ' + item.selectedVariationShortname)
                                    : item.variation }}

                                </span>



                            </div>

                            <div class="d-flex justify-content-between align-items-center m-1" style="height: 30px;">
                                <div class="mt-1 price-amount">
                                    <p class="mb-1" style="color: #8C8C8C;"> {{ issubscribeProduct
                                        ? ((item.selectedPrice || item.variations?.[0]?.sellingprice || 0) |
                                        currency:'GBP')
                                        : ((item.price || item.variations?.[0]?.price || 0) | currency:'GBP') }}</p>
                                </div>
                                <div class="d-flex justify-content-around align-items-center gap-4">
                                    <!-- subtotal for car-list -->
                                    <div *ngIf="!issubscribeProduct">
                                        <h6 class="mb-0" *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'"> {{
                                            (item.price ||
                                            item.variations?.[0]?.price
                                            || 0) * item.quantity | currency:'GBP' }}</h6>
                                    </div>
                                    <!-- subtotal for subscribe -->

                                    <div *ngIf="issubscribeProduct">
                                        <div class="mb-0"> {{ getSubTotalSubscribePrice() | currency: 'GBP' }}</div>
                                    </div>



                                    <div class="add-border d-flex align-items-center justify-content-around">
                                        <button class="btn border-0 p-0" (click)="decreaseQuantity(item)"
                                            [disabled]="item.quantity <= 1">
                                            <img src="../../assets/images/decrease_img.svg" alt="Decrease"
                                                style="width: 18px; height: 18px;"></button>
                                        <span class="px-2" style="font-size: 15px;">{{ item.quantity }}</span>
                                        <button class="btn border-0  p-0" (click)="increaseQuantity(item)"><img
                                                src="../../assets/images/increase_img.svg" alt="Increase"
                                                style="width: 18px; height: 18px;"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div style="font-size: 14px;"> {{ (item.selectedPrice || item.variations?.[0]?.sellingprice
            || 0) * item.quantity | currency:'GBP' }}</div> -->
                        <div class="" style="height: 15px;" *ngIf="!issubscribeProduct">
                            <div class="">
                                <div class="d-flex justify-content-center" *ngIf="item.next_delivery_slot !== null">
                                    <span class="slot-dates d-flex">*Delivery Slot :<p class="date-slot px-1"> {{
                                            item.next_delivery_slot }}</p></span>
                                </div>
                            </div>
                        </div>
                        <hr class="mb-0" *ngIf="!issubscribeProduct">
                        <div class="row m-2" *ngIf="!issubscribeProduct">
                            <div class="col-6 px-0 ms-2" *ngIf="!issubscribeProduct">
                                <input type="checkbox" name="substitute" id="substitute" class="form-check-input me-2"
                                    [checked]="item.allowsubstitute" (change)="onSubstituteChange($event, item)">
                                <label for="" class="sub-card">Allow Substitutions</label>
                            </div>
                            <div class="vr px-0 ms-2"></div>
                            <div class="col-5 px-0 ms-2 text-center"
                                (click)="deleteCartItem(item.cartitemid,item.cartid,item.comboid)">
                                <button class="btn text-center p-1" style="margin-top: -5px;"><img
                                        src="../../assets/images/cancel.svg" alt=""></button>

                                <label for="" class="text-center" style="font-size: 15px;">Cancel</label>
                            </div>

                        </div>
                        <!-- <hr> -->
                    </div>
                </div>
                <div class="form-check m-1" *ngIf="!issubscribeProduct">
                    <input class="form-check-input" type="checkbox" name="allowSubstitutes" id="allowSubstitutes"
                        (change)="onMasterCheckboxChange($event)">
                    <label class="form-check-label" for="allowSubstitutes">Allow substitutes for unavailable
                        products in this order*</label>
                </div>

                <!-- <div class="m-2 next_btn_container footer">
<button class="btn btn-next" (click)="productPage_next()">Next<img src="../../assets/images/next_rightarrow.svg" alt=""></button>
</div> -->
                <div class="sticky-footer " *ngIf="!orderEmpty">
                    <div class="total-info">
                        <label class="name">Total</label>

                        <span class="amount ms-4" *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                            {{ getTotalPriceAfterWalletDeduction() | currency: 'GBP' }}
                        </span>
                        <span class="amount ms-4" *ngIf="dataFromPreviousComponent === 'SubcribeProduct'">
                            {{ getTotalSubscribePriceAfterWalletDeduction() | currency: 'GBP' }}
                        </span>
                        <img src="../../assets/alert-icon.svg" alt="alert-icon" width="20px" height="20px"
                            class="mt-md-1 m-3 scroll-img-card" (click)="scrollToDiv('cart-summary')">
                    </div>
                    <button class="btn btn-next" (click)="productPage_next()" [disabled]="isNextButtonDisabled">Next
                        <img src="../../assets/images/next_rightarrow.svg" alt="">
                    </button>
                </div>
            </div>
            <!-- subscription-cart-page -->
            <!-- subscription delivery schedule -->
            <div class="col-md-8 col-12 d-lg-block d-none" *ngIf="issubscribePage">
                <div>
                    <h5><img src="../../assets/images/left_arrow.svg" alt=""
                            (click)="toggleCardSubcribeDelivery()">&nbsp; Delivery Schedule</h5>
                </div>
                <hr>
                <div class="container mb-2">
                    <div>
                        <p>Select schedule</p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="card schedule" [class.active]="selectedSchedule === 'Daily'">
                            <div class="card-title my-1">
                                <input type="radio" name="schedule" id="daily" [(ngModel)]="selectedSchedule"
                                    (ngModelChange)="calculateDeliveryDays()" value="DAILY" />
                                <label for="daily">Daily</label>
                            </div>
                        </div>
                        <div class="card schedule" [class.active]="selectedSchedule === 'Alternate'">
                            <div class="card-title my-1">
                                <input type="radio" name="schedule" id="alternate" [(ngModel)]="selectedSchedule"
                                    (ngModelChange)="calculateDeliveryDays()" value="ALTERNATE" />
                                <label for="alternate">Alternate</label>
                            </div>
                        </div>
                        <div class="card schedule" [class.active]="selectedSchedule === 'Every 3 days'">
                            <div class="card-title my-1">
                                <input type="radio" name="schedule" id="every-3-days" [(ngModel)]="selectedSchedule"
                                    (ngModelChange)="calculateDeliveryDays()" value="EVERY_3_DAYS" />
                                <label for="every-3-days">Every 3 days</label>
                            </div>
                        </div>
                        <div class="card schedule" [class.active]="selectedSchedule === 'Weekly'">
                            <div class="card-title my-1">
                                <input type="radio" name="schedule" id="weekly" [(ngModel)]="selectedSchedule"
                                    (ngModelChange)="calculateDeliveryDays()" value="WEEKLY" />
                                <label for="weekly">Weekly</label>
                            </div>
                        </div>
                        <div class="card schedule" [class.active]="selectedSchedule === 'Monthly'">
                            <div class="card-title my-1">
                                <input type="radio" name="schedule" id="monthly" [(ngModel)]="selectedSchedule"
                                    (ngModelChange)="calculateDeliveryDays()" value="MONTHLY" />
                                <label for="monthly">Monthly</label>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="mt-3">
                        <p>Subscription starts on</p>
                        <div>
                            <input type="date" id="start-date" class="form-control w-100" [(ngModel)]="startDate"
                                (change)="onStartDateChange()">
                        </div>
                    </div>
                    <div class="mt-3 mb-2">
                        <p>Subscription Ends on</p>
                        <div>
                            <input type="date" id="end-date" class="form-control w-100" [(ngModel)]="endDate"
                                [min]="minEndDate" (change)="onEndDateChange()">
                        </div>
                    </div> -->
                    <mat-form-field appearance="outline" class="mt-4">
                        <mat-label>Subscription starts on</mat-label>
                        <input matInput [matDatepicker]="picker1" [(ngModel)]="startDate" (dateChange)="onDateChange()"
                            [min]="currentDate">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 [dateClass]="dateClass"></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="mt-3">
                        <mat-label>Subscription ends on</mat-label>
                        <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate" (dateChange)="onDateChange()"
                            [min]="startDate">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 [dateClass]="dateClass"></mat-datepicker>
                    </mat-form-field>


                    <hr>
                    <div class="mt-2">
                        <div>
                            <p>Add delivery instructions</p>
                        </div>
                        <div>
                            <input type="text" class="instructions" [(ngModel)]="deliveryInstruction">
                        </div>
                    </div>
                    <div class="mt-2 mb-5">
                        <button class="btn next_btn" [disabled]="!isSelectScheduleFormValid()"
                            (click)="subscriptionDeliverySchedule()">Next <img
                                src="../../assets/images/next_rightarrow.svg" alt=""></button>
                    </div>
                </div>

            </div>
            <!-- for mobile -->
            <div class="col-md-8 col-12 d-lg-none d-block" *ngIf="issubscribePage">
                <div>
                    <h5><img src="../../assets/images/left_arrow.svg" alt=""
                            (click)="toggleCardSubcribeDelivery()">&nbsp; Delivery Schedule</h5>
                </div>
                <hr>
                <div class="container mb-2">
                    <div>
                        <p>Select schedule</p>
                    </div>
                    <div class="row m-1">
                        <div class="col-6 mb-2">
                            <div class="card schedule" [class.active]="selectedSchedule === 'Daily'">
                                <div class="card-title my-1">
                                    <input type="radio" name="schedules" id="" [(ngModel)]="selectedSchedule"
                                        (ngModelChange)="calculateDeliveryDays()" value="DAILY">
                                    <label for="">Daily</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <div class="card schedule" [class.active]="selectedSchedule === 'Alternate'">
                                <div class="card-title my-1">
                                    <input type="radio" name="schedules" id="" [(ngModel)]="selectedSchedule"
                                        (ngModelChange)="calculateDeliveryDays()" value="ALTERNATE">
                                    <label for="">Alternate</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <div class="card schedule" [class.active]="selectedSchedule === 'Every 3 days'">
                                <div class="card-title my-1">
                                    <input type="radio" name="schedules" id="" [(ngModel)]="selectedSchedule"
                                        (ngModelChange)="calculateDeliveryDays()" value="EVERY_3_DAYS">
                                    <label for="">Every 3 days</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <div class="card schedule" [class.active]="selectedSchedule === 'Weekly'">
                                <div class="card-title my-1">
                                    <input type="radio" name="schedules" id="" [(ngModel)]="selectedSchedule"
                                        (ngModelChange)="calculateDeliveryDays()" value="WEEKLY">
                                    <label for="">Weekly</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 mb-2">
                            <div class="card schedule" [class.active]="selectedSchedule === 'Monthly'">
                                <div class="card-title my-1">
                                    <input type="radio" name="schedules" id="" [(ngModel)]="selectedSchedule"
                                        (ngModelChange)="calculateDeliveryDays()" value="MONTHLY">
                                    <label for="">Monthly</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="mt-3">
                        <p>Subscription starts on</p>
                        <div>
                            <input type="date" name="" id="" class="form-control w-100" [(ngModel)]="startDate"
                                [min]="currentDate" (change)="onStartDateChange()">
                        </div>
                    </div>
                    <div class="mt-3 mb-2">
                        <p>Subscription Ends on</p>
                        <div>
                            <input type="date" name="" id="" class="form-control w-100" [(ngModel)]="endDate"
                                [min]="minEndDate" (change)="onEndDateChange()">
                        </div>
                    </div>  -->
                    <mat-form-field appearance="outline" class="mt-4">
                        <mat-label>Subscription starts on</mat-label>
                        <input matInput [matDatepicker]="picker1" [(ngModel)]="startDate" (dateChange)="onDateChange()"
                            [min]="currentDate">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 [dateClass]="dateClass"></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="mt-3">
                        <mat-label>Subscription ends on</mat-label>
                        <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate" (dateChange)="onDateChange()"
                            [min]="startDate">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 [dateClass]="dateClass"></mat-datepicker>
                    </mat-form-field>
                    <hr>
                    <div class="mt-2">
                        <div>
                            <p>Add delivery instructions</p>
                        </div>
                        <div>
                            <input type="text" class="instructions">
                        </div>
                    </div>
                    <!-- <div class="mt-2">
                        <button class="btn next_btn" [disabled]="!isSelectScheduleFormValid()" (click)="subscriptionDeliverySchedule()">Next sub2<img src="../../assets/images/next_rightarrow.svg"
                                alt=""></button>
                    </div> -->
                </div>
                <div class="sticky-footer  d-lg-none d-block d-flex " *ngIf="!orderEmpty">
                    <div class="total-info">
                        <label class="name">Total</label>
                        <span class="amount ms-4" *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                            {{ getTotalPriceAfterWalletDeduction() | currency: 'GBP' }}
                        </span>
                        <span class="amount ms-4" *ngIf="dataFromPreviousComponent === 'SubcribeProduct'">
                            {{ getTotalSubscribePriceAfterWalletDeduction() | currency: 'GBP' }}
                        </span>
                        <img src="../../assets/alert-icon.svg" alt="alert-icon" width="20px" height="20px"
                            class="mt-md-1 m-3 scroll-img-card" (click)="scrollToDiv('cart-summary')">
                    </div>
                    <button class="btn next_del_btn " [disabled]="!isSelectScheduleFormValid()"
                        (click)="subscriptionDeliverySchedule()">Next
                        <img src="../../assets/images/next_rightarrow.svg" alt="">
                    </button>
                </div>
            </div>
            <!-- subscription delivery schedule end -->
            <!-- deliver address 2nd page -->
            <div class="col-md-8 col-12 " *ngIf="cartPage &&deliverAddress&& !issubscribeProduct">
                <div class="d-flex justify-content-between align-items-center">
                    <h5><img src="../../assets/images/left_arrow.svg" alt="" (click)="toggleCardDetails()"
                            style="cursor: pointer;"> Delivery address</h5>
                    <button class="btn add-btn" data-bs-toggle="modal" data-bs-target="#addAddressModal"
                        (click)="initMap(); resetAddressForm()">
                        <img src="../../assets/images/plus.svg" alt=""> Add address
                    </button>


                </div>
                <ul class="mt-2 p-0" *ngFor="let address of UserAddressList">
                    <li class="list-unstyled border-top p-2">
                        <div class="d-flex">
                            <input type="radio" name="selectedAddress" [(ngModel)]="selectedAddressId"
                                [value]="address.id">

                            <label class="delivery_details">
                                {{address.firstname}}
                                <!-- Conditionally display phone if not null or empty -->
                                <span *ngIf="address.phone"> | {{address.phone}}</span>
                                <!-- Conditionally display email if not null or empty -->
                                <span *ngIf="address.email"> | {{address.email}}</span>
                            </label>
                            <small class="work p-1">{{address.type}}</small>
                        </div>
                        <p class="delivery_address">{{address.deliveryarea}}</p>
                    </li>
                </ul>

                <div class="d-flex justify-content-between align-items-center">
                    <div class="mt-2 mb-5 details" (click)="detectLocation()">
                        <img src="../../assets/icon/detect-address.svg" alt="" class="pe-2"> <span>Detect my current
                            location</span>
                    </div>
                    <div class="mt-2 mb-5 d-lg-block d-none btn_container">
                        <button class="btn next_del_btn" (click)="deliverAddress_next()"
                            [disabled]="!selectedAddressId">Next <img src="../../assets/images/next_rightarrow.svg"
                                alt=""></button>
                    </div>
                    <!-- <div class=" d-lg-none d-block btn_container">
<button class="btn next_del_btn " (click)="deliverAddress_next()">Next <img src="../../assets/images/next_rightarrow.svg"
    alt=""></button>
</div> -->
                    <div class="sticky-footer  d-lg-none d-block d-flex " *ngIf="!orderEmpty">
                        <div class="total-info">
                            <label class="name">Total</label>
                            <span class="amount ms-4" *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                                {{ getTotalPriceAfterWalletDeduction() | currency: 'GBP' }}
                            </span>
                            <span class="amount ms-4" *ngIf="dataFromPreviousComponent === 'SubcribeProduct'">
                                {{ getTotalSubscribePriceAfterWalletDeduction() | currency: 'GBP' }}
                            </span>
                            <img src="../../assets/alert-icon.svg" alt="alert-icon" width="20px" height="20px"
                                class="mt-md-1 m-3 scroll-img-card" (click)="scrollToDiv('cart-summary')">
                        </div>
                        <button class="btn next_del_btn " (click)="deliverAddress_next()"
                            [disabled]="!selectedAddressId">Next
                            <img src="../../assets/images/next_rightarrow.svg" alt="">
                        </button>
                    </div>
                </div>
            </div>
            <!--  Address popup -->
            <div class="modal fade" id="addAddressModal" tabindex="-1" aria-labelledby="addAddressModalLabel"
                data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true"
                (shown.bs.modal)="resetToFirstStep()">
                <div class="modal-dialog" style="margin-top: 10vh;">
                    <div class="modal-content p-4">
                        <div class="modal-header p-0">
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="modal"
                                aria-label="Close" (click)="handleModalClose()"></button>
                        </div>
                        <div class="modal-body pb-0">
                            <div *ngIf="isFirstStep">
                                <!-- <form> -->
                                <div class="mb-3">
                                    <!-- start -->
                                    <div class="map-container" *ngIf="isMapVisible">
                                        <div id="map-content" class=" position-relative">
                                            <input id="pac-input" type="text" placeholder="Search for places..."
                                                class="controls pac-target-input  position-absolute" autocomplete="off"
                                                [ngClass]="{'pac-container': isFirstStep}" [value]="selectedAddress" />
                                            <div id="map"></div>
                                        </div>
                                        <div class="place-holder">
                                            <label for="" class="form-label">Delivery area</label>
                                            <input type="text" class="form-control delivery-area "
                                                placeholder="Sagotharar St, Chetti Kulam, Nagercoil, Tamil Nadu."
                                                [value]="selectedAddress" readonly>
                                        </div>
                                    </div>
                                    <!--end  -->
                                </div>

                                <!-- </form> -->
                            </div>
                            <div *ngIf="!isFirstStep">
                                <div class="row mb-3">
                                    <div class="col-lg-6 col-12">
                                        <label for="" class="form-label">First name <span class="mandatory">*</span>
                                        </label>
                                        <input type="text" class="form-control" placeholder="Enter your First name"
                                            [(ngModel)]="firstName" (keypress)="validateInput($event)">
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <label for="" class="form-label">Last name</label>
                                        <input type="text" class="form-control" placeholder="Enter your Last name"
                                            [(ngModel)]="lastName" (keypress)="validateInput($event)">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-lg-6 col-12">
                                        <label for="" class="form-label">Mobile number</label>
                                        <ngx-intl-tel-input class="d-block w-100 phone"
                                            [preferredCountries]="[CountryISO.India, CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                            [searchCountryFlag]="true"
                                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                            [selectedCountryISO]="selectedCountryISO" [(ngModel)]="phone">
                                        </ngx-intl-tel-input>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <label for="" class="form-label">Email id</label>
                                        <input type="text" class="form-control" placeholder="Enter your Email id"
                                            [(ngModel)]="email">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <label for="" class="form-label">Delivery area</label>
                                        <!-- <div class="position-relative">
                                <input type="text" class="form-control"
                                    placeholder="Sagotharar St, Chetti Kulam, Nagercoil, Tamil Nadu."
                                    [ngModel]="isEditMode ? deliveryArea : selectedAddress"
                                    (ngModelChange)="isEditMode ? deliveryArea = $event : selectedAddress = $event"
                                    [readonly]="!isEditable">
                                <a href="javascript:void(0)" class="absolute-link"
                                    (click)="enableEdit()">Change</a>
                            </div> -->

                                        <div class="position-relative textarea-container">
                                            <textarea #textarea maxlength="200" class="auto-expand" id="deliveryArea"
                                                placeholder="Delivery area"
                                                [ngModel]="isEditMode ? deliveryArea : selectedAddress"
                                                (ngModelChange)="isEditMode ? deliveryArea = $event : selectedAddress = $event"
                                                readOnly appAutoResize>
                                </textarea>
                                            <a href="javascript:void(0)" class="absolute-link"
                                                (click)="enableEdit()">Change</a>
                                        </div>
                                    </div>

                                </div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <label for="" class="form-label">Address <span class="mandatory">*</span>
                                        </label>
                                        <textarea name="" id="" class="form-control" rows="3"
                                            [(ngModel)]="address"></textarea>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-lg-6 col-12">
                                        <label for="" class="form-label">Floor/Door no (optional)</label>
                                        <input type="text" class="form-control" [(ngModel)]="doorNo">
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <label for="" class="form-label">Landmark (optional)</label>
                                        <input type="text" class="form-control" [(ngModel)]="landmark">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-lg-6 col-12">
                                        <label for="" class="form-label">Postal code <span class="mandatory">*</span>
                                        </label>
                                        <input type="text" placeholder="Enter your Postal code" class="form-control"
                                            [(ngModel)]="postalCode">
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <label for="" class="form-label">Address type <span class="mandatory">*</span>
                                        </label>
                                        <div class="row">
                                            <div class="col-4">
                                                <button class="btn address-type-btn" (click)="setAddressType('home')"
                                                    [ngClass]="{'selected-address': selectedAddressType === 'home'}">Home</button>
                                            </div>
                                            <div class="col-4">
                                                <button class="btn address-type-btn" (click)="setAddressType('office')"
                                                    [ngClass]="{'selected-address': selectedAddressType === 'office'}">Office</button>
                                            </div>
                                            <div class="col-4">
                                                <button class="btn address-type-btn" (click)="setAddressType('others')"
                                                    [ngClass]="{'selected-address': selectedAddressType === 'others'}">Others</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer p-0">
                            <button *ngIf="isFirstStep" type="button" class="btn btn-popup-next m-auto"
                                (click)="goToNextStep()"
                                [disabled]="!selectedAddress || selectedAddress.trim() === ''">Next</button>
                            <button data-bs-dismiss="modal" aria-label="Close" *ngIf="!isFirstStep"
                                class="btn next-save-btn btn-close" (click)="saveAddress()"
                                [disabled]="!isFormValid()">Save
                                Address</button>

                        </div>
                    </div>
                </div>
            </div>
            <!-- end -->
            <!-- delivery schedule 3rd page-->
            <div class="col-md-8 col-12 d-lg-block d-none" *ngIf="cartPage &&deliverySchedule&& !issubscribeProduct">
                <div>
                    <h5><img src="../../assets/images/left_arrow.svg" alt="" (click)="toggleScheduleDetails()"
                            style="cursor: pointer;">&nbsp; Delivery Schedule</h5>
                </div>
                <hr>
                <div class="container">
                    <div class="d-flex gap-3 m-4">
                        <div class="card selecttime" *ngFor="let deliverytimelist of DeliveryTimesLotsList"
                            (click)="selectDay(deliverytimelist)" style="cursor: pointer;">
                            <div class="card-body days" [ngClass]="{'active-day': deliverytimelist === selectedDay}">
                                <h6 class="card-title">{{deliverytimelist.day_name}}</h6>
                                <p class="card-text">{{deliverytimelist.day_date}}{{deliverytimelist.id}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row m-2" *ngIf="selectedSlots && selectedSlots.length > 0">
                        <div *ngFor="let slot of selectedSlots" class="col-3 mb-3 p-0 d-flex align-items-center"
                            style="margin-left: 16px;">
                            <!-- Display radio button even if slot timerange is null -->
                            <input type="radio" name="deliverySlots" [value]="slot.id" [(ngModel)]="selectedSlotId">

                            <!-- If the slot timerange is null, show a default message -->
                            <label style="font-size: 14px;">{{ slot.timerange ? slot.timerange : 'No available time
                                slot' }}</label>
                        </div>
                    </div>


                </div>
                <hr>
                <div class="delivery_container">
                    <div>
                        <label for="">Add delivery instructions</label>
                    </div>
                    <div>
                        <textarea class="form-control" rows="3" [(ngModel)]="deliveryInstruction"></textarea>
                    </div>
                </div>
                <div class="mt-2 mb-5">
                    <button class="btn next_btn" (click)="deliverySchedule_next()" [disabled]="!selectedSlotId">Next
                        <img src="../../assets/images/next_rightarrow.svg" alt=""></button>
                </div>
            </div>
            <!-- for mobile -->
            <div class="col-md-8 col-12 d-lg-none d-block" *ngIf="cartPage &&deliverySchedule&& !issubscribeProduct">
                <div>
                    <h5><img src="../../assets/images/left_arrow.svg" alt="" (click)="toggleScheduleDetails()"
                            style="cursor: pointer;">&nbsp; Delivery Schedule</h5>

                    <!-- <h5><img src="../../assets/images/left_arrow.svg" alt="" class="d-lg-none d-block" (click)="toggleScheduleDetails()" >&nbsp; Delivery
Schedule</h5> -->

                </div>
                <hr>
                <div class="container">
                    <div class="row">
                        <div class="col-3 mb-3" *ngFor="let deliverytimelist of DeliveryTimesLotsList">
                            <div class="card selecttime" (click)="selectDay(deliverytimelist)" style="cursor: pointer;">
                                <div class="card-body days"
                                    [ngClass]="{'active-day': deliverytimelist === selectedDay}">
                                    <h6 class="card-title text-center">{{deliverytimelist.day_name}}</h6>
                                    <p class="card-text mobile_card">{{deliverytimelist.day_date}}</p>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="d-flex align-items-center mt-3 row" *ngIf="selectedSlots && selectedSlots.length > 0">
                        <div *ngFor="let slot of selectedSlots" class="col-6 mb-2 p-0 d-flex">
                            <input type="radio" name="deliverySlot" [value]="slot.id" [(ngModel)]="selectedSlotId"
                                *ngIf="slot.timerange">
                            <label for="" class="time">{{ slot.timerange ? slot.timerange : 'No available time slot'
                                }}</label>
                        </div>

                    </div>

                    <hr>
                    <div class="delivery_container">
                        <div>
                            <label for="">Add delivery instructions</label>
                        </div>
                        <div>
                            <textarea [(ngModel)]="deliveryInstruction" rows="3"></textarea>
                        </div>
                    </div>
                    <!-- <div class=" next_btn_container footer">
<button class="btn next_btn" (click)="deliverySchedule_next()">Next <img src="../../assets/images/next_rightarrow.svg"
alt=""></button> -->
                    <div class="sticky-footer" *ngIf="!orderEmpty">
                        <div class="total-info">
                            <label class="name">Total</label>
                            <span class="amount ms-4" *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                                {{ getTotalPriceAfterWalletDeduction() | currency: 'GBP' }}
                            </span>
                            <span class="amount ms-4" *ngIf="dataFromPreviousComponent === 'SubcribeProduct'">
                                {{ getTotalSubscribePriceAfterWalletDeduction() | currency: 'GBP' }}
                            </span>
                            <img src="../../assets/alert-icon.svg" alt="alert-icon" width="20px" height="20px"
                                class="mt-md-1 m-3 scroll-img-card" (click)="scrollToDiv('cart-summary')">
                        </div>
                        <button class="btn btn-next" (click)="deliverySchedule_next()" [disabled]="!selectedSlotId">Next
                            <img src="../../assets/images/next_rightarrow.svg" alt="">
                        </button>
                    </div>
                </div>
            </div>
            <!-- payment process 4th page -->
            <div class="col-md-8 col-12" *ngIf="cartPage &&paymentProcess && paymentActive&& !issubscribeProduct">
                <div>
                    <h5><img src="../../assets/images/left_arrow.svg" alt="" (click)="togglePaymentDetails()"
                            style="cursor: pointer;"> Payment process </h5>

                </div>
                <hr>

                <!-- Wallet Option -->
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <input type="checkbox" id="wallet" (change)="toggleSelection('wallet')"
                            [checked]="selectedPaymentMethod.includes('wallet')"
                            [disabled]="WalletBalanceAmount === 0 || selectedPaymentMethod.includes('cod')">

                        <label for="wallet">Wallet</label>
                    </div>
                    <div>
                        <div>
                            <span
                                style="color: #8C8C8C;font-family: 'BaiJamjuree';font-size: 16px;font-weight: 500;text-align: left; margin-right: 15px;">Available
                                Balance</span>
                            <span
                                style="font-family: 'BaiJamjuree'; font-size: 17px; font-weight: 700;text-align: left;color: #000000; ">$
                                {{ WalletBalanceAmount.toFixed(2) }}
                            </span>
                        </div>
                    </div>
                </div>

                <hr>

                <!-- Card Payment Option -->
                <div class="card_payment_container">
                    <div>
                        <input type="radio" id="cardPayment" name="paymentMethod" (change)="toggleSelection('card')"
                            [checked]="selectedPaymentMethod.includes('card')">
                        <label for="cardPayment">Card Payment</label>
                    </div>
                    <div class="card_payment_content m-4" *ngIf="selectedPaymentMethod.includes('card')">
                        <!-- Card Payment Content -->
                        <div class="card_details">
                            <label>Saved Card details</label>
                            <div class="card mt-2" *ngFor="let card of userCardsList">
                                <div class="card-body d-flex align-items-center justify-content-between">
                                    <input type="radio" name="savedCard" class="me-3" [(ngModel)]="selectedSavedCard"
                                        [value]="card" (change)="onSavedCardSelect(card)">
                                    <div class="d-block">
                                        <span>{{card.cardholdername}}</span>

                                    </div>
                                    <div>XXXX XXXX XXXX {{ card.cardnumber.slice(-4) }}</div>

                                    <!-- <div>
                <img src="../../assets/images/visa_bank.svg" alt="">
            </div> -->
                                </div>
                            </div>
                        </div>


                        <div class="newcard_details mt-4">
                            <input type="radio" name="savedCard" class="me-3" (click)="enableNewCard()">

                            <label>Pay with new card</label>
                            <div class="card mt-2" style="height: 230px;"
                                [ngClass]="{ 'disabled-card': isCardDisabled }">

                                <div class="card-body">
                                    <div>
                                        <label for="cardHolderName" class="form-label">Card Holder name</label>
                                        <input type="text" id="cardHolderName" class="form-control content"
                                            placeholder="Card holder name" [(ngModel)]="cardHolderName">
                                    </div>

                                    <div class="input-with-icon mt-2">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label for="cardDetails" class="form-label">Card details</label>
                                            <div class="row">
                                                <img src="../../assets/images/visa_bank.svg" alt="" id="cardDetailsImg"
                                                    class="col-4">
                                                <img src="../../assets/images/mastercard-img.svg" alt="" class="col-4">
                                                <img src="../../assets/images/discover-img.svg" alt="" class="col-4">
                                            </div>
                                        </div>
                                        <div class="input-container">
                                            <!-- <input type="text" id="cardDetails" class="form-control content"
                        placeholder="1234 1234 1234 9876"> -->
                                            <div class="payment-container">
                                                <div id="card-element"></div>
                                                <div *ngIf="cardErrors" class="error">{{ cardErrors }}</div>

                                            </div>

                                        </div>
                                    </div>
                                    <!--  
            <div class="row mt-2 mb-2">
                <div class="col">
                    <label for="expiryDate" class="form-label">Expiry date</label>
                    <input type="date" id="expiryDate" class="form-control content">
                </div>
                <div class="col cvc-input-container">
                    <label for="cvc" class="form-label">CVC</label>
                    <input type="text" id="cvc" class="form-control content">
                    <img src="../../assets/images/cvc_number_img.svg" alt="">
                </div>
            </div> -->
                                </div>
                            </div>
                            <div class="form-check mt-4">
                                <input type="checkbox" id="saveCardDetails" class="form-check-input"
                                    [(ngModel)]="saveCardDetails">
                                <label for="saveCardDetails" class="form-check-label">Save your card details for later
                                    payment</label>
                            </div>
                        </div>

                    </div>
                </div>

                <hr>

                <!-- Pay with Link Option -->
                <div>
                    <input type="radio" id="linkPayment" name="paymentMethod" (change)="toggleSelection('link')"
                        [checked]="selectedPaymentMethod.includes('link')">
                    <label for="linkPayment">Pay with link</label>
                </div>

                <hr>

                <!-- Cash on Delivery Option -->
                <div class="mb-2" *ngIf="!cashOnDeliveryVisible">
                    <input type="radio" id="cashPayment" name="paymentMethod" (change)="toggleSelection('cod')"
                        [checked]="selectedPaymentMethod.includes('cod')"
                        [disabled]="selectedPaymentMethod.includes('wallet')">
                    <label for="cashPayment">Cash on delivery</label>
                </div>


            </div>

            <!--  Cart total side card page-->
            <div class="col-md-4 col-12" *ngIf="!orderPlaced && !orderEmpty">
                <div id="cart-summary" class="card card-border mb-1">

                    <div class="card-body">
                        <h5 class="card-title">Cart total</h5>
                        <div class="card-text">
                            <div class="d-flex justify-content-between align-items-center cart"
                                *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                                <label for="">No of products</label>
                                <span>{{ add_to_cart_count() }}</span>
                            </div>
                            <hr *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                            <div class="d-flex justify-content-between align-items-center cart">
                                <label for="">MRP Total</label>
                                <span *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                                    {{ getMrpPrice() | currency: 'GBP' }}
                                </span>
                                <span *ngIf="dataFromPreviousComponent === 'SubcribeProduct'">
                                    {{ getMrpSubscribePrice() | currency: 'GBP' }}
                                </span>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-between align-items-center cart">
                                <label for="">Subtotal</label>
                                <span *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                                    {{ getSubTotalPrice() | currency: 'GBP' }}
                                </span>
                                <span *ngIf="dataFromPreviousComponent === 'SubcribeProduct'">
                                    {{ getSubTotalSubscribePrice() | currency: 'GBP' }}
                                </span>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-between align-items-center cart saved-cart">
                                <label for="">Saved</label>
                                <span *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                                    {{ getPriceDifference() | currency: 'GBP' }}
                                </span>
                                <span *ngIf="dataFromPreviousComponent === 'SubcribeProduct'">
                                    {{ getSubscribePriceDifference() | currency: 'GBP' }}
                                </span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center cart saved-cart"
                                *ngIf="selectedPaymentMethod.includes('wallet')">
                                <label for="">Wallet Balance</label>
                                <span>- {{ WalletBalanceAmount | currency: 'GBP' }}</span>
                            </div>
                            <div class="d-flex justify-content-between align-items-center cart mt-1 total-cart">
                                <label for="">Total</label>
                                <span *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                                    {{ getTotalPriceAfterWalletDeduction() | currency: 'GBP' }}
                                </span>
                                <span *ngIf="dataFromPreviousComponent === 'SubcribeProduct'">
                                    {{ getTotalSubscribePriceAfterWalletDeduction() | currency: 'GBP' }}
                                </span>
                            </div>
                        </div>
                        <div class="align-items-center mt-2 d-lg-block d-none" *ngIf="!orderEmpty">
                            <button class="btn order-btn" (click)="placeOrder()" [disabled]="!isPlaceOrderEnabled">
                                Place order
                            </button>
                        </div>
                        <div *ngIf="placeOrderList">

                            <div class="sticky-footer d-lg-none d-block d-flex">
                                <div class="total-info">
                                    <label class="name">Total</label>
                                    <span class="amount ms-4" *ngIf="dataFromPreviousComponent !== 'SubcribeProduct'">
                                        {{ getTotalPriceAfterWalletDeduction() | currency: 'GBP' }}
                                    </span>
                                    <span class="amount ms-4" *ngIf="dataFromPreviousComponent === 'SubcribeProduct'">
                                        {{ getTotalSubscribePriceAfterWalletDeduction() | currency: 'GBP' }}
                                    </span>

                                    <img src="../../assets/alert-icon.svg" alt="alert-icon" width="20px" height="20px"
                                        class="mt-md-1 m-3 scroll-img-card" (click)="scrollToDiv('cart-summary')">
                                </div>

                                <button class="btn order-btn" (click)="placeOrder()" [disabled]="!isPlaceOrderEnabled"
                                    *ngIf="!orderEmpty">
                                    Place order
                                    <img src="../../assets/images/next_rightarrow.svg" alt="">
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- end -->
            </div>
            <!-- Order received -->
            <div class="mt-5 d-lg-block d-none" *ngIf="orderPlaced && orderDetails">
                <!-- <img src="../../assets/gif/Animation-tick.gif" class="m-auto d-block" alt=""> -->
                <!-- Subscription Section -->
                <div class="mt-5 d-lg-block d-none" *ngIf="isSubscriptionOrder && orderPlaced && orderDetails">
                    <img src="../../assets/gif/Animation-tick.gif" class="m-auto d-block" alt="">
                    <div class="d-flex flex-column justify-content-center align-items-center text-center">
                        <h5>Thank You. Order Subscription Placed! 🎉</h5>
                        <small class="d-flex align-items-center gap-2" *ngIf="payWithUrl">
                            <i class="fas fa-external-link-alt  copy-icon ms-1"></i> <!-- Link Icon -->
                            <a [href]="payWithUrl" target="_blank" rel="noopener noreferrer">
                                {{ payWithUrl }}
                            </a>
                            <i class="fas fa-copy copy-icon ms-2" data-bs-toggle="tooltip"  data-bs-placement="top" 
                            title="Copy"   (click)="copyToClipboard(payWithUrl)"></i>
                            <!-- Copy Icon -->
                        </small>
                    </div>

                    <div class="row mt-5 mb-5 order_border">
                        <!-- Subscription ID -->
                        <div class="col-2 col-md-2 border-end">
                            <div>
                                <label for="">Subscription ID</label>
                                <span class="d-block order_details">#GP000{{ orderDetails.subscriptionnumber }}</span>
                            </div>
                        </div>
                        <!-- Order Items -->
                        <div class="col-3 col-md-3 border-end">
                            <div>
                                <label for="">Subscription items</label>
                                <li class="d-block order_details">{{ orderDetails.title}}</li>
                                <a href="javascript:void(0)"
                                    (click)="navigateToProfile('subscriptions',$event, orderDetails.id)">show more</a>
                            </div>
                        </div>
                        <!-- Start Date -->
                        <div class="col-2 col-md-2 border-end">
                            <div>
                                <label for="">Start Date</label>
                                <span class="d-block order_details">
                                    {{ orderDetails.deliverystartdate | date:'EEEE, dd.MM.yyyy' }}
                                </span>
                            </div>
                        </div>
                        <!-- End Date -->
                        <div class="col-2 col-md-2 border-end">
                            <div>
                                <label for="">End Date</label>
                                <span class="d-block order_details">{{ orderDetails.deliveryenddate | date:'EEEE,
                                    dd.MM.yyyy' }}</span>

                            </div>
                        </div>
                        <!-- Delivery Address -->
                        <div class="col-3 col-md-3">
                            <div>
                                <label for="">Delivery Address</label>
                                <div class="d-block order_details">
                                    <span>{{ orderDetails.firstname }} | {{ orderDetails.phone }} | {{
                                        orderDetails.email
                                        }}</span>
                                    <span>
                                        {{ orderDetails.doorno }}, {{ orderDetails.address }},
                                        {{ orderDetails.landmark }}, {{ orderDetails.postalcode }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Order Section -->
                <div class="mt-5 d-lg-block d-none" *ngIf="!isSubscriptionOrder && orderPlaced && orderDetails">
                    <img src="../../assets/gif/Animation-tick.gif" class="m-auto d-block" alt="">
                    <div class="d-flex flex-column justify-content-center align-items-center text-center">
                        <h5>Thank You. Order Successfully Placed! 🎉</h5>
                        <small class="d-flex align-items-center gap-2" *ngIf="payWithUrl">
                            <i class="fas fa-external-link-alt copy-icon ms-1"></i> <!-- Link Icon -->
                            <a [href]="payWithUrl" target="_blank" rel="noopener noreferrer">
                                {{ payWithUrl }}
                            </a>
                            <i class="fas fa-copy copy-icon ms-2" data-bs-toggle="tooltip"  data-bs-placement="top" 
                            title="Copy"   (click)="copyToClipboard(payWithUrl)"></i>
                            <!-- Copy Icon -->
                        </small>
                    </div>

                    <div class="row mt-5 mb-5 order_border">
                        <!-- Order ID -->
                        <div class="col-2 col-md-2 border-end">
                            <div>
                                <label for="">Order ID</label>
                                <span class="d-block order_details">#GP000{{ orderDetails.ordernumber }}</span>
                            </div>
                        </div>
                        <!-- Order Items -->
                        <div class="col-3 col-md-3 border-end">
                            <div>
                                <label for="">Order items</label>
                                <li class="d-block order_details">{{ orderDetails.products[0]?.producttitle }}</li>
                                <a href="javascript:void(0)"
                                    (click)="navigateToProfile('orders',$event, orderDetails?.id)">show more</a>
                            </div>
                        </div>
                        <!-- Order Date -->
                        <div class="col-2 col-md-2 border-end">
                            <div>
                                <label for="">Order Date</label>
                                <span class="d-block order_details">
                                    {{ orderDetails.orderdate | date:'EEEE, dd.MM.yyyy' }}
                                </span>
                            </div>
                        </div>
                        <!-- Delivery Date -->
                        <div class="col-2 col-md-2 border-end">
                            <div>
                                <label for="">Delivery Date</label>
                                <span class="d-block order_details">{{ selectedDay.day_date }}</span>
                            </div>
                        </div>
                        <!-- Delivery Address -->
                        <div class="col-3 col-md-3">
                            <div>
                                <label for="">Delivery Address</label>
                                <div class="d-block order_details">
                                    <span>{{ orderDetails.firstname }} | {{ orderDetails.phone }} | {{
                                        orderDetails.email
                                        }}</span>
                                    <span>{{ orderDetails.doorno }}, {{ orderDetails.address }}, {{
                                        orderDetails.landmark
                                        }}, {{
                                        orderDetails.postalcode }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="d-flex justify-content-between align-items-center order-details-mobile mb-3">
                    <a href="javascript:void(0)" class="details" *ngIf="!isSubscriptionOrder"
                        (click)="navigateToProfile('orders',$event, orderDetails?.id)">
                        <img src="../../assets/images/left_arrow.svg" alt="">&nbsp; Order details
                    </a>
                    <a href="javascript:void(0)" class="details" *ngIf="isSubscriptionOrder"
                        (click)="navigateToProfile('subscriptions',$event, orderDetails.id)">
                        <img src="../../assets/images/left_arrow.svg" alt="">&nbsp; Order details
                    </a>
                    <a href="/home" class="details">
                        Back to home &nbsp;<img src="../../assets/images/right_arrow.svg" alt="">
                    </a>
                </div>

            </div>
            <!-- for mobile -->
            <div class="mt-5 d-lg-none d-block" *ngIf="orderPlaced">
                <img src="../../assets/gif/Animation-tick.gif" class="m-auto d-block" alt="">

                <div class="d-flex flex-column justify-content-center align-items-center text-center">
                    <h6 class="text-center" *ngIf="!isSubscriptionOrder">Thank You. Order Successfully Placed! 🎉</h6>
                    <h6 class="text-center" *ngIf="isSubscriptionOrder">Thank You. subscription Successfully Placed! 🎉
                    </h6>
                    <small class=" align-items-center " *ngIf="payWithUrl">
                        <i class="fas fa-external-link-alt copy-icon ms-1"></i> <!-- Link Icon -->
                            <a [href]="payWithUrl" target="_blank" rel="noopener noreferrer">
                                {{ payWithUrl }}
                            </a>
                            <i class="fas fa-copy copy-icon ms-2" data-bs-toggle="tooltip"  data-bs-placement="top" 
                            title="Copy"   (click)="copyToClipboard(payWithUrl)"></i>
                            <!-- Copy Icon -->
                        </small>
                </div>
                <div class="row mt-5 mb-5 order_border">
                    <div class="col-12 mb-3">
                        <div>
                            <label for="" *ngIf="!isSubscriptionOrder">Order ID</label>
                            <label for="" *ngIf="isSubscriptionOrder">Subscription ID</label>
                            <span class="d-block order_details" *ngIf="!isSubscriptionOrder">#GP000{{
                                orderDetails.ordernumber }}</span>
                            <span class="d-block order_details" *ngIf="isSubscriptionOrder">#GP000{{
                                orderDetails.subscriptionnumber }}</span>
                        </div>
                    </div>
                    <hr>
                    <div class="col-12 mb-3">
                        <div>
                            <label for="" *ngIf="!isSubscriptionOrder">Order items</label>
                            <label for="" *ngIf="isSubscriptionOrder">Subscription items</label>
                            <span class="d-block order_details" *ngIf="!isSubscriptionOrder">{{
                                orderDetails.products[0]?.producttitle }} <br> <a href="javascript:void(0)"
                                    (click)="navigateToProfile('orders',$event, orderDetails?.id)">show
                                    more</a></span>
                            <span class="d-block order_details" *ngIf="isSubscriptionOrder">{{ orderDetails.title }}
                                <br> <a href="javascript:void(0)"
                                    (click)="navigateToProfile('subscriptions',$event, orderDetails.id)">show
                                    more</a></span>
                        </div>
                    </div>
                    <hr>
                    <div class="col-12 mb-3">
                        <div>
                            <label for="" *ngIf="!isSubscriptionOrder">Order Date</label>
                            <label for="" *ngIf="isSubscriptionOrder">Start Date</label>

                            <span class="d-block order_details" *ngIf="!isSubscriptionOrder">
                                {{ orderDetails.orderdate | date:'EEEE, dd.MM.yyyy' }}
                            </span>
                            <span class="d-block order_details" *ngIf="isSubscriptionOrder">
                                {{ orderDetails.deliverystartdate | date:'EEEE, dd.MM.yyyy' }}
                            </span>
                        </div>
                    </div>

                    <hr>
                    <div class="col-12 mb-3">
                        <div>
                            <label for="" *ngIf="!isSubscriptionOrder">Delivery Date</label>
                            <label for="" *ngIf="isSubscriptionOrder">End Date</label>

                            <span class="d-block order_details" *ngIf="!isSubscriptionOrder">{{ selectedDay.day_date
                                }}</span>
                            <span class="d-block order_details" *ngIf="isSubscriptionOrder">{{
                                orderDetails.deliveryenddate | date:'EEEE, dd.MM.yyyy' }}</span>
                        </div>
                    </div>

                    <hr>
                    <div class="col-12 mb-3">
                        <div>
                            <label for="">Delivery Address</label>
                            <div class="d-block order_details">
                                <span>{{ orderDetails.firstname }} | {{ orderDetails.phone }} | {{
                                    orderDetails.email
                                    }}</span>
                                <span>{{ orderDetails.doorno }}, {{ orderDetails.address }}, {{
                                    orderDetails.landmark
                                    }}, {{
                                    orderDetails.postalcode }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3" *ngIf="!isSubscriptionOrder">
                    <a href="javascript:void(0)" class="details"
                        (click)="navigateToProfile('orders',$event, orderDetails?.id)"><img
                            src="../../assets/images/left_arrow.svg" alt="">&nbsp;
                        Order details</a>
                    <a href="/home" class="details">Back to home &nbsp;<img src="../../assets/images/right_arrow.svg"
                            alt=""></a>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3" *ngIf="isSubscriptionOrder">
                    <a href="javascript:void(0)" class="details"
                        (click)="navigateToProfile('subscriptions',$event, orderDetails.id)"><img
                            src="../../assets/images/left_arrow.svg" alt="">&nbsp;
                        Order details</a>
                    <a href="/home" class="details">Back to home &nbsp;<img src="../../assets/images/right_arrow.svg"
                            alt=""></a>
                </div>
            </div>


            <!-- Empty card -->
            <div class="empty-cart-container " *ngIf="orderEmpty && !issubscribeProduct">
                <div class="empty-cart-content">
                    <img src="../../assets/images/empty_cart.svg" alt="" class="img-fluid d-block mx-auto">
                    <h5 class="text-center mt-3">Your Cart is Empty!</h5>
                    <div class="shopping">
                        <a href="/home">Continue Shopping</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-lg-block d-none">
            <app-play-store></app-play-store>
        </div>
        <div class=" d-lg-none d-block">
            <app-play-store></app-play-store>
        </div>
    </div>