import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit,ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductPopupDialogComponent } from '../product-popup-dialog/product-popup-dialog.component';
import { ProductService } from '../services/product.service';
import { Options } from '@angular-slider/ngx-slider';
import { Subscription } from 'rxjs';
 
@Component({
  selector: 'app-product-list-pages',
  templateUrl: './product-list-pages.component.html',
  styleUrls: ['./product-list-pages.component.css']
})
export class ProductListPagesComponent implements OnInit, OnDestroy {
  showProductList: boolean = true;
  showHr: boolean = false;
  showFilterSection: boolean = false;
  isGroceryMenuOpen: boolean = false;
  isFrozenFoodOpen: boolean = false;
  outOfStockIncluded: boolean = false;
  foodTypeIncluded: boolean = false;
  loadMoreRecentlyViewed: boolean = false;
  isLoadingRecentlyViewed: boolean = false;
  activeButton: number = 1;
  isSortMenuOpen: boolean = false;
  homeData: any;
  // activeCategoryKey: string = '';
  categoryname: string | undefined;
  showApplyBtn: boolean = false;
 
  categoryKey: any;
  foodTypeList: any[] = [];
  categoryList: any[] = [];
  subCategories: any[] = [];
  subCategories_list: any[] = [];
  mainCategories: any[] = [];
  frozenFoodItems: string[] = [];
  foodCategories: string[] = [];
  isDropdownContentVisible: boolean[] = [];
 
  activeCategoryId: string = '';
  activeSubCategoryId: string = '';
  page: number = 1; // Initial page number
  count: number = 9;
  productsPerPage: number = 9;
  productList: any[] = [];
  selectedVariation: any;
  isLoggedIn: boolean = false;
  userId: string | null = null;
  add_cart: boolean = true;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
  isAvailability = false;
  isOffer = false;
  isfoodType = false;
  isPriceVisible: boolean = false;
  minPrice: number = 0; // Minimum price
  maxPrice: number = 5000; // Maximum price
  sliderValue: number = 0; // Current value of the slider
  minValue: number = 0; // Minimum value for the slider
  maxValue: number = 1000; // Maximum value for the slider
  private subscription: Subscription = new Subscription();
  currentQuery: string | string[] = '';
  isSearchQuery: boolean = false;
  searchQuery: string | null = null;
  showSubscribeProductComponent: boolean = false;
  sortColumn: string = '';
  sort: string = '';
  totalPages: number = 0;
  totalProducts: any;
  selectedDiscountRange: { minDiscount: number | null; maxDiscount: number | null } = { minDiscount: null, maxDiscount: null };
 
  triggeredByMostPopular: boolean = false;
  constructor(public auth: AuthService, public router: Router, private route: ActivatedRoute, private http: HttpClient, private productService: ProductService, private dialog: MatDialog, private elementRef: ElementRef, private cartService: CartService,private cdr: ChangeDetectorRef) {
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {
      if (data) {
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      } else {
        this.isLoggedIn = false;
      }
    });
  }
 
  ngOnInit(): void {
    const storedCategoryId = sessionStorage.getItem('activeSubCategoryId');
    if (storedCategoryId) {
      this.activeSubCategoryId = storedCategoryId;
    }
 
    const ActiveCategoryId = sessionStorage.getItem('activeCategory_Id');
    if (ActiveCategoryId) {
      this.activeCategoryId = ActiveCategoryId;
   
        this.categoriesListLevel_1(this.activeCategoryId);
    }
 
    this.retrieveHomeData();
    this.retrieveProductList();
    this.subscribeToQueryParams();
    this.categoriesListLevel_0(); // Fetch main categories
    this.wish_list();
  }
 
  retrieveHomeData(): void {
    // Retrieve homeData from local storage if available
    const storedHomeData = localStorage.getItem('homeData');
 
 
    if (storedHomeData) {
      this.homeData = JSON.parse(storedHomeData);
 
      if (this.homeData) {
        this.activeSubCategoryId = this.homeData.subcategory_Id || null;
        this.categoryKey = this.homeData.category || null;
        this.activeCategoryId = this.homeData.parentId || null;
 
 
        // Fetch subcategories if activeCategoryId is available
        if (this.activeCategoryId) {
          this.categoriesListLevel_1(this.activeCategoryId);
        }
      } else {
        console.warn("Parsed homeData is null.");
      }
    }
  }
 
  retrieveProductList(): void {
    const storedProductList = localStorage.getItem('productList');
    if (storedProductList) {
      this.productList = JSON.parse(storedProductList);
 
    }
  }
 
  subscribeToQueryParams(): void {
    // Subscribe to query params
    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        this.searchQuery = params['q'] || null;
        this.categoryKey = params['category'];
       
        // Check for state and clear session if required
        const state = history.state;
        if (state?.dataToPass?.datafrom === "From_Main_Category") {
          sessionStorage.removeItem('activeSubCategoryId');
          sessionStorage.removeItem('activeCategory_Id');
       
        }
 
        // Handle category key
        if (this.categoryKey) {
          this.categoryKeyCall(this.categoryKey);
        }
 
        // Handle search query
        this.showSubscribeProductComponent = !!this.searchQuery;
        if (this.searchQuery) {
 
          this.ProductListCall(this.searchQuery, true);
        }
      })
    );
 
    // Subscribe to product data from the service
    this.subscription.add(
      this.productService.getData().subscribe(
        data => {
          this.homeData = data;
 
          if (this.homeData) {
            localStorage.setItem('homeData', JSON.stringify(this.homeData));
 
            // Extract active subcategory and category details
            this.activeSubCategoryId = this.homeData.subcategory_Id || null;
            this.categoryKey = this.homeData.category || null;
            this.activeCategoryId = this.homeData.parentId || null;
           
 
            // Load category-related data
            if (this.activeCategoryId) {
              this.categoriesListLevel_1(this.activeCategoryId);
              this.Category_Section(this.activeCategoryId, this.categoryKey);
            }
          } else {
            console.warn("homeData from API is not available.");
          }
        },
        error => {
          console.error("Failed to fetch product data:", error);
        }
      )
    );
  }
 
 
  ngOnDestroy(): void {
    this.subscription.unsubscribe(); // Clean up subscriptions
  }
 
 
  toggleFilterSection() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
          })
 
    this.showProductList = !this.showProductList;
    this.showFilterSection = !this.showFilterSection;
    this.showApplyBtn = !this.showApplyBtn;
 
  }
 
  closeDropdown(type: string): void {
    if (type === 'sortMenu') {
      this.isSortMenuOpen = false; // Set to false to close the menu
    }
  }
 
  onCategoryClick(item: any): void {
    // Toggle checkbox status when div is clicked
    item.isChecked = !item.isChecked;
 
    // Gather all selected categories
    const selectedCategories = this.subCategories_list
      .filter((category: any) => category.isChecked)
      .map((category: any) => category.id);
    if (selectedCategories.length === 0 && this.categoryKey) {
      this.categoryKeyCall(this.categoryKey);
    } else {
      // Call ProductListCall with selected categories
 
      this.ProductListCall(selectedCategories);
    }
  }
 
  toggleSortMenu(): void {
    this.isSortMenuOpen = !this.isSortMenuOpen;
  }
 
  sortProducts(sortColumn: string, sort: string, isMostPopular: boolean): void {
    if (isMostPopular) {
      this.triggeredByMostPopular = true; // Set the flag
if (this.categoryKey) {
      this.categoryKeyCall(this.categoryKey);
  this.ProductListCall([], false, "", "", this.triggeredByMostPopular);
}
 
    } else {
      this.triggeredByMostPopular= false;
      if (this.categoryKey) {
      this.categoryKeyCall(this.categoryKey);
        this.ProductListCall([], false, sortColumn, sort, this.triggeredByMostPopular);
      }
    }
  }
 
 
  openProductDialog(product: any): void {
 
    //const variationId = this.selectedVariation || selectedVariationId;
    const bestSelling = "bestSelling";
 
    this.dialog.open(ProductPopupDialogComponent, {
      data: {
        id: product.id,
        datafrom: bestSelling,
        variationId: product.selectedVariation
      },
      width: '90%',
      height: '90%',
      disableClose: true
    });
    //this.selectedVariation = ''
    product.selectedVariation = ''
  }
 
  grocery_click(productId: string, selectedVariation: string): void {
 
    const bestSelling = "bestSelling";
    const variationId = this.selectedVariation || selectedVariation;
    const name = this.categoryname;
 
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: bestSelling, variationId: variationId, name: name } }
    });
 
  }
 
  // this.router.navigate(['/product-list-pages'], {
  //   queryParams: { category: card.categorykey },
  //   state: { dataToPass: { category_Id: card.id } }
  // });
 
  Category_Section_click(id: any, categorykey: any){
    this.homeData = '';
    sessionStorage.removeItem('activeSubCategoryId');
    localStorage.removeItem('homeData');
    sessionStorage.setItem('activeCategory_Id', id);
    this.Category_Section(id,categorykey)
 
  }
  //api call
  Category_Section(id: any, categorykey: any) {
 
    this.activeCategoryId = id;
   
    this.categoryKey = categorykey;
    this.activeSubCategoryId = '';
 
    this.categoriesListLevel_1(id)
    this.categoryKeyCall(categorykey)
    this.router.navigate(['/product-list-pages'], {
      queryParams: { category: categorykey },
      state: { dataToPass: { category_Id: this.activeCategoryId } }
    });
 
 
  }
 
  Category_menu(id: any, index: number, categorykey: any) {
    this.categoriesListLevel_2(id)
    this.activeSubCategoryId = id;
    this.categoryKeyCall(categorykey)
 
  }
 
  toggleDropdownContent(event: MouseEvent, index: number, categoryId: any, categorykey: any): void {
    this.router.navigate(['/product-list-pages'], {
      queryParams: { category: categorykey },
      state: { dataToPass: { sub_category_Id: categoryId,category_Id:this.activeCategoryId ,index:index} }
    });
    sessionStorage.setItem('activeSubCategoryId', categoryId);
    const ActiveCategoryId = sessionStorage.getItem('activeCategory_Id');
    if (ActiveCategoryId) {
      this.activeCategoryId = ActiveCategoryId;
    }
    this.isDropdownContentVisible[index] = !this.isDropdownContentVisible[index];
    if (this.isDropdownContentVisible[index]) {
      for (let i = 0; i < this.isDropdownContentVisible.length; i++) {
        if (i !== index) {
          this.isDropdownContentVisible[i] = false;
        }
      }
    }
    if (this.isDropdownContentVisible[index]) {
      this.Category_menu(categoryId, index, categorykey);
 
 
    }
    event.stopPropagation();
 
  }
 
 
  categoriesListLevel_0(): void {
    const requestBody = {
      status: 1,
      parentId: '',
      level: '0',
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
 
          // Scroll to the top of the page
          const element = this.elementRef.nativeElement.querySelector('.fixed-margin-top');
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest'
            });
          }
 
          // Only clear homeData if it's undefined or null
          if (this.categoryKey && !this.homeData) {
            const selectedCategory = data.categories_output.find((x: any) => x.categorykey === this.categoryKey);
            if (selectedCategory) {
              this.activeCategoryId = selectedCategory.id;
              this.categoriesListLevel_1(this.activeCategoryId);
              this.categoryKeyCall(this.categoryKey);
              this.categoryname = selectedCategory.name;
 
            }
          }
 
          // Set the main categories
          this.mainCategories = data.categories_output;
 
          // Handle homeData only if present
          if (this.homeData) {
            this.handleHomeData();
          }
        }
      }
    );
  }
 
  handleHomeData(): void {
    const parentId = this.homeData.parentId;
    this.categoriesListLevel_1(parentId);
  }
 
  categoriesListLevel_1(parentId: string): void {
    const requestBody = {
      status: 1,
      parentId: parentId,
      level: '1',
 
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
 
          const element = this.elementRef.nativeElement.querySelector('.fixed-margin-top');
          if (element) {
            window.scroll(0, 0);
          }
          this.subCategories = data.categories_output;
          this.isDropdownContentVisible = new Array(this.subCategories.length).fill(false);
 
          if (this.subCategories.length > 0) {
            this.showHr = true;
          }
 
          if (this.homeData) {
            this.processHomeDataSubcategory();
          }
        }
      }
    );
  }
 
  processHomeDataSubcategory(): void {
    const subcategory_Id = this.homeData.subcategory_Id;
    const subCategoryIndex = this.subCategories.findIndex((x: any) => x.id === subcategory_Id);
 
    if (subCategoryIndex !== -1) {
      this.activeCategoryId = this.homeData.parentId;
 
      this.activeSubCategoryId = subcategory_Id;
 
      this.isDropdownContentVisible[subCategoryIndex] = true;
 
      this.categoriesListLevel_2(subcategory_Id);
    }
  }
 
  categoriesListLevel_2(parentId: string): void {
    const requestBody = {
      status: 1,
      parentId: parentId,
      level: '2',
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
         
          this.activeSubCategoryId =parentId;
 
          this.subCategories_list = data.categories_output;
 
          // this.ProductListCall(this.subCategories_list[0].id);
        }
      }
    );
  }
 
 
  categoryKeyCall(categoryKey: string): void {
    const initialRequestBody = {
      "status": 1,
      "categoryKey": categoryKey
    };
 
    // First API call to get the count
    this.http.post(environment.apiUrl + `/categories/list`, initialRequestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          // Assuming the count is available in the response
          const count = data.count || 300; // Use a default value of 300 if not found in the response
 
          // Now make the second call with the count included
          const requestBodyWithCount = {
            "status": 1,
            "categoryKey": categoryKey,
            "count": count
          };
 
          this.http.post(environment.apiUrl + `/categories/list`, requestBodyWithCount, { responseType: 'json' }).subscribe(
            (data: any) => {
              if (data.status === 'SUCCESS') {
                this.categoryList = data.categories_output;
                const categoryListIds: string[] = [];
                this.categoryList.forEach((category: any) => {
                  const categoryList_id = category.id;
                  categoryListIds.push(categoryList_id);
                });
                this.ProductListCall(categoryListIds, false, '', '', this.triggeredByMostPopular);
                this.triggeredByMostPopular = false;
              }
            },
          );
        }
      },
    );
  }
 
 
 
 
  selectSize(productId: any, variation: any): void {
    const product = this.productList.find((item: any) => item.id === productId);
 
    if (product) {
      // Update selected variation details
      product.selectedVariation = variation.productvariationid;
 
      // Handle price logic based on available fields
      if (variation?.priceToShow != null) {
        product.price = variation.priceToShow; // Use priceToShow if available
      } else if (variation?.sellingprice != null) {
        product.price = variation.sellingprice; // Fallback to sellingprice
      } else if (variation?.mrpprice != null) {
        product.price = variation.mrpprice; // Fallback to mrpprice
      } else {
        product.price = 0; // Default to 0 if no price is found
      }
 
      product.originalPrice = variation.originalPrice;
 
      // Use the variation object directly for cartitemsquantity
      if (variation?.cartitemsquantity != null) {
        product.cartitemsquantity = variation.cartitemsquantity;
      } else {
        // Fallback to productvariations if needed
        const selectedVariation = product.productvariations.find(
          (v: any) => v.productvariationid === variation.productvariationid
        );
        product.cartitemsquantity = selectedVariation?.cartitemsquantity ?? 0;
      }
      // const selectedVariation = product.variations.find(
      //   (v: any) => v.productvariationid === variation.productvariationid
      // );
      // product.wishlistIcon = selectedVariation?.wishlistIcon ?? false;
      product.wishlistIcon = variation?.wishlistIcon ?? false;
     
      // Update button visibility based on cart quantity
      this.addedToCart.set(productId, product.cartitemsquantity > 0);
 
      // Trigger UI change detection
      this.cdr.detectChanges();
 
    }
  }
 
  previousPage(): void {
    if (this.page > 1) {
      this.page--;
      this.categoryKeyCall(this.categoryKey);
 
      // this.ProductListCall(this.currentQuery, this.isSearchQuery, this.sortColumn, this.sort);
    }
  }
 
  nextPage(): void {
    if (this.page < this.totalPages) {
      this.page++;
      this.categoryKeyCall(this.categoryKey);
 
      // this.ProductListCall(this.currentQuery, this.isSearchQuery, this.sortColumn, this.sort);
    }
  }
 
  goToPage(page: number): void {
    if (page !== this.page) {
      this.page = page;
      // this.ProductListCall(this.currentQuery, this.isSearchQuery, this.sortColumn, this.sort);
      this.categoryKeyCall(this.categoryKey);
    }
  }
  onDiscountChange(minDiscount: number, maxDiscount: number, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
 
    if (inputElement) {
      if (inputElement.checked) {
        this.selectedDiscountRange.minDiscount = minDiscount;
        this.selectedDiscountRange.maxDiscount = maxDiscount;
      } else {
        this.selectedDiscountRange.minDiscount = null;
        this.selectedDiscountRange.maxDiscount = null;
      }
    }
 
    if (this.categoryKey) {
     
      // Call the API with the active categoryKey
      this.categoryKeyCall(this.categoryKey);
      this.ProductListCall([],false)
    } else {
      console.error("No active categoryKey found.");
    }
  }
 
  ProductListCall(query: string | string[], isSearchQuery: boolean = false, sortColumn: string = '', sort: string = '', isMostPopular: boolean = false ): void {
    const requestBody: any = {
      status: 1,
      categoryKey : this.categoryKey
    };
 
    if (isMostPopular) {
      requestBody.isMostPopular = true;
    } else if (this.selectedDiscountRange.minDiscount !== null && this.selectedDiscountRange.maxDiscount !== null) {
      requestBody.minDiscount = this.selectedDiscountRange.minDiscount;
      requestBody.maxDiscount = this.selectedDiscountRange.maxDiscount;
    }
    else {
      requestBody.isSale = true;
      requestBody.page = this.page;
      requestBody.count = this.count;
 
      if (this.minPrice !== null && this.isPriceVisible) {
        requestBody.minPrice = this.minPrice;
      }
      if (this.maxPrice !== null && this.isPriceVisible) {
        requestBody.maxPrice = this.maxPrice;
      }
      if (sortColumn && sort) {
        requestBody.sortColumn = sortColumn;
        requestBody.sort = sort;
      }
    }
 
    if (isSearchQuery) {
      requestBody.q = query as string;
    } else if (this.foodTypeList.length > 0 && this.isfoodType) {
      requestBody.foodType = this.foodTypeList;
    } else if (query.length > 0) {
      requestBody.categoryIds = query as string[];
    }
 
    if (this.userId) {
      requestBody.userId = this.userId;
    }
 
    if (this.outOfStockIncluded) {
      requestBody.outOfStock = 'include';
    }
 
    this.http.post(environment.apiUrl + `/products/list`, requestBody).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const products = data.products_output;
          this.totalProducts = data.count;
          this.totalPages = this.totalProducts > 9 ? Math.ceil(this.totalProducts / this.productsPerPage) : 0;
 
          products.forEach((product: any) => {
            if (product.productvariations && product.productvariations.length > 0) {
              // Sort product variations based on sort order
              if (sort === 'DESC') {
                product.productvariations.sort((a: any, b: any) => b.sellingprice - a.sellingprice);
              } else {
                product.productvariations.sort((a: any, b: any) => a.sellingprice - b.sellingprice);
              }
 
              const firstVariation = product.productvariations[0];
              product.originalPrice = firstVariation.mrpprice;
              product.price = firstVariation.sellingprice;
              product.variationvalue = firstVariation.variationvalue;
              product.variationshortname = firstVariation.variationshortname;
              product.selectedVariation = firstVariation.productvariationid;
              product.cartitemsquantity = firstVariation.cartitemsquantity;
              product.cartid = firstVariation.cartid;
 
              // Initialize variations array and add unique variations
              product.variations = [];
              product.productvariations.forEach((variation: any) => {
                const variationExists = product.variations.some(
                  (v: any) => v.productvariationid === variation.productvariationid
                );
 
                if (!variationExists) {
                  product.variations.push({
                    originalPrice: variation.mrpprice,
                    priceToShow: variation.sellingprice,
                    variationvalue: variation.variationvalue,
                    variationshortname: variation.variationshortname,
                    productvariationid: variation.productvariationid,
                  });
                }
              });
            }
 
            // Fetch product images
            product.src = [];
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${product.id}`).subscribe((datas: any) => {
              if (datas.status === 'SUCCESS' && datas.uploads.length > 0) {
                this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
                  if (res.status === 'SUCCESS') {
                    product.src.push(res.uploads.base64data);
                  }
                });
              }
            });
          });
 
          // Merge new products with the existing product list
          this.productList = [...products];
          localStorage.setItem('productList', JSON.stringify(this.productList));
          this.loadMoreRecentlyViewed = this.totalProducts > this.productList.length;
          this.isLoadingRecentlyViewed = false;
 
          if (this.userId) {
            this.wish_list();
          }
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
 
  // food type
  toggleFoodType() {
    this.isfoodType = !this.isfoodType
  }
 
  getProductsForPage(page: number): any[] {
    // Mock function to get products. In real implementation, this should be fetched from the server.
    // Calculate start and end index based on the current page
    const start = (page - 1) * this.productsPerPage;
    const end = start + this.productsPerPage;
 
    // Here, you would return the actual products based on the start and end indexes
    return []; // Return actual products array based on start and end
  }
  foodType(event: any, type: string): void {
    const isChecked = event.target.checked;
 
    if (type === 'both') {
      // When "Both" is checked, handle adding/removing both "veg" and "non-veg"
      if (isChecked) {
        if (!this.foodTypeList.includes('veg')) {
          this.foodTypeList.push('veg');
        }
        if (!this.foodTypeList.includes('non-veg')) {
          this.foodTypeList.push('non-veg');
        }
      } else {
        this.foodTypeList = this.foodTypeList.filter(foodType => foodType !== 'veg' && foodType !== 'non-veg');
      }
    } else {
      // Handle individual "veg" or "non-veg" options
      if (isChecked) {
        if (!this.foodTypeList.includes(type)) {
          this.foodTypeList.push(type);
        }
      } else {
        this.foodTypeList = this.foodTypeList.filter(foodType => foodType !== type);
      }
    }
 
    // Call the API with the updated food type list
 
    this.ProductListCall(this.foodTypeList, false);
  }
 
 
  toggleAvailability() {
    this.isAvailability = !this.isAvailability;
  }
  onOutOfStockChange(event: any): void {
    this.outOfStockIncluded = event.target.checked; // Update state based on checkbox
    this.categoryKeyCall(this.categoryKey);
  }
 
  toggleOffer() {
    this.isOffer = !this.isOffer;
  }
 
  togglePriceContent() {
    this.isPriceVisible = !this.isPriceVisible;
  }
  validateInput(type: 'min' | 'max', min: number, max: number): void {
    if (type === 'min' && this.minPrice > max - 5) {
      this.minPrice = max - 5; // Ensure min price is valid
    } else if (type === 'max' && this.maxPrice < min + 5) {
      this.maxPrice = min + 5; // Ensure max price is valid
    }
    this.categoryKeyCall(this.categoryKey); // Trigger products API call
  }
  onInputPriceChange(isMin: boolean): void {
    if (isMin) {
      if (this.minPrice > this.maxPrice - 5) {
        this.minPrice = this.maxPrice - 5;
      }
    } else {
      if (this.maxPrice < this.minPrice + 5) {
        this.maxPrice = this.minPrice + 5;
      }
    }
    this.categoryKeyCall(this.categoryKey); // Trigger products API call
 
  }
  onRangeInputChange(isMin: boolean): void {
    if (isMin) {
      if (this.minPrice > this.maxPrice - 5) {
        this.minPrice = this.maxPrice - 5;
      }
    } else {
      if (this.maxPrice < this.minPrice + 5) {
        this.maxPrice = this.minPrice + 5;
      }
    }
    this.categoryKeyCall(this.categoryKey); // Trigger products API call
  }
  // preventKeyPress(event: KeyboardEvent): void {
  //   // Prevent default action for the keypress event
  //   if (!/[0-9]/.test(event.key)) {
  //     event.preventDefault();
  //   }  }
 
  // Method to handle slider changes
  onSliderChange(event: any): void {
    this.sliderValue = Number(event.target.value);
 
    // Adjust the min and max prices based on the slider value
    const midpoint = (this.maxValue - this.minValue) / 2;
 
    if (this.sliderValue <= midpoint) {
      // Increase min price until the midpoint
      this.minPrice = this.sliderValue;
      this.maxPrice = midpoint + (this.sliderValue * (this.maxValue - midpoint) / midpoint);
    } else {
      // Increase max price after the midpoint
      this.minPrice = midpoint; // Set minPrice to midpoint
      this.maxPrice = this.sliderValue;
    }
    this.categoryKeyCall(this.categoryKey);
    // Call the API whenever the price changes
    // this.ProductListCall([], false);
  }
  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
 
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
     // Find product
     const product = this.productList.find(item => item.id === productId);
     if (product) {
       product.cartitemsquantity  = quantity; // Update UI immediately
       this.addedToCart.set(productId, quantity > 0); // Show/hide quantity buttons
       // this.addedToCart.set(productId, true); // Show quantity buttons
       this.cdr.detectChanges(); // Trigger change detection
     }
   
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity,
      createdBy: this.userId,
      type:  "is_sale",
 
    };
 
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
         // Update cart ID and new quantity from API response
         const selectedVariation = product?.variations.find(
          (v: any) => v.productvariationid === productVariationId
        );
        if (selectedVariation) {
          selectedVariation.cartitemid = data.cartitems?.id;
            product.cartitemid = data.cartitems?.id;
            selectedVariation.cartitemsquantity = quantity; // Sync with server response
            product.cartitemsquantity = quantity; // Sync with server response
   
        }
 
        // Update cart count in the service
        this.cartService.updateCartCount(data.newCartCount);
        this.cdr.detectChanges();
        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }
 
  getQuantity(productId: number): number {
 
    return this.quantities.get(productId) || 1;
 
  }
  increaseQuantity(productId: number, productVariationId: string) {
    const product = this.productList.find(item => item.id === productId);
    if (product) {
      // product.cartitemsquantity += 1;
      // product.cartitemsquantity = (product.cartitemsquantity || 0) + 1;
      product.cartitemsquantity = product.cartitemsquantity || 0;
      product.cartitemsquantity += 1;
 
      this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
      this.cdr.detectChanges(); // Trigger change detection
    }
  }
 
  decreaseQuantity(productId: number, productVariationId: string): void {
    const product = this.productList.find(item => item.id === productId);
 
    if (product) {
      // Ensure cartitemsquantity is initialized
      product.cartitemsquantity = product.cartitemsquantity || 0;
 
      if (product.cartitemsquantity > 1) {
        // Decrease quantity
        product.cartitemsquantity -= 1;
 
        // Update the cart with the new quantity
        this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
      } else if (product.cartitemsquantity === 1) {
        // If quantity reaches 0, remove the item from the cart
        product.cartitemsquantity = 0;
        this.deleteCartItem(product.cartitemid); // Call the delete API
 
        // Reset UI: Show "Add to Cart" button
        this.addedToCart.set(productId, false);
      }
 
      // Trigger change detection to reflect the updated quantity
      this.cdr.detectChanges();
    } else {
      console.error('Product not found for ID:', productId);
    }
  }
 
  deleteCartItem(cartId: any): void {
   
    const requestBody = {
      updatedBy: this.userId,
      cartitemIds: [cartId],
    };
 
    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          if (this.userId !== null && this.userId !== undefined) {
            this.cartService.updateCartCount(data.newCartCount);
          } else {
            console.error('User ID is null or undefined. Cannot update cart count.');
          }
        }
      },
      (error: any) => {
        console.error('An error occurred:', error);
      }
    );
  }
 
  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const variation = product.variations.find((v: any) => v.productvariationid === productVariationId);
    if (!variation) {
      console.error('Variation not found for Product ID:', productId, 'Variation ID:', productVariationId);
      return;
    }
    const newStatus = !variation.wishlistIcon;
 
    // product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
    variation.wishlistIcon = newStatus; // Update the UI state for the specific variation
    product.wishlistIcon = variation.wishlistIcon; // Update the product wishlist state
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId,
        type:'is_sale'
      };
 
      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
        .subscribe(
          (data: any) => {
            // Handle the success response here if needed
          },
          (error) => {
            console.error("API Error:", error);
          }
        );
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }
 
  wish_list(): void {
    // if (!this.userId) {
    //   this.router.navigate(['/login']);
    //   return;
    // }
 
    const requestBody = {
      userId: this.userId,
    };
 
    this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            const wish_list_product = data.wishlistitems_output;
 
            this.productList.forEach((product: any) => {
              product.variations.forEach((variation: any) => {
                const isInWishlist = wish_list_product.some(
                  (item: any) => item.productid === product.id && item.productvariationid === variation.productvariationid
                );
                variation.wishlistIcon = isInWishlist; // Set wishlist icon for each variation
              });
                // Set the wishlist status for the default selected variation
            const defaultVariation = product.variations.find(
              (v: any) => v.productvariationid === product.selectedVariation
            );
 
            if (defaultVariation) {
              product.wishlistIcon = defaultVariation.wishlistIcon;
            } else {
              // Fallback if no default variation is selected
              product.wishlistIcon = false;
            }
            });
          }
        },
        (error) => {
          console.error("Wishlist API Error:", error);
        }
      );
  }
 
}
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 