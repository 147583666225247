<div class="container mt-4 scroll_top">
  <div class="d-lg-block d-none  product-container">
    <div class="row product-img">
      <!-- Thumbnail Images -->
      <div class="col-lg-1 px-0">
        <div id="myCarousel" class="carousel slide position-relative" data-ride="carousel">
          <div class="carousel-inner" role="listbox">
            <!-- Loop through product images and display only 3 at a time -->
            <div class="img item m-0" *ngFor="let image of productImages.src.slice(startIndex, endIndex); let i = index"
              (click)="selectImage(startIndex + i)">
              <img [src]="image" alt="Product Image" class=" border mx-0 mb-2"
                [ngClass]="{'border': selectedIndex !== (startIndex + i), 'active-thumbnail': selectedIndex === (startIndex + i)}" />
            </div>
          </div>
          <!-- Carousel controls -->
          <div *ngIf="startIndex > 0" class="carousel-control-prev position-absolute">
            <img [src]="prevImageSrc" alt="Previous" class="m-0  carousel-img-scroll" (click)="prevImage()"
              (mouseover)="hoverImage('prev', true)" (mouseout)="hoverImage('prev', false)" width="35px" height="35px">
          </div>
          <div *ngIf="endIndex < productImages.src.length" class="carousel-control-next position-absolute">
            <img [src]="nextImageSrc" alt="Next" class="m-0  carousel-img-scroll" (click)="nextImage()"
              (mouseover)="hoverImage('next', true)" (mouseout)="hoverImage('next', false)" width="35px" height="35px">
          </div>
        </div>
      </div>
 
      <!-- Main Product Image -->
      <div class="col-lg-5 mainimage-border p-0">
        <img [src]="mainImage" alt="Product Image" class="main-product-image" />
      </div>
 
      <!-- Product Details -->
      <div class="col-lg-5 productDetails">
        <h4 *ngIf="productDetails">{{ productDetails.title }}</h4>
        <h4 *ngIf="isComboOfferProduct && !productDetails">{{productComboDetails.title}}</h4>
 
        <div class="promotions mb-3">
          <div class="d-flex">
          <div *ngFor="let variation of productDetails?.productvariations" class="">
            <span class="promo_off me-1 p-1"
              *ngIf="variation?.discountpercentage > 0 && variation?.productvariationid === productDetails?.selectedVariation && !isDeliverySchedule">
              {{variation?.discountpercentage}}% Off
            </span>
            <span class="promo_off me-1 p-1"
              *ngIf="variation?.scribecap && variation?.productvariationid === productDetails?.selectedVariation && isDeliverySchedule">
              {{variation?.scribecap }}
            </span>
          </div>
 
 
          <!-- combooffer only -->
          <span class="promo_off me-1" *ngIf="isComboOfferProduct"> {{productComboDetails.offercaption}}</span>
          <span class="combo_off mx-1" *ngIf="isComboOfferProduct"> Combo offer</span>
 
          <span class="halal-offer mx-1 p-1" *ngIf="ishalalfood">Halal Food
            <img src="../../assets/images/halal-food.svg" alt="">
          </span>
          <span class="combo_off" *ngIf="iscomboavailable && !isComboOfferProduct">Combo offer available</span>
        </div>
 
          <div class="row mt-3" *ngIf="isComboOfferProduct">
            <div class="col-6 mb-2" *ngFor="let product of this.productComboDetails.comboproducts">
              <button class="btn w-100 combo-btn text-truncate"  *ngIf="product.producttitle !== null">
                {{product.producttitle}}
              </button>
            </div>
 
          </div>
          <div class="description-container mt-3">
            <p class="description" [innerHTML]="sanitizedDescription"></p>
          </div>
          <a href="javascript:void(0)" class="show-more" (click)="toggleShowMore()">Show more</a>
          <div class="pricing">
            <span class="new-price">{{ priceToShow | currency: 'GBP' }}</span>
            <span class="original-price ms-2 text-decoration-line-through">
              <del>{{ mrpPrice | currency: 'GBP' }}</del>
            </span>
            <span class="ms-2 tax">(inclusive of all taxes)</span>
          </div>
 
 
          <div class="size-selection mt-1">
            <div class="sizes p-1">
              <button *ngFor="let variation of productDetails.variations" (click)="selectSize( variation)"
                [ngClass]="{'selected-btn': variation === selectedVariation}" class="btn size-btn me-2">
                {{ variation.variationvalue }} {{ variation.variationshortname }}
              </button>
 
            </div>
          </div>
 
          <!-- delivery schedule -->
          <div class="delivery-selection mt-3" *ngIf="isDeliverySchedule  && !isComboOfferProduct">
            <div>
              <h6>Delivery Schedule</h6>
              <div>
                <select name="schedule" id="schedule" class="form-select" [(ngModel)]="selectedSchedule">
                  <option value="">Select Schedule</option>
                  <hr>
                  <option value="Daily">Daily</option>
                  <hr>
                  <option value="Alternate">Alternate</option>
                  <hr>
                  <option value="Every 3 days">Every 3 days</option>
                  <hr>
                  <option value="Weekly">Weekly</option>
                  <hr>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>
            </div>
          </div>
 
          <!-- schedule date -->
          <div class="date-selection mt-3 row" *ngIf="isDeliverySchedule && !isComboOfferProduct">
            <div class="col-6">
              <!-- <h6>Starts on</h6>
              <div class="">
                <input type="date" name="startDate" id="startDate" class="form-control" [(ngModel)]="startDate" (change)="onStartDateChange()">
              </div> -->
              <mat-form-field appearance="outline" >
                <mat-label>Starts on</mat-label>
                <input matInput [matDatepicker]="picker1" [(ngModel)]="startDate" (dateChange)="onDateChange()" [min]="currentDate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 [dateClass]="dateClass"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-6">
              <!-- <h6>Ends on</h6>
              <div class="">
                <input type="date" name="endDate" id="endDate" class="form-control" [(ngModel)]="endDate" [min]="minEndDate" >
              </div> -->
              <mat-form-field appearance="outline" >
                <mat-label>Ends on</mat-label>
                <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate" (dateChange)="onDateChange()" [min]="startDate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 [dateClass]="dateClass"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
         
          
       
          <!-- only for food product -->
          <div>
            <div class="mt-2" *ngIf="productDetails.next_delivery_slot && !isDeliverySchedule">
              <span class="slot">*Next Delivery Slot will be:</span>
              <span class="slot-date">{{ productDetails.next_delivery_slot }}</span>
            </div>
 
            <div class="mt-2" *ngIf="FoodDeliverySlot && flavorList != null &&!iscomboavailable">
              <label for="" class="food-instruction">Flavor Instruction</label>
              <div class="mt-1">
                <select name="flavor" id="flavor-select" class="flavor-add" [(ngModel)]="selectedFlavor"
                  (change)="onFlavorChange($event,productDetails)">
                  <option value="">Add food flavor instruction</option>
                  <option *ngFor="let flavor of flavorList" [value]="flavor">{{ flavor }}</option>
                </select>
              </div>  
            </div>
 
            <div class=" row" *ngIf="isComboOfferProduct ">
              <div class="  row" *ngFor="let product of comboProducts; let i = index">
                <div class="col-6" *ngIf="product.productflavor !==null" >
                  <label class="mb-2 ">{{ product.producttitle }}</label>
                  <select [id]="'flavor-select-' + i" class="form-select combo-flavor-add"
                    [(ngModel)]="product.selectedFlavor" (change)="onComboFlavorChange($event, product)">
                    <option value="">Add flavor </option>
                    <option *ngFor="let flavor of product.availableFlavors" [value]="flavor">
                      {{ flavor }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
          <button class="btn add-card btn-lg"
            (click)="Add_to_cart(productDetails.id, productDetails.selectedVariation, 1,'is_sale')"
            *ngIf="(!productDetails.cartitemquantity || productDetails.cartitemquantity === 0) && !isDeliverySchedule  && !isComboOfferProduct">
            <div>
              <img src="../../assets/images/bag-add.svg" alt="" class="mx-2">Add to cart
            </div>
          </button>
 
          <button class="btn add-card btn-lg" (click)="SubcribeClick(productDetails.id, productDetails.selectedVariation)" *ngIf="isDeliverySchedule && !isComboOfferProduct">
            <div><img src="../../assets/images/bag-add.svg" alt="" class="mx-2">Subscribe </div>
          </button>
 
          <button class="btn add-card btn-lg"
          *ngIf="isComboOfferProduct && productComboDetails && (productComboDetails.cartitemquantity === 0 || productComboDetails.cartitemquantity === null)"

            (click)="Add_to_cart(productComboDetails.id, comboProducts, 1,'is_combo')">
            <div>
              <img src="../../assets/images/bag-add.svg" alt="" class="mx-2">
              Add Combo to Cart
            </div>
          </button>
          <div class="d-flex align-items-center justify-content-around add-border cart"
          *ngIf="isLoggedIn && !isDeliverySchedule && 
          (((productDetails?.cartitemquantity || 0) > 0 && !isComboOfferProduct) || 
           (isComboOfferProduct && (productComboDetails?.cartitemquantity || 0) > 0))">
            <div class="text-center d-flex   justify-content-around" style="width: 240px;">
              <button class="btn p-0"
                (click)="decreaseQuantity(productComboDetails?.comboproducts ? productComboDetails.id: productDetails.id, productComboDetails?.comboproducts ? productComboDetails.comboproducts: productDetails.selectedVariation)"><img
                  src="../../assets/images/decrease_img.svg" alt="Decrease" style="width: 50px; height: 25px;"></button>
              <span class="px-2" style="font-size: 16px; margin-top: 5px;">{{ productDetails.cartitemquantity ||
                productComboDetails.cartitemquantity }}</span>
              <button class="btn p-0"
                (click)="increaseQuantity(productComboDetails?.comboproducts ? productComboDetails.id: productDetails.id, productComboDetails?.comboproducts ? productComboDetails.comboproducts: productDetails.selectedVariation)"><img
                  src="../../assets/images/increase_img.svg" alt="Increase" style="width: 50px; height: 25px;"></button>
            </div>
          </div>
 
        </div>
 
 
      </div>
      <div class="col-lg-1">
        <div class="d-flex justify-content-between align-items-center">
          <div class="">
            <img src="../../assets/icon/share.svg" alt="share" class="icon pointer"
              (click)="copyTextToClipboard(hostName+'/product-pages/'+productDetails.id);">
            <div *ngIf="showCopiedOptions" class="mt-3 d-block copyOptions" style="position: absolute;">
              <ul>
                <li>Copy link</li>
                <li>E-mail</li>
                <li>Whatsapp</li>
                <li>Facebook</li>
                <li>Twitter</li>
              </ul>
            </div>
            <div class="copy-success position-absolute" *ngIf="showCopiedMessage">Copied
              to clipboard!</div>
 
          </div>
 
 
          <div class="like-icon-div">
            <img
              *ngIf="isLoggedIn && productDetails.wishlistIcon || productComboDetails.wishlistitemstatus; else noWishListIcon"
              (click)="toggleWishListOrCombo($event, productComboDetails?.comboproducts ? productComboDetails : productDetails)"
              src="../../assets/images/single-like-img.svg" alt="like" />
            <ng-template #noWishListIcon>
              <img
                (click)="toggleWishListOrCombo($event,productComboDetails?.comboproducts ? productComboDetails : productDetails)"
                src="../../assets/images/single-red-like-img.svg" alt="like">
            </ng-template>
          </div>
        </div>
 
 
      </div>
    </div>
  </div>
  <!-- for mobile -->
  <div class="d-lg-none d-block">
    <div class="row">
      <!-- Main Product Image -->
      <div class="col-lg-5">
        <div class=" main-product-border m-0">
          <img [src]="mainImage" alt="Product Image" class="main-product-image d-block p-0" />
        </div>
      </div>
      <!-- h -->
      <div class="col-lg-2 mt-3">
        <div class="parent" #elemt (mousedown)="startDragging($event, false, elemt, 'mouse', child, '')"
          (mouseup)="stopDragging($event, false, 'mouse')" (mouseleave)="stopDragging($event, false, 'mouse')"
          (mousemove)="moveEvent($event, elemt, 'mouse', child)">
          <div class="child position-relative" #child>
            <!-- Loop through product images and display them -->
            <div class="img item ps-2 m-0" *ngFor="let image of productImages.src; let i = index"
              (click)="selectImage(startIndex + i)">
              <img [src]="image" alt="Product Image" class="my-0 border"
                [ngClass]="{'active-thumbnail': selectedIndex === (startIndex + i)}" />
            </div>
          </div>
        </div>
      </div>
 
 
      <div class="col-lg-5 mt-3">
        <div class="d-flex justify-content-between gap-3 m-auto p-2">
          <h4 class="w-75" *ngIf="productDetails">{{ productDetails.title }}</h4>
          <h4 class="w-75" *ngIf="isComboOfferProduct && !productDetails">{{productComboDetails.title}}</h4>
 
          <!-- like & share -->
          <div class="d-flex justify-content-between w-25 ipad">
            <!-- <img src="../../assets/icon/share.svg" alt="share"  class="icon"> -->
             <div>
            <img src="../../assets/icon/share.svg" alt="share" class="icon"
              (click)="copyTextToClipboard(hostName+'/product-pages/'+productDetails.id);">
            <div *ngIf="showCopiedOptions" class="mt-3 d-block copyOptions" style="position: absolute;">
              <ul>
                <li>Copy link</li>
                <li>E-mail</li>
                <li>Whatsapp</li>
                <li>Facebook</li>
                <li>Twitter</li>
              </ul>
            </div>
            <div class="copy-success position-absolute" *ngIf="showCopiedMessage">Copied
              to clipboard!</div>
 
</div>
 
            <div class="">
              <!-- If product is in the wishlist (heart icon) -->
              <span
                *ngIf="isLoggedIn && productDetails.wishlistIcon || productComboDetails.wishlistitemstatus; else noWishListIcon"
                (click)="toggleWishListOrCombo($event, productComboDetails?.comboproducts ? productComboDetails : productDetails)">
                <img src="../../assets/images/single-like-img.svg" class="">
              </span>
 
              <!-- If product is not in the wishlist (border heart icon) -->
              <ng-template #noWishListIcon>
                <span
                  (click)="toggleWishListOrCombo($event, productComboDetails?.comboproducts ? productComboDetails : productDetails)">
                  <img src="../../assets/images/single-red-like-img.svg" class="">
                </span>
              </ng-template>
            </div>
            <!-- <img src="../../assets/images/like.svg" alt="like" class="icon" /> -->
          </div>
        </div>
        <div class="promotions mb-3">
          <!-- <span class="promo_off me-1">50% offer</span> -->
          <div *ngFor="let variation of productDetails?.productvariations" class="d-inline">
            <span class="promo_off me-1"
              *ngIf="variation?.discountpercentage > 0 && variation?.productvariationid === productDetails?.selectedVariation && !isDeliverySchedule">
              {{variation?.discountpercentage}}% Off
            </span>
            <span class="promo_off me-1"
              *ngIf="variation?.scribecap && variation?.productvariationid === productDetails?.selectedVariation && isDeliverySchedule">
              {{variation?.scribecap }}
            </span>
          </div>
 
  
          
          <span class="halal-offer mx-1" *ngIf="ishalalfood">Halal Food
            <img src="../../assets/images/halal-food.svg" alt="">
          </span>
          <span class="combo_off " *ngIf="iscomboavailable">Combo offer available</span>
          <!-- <span class="badge"
            [ngClass]="{ 'bg-success': productDetails.type === 'discount', 'bg-info': productDetails.type === 'offer' }">
            {{ productDetails.label }}
          </span> -->
        </div>
 
        <div class="description-container">
          <p class="description" [innerHTML]="sanitizedDescription"></p>
        </div>
        <a href="javascript:void(0)" class="show-more" (click)="toggleShowMore()">Show more</a>
        <div class="row mt-3 gap-3" *ngIf="isComboOfferProduct">
          <div class="col-12" *ngFor="let product of this.productComboDetails.comboproducts">
            <button class="btn w-100 combo-btn text-truncate"   *ngIf="product.producttitle !== null">
              {{product.producttitle}}
            </button>
          </div>
 
        </div>
        <div class="pricing mt-3">
          <span class="new-price">{{ priceToShow | currency: 'GBP' }}</span>
          <span class="original-price ms-2 text-decoration-line-through" *ngIf="!iscomboavailable">
            <del>{{ mrpPrice | currency: 'GBP' }}</del>
          </span>
          <span class="ms-2 tax">(inclusive of all taxes)</span>
        </div>
 
 
        <div class="size-selection mt-3 mb-3">
          <div class="sizes p-1">
            <button *ngFor="let variation of productDetails.variations" (click)="selectSize( variation)"
              [ngClass]="{ 'selected-btn': variation === selectedVariation }" class="btn size-btn me-2">
              {{ variation.variationvalue }} {{ variation.variationshortname }}
            </button>
 
          </div>
        </div>
        <!-- delivery schedule -->
        <div class="delivery-selection" *ngIf="isDeliverySchedule">
          <div>
            <h6>Delivery Schedule</h6>
            <div>
              <select name="" id="" class="form-select">
                <option value="">Select Schedule</option>
                <hr>
                <option value="">Daily</option>
                <hr>
                <option value="">Alternate</option>
                <hr>
                <option value="">Every 3 days</option>
                <hr>
                <option value="">Weekly</option>
                <hr>
                <option value="">Monthly</option>
              </select>
            </div>
          </div>
        </div>
        <!-- schedule date -->
        <div class="date-selection mt-3 mb-3 row" *ngIf="isDeliverySchedule">
          <div class="col-6">
            <!-- <h6>Starts on</h6>
            <div class="">
              <input type="date" name="" id="" class="form-control" [(ngModel)]="startDate" (change)="onStartDateChange()">
            </div> -->
            <mat-form-field appearance="outline">
              <mat-label>Starts on</mat-label>
              <input matInput [matDatepicker]="picker1" [(ngModel)]="startDate" (dateChange)="onDateChange()" [min]="currentDate">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 [dateClass]="dateClass"></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-6">
            <!-- <h6>Ends on</h6>
            <div class="">
              <input type="date" name="" id="" class="form-control" [(ngModel)]="endDate" [min]="minEndDate" >
            </div> -->
        <mat-form-field appearance="outline" >
          <mat-label>Ends on</mat-label>
          <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate" (dateChange)="onDateChange()" [min]="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 [dateClass]="dateClass"></mat-datepicker>
        </mat-form-field>
          </div>
        </div>
       
      
        <!-- only for food product -->

        <div>
          <div class="mt-2" *ngIf="productDetails.next_delivery_slot !==null && !isDeliverySchedule">
            <span class="slot">*Next Delivery Slot will be : </span><span class="slot-date">
              {{productDetails.next_delivery_slot}}</span>
          </div>
          <div class="mt-2" *ngIf="FoodDeliverySlot && flavorList !=null">
            <label for="" class="food-instruction">Flavor Instruction</label>
            <div class="mt-1" *ngIf="!iscomboavailable">
              <select name="flavor" id="flavor-select" class="flavor-add" [(ngModel)]="selectedFlavor"
                (change)="onFlavorChange($event,productDetails)">
                <option value="">Add food flavor instruction</option>
                <option *ngFor="let flavor of flavorList" [value]="flavor">{{ flavor }}</option>
              </select>
            </div>
          </div>
          <div class=" mt-2 row" *ngIf="isComboOfferProduct">
            <div class=" text-truncate" *ngFor="let product of comboProducts; let i = index">
              <div class="col-12" *ngIf="product.productflavor !==null">
                <label class="mb-2 text-truncate">{{ product.producttitle }}</label>
                <select [id]="'flavor-select-' + i" class="form-select combo-flavor-add"
                  [(ngModel)]="product.selectedFlavor" (change)="onComboFlavorChange($event, product)">
                  <option value="">Add flavor</option>
                  <option *ngFor="let flavor of product.availableFlavors" [value]="flavor">
                    {{ flavor }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
 
        <div class="sticky-footer   d-flex">
 
          <button class="btn add-card btn-lg mt-1"
          (click)="Add_to_cart(productDetails.id, productDetails.selectedVariation, 1,'is_sale')"
          *ngIf="(!productDetails.cartitemquantity || productDetails.cartitemquantity === 0) && !isDeliverySchedule  && !isComboOfferProduct">
          <img src="../../assets/images/bag-add.svg" alt="">Add to cart</button>
 
          <button class="btn add-card btn-lg mt-1" (click)="SubcribeClick(productDetails.id, productDetails.selectedVariation)" *ngIf="isDeliverySchedule && !isComboOfferProduct">
            <div><img src="../../assets/images/bag-add.svg" alt="" class="mx-2">Subscribe </div>
          </button>
          <button class="btn add-card btn-lg"
          *ngIf="isComboOfferProduct && productComboDetails && (!isDeliverySchedule || !productDetails) && (productComboDetails.cartitemquantity === 0 ||  productComboDetails.cartitemquantity === null)"
            (click)="Add_to_cart(productComboDetails.id, comboProducts, 1,'is_combo')">
            <div>
              <img src="../../assets/images/bag-add.svg" alt="" class="mx-2">
              Add Combo to Cart
            </div>
          </button>
 
          <div class="d-flex align-items-center justify-content-around add-border cart"
            *ngIf="isLoggedIn && !isDeliverySchedule && ((productDetails.cartitemquantity > 0 && !isComboOfferProduct) || (isComboOfferProduct && productComboDetails.cartitemquantity > 0))">
            <div class="text-center d-flex justify-content-around" style="width: 240px;">
              <button class="btn p-0"
                (click)="decreaseQuantity(productDetails.id, productDetails.selectedVariation)"><img
                  src="../../assets/images/decrease_img.svg" alt="Decrease"
                  style="width: 20px; height: 20px; margin-left: 20px;"></button>
                  <span class="px-2 py-2" style="font-size: 14px;">{{ productDetails.cartitemquantity ||
                    productComboDetails.cartitemquantity}} </span>
                 
              <button class="btn p-0"
                (click)="increaseQuantity(productDetails.id, productDetails.selectedVariation)"><img
                  src="../../assets/images/increase_img.svg" alt="Increase"
                  style="width: 20px; height: 20px; margin-right: 20px;"></button>
            </div>
          </div>
 
        </div>
      </div>
 
      <!-- Share and Like Icons -->
      <!-- <div class="col-lg-2">
        <div class="d-flex justify-content-center gap-3">
          <img src="../../assets/icon/share.svg" alt="share">
          <img src="../../assets/images/like.svg" alt="like" class="icon" />
        </div>
      </div> -->
    </div>
  </div>
  <!-- Tabs for Description and Product Details -->
  <div class="row mt-4" #tabSection>
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item p-2">
        <a href="javascript:void(0)" class="description-link mx-2"
          [ngClass]="{'active-tab': activeTab === 'description', 'inactive-tab': activeTab !== 'description'}"
          (click)="setActiveTab('description')" role="tab">
          Description
        </a>
      </li>
      <li class="nav-item p-2" *ngIf="productDetails.additionaldetails  !== null">
        <a href="javascript:void(0)" class="product-link mx-2"
          [ngClass]="{'active-tab': activeTab === 'product-details', 'inactive-tab': activeTab !== 'product-details'}"
          (click)="setActiveTab('product-details')" role="tab">
          Product details
        </a>
      </li>
    </ul>
 
    <div class="tab-content">
      <div class="tab-pane" [class.active]="activeTab === 'description'" id="description" role="tabpanel">
        <!-- Non-Combo Offer Product -->
        <p class="description mt-3" [innerHTML]="sanitizedDescription" *ngIf="!isComboOfferProduct"></p>
        <!-- Combo Offer Product -->
        <p class="description mt-3" [innerHTML]="sanitizedTitleAndDescription" *ngIf="isComboOfferProduct"></p>
      </div>
      <div class="tab-pane" [class.active]="activeTab === 'product-details'" id="product-details" role="tabpanel">
        <div class="mt-3">
          <!-- Non-Combo Offer Product -->
          <ng-container *ngIf="!isComboOfferProduct">
            <div class="form-group row" *ngFor="let key of getKeys(additionaldetails)">
              <label class="col-6 font-weight-bold">{{ key | titlecase }}</label>
              <div class="col-6 text-muted">: {{ additionaldetails[key] || 'N/A' }}</div>
            </div>
          </ng-container>
 
          <!-- Combo Offer Product -->
          <div [innerHTML]="sanitizedTitleAndBrand" *ngIf="isComboOfferProduct"></div>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <!-- Product Related Offers -->
  <div class="row mb-3" *ngIf="ComboOfferList.length">
    <div>
      <h5 class="mb-3 fs-md-5">Product related offers</h5>
    </div>
    <div class="section mt-4">
    <div id="relatedProduct-list">
      <div *ngIf="isMobileView; else relatedProduct" class="comboproduct">
        <div class="comboproduct" #relatedelemt
          (mousedown)="startDragging($event, false, relatedelemt,'mouse',child, '')"
          (mouseup)="stopDragging($event, false,'mouse')" (mouseleave)="stopDragging($event, false,'mouse')"
          (mousemove)="moveEvent($event, relatedelemt, 'mouse', child)">
          <div class="child d-flex  position-relative gap-4 px-09" #relatedchild>
            <ng-container *ngTemplateOutlet="relatedProductContent"></ng-container>
          </div>
        </div>
      </div>
      <ng-template #relatedProduct>
        <div #relatedelemt>
          <div class="child d-flex  position-relative  gap-4 px-0" #relatedchild>
            <ng-container *ngTemplateOutlet="relatedProductContent"></ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template #relatedProductContent>
 
        <!-- <div class=" d-lg-block d-none"> -->
        <!-- <div class="row gap-4 m-auto"> -->
        <div class="card col-lg-4 px-2 related-card" *ngFor="let combo of ComboOfferList">
          <!-- Display Combo Title and Offer Caption -->
          <div class="d-flex mt-3 gap-3 text-center mobile-position">
            <div class="combo-offer">Combo offer </div>
            <div class="extra-offer">{{ combo.offercaption }}</div>
          </div>
          <!-- <div class="offer">50% off</div> -->
          <div class="like-border me-5 d-none d-lg-block">
            <!-- If product is in the wishlist (heart icon) -->
            <span *ngIf="isLoggedIn &&combo.wishlistitemstatus; else noWishListIcon"
              (click)="toggleWishList($event, combo)">
              <img src="../../assets/images/heart-icon.svg" class="like" alt="heart-icon">
            </span>
 
            <!-- If product is not in the wishlist (border heart icon) -->
            <ng-template #noWishListIcon>
              <span (click)="toggleWishList($event, combo)">
                <img src="../../assets/images/heart-border-web.png" class="like" alt="like">
              </span>
            </ng-template>
            <!-- popup img -->
            <!-- <img src="../../assets/images/view-border.svg" class="view" alt="view"> -->
          </div>
          <!-- for mobile -->
          <div class="mobile-like mt-2 d-lg-none d-block">
            <span *ngIf="isLoggedIn &&combo.wishlistitemstatus; else noWishListIcon"
              (click)="toggleWishList($event, combo)">
              <img src="../../assets/images/heart-icon.svg" class="heartlike" alt="like">
            </span>
 
            <!-- If product is not in the wishlist (border heart icon) -->
            <ng-template #noWishListIcon>
              <span (click)="toggleWishList($event, combo)">
                <img src="../../assets/images/heartborder.svg" class="heartlike" alt="like">
              </span>
            </ng-template>
          </div>
          <!-- Display Product Images in the Combo -->
          <div class="d-flex justify-content-around align-items-center gap-3 m-auto">
 
            <!-- Display the main image -->
            <!-- <img *ngIf="combo.mainImage" [src]="combo.mainImage" alt="Combo Main Image"> -->
 
            <!-- Display the additional images in a list -->
            <div class="mt-4">
              <img class="related-img" [src]="combo.mainImage" alt="Combo Image" style="width: m-auto; "
                (click)="comboProductListClick(combo.id)">
            </div>
 
          </div>
          <!-- Combo Details -->
          <div class="card-body px-4" (click)="comboProductListClick(combo.id)">
            <h6 class="card-title">{{ combo.title }}</h6>
            <!-- <div class="text-center">+</div> -->
            <!-- <h6 class="card-title">Johnson's & Johnson's Baby Soap</h6> -->
          </div>
          <div class="d-flex justify-content-between align-items-center card-btn mt-2 px-1 mb-2">
            <div class="price-section">
              <!-- <span>(£4.99 + £1.99)</span> -->
              <div class="total-price">£ {{ combo.total_price }}</div>
            </div>
            <button class="btn btn-warning add-btn 0"
              *ngIf=" !isLoggedIn ||combo.cartitemquantity === 0 ||  combo.cartitemquantity === null"
              (click)="addComboToCart(combo, 1)">Add Combo to Cart</button>
 
            <div class="d-flex w-50 align-items-center justify-content-around add-combo-border cart"
              *ngIf="isLoggedIn&& combo.cartitemquantity > 0">
              <div class="text-center d-flex w-50  justify-content-around">
                <button class="btn p-0" (click)="decreaseComboQuantity(combo)"><img
                    src="../../assets/images/decrease_img.svg" alt="Decrease"
                    style="width: 40px; height: 20px;"></button>
                <span class="px-2" style="font-size: 14px; margin-top: 5px;">{{ combo.cartitemquantity }}</span>
                <button class="btn p-0" (click)="increaseComboQuantity(combo)"><img
                    src="../../assets/images/increase_img.svg" alt="Increase"
                    style="width: 40px; height: 20px;"></button>
              </div>
            </div>
          </div>
        </div>
 
        <!-- </div> -->
        <!-- </div> -->
      </ng-template>
 
    </div>
    <div class="d-lg-block d-none" *ngIf="productListCount > 4">
      <div class="see-more " (click)="onRightScrollOrClick()" *ngIf="rightArrow">
        <img src="../../assets/images/carousel.svg" class="d-block mx-auto seemoreIcon" alt="rightArrow"
          id="right-relatedProduct" />
      </div>
 
      <div class="see-less " (click)="onLeftScrollOrClick()" *ngIf="upArrow">
        <img src="../../assets/images/arrow-left.svg" class="d-block mx-auto seemoreIcon" alt="upArrow"
          id="left-relatedProduct" />
      </div>
    </div>
    <!-- for mobile -->
 </div>
  </div>
 
  <!-- similar products -->
  <app-similar-product *ngIf="CategoryId && productId && CategoryId.length > 0" [categoryId]="CategoryId"
    [productId]="productId" [dataFromPreviousComponent]="dataFromPreviousComponent"></app-similar-product>
 
  <app-play-store></app-play-store>
</div>
 