import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { loadStripe } from '@stripe/stripe-js';

declare const google: any;

@Component({
  selector: 'app-add-cart',
  templateUrl: './add-cart.component.html',
  styleUrls: ['./add-cart.component.css']
})
export class AddCartComponent {
  cartPage = true;
  productPage = true;
  orderPlaced = false;
  deliverAddress = false;
  deliverySchedule = false;
  placeOrderList = false;
  paymentProcess = false;
  orderEmpty = false;
  selectedDay: any;
  orderId: any;
  WalletBalanceAmount: number = 0;
  selectedAddressId: number | string | null = null;
  paymentActive: boolean = true;
  isNextButtonDisabled: boolean = true;
  selectedPaymentMethod: string[] = [];
  cartItems: any[] = [];
  variations: any[] = [];
  selectedItems: number[] = [];
  count: number | null = null;
  isFirstStep: boolean = true;
  user_details: any = {};
  UserAddressList: any[] = [];
  DeliveryTimesLotsList: any[] = [];
  selectedSlots: any[] = [];
  selectedSlotId: string | null = null;
  deliveryInstruction: string = '';
  isPlaceOrderEnabled: boolean = false;
  currentStep: number = 0;
  tittle: string = 'cart';
  accessToken: string | null = null;
  orderDetails: any = null;
  userId: string = '';
  selectedVariationId: string | null = null;
  private variationsCache = new Map<string, any[]>();
  dataFromPreviousComponent: string | undefined;
  SubcribeProductVariationId: string | undefined;
  SubcribeProductId: string | undefined;
  issubscribeProduct: boolean = false;
  isCardDisabled: boolean = false;
  private variationsCacheSubject = new BehaviorSubject<Map<string, any[]>>(this.variationsCache);

  // start
  map!: google.maps.Map;
  SearchCountryField = SearchCountryField;
  isMapVisible: boolean = true;
  showCardAddressContent = false;
  showNewAddress = false;
  isEditMode: boolean = false;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: any;  // Depending on the format from ngx-intl-tel-input
  selectedCountryISO: CountryISO = CountryISO.India; // Default value
  deliveryArea: string = '';
  address: string = '';
  doorNo: string = '';
  landmark: string = '';
  postalCode: string = '';
  selectedAddressType: string | null = null;
  isEditable: boolean = false;
  latitude: number | undefined | null;
  longitude: number | undefined | null;
  selectedAddress: any = '';
  isLoggedIn: any;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  CountryISO = CountryISO;
  // popupOpenDialog = false;
  // end
  saveCardDetails: boolean = false;
  cardHolderName: string = '';
  selectedSavedCard: any = null;
  userCardsList: any[] = [];
  stripe: any;
  cardElement: any;
  cardErrors: string | null = null;
  @ViewChild('pacInput', { static: false }) pacInput!: ElementRef;

  constructor(public router: Router, private route: ActivatedRoute, private http: HttpClient, public auth: AuthService, private cartService: CartService, private cdr: ChangeDetectorRef
  ) {
    if (this.userId === '') {
      this.orderEmpty = true;
      this.cartPage = false;
    }
    this.auth.userInfo.subscribe((data: any) => {
      this.userId = data.id;
      if (this.userId) {
        this.orderEmpty = false;
        this.cartPage = true;
      }
      else {
        this.orderEmpty = true;
        this.cartPage = false;

      }
    });
    const navigation = this.router.getCurrentNavigation();
    const dataToPass = navigation?.extras?.state?.['dataToPass'];
    // Check if dataToPass exists and access its properties safely
    if (dataToPass) {
      this.SubcribeProductId = dataToPass.product_id
      this.SubcribeProductVariationId = dataToPass.variation_id
      this.dataFromPreviousComponent = dataToPass.datafrom;

      if (this.dataFromPreviousComponent === 'SubcribeProduct') {
        this.issubscribeProduct = true;
        this.cartList(this.SubcribeProductId);

      } else {
        this.issubscribeProduct = false;
      }
    } else if (this.userId) {
      this.SubcribeProductId = ''
      this.SubcribeProductVariationId = ''
      this.dataFromPreviousComponent = '';
      this.cartList();

    }

  }

  onSavedCardSelect(card: any) {
    this.selectedSavedCard = card;
    this.isPlaceOrderEnabled = true;
    this.isCardDisabled = true;
  }
  async stripePayment() {
    this.stripe = await loadStripe(environment.STRIPE_PUBLISHABLE_KEY);

    const elements = this.stripe.elements();

    // Create card element with ZIP code disabled
    this.cardElement = elements.create('card', {
      hidePostalCode: true  // Disable ZIP code
    });

    this.cardElement.mount('#card-element');

    this.cardElement.on('change', (event: any) => {
      this.cardErrors = event.error ? event.error.message : null;
      if (this.cardErrors === null) {  // Check if cardErrors is null
        this.isPlaceOrderEnabled = true;
      } else {
        this.isPlaceOrderEnabled = false;  // Disable if there's an error
      }
    });
  }


  async handlePayment(amount: number) {
    if (this.selectedSavedCard) {


      this.createPaymentIntent(this.selectedSavedCard.paymentmethodid, amount ?? 0);
      return;
    }
    const { error, paymentMethod } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,

    });

    if (error) {
      console.error(error);
      return;
    }
    if (this.saveCardDetails) {
      this.SaveCard(paymentMethod.id, paymentMethod.card);
      this.createPaymentIntent(paymentMethod.id, amount);

    } else {
      this.createPaymentIntent(paymentMethod.id, amount);

    }
  }

  createPaymentIntent(paymentMethodId: string, amount: number): void {
    const dayDate = this.selectedDay.day_date; // e.g., "Oct 18"

    // Convert the day_date string into a Date object (assuming it's in 'MMM dd' format)
    const currentYear = new Date().getFullYear(); // Get the current year
    const fullDateString = `${dayDate} ${currentYear}`; // Combine with year, e.g., "Oct 18 2024"

    // Create a new Date object and get the timestamp (milliseconds since 1970)
    const timestamp = new Date(fullDateString).getTime();
    const requestBody: any = {

      createdBy: this.userId,
      userId: this.userId,
      cartItemIds: this.selectedItems,
      userAddressId: this.selectedAddressId,
      deliveryScheduleDate: timestamp,
      deliveryTimeSlotId: this.selectedSlotId,
      paymentMethods: this.selectedPaymentMethod,
      paymentMethodId: paymentMethodId,
      returnUrl: "https://gpstore.amicodevelopment.net/home"
    };

    if (this.deliveryInstruction) {
      requestBody.deliveryInstruction = this.deliveryInstruction;
    }

    this.http.post(environment.apiUrl + '/orders/create', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          this.orderPlaced = true;
          this.cartPage = false;
          this.orderDetails = response.orders;

          const clientSecret = response.paymentIntent?.client_secret;
          const confirmationMethod = response.paymentIntent?.confirmation_method;

          if (clientSecret && confirmationMethod !== 'automatic') {
            // If confirmation_method is not 'automatic', confirm payment manually

            this.stripe.confirmCardPayment(clientSecret)
              .then((result: any) => {
                if (result.error) {
                  console.error('Payment Error:', result.error.message, result.error);
                } else if (result.paymentIntent.status === 'succeeded') {
                  alert('Payment successful!');
                } else {
                  console.error('Payment failed:', result.paymentIntent.status);
                }
              })
              .catch((error: any) => {
                console.error('Error during payment confirmation:', error);
              });
          } else if (confirmationMethod === 'automatic' && response.paymentIntent?.status === 'succeeded') {
            // Payment is automatically confirmed and succeeded
            alert('Payment successful!');
          } else {
            console.error('Failed to initiate payment or payment is already confirmed', response);
          }
        },
        (error) => {
          console.error('Error initiating payment:', error);
        }
      );
  }

  SaveCard(paymentMethodId: any, paymentMethodCard: any): void {
    const requestBody = {
      createdBy: this.userId,
      userId: this.userId,
      cardHolderName: this.cardHolderName,
      cardNumber: paymentMethodCard.last4,
      expMonth: paymentMethodCard.exp_month,
      expYear: paymentMethodCard.exp_year,
      paymentMethodId: paymentMethodId,
      brand: paymentMethodCard.brand
    };

    this.http.post(environment.apiUrl + '/usercarddetails/create', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
          } else {
            console.error('Failed to create card', response);
          }
        },
        (error) => {
          console.error('Error creating card:', error);
        }
      );
  }






  // start
  // tooggleOpenDialog(){
  //   this.popupOpenDialog = true;

  //   this.initMap();
  // }
  UserEditAddress(id: any): void {
    this.isEditMode = true;
    // Make the GET request to retrieve the address by id
    this.http.get(environment.apiUrl + `/useraddress/get?id=${id}`, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            // Assuming the response contains the address data
            const addressData = response.useraddress;

            // Set the component variables to the retrieved address values
            this.firstName = addressData.firstname;
            this.lastName = addressData.lastname;
            this.phone = addressData.phone;
            this.selectedCountryISO = CountryISO.India;
            this.email = addressData.email;
            this.deliveryArea = addressData.deliveryarea;
            this.address = addressData.address;
            this.doorNo = addressData.doorno;
            this.landmark = addressData.landmark;
            this.postalCode = addressData.postalcode;
            this.latitude = addressData.latitude;
            this.longitude = addressData.longitude;
            this.selectedAddressType = addressData.type
            this.showNewAddress = true;
            this.showCardAddressContent = false;
          } else {
            console.error('Failed to retrieve address:', response);
          }
        },
        (error) => {
          console.error('Error retrieving address:', error);
        }
      );
  }
  saveAddress(): void {
    this.isFirstStep = true
    if (this.isEditMode) {

      this.updateUserAddress();
    } else {
      this.createUserAddress();

      this.isMapVisible = true;
      this.resetToFirstStep();
      const addAddressModal = document.getElementById('addAddressModal') as HTMLElement;
      if (addAddressModal) {
        addAddressModal.addEventListener('shown.bs.modal', () => {
          this.initMap(); // Call initMap only when the modal is fully shown
        });
      }


    }

  }
  handleModalClose() {
    this.isFirstStep = true;

    // if(  this.isFirstStep &&   this.isMapVisible){
    this.resetToFirstStep();
    // }

  }

  scrollToDiv(divId: string): void {
    const element = document.getElementById(divId);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
}
 
  resetAddressForm(): void {
    this.firstName = '';
    this.lastName = '';
    this.phone = '';
    this.email = '';
    this.deliveryArea = '';
    this.address = '';
    this.doorNo = '';
    this.landmark = '';
    this.postalCode = '';
    this.selectedAddressType = ''; // or 'home', 'office', etc. if you have a default
    this.latitude = null;
    this.longitude = null;
  }
  isFormValid(): boolean {
    return this.firstName  && this.selectedAddress && this.address && this.postalCode && this.selectedAddressType;
    // return this.firstName && this.lastName && this.phone && this.email && this.selectedAddress && this.address && this.postalCode && this.selectedAddressType;
  }
  enableEdit() {
    this.isEditable = true; // Allow editing when 'Change' is clicked
  }

  ngOnInit() {
    setTimeout(() => {
      this.initMap();
    }, 0);

    this.isMapVisible = true;

  }


  //  wallet
  UserWallet(): void {
    this.http.get(environment.apiUrl + `/userwallet/get-available-balance?userId=${this.userId}`, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.WalletBalanceAmount = parseFloat(response.availableBalance) || 0;
          } else {
            console.error('Failed to retrieve wallet:', response);
          }
        },
        (error) => {
          console.error('Error retrieving wallet:', error);
        }
      );
  }


  GoBack() {

    if (this.showNewAddress) {
      // If the address form is open, go back to the map
      this.showNewAddress = false; // Hide the new address form
      this.isMapVisible = true;
      this.initMap();
    } else if (this.isMapVisible) {
      // If the map is visible, close it and hide the address card
      this.showCardAddressContent = false;
      this.isMapVisible = false;
      this.isEditMode = false;     // Reset edit mode
      this.UserAddressListApi();   // Reload or refresh the address list if needed
    }
  }
  createUserAddress(): void {
    const requestBody = {
      id: this.selectedAddressId,  // Or you can dynamically generate an ID if necessary
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone?.e164Number, // Assuming this is a method to get the phone number
      deliveryArea: this.selectedAddress,
      address: this.address,
      doorNo: this.doorNo,
      landmark: this.landmark,
      postalCode: this.postalCode,
      latitude: this.latitude,
      longitude: this.longitude,
      createdBy: this.userId,
      userId: this.userId,
      type: this.selectedAddressType,
    };

    this.http.post(environment.apiUrl + '/useraddress/create', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.showNewAddress = false;

            this.GoBack()
            // Handle success (e.g., show a success message, navigate, or update the UI)
          } else {
            console.error('Failed to create address', response);
          }
        },
        (error) => {
          console.error('Error creating address:', error);
        }
      );
    this.isMapVisible = true;
  }
  updateUserAddress(): void {
    const requestBody = {
      id: this.selectedAddressId,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone?.e164Number,
      deliveryArea: this.deliveryArea,
      address: this.address,
      doorNo: this.doorNo,
      landmark: this.landmark,
      postalCode: this.postalCode,
      latitude: this.latitude,
      longitude: this.longitude,
      updatedBy: this.userId,
      type: this.selectedAddressType,
    };

    // Send HTTP PUT request to update the address
    this.http.post(environment.apiUrl + '/useraddress/update', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.showNewAddress = false;

            this.GoBack();
            // Handle success, such as showing a success message or updating UI
          } else {
            console.error('Failed to update address', response);
          }
        },
        (error) => {
          console.error('Error updating address:', error);
        }
      );
    this.isMapVisible = true;
  }
  toggleCardAddressContent() {
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
    this.selectedAddress = '';
    this.showCardAddressContent = true // Toggle showCardAddressContent
    if (this.showCardAddressContent) {
      this.isMapVisible = true; // Show map only if the card content is visible
      this.showNewAddress = false;
      this.resetAddressForm();
      this.initMap();
    }
    else {
      this.isMapVisible = false; // Hide map if card content is hidden
    }
  }

  initMap() {
    this.isFirstStep = true;
    this.isMapVisible = true

    const mapElement = document.getElementById("map") as HTMLElement;
    if (!mapElement) {

      // console.error("Map container not found.");
      return;
    }
    else {
      const input = document.getElementById("pac-input") as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);
      if (!input) {
        // console.error("Search input not found. Aborting map initialization.");
        return;  // Exit early if the input is not available
      }

      const defaultLat = -33.8688;
      const defaultLng = 151.2195;

      // Use the selected latitude and longitude if available, else default coordinates
      const centerLat = this.latitude || defaultLat;
      const centerLng = this.longitude || defaultLng;

      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          center: { lat: centerLat, lng: centerLng },
          zoom: 13,
          mapTypeId: "roadmap",
        }
      );

      // Add a marker at the center of the map
      let centerMarker = new google.maps.Marker({
        position: { lat: centerLat, lng: centerLng },
        map: map,
        title: "Selected Location", // Optional: You can set a title for the marker
      });

      // Create the search box and link it to the UI element.
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });

      let markers: google.maps.Marker[] = [];

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener("places_changed", () => {
        const places: google.maps.places.PlaceResult[] | undefined = searchBox.getPlaces();

        if (!places || places.length === 0) {
          console.error("No places found");
          return;
        }

        // Remove the center marker if a new place is selected
        if (centerMarker) {
          centerMarker.setMap(null); // Hide the center marker
        }

        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            return;
          }

          if (place.formatted_address) {
            this.selectedAddress = place.formatted_address; // Set the selected address
            this.cdr.detectChanges();

            // Initialize the geocoder
            const geocoder = new google.maps.Geocoder();

            // Geocode the address
            geocoder.geocode({ address: this.selectedAddress }, (results: {
              geometry: {
                location: {
                  lat(): number | null | undefined; lng: () => number | null | undefined;
                };
              };
            }[], status: string) => {
              if (status === google.maps.GeocoderStatus.OK) {
                // Get latitude and longitude from the result
                this.latitude = results[0].geometry.location.lat();
                this.longitude = results[0].geometry.location.lng();
              } else {
                console.error('Geocoding failed: ' + status);
              }
            });
          }

          const icon = {
            url: place.icon as string,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };

          // Create a marker for the selected place.
          markers.push(
            new google.maps.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            })
          );

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        map.fitBounds(bounds);
      });
    }
  }

  setAddressType(type: string): void {
    this.selectedAddressType = type;
  }
  showNewAddressContainer() {
    this.showNewAddress = true; // Show the "Add New Address" form
    this.isMapVisible = false;  // Hide the map
  }

  // end
  toggleCardDetails(): void {
    this.deliverAddress = false;
    this.productPage = true;
    this.currentStep = 0;

  }
  goToNextStep() {
    this.isFirstStep = false;
  }
  resetToFirstStep() {

    this.isFirstStep = true;

    this.showCardAddressContent = true // Toggle showCardAddressContent
    // this.isMapVisible = true;

    this.selectedAddress = '';
    if (!this.showCardAddressContent && this.isMapVisible) {
      this.isMapVisible = true; // Show map only if the card content is visible
      this.showNewAddress = false;
      this.selectedAddress = '';
      this.cdr.detectChanges();

      this.resetAddressForm();

      this.initMap();
    }

  }

  // resetToFirstStep() {
  //   this.isFirstStep = true;
  //   this.selectedAddress = '';
  //   this.showCardAddressContent = true // Toggle showCardAddressContent

  //   if (this.showCardAddressContent) {
  //     this.isMapVisible = true; // Show map only if the card content is visible
  //     this.showNewAddress = false;
  //     this.resetAddressForm();
  //     setTimeout(() => {

  //       this.initMap();
  //     }, 0);
  //   }
  //   else {
  //     this.isMapVisible = false; // Hide map if card content is hidden
  //   }
  // }
  toggleScheduleDetails(): void {
    this.deliverAddress = true;
    this.deliverySchedule = false;
    this.currentStep = 1;

  }

  togglePaymentDetails(): void {
    this.deliverySchedule = true;
    this.paymentProcess = false;
    this.currentStep = 2;
    this.selectedPaymentMethod = []
    this.isCardDisabled = false;
  }

  // validation for name
  validateInput(event: KeyboardEvent): void {
    const pattern = /[A-Za-z]/; // Allow only alphabets
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault(); // Prevent input if it's not an alphabet
    }
  }

  // 1st page next btn
  productPage_next() {

    this.currentStep = 1;
    this.deliverAddress = true;
    this.productPage = false;
    this.placeOrderList = false;
    this.UserAddressListApi()
  }
  // 2nd page next btn
  deliverAddress_next() {

    this.currentStep = 2;
    this.deliverySchedule = true
    this.deliverAddress = false;
    this.placeOrderList = false;
    this.DeliveryTimesLots()
  }
  // 3rd page next btn
  deliverySchedule_next() {
    this.currentStep = 3;
    this.deliverySchedule = false;
    this.paymentProcess = true;
    if (this.paymentProcess) {
      this.UserWallet()

    }
    this.placeOrderList = true;

  }

  // 4th page next btn
  toggleSelection(paymentMethod: string) {
    if (paymentMethod === 'wallet') {
      if (this.selectedPaymentMethod.includes('wallet')) {
        // Unselect wallet if it's already selected
        const index = this.selectedPaymentMethod.indexOf('wallet');
        this.selectedPaymentMethod.splice(index, 1);
      } else {
        // Add wallet if it's not already selected
        this.selectedPaymentMethod.push('wallet');

      }
    } else {
      this.UserCardList()
      setTimeout(() => {
        this.stripePayment();
      }, 0);

      // For other payment methods, allow only one to be selected at a time
      if (this.selectedPaymentMethod.includes(paymentMethod)) {
        // Unselect the current payment method if it's already selected
        const index = this.selectedPaymentMethod.indexOf(paymentMethod);
        this.stripePayment()
        this.selectedPaymentMethod.splice(index, 1);
      } else {

        // Clear any existing non-wallet selections (radio button behavior)
        this.selectedPaymentMethod = this.selectedPaymentMethod.filter(method => method === 'wallet');
        // Add the selected payment method
        this.selectedPaymentMethod.push(paymentMethod);
      }
    }
    const walletBalanceCoversTotal = this.getTotalPriceAfterWalletDeduction() === 0;
    // Enable "Place Order" if wallet balance fully covers the total or a non-wallet method is selected
    if (this.selectedPaymentMethod.includes('wallet') && walletBalanceCoversTotal) {
      this.isPlaceOrderEnabled = true;  // Enable if wallet covers the total

    } else if (this.selectedPaymentMethod.some(method => method !== 'wallet')) {
      if (this.selectedPaymentMethod.some(method => method == 'card')) {
        this.isPlaceOrderEnabled = false;

      } else {
        this.isPlaceOrderEnabled = true;

      }

    } else {
      this.isPlaceOrderEnabled = false; // Disable otherwise

    }

    // Enable the "Place Order" button if any payment method is selected
    // this.isPlaceOrderEnabled = this.selectedPaymentMethod.length > 0;
  }

  UserCardList() {
    const requestBody = {
      userId: this.userId,
    }
    this.http.post(environment.apiUrl + `/usercarddetails/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.userCardsList = data.usercarddetails_output;

        }
      })
  }

  placeOrder() {
    this.currentStep = 4;

    if (this.isPlaceOrderEnabled) {
      // Check if 'card' is selected (with or without 'wallet')
      if (this.selectedPaymentMethod.includes('card')) {
        this.handlePayment(this.getTotalPriceAfterWalletDeduction());
      }
      // Check if 'cash on delivery' is selected (with or without 'wallet')
      else if (this.selectedPaymentMethod.includes('cash on delivery')) {
        this.createOrder();
      }
      // Check if only 'wallet' is selected
      else if (this.selectedPaymentMethod.length === 1 && this.selectedPaymentMethod.includes('wallet')) {
        this.createOrder();
      }
    }
  }
  navigateToProfile(section: string, event: MouseEvent, id: any) {
    event.stopPropagation();
    this.router.navigate(['/profile'], { queryParams: { section, id } });
  }
  
  getOrder(id: any): void {
    this.http.get(`${environment.apiUrl}/orders/get?id=${id}&userId=${this.userId}`, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            this.orderDetails = data.orders;
           
            // Fetch images for each cart item
            this.orderDetails.cartitems.forEach((item: any) => {
              item.src = []; // Initialize src array for each item
 
              // Fetch image data
              this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`)
                .subscribe((datas: any) => {
                  if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                    this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`)
                      .subscribe((res: any) => {
                        if (res.status === "SUCCESS") {
                          // Push the base64 image data to the item
                          item.src.push(res.uploads.base64data);
                        }
                      });
                  }
                });
            });
          }
        },
        (error: any) => {
          console.error("Error:", error);
        }
      );
  }
 

  createOrder() {
    const dayDate = this.selectedDay.day_date; // e.g., "Oct 18"

    // Convert the day_date string into a Date object (assuming it's in 'MMM dd' format)
    const currentYear = new Date().getFullYear(); // Get the current year
    const fullDateString = `${dayDate} ${currentYear}`; // Combine with year, e.g., "Oct 18 2024"

    // Create a new Date object and get the timestamp (milliseconds since 1970)
    const timestamp = new Date(fullDateString).getTime();
    const requestBody: any = {

      createdBy: this.userId,
      userId: this.userId,
      cartItemIds: this.selectedItems,
      userAddressId: this.selectedAddressId,
      deliveryScheduleDate: timestamp,
      deliveryTimeSlotId: this.selectedSlotId,
      paymentMethods: this.selectedPaymentMethod
    };

    if (this.deliveryInstruction) {
      requestBody.deliveryInstruction = this.deliveryInstruction;
    }

    this.http.post(environment.apiUrl + '/orders/create', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.orderPlaced = true;
            this.cartPage = false;
            this.orderDetails = response.orders;
            // Handle success (e.g., show a success message, navigate, or update the UI)
          } else {
            console.error('Failed to create order', response);
          }
        },
        (error) => {
          console.error('Error creating order:', error);
        }
      );
  }

  detectLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
      }, (error) => {
        console.error(error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  setActiveTittle(tittle: string): void {
    this.tittle = tittle;
  }
  // (change)="onCheckboxChange($event, item)"
  onCheckboxChange(event: any, item: any) {
    console.log(event, item,"event");

    if (event.target.checked) {
      this.selectedItems.push(item.id);
      item.hasChecked = true;
      this.add_to_cart_count()

    } else {
      // Remove the id from the array if checkbox is unchecked
      this.selectedItems = this.selectedItems.filter(itemId => itemId !== item.id);
      item.hasChecked = false;
      this.add_to_cart_count()
    }
    this.checkIfAnyCheckboxChecked();
  }
  add_to_cart_count() {
    return this.cartItems.filter((item: any) => item.hasChecked).length;
  }
  checkIfAnyCheckboxChecked() {
    // If at least one item is selected, enable the "Next" button
    this.isNextButtonDisabled = this.selectedItems.length === 0;
  }
  // cart-list
  cartList(subcategoryId?: string) {
    const subcategory_id = subcategoryId || '';

    const userIdParam = this.userId ? `&userId=${this.userId}` : '';
    const requestBody = { userId: this.userId };

    // Check if issubscribeProduct is true to decide which API to call
    const apiCall = this.issubscribeProduct
      ? this.http.get(`${environment.apiUrl}/products/get?id=${subcategory_id}${userIdParam}`, { responseType: 'json' })
      : this.http.post(environment.apiUrl + `/cart/list`, requestBody, { responseType: 'json' });

    apiCall.pipe(
      switchMap((data: any) => {

        // Initialize cartItems based on the response
        if (data.cart_output?.length > 0 || data.products) {
          // Handle case when products is a single object for subscribed products
          if (this.issubscribeProduct && !Array.isArray(data.products)) {
            data.products.forEach((x: any) => {
              x.hasChecked = false;
            })
            this.cartItems = [data.products]; // Wrap the single product in an array
          } else {
            this.cartItems = this.issubscribeProduct ? data.products : data.cart_output;
          }

          // Ensure cartItems is an array
          if (!Array.isArray(this.cartItems)) {
            this.cartItems = [];
          }


          this.count = data.count;
          this.orderEmpty = false;

          // Handle image requests
          const imageRequests = this.cartItems.map((item: any) => {

            // Conditional logic to use the correct API endpoint based on whether it's a subscription or cart
            const dataForId = this.issubscribeProduct ? item.id : item.productid; // Check if issubscribeProduct is true
            const apiEndpoint = `${environment.apiUrl}/uploads/list-by-datafor?dataFor=products&dataForId=${dataForId}`;

            return this.http.get(apiEndpoint).pipe(
              switchMap((datas: any) => {
                if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                  return this.http.get(`${environment.apiUrl}/uploads/get?id=${datas.uploads[0].id}`);
                } else {
                  return of({ status: 'ERROR' });
                }
              }),
              map((res: any) => {
                if (res.status === "SUCCESS") {
                  item.src = [res.uploads.base64data];
                } else {
                  item.src = [];
                }
                return item;
              })
            );
          });

          // Use forkJoin to wait for all image requests to complete
          return forkJoin(imageRequests).pipe(
            switchMap((itemsWithImages: any[]) => {
              // Get variations for all items
              const variationRequests = itemsWithImages.map((item: any) => {
                // Conditionally handle cart vs subscription logic
                if (this.issubscribeProduct) {
                  // Subscription product variation API call

                  return this.variationApiCall(subcategory_id, this.SubcribeProductVariationId).pipe(
                    map((filteredVariations: any) => {
                      item.variations = filteredVariations;

                      const selectedVariation = item.variations.find((v: any) => v.id === this.SubcribeProductVariationId);

                      if (selectedVariation) {
                        item.selectedVariationId = selectedVariation.id;
                        item.selectedPrice = selectedVariation.priceupto7days;
                        item.mrpPrice = selectedVariation.mrpprice;
                      }

                      return item;
                    })
                  );
                } else {
                  // Cart product variation API call
                  return this.variationApiCall(item.productid, item.productvariationid).pipe(
                    map((filteredVariations: any) => {
                      item.variations = filteredVariations;

                      const selectedVariation = item.variations.find((v: any) => v.id === item.productvariationid);

                      if (selectedVariation) {
                        item.selectedVariationId = selectedVariation.id;
                        item.selectedPrice = selectedVariation.sellingprice;
                        item.mrpPrice = selectedVariation.mrpprice;
                      }

                      return item;
                    })
                  );
                }
              });

              return forkJoin(variationRequests);
            })
          );
        } else {
          this.orderEmpty = true;
          return of([]); // Return an empty array if no cart output or products
        }
      }),
      catchError(error => {
        this.orderEmpty = true;
        console.error("Error in cartList API call:", error);
        return of([]);
      })
    ).subscribe(
      (updatedItems: any[]) => {
        this.cartItems = updatedItems;

        if (!this.issubscribeProduct) {
          this.getMrpPrice();
          this.getSubTotalPrice();
        }

      }
    );
  }
  enableNewCard(){
    this.isCardDisabled=false;
    this.selectedSavedCard=''
    this.isPlaceOrderEnabled=false
  }
 
  ShowMore() {
    this.router.navigate(['/profile'], { queryParams: { section: 'orders' } });
  }

  UserAddressListApi(): void {
    const requestBody = {
      userId: this.userId,
    };
    this.http.post(environment.apiUrl + `/useraddress/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            if (Array.isArray(data.useraddress_output)) {
              this.UserAddressList = data.useraddress_output;

              // Find the default address and set selectedAddressId
              const defaultAddress = this.UserAddressList.find(address => address.isdefault === true);
              if (defaultAddress) {
                this.selectedAddressId = defaultAddress.id; // Set the default address as selected
              } else if (this.UserAddressList.length > 0) {
                // If no default address is found, optionally select the first address
                this.selectedAddressId = this.UserAddressList[0].id;
              }
            } else {
              console.error("Expected an array for useraddress_output");
            }
          }
        },
        (error) => {
          console.error("UserAddressList API Error:", error);
        }
      );

  }

  DeliveryTimesLots() {
    const requestBody = {
      cartItemIds: this.selectedItems,

    };

    this.http.post(environment.apiUrl + `/deliverytimeslots/list-next-7days-deliveryslot`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.DeliveryTimesLotsList = data.deliverytimeslots

        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }
  selectDay(deliverytimelist: any) {
    this.selectedSlots = deliverytimelist.slots || []; // Get the slots for the selected day
    this.selectedSlotId = ''; // Reset the selected slot ID
    this.selectedDay = deliverytimelist;

  }
  variationApiCall(productid: any, productvariationid: any) {

    // if (this.variationsCache.has(productid)) {
    //   return of(this.variationsCache.get(productid));
    // }

    const requestBody = {
      productId: productid,
      productvariationId: productvariationid
    };

    return this.http.post(environment.apiUrl + `/products/list-productvariations`, requestBody, { responseType: 'json' }).pipe(
      map((data: any) => {
        if (data.status === 'SUCCESS') {

          let filteredVariations;
          if (this.issubscribeProduct) {
            // Filter by `issubscribe` when `this.issubscribeProduct` is true
            filteredVariations = data.productvariations_output.filter((variation: any) => variation.issubscribe === true);
          } else {
            // Otherwise, filter by `issale`
            filteredVariations = data.productvariations_output.filter((variation: any) => variation.issale === true);
          }

          // this.variationsCache.set(productid, filteredVariations);
          // this.variationsCacheSubject.next(this.variationsCache);
          return filteredVariations;
        } else {
          return [];
        }
      }),
      catchError(error => {
        console.error("Error in variationApiCall API call:", error);
        return of([]);
      })
    );
  }

  updatePrice(event: any, item: any): void {
    const selectedVariationId = event.target.value;
    const selectedVariation = item.variations.find((variation: any) => variation.id === selectedVariationId);

    if (selectedVariation) {
      // Update item.selectedVariationId instead of this.selectedVariationId
      item.selectedVariationId = selectedVariation.id;
      item.selectedPrice = this.issubscribeProduct ? selectedVariation.priceupto7days : selectedVariation.sellingprice;
      item.mrpPrice = selectedVariation.mrpprice;

      this.getMrpPrice();
      this.getSubTotalPrice();
      this.getPriceDifference();

    }
  }
  getMrpPrice(): number {
    return this.cartItems.reduce((total, item) => {
      if (this.issubscribeProduct) {
        item.quantity = 1
      }
      const mrpPrice = parseFloat(item.mrpPrice ?? '0');
      if (item.hasChecked) {
        return total + (mrpPrice * item.quantity);
      } else {
        return total;
      }
    }, 0);
  }

  getSubTotalPrice(): number {
    return this.cartItems.reduce((total, item) => {
      if (this.issubscribeProduct) {
        item.quantity = 1
      }
      const sellingPrice = parseFloat(item.selectedPrice ?? '0');  // Use item.selectedPrice directly
      if (item.hasChecked) {
        return total + (sellingPrice * item.quantity);
      } else {
        return total;
      }
    }, 0);
  }
  getTotalPriceAfterWalletDeduction(): number {
    const subTotalPrice = this.getSubTotalPrice();
    const walletBalance = this.WalletBalanceAmount ?? 0;

    if (this.selectedPaymentMethod.includes('wallet')) {
      // Deduct wallet balance from the subtotal
      return Math.max(subTotalPrice - walletBalance, 0); // Ensure the total doesn't go below 0
    }

    return subTotalPrice; // If wallet is not selected, return subtotal
  }

  getPriceDifference(): number {

    const mrpPrice = this.getMrpPrice();
    const subTotalPrice = this.getSubTotalPrice();

    return mrpPrice - subTotalPrice;

  }

  increaseQuantity(item: any): void {
    // Use the selectedVariationId from the item object
    const selectedVariationId = item.selectedVariationId || item.variations?.[0]?.id;
    // Find the selected variation
    const selectedVariation = item.variations.find((variation: any) => variation.id === selectedVariationId);
    if (this.issubscribeProduct) {
      item.quantity = 1
    }
    if (selectedVariation) {
      if (item.quantity > 0) {
        item.quantity = Number(item.quantity) + 1;
        if (!this.issubscribeProduct) {
          this.Add_to_cart(item.productid, selectedVariation.id, item.quantity);

        }
      }
    }
  }

  decreaseQuantity(item: any): void {
    const selectedVariationId = item.selectedVariationId || item.variations?.[0]?.id;
    const selectedVariation = item.variations.find((variation: any) => variation.id === selectedVariationId);
    if (this.issubscribeProduct) {
      item.quantity = 1
    }
    if (selectedVariation) {
      if (item.quantity > 0) {
        item.quantity = Number(item.quantity) - 1; // Convert to number and add 1
        if (!this.issubscribeProduct) {
          this.Add_to_cart(item.productid, selectedVariation.id, item.quantity);

        }
      }
    }
  }

  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };

    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {

        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }

  // allow-substitute

  allow_substitute(AllowSubstitute: boolean, CartIds: any[]) {
    const requestBody = {
      allowSubstitute: AllowSubstitute,
      cartitemIds: CartIds,
    };

    this.http.post(environment.apiUrl + `/cart/update-allow-substitute`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
        }
      },
      (error: any) => {
        // Handle the error (e.g., show an error message)
        console.error('An error occurred:', error);
      }
    );
  }

  onSubstituteChange(event: any, item: any): void {
    const isChecked = event.target.checked;
    const cartId = item.id;
    console.log(cartId, "cartId");

    this.allow_substitute(isChecked, [cartId]);
  }

  onMasterCheckboxChange(event: any): void {
    const isChecked = event.target.checked;
    this.cartItems.forEach(item => {
      item.allowsubstitute = isChecked;
    });
    const cartIds = this.cartItems.map(item => item.id);
    this.allow_substitute(isChecked, cartIds);
  }

  // delete cart-items
  deleteCartItem(cartId: any): void {
    const requestBody = {
      updatedBy: this.userId,
      cartitemIds: [cartId],
    };

    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.cartItems = this.cartItems.filter(item => !cartId.includes(item.id));
          this.count = this.cartItems.length;
          this.cartService.updateCartCount(this.userId);
        }
      },
      (error: any) => {
        console.error('An error occurred:', error);
      }
    );
  }


  clearAllItems() {
    // Extract all cart IDs from cartItems
    const cartIds = this.cartItems.map(item => item.id);

    if (cartIds.length > 0) {
      // Call the delete function with all cart IDs
      this.deleteCartItem(cartIds);
    }
  }
}





