import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stripe-order-success',
  templateUrl: './stripe-order-success.component.html',
  styleUrls: ['./stripe-order-success.component.css']
})
export class StripeOrderSuccessComponent {

  userId: string | null = null;
  isLoggedIn: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient ,public auth: AuthService// Import HttpClient for API calls
  ) { this.auth.userInfo.subscribe((data: any) => {

    if (data) {

      this.userId = data?.id;
      this.isLoggedIn = !!data;
     
    } else {
      this.isLoggedIn = false;
    }
  });}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const id = params['id']; // Extract 'id' from query parameters

      if (id) {
        this.updatePaymentStatus(id);
      } else {
        console.error('No ID provided in query parameters');
      }
    });
  }

  updatePaymentStatus(id:any) {
 
   
     // Find product
    const requestBody ={
      refNo: id,
    // updatedBy: this.userId,
    paymentStatus	: "success"
     }
 
    this.http.post(environment.apiUrl + `/orders/payment_status_update
 `, requestBody, { responseType: 'json' }).subscribe({
      next:(data: any) => {
        if (data.status === 'SUCCESS') {
        
        }
      },
      error:(error: any) => {
        console.error("Error in cartList API call:", error);
      }
  });
  }
  goHome() {
    this.router.navigate(['/home']);
}

viewOrder(){
  this.router.navigate(['/profile'], { queryParams: { section:'orders' } });
 
}
  
}


 
// {
//   "refNo": "plink_1QaWuhLW9shJTXnhwz8NBlIL",
//   "updatedBy": "b0f4561f-fd85-45f3-b3a6-edcd3de9ee68",
//   "paymentStatus": "success"
// }
// localhost:3059/userwallet/payment_status_update

// {
//   "refNo": "plink_1QaXmILW9shJTXnhPseJ9VZ1",
//   "updatedBy": "f47bd44b-c3cf-4c2b-af41-6684dc48aef1",
//   "transactionStatus": "success"
// }
// localhost:3059/orders/payment_status_update

// {
//   "refNo": "plink_1QaWuhLW9shJTXnhwz8NBlIL",
//   "updatedBy": "b0f4561f-fd85-45f3-b3a6-edcd3de9ee68",
//   "paymentStatus": "success"
// }

