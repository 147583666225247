import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCartComponent } from './add-cart/add-cart.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { HomeComponent } from './home/home.component';
import { ProductListPagesComponent } from './product-list-pages/product-list-pages.component';
import { ProductPagesComponent } from './product-pages/product-pages.component';
import { SubscribeListProductComponent } from './subscribe-list-product/subscribe-list-product.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { CancellationPolicyPageComponent } from './cancellation-policy-page/cancellation-policy-page.component'
 
const routes: Routes = [
  {
    path: '', redirectTo: '/home', pathMatch: 'full'
  },
  {
    path: 'home', component: HomeComponent,
  },
  {
    path: 'login', component: LoginComponent,
  },
  {
    path: 'register', component: RegisterComponent,
  },
  {
    path: 'product-list-pages', component: ProductListPagesComponent,
  },
  {
    path: 'product-pages/:id', component: ProductPagesComponent,
  },
  {
    path: 'add-cart', component: AddCartComponent,
  },
  {
    path: 'subscribe-list-pages', component: SubscribeListProductComponent,
  },
  { path: 'profile', component: ProfilePageComponent },

  { path: 'contact-us', component: ContactUsPageComponent },
 
  { path: 'about-us', component: AboutUsPageComponent },
 
  { path: 'terms-conditions', component: TermsAndConditionsComponent },
 
  { path: 'privacy-policy', component: PrivacyPolicyPageComponent },
   
  { path: 'cancellation-policy', component: CancellationPolicyPageComponent },
 
 
  
  {path:'404-not-found',component:ErrorPageComponent},
  { path: '**', redirectTo: '404-not-found' }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
