import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cartCount = new BehaviorSubject<number>(0);
  cartCount$ = this.cartCount.asObservable();

  constructor(private http: HttpClient) {}

  updateCartCount(userId: string) {
    const requestBody = { userId: userId };
    this.http.post(environment.apiUrl + `/cart/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.cartCount.next(data.count);
        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }

  resetCartCount() {
    this.cartCount.next(0);
  }

  getCartCount() {
    return this.cartCount.getValue();
  }
}