import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { Clipboard } from '@angular/cdk/clipboard';
 
 
declare var $: any;
@Component({
  selector: 'app-product-pages',
  templateUrl: './product-pages.component.html',
  styleUrls: ['./product-pages.component.css']
})
export class ProductPagesComponent implements OnInit {
  iscomboavailable = false;
  ishalalfood = false;
  isSale: boolean | undefined;
  isSubscribe: boolean | undefined;
  mrpPrice: number | undefined;
  priceToShow!: number;
  variationDisplay: string | undefined;
  selectedVariation: any;
  CategoryId: any[] = [];
  productDetails: any = {};
  ComboOfferList: any = {};
  productVariations: any[] = [];
  flavorList: string[] = [];
  selectedFlavor: string = '';
  category: string | undefined;
  variations: any[] = [];
  dataFromPreviousComponent: string | undefined;
  mainImage: string = '';
  selectedIndex: number = 0;
  selectedSize = '50 ML';
  isExpanded = false;
  isDeliverySchedule: boolean = false;
  productImages: { src: string[] } = { src: [] };
  sanitizedDescription: SafeHtml = '';
  activeTab = 'description';
  product_list_Id: any;
  additionaldetails: any;
  isLoggedIn: boolean = false;
  showMore: boolean = false;
  FoodDeliverySlot: boolean = false;
  responsiveOptions: any | undefined;
  numVisible: number = 1;
  variationid: any;
  userId: string | null = null;
  add_cart: boolean = true;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
  startIndex: number = 0;
  endIndex: number = 5;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  hostName: any = "";
  showCopiedMessage = false;
 
  // numVisible: number = 3;
 
  // Image sources for controls
  prevImageSrc: string = '../../assets/icon/carousel-down.svg';
  nextImageSrc: string = '../../assets/icon/carousel-down.svg';
 
  offers = [
    {
      title1: "Johnson's & Johnson's Baby Shampoo",
      title2: "Johnson's & Johnson's Baby Soap",
      image1: "../../assets/images/johnsonoil.svg",
      image2: "../../assets/images/johnson.svg",
      comboOffer: "Combo offer",
      extraOffer: "Save extra 20%",
      price: "£6.98",
      individualPrices: "(£4.99 + £1.99)"
    },
    {
      title1: "Johnson's & Johnson's Baby Shampoo",
      title2: "Johnson's & Johnson's Baby Soap",
      image1: "../../assets/images/johnsonoil.svg",
      image2: "../../assets/images/johnson.svg",
      comboOffer: "Combo offer",
      extraOffer: "Save extra 20%",
      price: "£6.98",
      individualPrices: "(£4.99 + £1.99)"
    },
    {
      title1: "Johnson's & Johnson's Baby Shampoo",
      title2: "Johnson's & Johnson's Baby Soap",
      image1: "../../assets/images/johnsonoil.svg",
      image2: "../../assets/images/johnson.svg",
      comboOffer: "Combo offer",
      extraOffer: "Save extra 20%",
      price: "£6.98",
      individualPrices: "(£4.99 + £1.99)"
    }
  ];
  @ViewChild('tabSection', { static: false }) tabSection!: ElementRef;
 
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public auth: AuthService,
    private cartService: CartService,
    private elementRef: ElementRef,
    private clipboard: Clipboard,
 
  ) {
    this.hostName = window.location.host;
    const navigation = this.router.getCurrentNavigation();
    const dataToPass = navigation?.extras?.state?.['dataToPass'];
   
    console.log(dataToPass,"dataToPass");
   
    if (dataToPass) {
      this.dataFromPreviousComponent = dataToPass.datafrom || '';
      this.variationid = dataToPass.variationId || '';
      this.category = dataToPass.name || '';
     
      // Store in local storage with fallback values
      localStorage.setItem('dataFromPreviousComponent', this.dataFromPreviousComponent||'');
      localStorage.setItem('variationId', this.variationid);
      localStorage.setItem('category', this.category||'');
    } else {
      // Retrieve from local storage or use an empty string as fallback
      this.dataFromPreviousComponent = localStorage.getItem('dataFromPreviousComponent') || '';
      this.variationid = localStorage.getItem('variationId') || '';
      this.category = localStorage.getItem('category') || '';
    }
 
    this.isDeliverySchedule = this.dataFromPreviousComponent === 'subscribeProduct';
 
 
    // Subscribing to auth userInfo
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {
      if (data) {
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      } else {
        this.isLoggedIn = false;
      }
    });
  }
 
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.product_list_Id = params.get('id');
      const navigation = this.router.getCurrentNavigation();
      const dataToPass = navigation?.extras?.state?.['dataToPass'];
     
      console.log(dataToPass,"dataToPass");
     
      if (dataToPass) {
        this.dataFromPreviousComponent = dataToPass.datafrom || '';
        this.variationid = dataToPass.variationId || '';
        this.category = dataToPass.name || '';
       
        // Store in local storage with fallback values
        localStorage.setItem('dataFromPreviousComponent', this.dataFromPreviousComponent||'');
        localStorage.setItem('variationId', this.variationid);
        localStorage.setItem('category', this.category||'');
      } else {
        // Retrieve from local storage or use an empty string as fallback
        this.dataFromPreviousComponent = localStorage.getItem('dataFromPreviousComponent') || '';
        this.variationid = localStorage.getItem('variationId') || '';
        this.category = localStorage.getItem('category') || '';
      }
      console.log(this.product_list_Id,"this.product_list_Id")
      this.ProductListCall(this.product_list_Id);
 
    })
 
    this.responsiveOptions = [
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '810px',
        numVisible: 2,
        numScroll: 1
      },
 
    ];
 
    this.updateVisibleItems();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItems();
  }
 
 
 
  updateVisibleItems() {
 
    const width = window.innerWidth;
    if (width > 1199) {
      this.numVisible = 1;
    }
  }
 
  // @ViewChild('myCarousel', { static: false }) myCarousel!: ElementRef;
 
  ngAfterViewInit() {
    // Initialize the carousel using jQuery
    // $(this.myCarousel.nativeElement).carousel({
    //   interval: 3000, // Adjust the interval as needed
    //   wrap: true
    // });
  }
 
 
  prevImage(): void {
 
    if (this.startIndex > 0) {
      this.startIndex--;
      this.endIndex--;
    }
    // if (this.startIndex > 0) {
    //   this.startIndex -= this.numVisible;
    // }
  }
 
  nextImage() {
    if (this.endIndex < this.productImages.src.length) {
      this.startIndex++;
      this.endIndex++;
 
    }
    // if (this.startIndex + this.numVisible < this.productImages.src.length) {
    //   this.startIndex += this.numVisible;
    // }
  }
 
  get visibleImages() {
    return this.productImages.src.slice(this.startIndex, this.startIndex + this.numVisible);
  }
 
  hoverImage(control: string, isHover: boolean) {
    if (control === 'prev') {
      this.prevImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    } else if (control === 'next') {
      this.nextImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    }
  }
 
  getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }
 
  selectImage(index: number): void {
    this.mainImage = this.productImages.src[index];
    this.selectedIndex = index;
  }
 
  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }
 
 
 
    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }
 
  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }
 
  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }
 
 
  onScroll(e: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, child: any) {
    if ((child.clientWidth - el.scrollLeft) <= el.clientWidth + 1) {
      // this.loadmore();
 
      // if (this.showMoreReview && !this.reviewLoading) {
      //   this.reviewLoading = true;
      //   this.loadmore();
      // }
    }
  }
 
  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }
 
  toggleShowMore() {
    this.showMore = !this.showMore;
    this.setActiveTab('description');
 
    const tabSectionPosition = this.tabSection.nativeElement.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: tabSectionPosition - 200, // Adjust this value (-100) to control how far up you want to scroll
      behavior: 'smooth'
    });
 
  }
 
 
  addToCart(): void {
    // alert(Added ${this.selectedSize} of ${this.productDetails.name} to the cart!);
  }
 
 
  selectSize(variation: any): void {
 
    this.selectedVariation = variation;
 
    this.productDetails.selectedVariation = variation.productvariationid;
 
    this.priceToShow = this.getPriceToShow(variation);
    this.mrpPrice = variation.mrpprice;
    if (this.selectedVariation.variationid && this.selectedVariation.iscomboavailable === true) {
      this.iscomboavailable = true;
    }
    else {
      this.iscomboavailable = false;
 
    }
    if (this.selectedVariation.name === 'Food') {
      this.FoodDeliverySlot = true;
    }
    else if (this.selectedVariation.name === 'Grocery') {
      this.FoodDeliverySlot = false;
 
    }
 
 
    this.combo_offer(this.productDetails.selectedVariation)
 
  }
 
  getPriceToShow(variation: any): number {
    if (variation.issubscribe && variation.issale) {
      if (variation.dataFromPreviousComponent === 'bestSelling') {
        this.FoodDeliverySlot = true
        this.priceToShow = variation.sellingprice; // Set priceToShow when dataFromPreviousComponent is 'bestSelling'
        if (variation.name === 'Food') {
          this.FoodDeliverySlot = true;
        } else if (variation.name === 'Grocery') {
          this.FoodDeliverySlot = false;
        }
 
      } else if (variation.dataFromPreviousComponent === 'subscribeProduct') {
        this.priceToShow = variation.priceupto7days; // Set priceToShow when dataFromPreviousComponent is 'subscribe'
      } else {
        this.priceToShow = variation.priceupto7days; // Default to priceupto7days if both issubscribe and issale are true
      }
    } else if (variation.issubscribe) {
      this.priceToShow = variation.priceupto7days; // Set priceToShow if only issubscribe is true
    } else if (variation.issale) {
      this.priceToShow = variation.sellingprice; // Set priceToShow if only issale is true
    } else {
      this.priceToShow = variation.mrpprice; // Default to mrpPrice if neither issubscribe nor issale is true
    }
    return this.priceToShow; // Return the updated priceToShow
  }
 
 
 
 
 
  ProductListCall(subcategoryId?: string): void {
    const id = subcategoryId || '';
    const userIdParam = this.userId ? `&userId=${this.userId}` : '';
    this.http.get(`${environment.apiUrl}/products/get?id=${id}${userIdParam}`).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const CategoryIds = data.products.categoryid || [];
          if (CategoryIds) {
            this.CategoryId = [CategoryIds];
          }
         
 
          this.flavorList = data.products.flavor || null;
 
          if (data.cartitems && data.cartitems.flavor && data.cartitems.flavor.length > 0) {
            this.selectedFlavor = data.cartitems.flavor[0];
          }
          const element = this.elementRef.nativeElement.querySelector('.scroll_top');
          if (element) {
            window.scroll(0, 0);
          }
          this.productDetails = data.products;
          console.log(this.productDetails,"this.productDetails");
         
          if (data.products.ishalalfood === true) {
            this.ishalalfood = true;
          }
          else {
            this.ishalalfood = false;
          }
          this.productDetails.variations = [];
          const matchingVariation = this.productDetails.productvariations.find(
            (variation: any) => variation.productvariationid === this.variationid
          );
 
          if (matchingVariation) {
            this.productDetails.cartitemsquantity = matchingVariation.cartitemsquantity;
            this.productDetails.cartid = matchingVariation.cartid;
            this.productDetails.selectedVariation = matchingVariation.productvariationid;
 
          }
          if (this.productDetails.productvariations && this.productDetails.productvariations.length > 0) {
 
            this.productDetails.productvariations.sort((a: any, b: any) => a.sellingprice - b.sellingprice);
 
            this.productDetails.productvariations.forEach((x: any) => {
 
              if (x.productvariationid && x.iscomboavailable === true) {
                this.iscomboavailable = true;
              }
              else {
                this.iscomboavailable = false;
 
              }
              if (this.dataFromPreviousComponent === 'subscribeProduct' && x.issubscribe === true) {
                // Filter for subscribeProduct
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice, sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale: x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  name: this.category,
                  iscomboavailable: x.iscomboavailable
 
 
                });
                this.productDetails.variations.sort((a: any, b: any) => {
                  if (a.variationvalue < b.variationvalue) {
                return a.sellingprice - b.sellingprice;
                // return -1;
                  }
                  if (a.variationvalue > b.variationvalue) {
                return a.sellingprice - b.sellingprice;
                // return 1;
                  }
                return a.sellingprice - b.sellingprice;
                // return 0;
                });
              } else if (this.dataFromPreviousComponent === 'bestSelling' && x.issale === true) {
                // Filter for bestSelling
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice,
                  sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale: x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  name: this.category,
                  iscomboavailable: x.iscomboavailable
 
 
 
                });
                // Sort variations by variationvalue in ascending order
                this.productDetails.variations.sort((a: any, b: any) => {
                  if (a.variationvalue < b.variationvalue) {
                return a.sellingprice - b.sellingprice;
                // return -1;
                  }
                  if (a.variationvalue > b.variationvalue) {
                return a.sellingprice - b.sellingprice;
                // return 1;
                  }
                return a.sellingprice - b.sellingprice;
                // return 0;
                });
 
              } else if (this.dataFromPreviousComponent === '' && x.issale === true) {
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice,
                  sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale: x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  name: this.category,
                  iscomboavailable: x.iscomboavailable
 
 
 
                });
                // Sort variations by variationvalue in ascending order
                this.productDetails.variations.sort((a: any, b: any) => {
                  if (a.variationvalue < b.variationvalue) {
                return a.sellingprice - b.sellingprice;
                // return -1;
                  }
                  if (a.variationvalue > b.variationvalue) {
                return a.sellingprice - b.sellingprice;
                // return 1;
                  }
                return a.sellingprice - b.sellingprice;
                // return 0;
                });
 
              }
            })
 
            if (this.variationid) {
              const mathProductvariationId = this.productDetails.variations.find(
                (variation: any) => variation.productvariationid === this.variationid
              );
              this.selectedVariation = mathProductvariationId;
 
 
            } else if (this.productDetails.variations.length > 0) {
              this.selectedVariation = this.productDetails.variations[0]; // Set the first variation as default
 
 
            }
            else {
              this.selectedVariation = this.productDetails.productvariations[0];
 
            }
            // Set the default variation (first one)
            // this.selectedVariation = this.productDetails.productvariations[0];
 
            // Calculate the default price to show based on the first variation
            this.getPriceToShow(this.selectedVariation);
            this.mrpPrice = this.selectedVariation.mrpprice;
          }
 
          // Other code for description and image handling
          if (this.productDetails.description) {
            this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.productDetails.description);
          } else {
            this.sanitizedDescription = '';
          }
 
          let x = data.products.additionaldetails;
 
          try {
            if (x && x.trim() !== '') {
              this.additionaldetails = JSON.parse(x);
            } else {
              this.additionaldetails = {}; // or any default value
            }
          } catch (e) {
            console.error("Error parsing additionaldetails:", e);
            this.additionaldetails = {}; // Handle the error by assigning a default value
          }
console.log("saji");
 
          this.http.get(`${environment.apiUrl}/uploads/list-by-datafor?dataFor=products&dataForId=${this.productDetails.id}`).subscribe((datas: any) => {
            if (datas.status === "SUCCESS") {
              console.log(datas,"datas");
             
              this.productImages.src = [];
 
              datas.uploads.forEach((upload: any, index: number) => {
                this.http.get(`${environment.apiUrl}/uploads/get?id=${upload.id}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {
                    this.productImages.src.push(res.uploads.base64data);
                    if (index === 0) { // Set the first image as the default main image
                      this.mainImage = this.productImages.src[0];
                      this.selectedIndex = 0;
                    }
                  }
                });
              });
            }
          });
          if (this.userId) {
            this.wish_list();
 
          }
          this.combo_offer(this.variationid);
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
 
  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to login page if user is not logged in
      this.router.navigate(['/login']);
      return;
    }
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity,
      flavor: this.selectedFlavor ? [this.selectedFlavor] : null
    };
 
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
 
          this.productDetails.cartitemsquantity = quantity;
 
 
          // const product = this.productDetails.productvariations.find(
          //   (variation: any) => variation.productvariationid === data.productvariationid
          // );
          // if (product) {
          //   product.cartitemsquantity = quantity;
 
          // }
 
          this.cartService.updateCartCount(data.newCartCount);
          if (data.cartitems && data.cartitems.flavor && data.cartitems.flavor.length > 0) {
            this.selectedFlavor = data.cartitems.flavor[0];
          }
        }
      },
      (error: any) => {
        console.error("Error in add-to-cart API call:", error);
      }
    );
  }
 
  increaseQuantity(productId: number, productVariationId: string) {
    const product = this.productDetails.productvariations.find(
      (variation: any) => variation.productvariationid === productVariationId
    );
    if (product) {
      product.cartitemsquantity += 1;
 
      // this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
      this.productDetails.cartitemsquantity = product.cartitemsquantity; // Update the main product cart quantity
    }
  }
 
  decreaseQuantity(productId: number, productVariationId: string, cartId: any) {
    const product = this.productDetails.productvariations.find(
      (variation: any) => variation.productvariationid === productVariationId
    );
    if (product) {
      if (product.cartitemsquantity > 1) {
        product.cartitemsquantity -= 1;
        this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
      } else if (product.cartitemsquantity === 1) {
        product.cartitemsquantity = 0;
        this.productDetails.cartitemsquantity = 0;  // Also set the main product cart quantity to 0
        this.deleteCartItem(cartId);  // Call delete function when quantity reaches zero
      }
    }
  }
 
  deleteCartItem(cartId: any): void {
    const requestBody = {
      updatedBy: this.userId,
      cartitemIds: [cartId],
    };
 
    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          // Update cart count after deletion
          this.cartService.updateCartCount(data.newCartCount);
 
          // Find the product variation associated with the deleted cart item
          const product = this.productDetails.productvariations.find(
            (variation: any) => variation.cartid === cartId
          );
 
          if (product) {
            // Reset the cart quantity to 0 for the deleted product variation
            product.cartitemsquantity = 0;
          }
 
          // Reset main product cartitemsquantity if it was the last item in the cart
          this.productDetails.cartitemsquantity = 0;
 
        }
      },
      (error: any) => {
        console.error('An error occurred while deleting the cart item:', error);
      }
    );
  }
 
  Subscribe_click() {
    const subcribe = "SubcribeProduct"
    this.router.navigate(['/add-cart'], {
      state: { dataToPass: { datafrom: subcribe } }
    });
  }
 
 
  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const newStatus = !product.wishlistIcon;
 
    product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId
      };
 
      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
        .subscribe(
          (data: any) => {
            // Handle the success response here if needed
          },
          (error) => {
            console.error("API Error:", error);
          }
        );
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }
 
  wish_list(): void {
    const requestBody = {
      userId: this.userId,
    };
 
    this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            const wish_list_product = data.wishlistitems_output;
 
            // Check if the product is in the wishlist
            const isInWishlist = wish_list_product.some((item: any) => item.productid === this.productDetails.id);
 
            // Set wishlistIcon property based on the result
            this.productDetails.wishlistIcon = isInWishlist;
          }
        },
        (error) => {
          console.error("Wishlist API Error:", error);
        }
      );
  }
 
  copyTextToClipboard(params: any,) {
    this.clipboard.copy(params);
 
    this.showCopiedMessage = true;
 
    // Hide the message after 3 seconds
    setTimeout(() => {
      this.showCopiedMessage = false;
    }, 3000);
 
  }
 
  combo_offer(variationId: any): void {
    const requestBody = {
      productId: this.product_list_Id,
      productVariationId: variationId
    };
    this.http.post(environment.apiUrl + `/combos/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            this.ComboOfferList = data.combos_output;
 
            // Loop through each combo to fetch images
            this.ComboOfferList.forEach((combo: any) => {
              const imageInfoArray = JSON.parse(combo.imageinfo); // Parse imageinfo JSON string
              combo.images = []; // Initialize an empty array to store image data
 
              // Loop through parsed image info array and fetch each image
              imageInfoArray.forEach((imageInfo: any) => {
                this.http.get(`${environment.apiUrl}/uploads/get?id=${imageInfo.imageid}`).subscribe(
                  (res: any) => {
                    if (res.status === "SUCCESS") {
                      combo.images.push(res.uploads.base64data); // Store base64 image data
                      if (combo.images.length === 1) { // Set the first image as the main image
                        combo.mainImage = combo.images[0];
                      }
                    }
                  },
                  (error) => console.error("Image Fetch Error:", error)
                );
              });
            });
          }
        },
        (error) => {
          console.error("Combo Offer API Error:", error);
        }
      );
  }
 
}
 
 
 
 
 
 
 