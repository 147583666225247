import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input'
import { BehaviorSubject, catchError, forkJoin, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { ProductService } from '../services/product.service';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { loadStripe } from '@stripe/stripe-js';
import * as bootstrap from 'bootstrap';
declare module 'bootstrap';

declare const google: any;
declare var $: any;

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.css']
})

export class ProfilePageComponent implements OnInit {
  activeSection: string | null = null;
  userId: string | null = null;
  wishlistitems_output: any[] = [];
  user_details: any = {};
  gender: string | null = null;
  src: any;
  price: any;
  WalletBalanceAmount: any;
  name: string | null = null;
  showOrderPopup = false;
  UserOrderList: any[] = [];
  orderDetails: any;
  cartCount: any;
  wishlistCount: any;
  count: any;
  productList: any[] = [];
  cartItems: any[] = [];
  wishList: any[] = [];
  profileImageUrl: string = '';
  selectedTimeline: string = 'old_transaction';
  myGroup: FormGroup;
  MyCard: FormGroup;
  priceToShow!: number;
  newMobileInput: boolean = false;
  NewMobileInputOpen: boolean = false;
  NewEmailInputOpen: boolean = false;
  verifyEmailBtn: boolean = false;
  NewMobileSavebtn: boolean = false;
  isNameEditing: boolean = false;
  isGenderEditing: boolean = false;
  isPhoneEditing: boolean = false;
  isEmailEditable: boolean = false;
  isUserDetailEmail: boolean = false;
  showsubscriptionPopup = false;
  showCardDeletePopup = false;
  isEditMode: boolean = false;
  usercardid: any = null;
  isEditCardMode: boolean = false;
  passwordInput: string = '';
  isVerifyDisabled: boolean = true;
  latitude: number | undefined | null;
  longitude: number | undefined | null;
  isLoggedIn: boolean = false;
  isVerifyPasswordDisabled: boolean = true;
  showSignoutPopup = false;
  showOtpVerifyPopup = false;
  showSubscribePaymentDetails: boolean = false;
  selectedPaymentMethod: boolean = false;
  UserAddressList: any[] = [];
  userWalletList: any[] = [];
  userCardsList: any[] = [];

  selectedAddressId: string = '';
  showPhoneOtpVerifyPopup = false;
  showOrderDetails: boolean = false;
  showSubscribeDetails: boolean = false;
  showPauseSubscribeDetails: boolean = false;
  isCardDisabled: boolean = false;
  showWllaetDetails: boolean = false;
  showCardPaymentContent = false;
  showCardAddressContent = false;
  showNewAddress = false;
  isMapVisible: boolean = true;
  isPasswordEditable: boolean = false;
  oldemail: string = 'example@email.com';
  oldPassword: string = '********';
  newPassword: string = '';
  OldPasswordVerifyPopup = false;
  passwordVisible: boolean = false;
  mobileNum: string | null = null;
  addedToCart = new Map<number, boolean>();
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  phone: any;  // Depending on the format from ngx-intl-tel-input
  selectedCountryISO: CountryISO = CountryISO.India; // Default value
  deliveryArea: string = '';
  address: string = '';
  doorNo: string = '';
  landmark: string = '';
  postalCode: string = '';
  selectedAddressType: string = 'Home';
  selectedPaymentDetailsMethod: boolean = false;
  showDeletePopup: boolean = false;  // Track whether the popup is shown
  addressToDelete: any = null;
  cardToDelete: any = null;
  logintype: any = null;
  isEmailEditing: boolean = false;
  showEmailOtpVerifyPopup: boolean = false;
  isOldEmail: boolean = true;
  otpMessage: string = '';
  phoneToVerify: string = '';
  isOldPhone: boolean = true;
  isChangePassword: boolean = false;
  UserNotificationList: any[] = [];
  notificationCount: any
  originalFirstName: string = '';
  originalLastName: string = '';
  originalGender: string = '';

  // card save 
  cardHolderName: string = '';
  cardNumber: string = '';
  expiryDate: string = '';
  cvv: string = '';
  expiryMonth: string = '';
  expiryYear: string = '';
  selectedSavedCard: any = null;

  // walletCardNumber: string = '';
  // walletExpiryDate: string = '';
  // walletCVC: string = '';
  // Message: string = '';
  cvvMessage: string = '';
  isCardNumberValid: boolean = false;
  isCardNumberCvvValid: boolean = false;
  isExpiryValid: boolean = true;
  // expiryMessage: string = '';

  // map
  map!: google.maps.Map;
  selectedAddress: string = '';
  private variationsCache = new Map<string, any[]>();
  private variationsCacheSubject = new BehaviorSubject<Map<string, any[]>>(this.variationsCache);
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  stripe: any;
  cardElement: any;
  cardErrors: string | null = null;
  // wallet
  walletAmount: string = '';
  isAmountValid: boolean = false;
  isCardValid: boolean = false;
  saveCardDetails: boolean = false;
  pauseSubscription = false;
  pauseSubscriptionDate = false;

  isEditable: boolean = false;
  isdeliveryArea: boolean = true;

  constructor(private route: ActivatedRoute, private router: Router, private elementRef: ElementRef, private productService: ProductService, public auth: AuthService, private http: HttpClient, private cartService: CartService, private fb: FormBuilder, private renderer: Renderer2, private snackBar: MatSnackBar, private cdr: ChangeDetectorRef) {
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {

      if (data) {

        this.userId = data?.id;
        this.isLoggedIn = !!data;
        this.cartList();
        this.getUserDetails();
      } else {
        this.isLoggedIn = false;
      }
    });

    this.myGroup = this.fb.group({
      firstname: [{ value: this.user_details.firstname, disabled: true }],
      lastname: [{ value: this.user_details.lastname, disabled: true }],
      gender: [{ value: this.user_details.gender, disabled: true }],
      mobileInput: [{ value: this.user_details.phone, disabled: true }],
      newEmailInput: [{ value: '', disabled: false }],
      oldPassword: [{ value: '', disabled: false }],
      newPassword: [{ value: '', disabled: false }],
      otpCode: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
      oldEmail: [{ value: this.user_details.email, disabled: true }],
      newEmail: ['', [Validators.required, Validators.email]],
      newMobileInput: ['', [ // Add an initial value (empty string)
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(15),
        Validators.pattern(/^\+?\d{10,15}$/) // Regex for 10-15 digit phone numbers
      ]],
    });

    this.MyCard = this.fb.group({
      cardHolderName: [{ value: this.user_details.firstname, disabled: true }],
      // cardNumber: [{ value: this.user_details.lastname, disabled: true }],
      // expiryDate: [{ value: this.user_details.gender, disabled: true }],
      // cvv: [{ value: this.user_details.phone, disabled: true }],
    });
  }

  ngOnInit(): void {
    this.initTooltips();
    this.NotificationList();
    this.getUnreadCount();
    this.orderList();
    this.route.queryParams.subscribe(params => {
      if (params['section']) {
        this.activeSection = params['section'];

        if (this.activeSection !== 'orders') {
          // Navigate without the `id` parameter
          this.router.navigate([], { queryParams: { section: this.activeSection } });
        } else if (params['id']) {
          // Handle the `id` parameter for "orders" section
          const Id = params['id'];
          this.getOrder(Id);
        }

        // Additional logic for other sections
        if (this.activeSection === 'address') {
          this.UserAddressListApi();
        } else if (this.activeSection === 'wallet') {
          this.UserWallet();
          this.UserWalletList();
        } else if (this.activeSection === 'wishlist') {
          this.wish_list();
        } else if (this.activeSection === 'cards') {
          this.UserCardList();
        }
      } else {
        this.activeSection = null;
      }

      // Scroll to top when navigating
      const element = this.elementRef.nativeElement.querySelector('.profile-container');
      if (element) {
        window.scroll(0, 0);
      }
    });

    if (this.userId) {
      this.auth.getUserDataById(this.userId).subscribe((res: any) => {
        if (res.status === "SUCCESS") {
          this.user_details = res.users;
          this.name = res.users.firstname;

          // Call the helper method to fetch the profile image
          this.auth.fetchProfileImage(res.users.id);
        }
      });
    } else {
      console.warn('User ID is null.');
    }
  }

  onSavedCardSelect(card: any) {
    this.selectedSavedCard = card;
    this.isCardDisabled = true;
  }
  async stripePayment() {

    this.stripe = await loadStripe(environment.STRIPE_PUBLISHABLE_KEY);

    const elements = this.stripe.elements();

    // Create card element with ZIP code disabled
    this.cardElement = elements.create('card', {
      hidePostalCode: true  // Disable ZIP code
    });

    this.cardElement.mount('#card-element');

    let thisObject = this;
    this.cardElement.on('change', (event: any) => {
      this.isCardValid = !event.error && event.complete;
      this.cardErrors = event.error ? event.error.message : null;
    });
  }

  convertToNumber(value: string): number {
    return Number(value);
  }

  async handlePayment(amount?: number) {
    if (amount !== undefined && (amount <= 0 || isNaN(amount))) {
      alert("Please enter a valid amount.");
      return;
    }
    if (this.selectedSavedCard) {

      this.createPaymentIntent(this.selectedSavedCard.paymentmethodid, amount ?? 0);
      return;
    }
    const { error, paymentMethod } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,

    });
    if (error) {
      console.error(error);
      return;
    }
    if (this.selectedPaymentMethod) {
      const paymentAmount = amount ?? 0;
      if (this.saveCardDetails) {
        this.SaveCard(paymentMethod.id, paymentMethod.card);
        this.createPaymentIntent(paymentMethod.id, paymentAmount);

      } else {
        this.createPaymentIntent(paymentMethod.id, paymentAmount);
      }
    } else if (this.showCardPaymentContent) {
      this.SaveCard(paymentMethod.id, paymentMethod.card);
    }

  }

  createPaymentIntent(paymentMethodId: string, amount: number): void {

    const requestBody = {
      amount: amount,                 // Payment amount
      paymentMethodId: paymentMethodId,  // Stripe payment method ID
      createdBy: this.userId,          // ID of the user who initiated the payment
      userId: this.userId,             // User ID for the payment record
      transactionMethod: "card",       // Payment method type, e.g., card
      // currency: "INR",                 // Currency for the payment
      returnUrl: "https://gpstore.amicodevelopment.net/home"  // URL to return after payment
    };

    this.http.post(environment.apiUrl + '/userwallet/create', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          const clientSecret = response.paymentIntent?.client_secret;
          const confirmationMethod = response.paymentIntent?.confirmation_method;
          this.showWllaetDetails = false
          this.selectedPaymentMethod = false
          this.walletAmount = '';
          this.UserWalletList()
          this.UserWallet()
          if (clientSecret && confirmationMethod !== 'automatic') {
            // If confirmation_method is not 'automatic', confirm payment manually
            this.stripe.confirmCardPayment(clientSecret)
              .then((result: any) => {
                if (result.error) {
                  console.error('Payment Error:', result.error.message, result.error);
                } else if (result.paymentIntent.status === 'succeeded') {
                  alert('Payment successful!');
                  this.UserWalletList();
                } else {
                  console.error('Payment failed:', result.paymentIntent.status);
                }
              })
              .catch((error: any) => {
                console.error('Error during payment confirmation:', error);
              });
          } else if (confirmationMethod === 'automatic' && response.paymentIntent?.status === 'succeeded') {
            // Payment is automatically confirmed and succeeded
            alert('Payment successful!');
          } else {
            console.error('Failed to initiate payment or payment is already confirmed', response);
          }
        },
        (error) => {
          console.error('Error initiating payment:', error);
        }
      );
  }


  initTooltips(): void {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  toggleNameEdit() {
    this.isNameEditing = !this.isNameEditing;
    if (this.isNameEditing) {
      this.originalFirstName = this.myGroup.get('firstname')?.value;
      this.originalLastName = this.myGroup.get('lastname')?.value;
      this.myGroup.get('firstname')?.enable();
      this.myGroup.get('lastname')?.enable();
    } else {
      this.myGroup.get('firstname')?.disable();
      this.myGroup.get('lastname')?.disable();
    }
  }
  toggleOrderPopup() {
    this.showOrderPopup = !this.showOrderPopup;
  }

  toggleGenderEdit() {
    this.isGenderEditing = !this.isGenderEditing;
    if (this.isGenderEditing) {
      this.originalGender = this.myGroup.get('gender')?.value;
      this.myGroup.get('gender')?.enable();
    } else {
      this.myGroup.get('gender')?.disable();
    }
  }
  togglePauseSubscribeDate() {
    this.pauseSubscriptionDate = !this.pauseSubscriptionDate;
    this.pauseSubscription = false;
  }

  togglePauseSubscribe() {
    this.pauseSubscription = !this.pauseSubscription;
    this.pauseSubscriptionDate = false;
  }

  wish_list() {
    const requestBody = {
      userId: this.userId,
    }
    this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.wishlistCount = data.count

          this.wishList = data.wishlistitems_output
          const element = this.elementRef.nativeElement.querySelector('.profile-container');
          if (element) {
            window.scroll(0, 0);
          }

          const imageRequests = this.wishList.map((item: any) =>
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`).pipe(
              switchMap((datas: any) => {

                if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                  return this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`);
                } else {
                  return of({ status: 'ERROR' });
                }
              }),
              map((res: any) => {
                if (res.status === "SUCCESS") {
                  item.src = [res.uploads.base64data];
                } else {
                  item.src = [];
                }
                return item;
              })
            )
          );
          forkJoin(imageRequests).subscribe((results) => {
            this.wishList = results;
          });

          data.wishlistitems_output.forEach((item: any) => {
            this.getPriceToShow(item);
            item.src = [];
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.id}`).subscribe((datas: any) => {
              if (datas.status === "SUCCESS") {
                if (datas.uploads.length > 0) {
                  this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      item.src.push(res.uploads.base64data);
                    }
                  });
                }
              }
            });
          })

          this.wishlistitems_output = data.wishlistitems_output;
        }
      })
  }

  getUserDetails() {
    this.http.get(`${environment.apiUrl}/users/get?id=${this.userId}`).subscribe((res: any) => {
      if (res.status === "SUCCESS") {
        this.user_details = res.users;

        // Parse and set image info if available
        const imageInfoArray = this.user_details.imageinfo ? JSON.parse(this.user_details.imageinfo) : [];
        if (Array.isArray(imageInfoArray) && imageInfoArray.length > 0) {
          const imageId = imageInfoArray[0].imageid;
          // Add any additional code needed to handle the imageId here
        }

        // Call function to upload images
        this.uploadimages(this.user_details.id);

        // Set user details properties
        this.name = this.user_details.firstname;
        this.logintype = this.user_details.logintype;

        // Handle email presence
        this.isUserDetailEmail = this.user_details.email !== null;

        // Format gender if available
        this.gender = this.user_details.gender
          ? this.user_details.gender.charAt(0).toUpperCase() + this.user_details.gender.slice(1).toLowerCase()
          : null;

        // Format phone and set selected country
        if (this.user_details.phone) {
          const phone = this.user_details.phone.replace(/^\+(\d{2})/, ''); // Remove country code
          this.mobileNum = phone;
          this.selectedCountryISO = this.user_details.phone.startsWith('+44')
            ? CountryISO.UnitedKingdom
            : CountryISO.India;
          this.isPhoneEditing = true;
        } else {
          this.mobileNum = null;
          this.isPhoneEditing = false;
        }

        // Manage mobile input control enable/disable
        const mobileInputControl = this.myGroup.get('mobileInput');
        if (mobileInputControl) {
          if (this.isPhoneEditing) {
            mobileInputControl.disable();
          } else if (this.NewMobileSavebtn) {
            mobileInputControl.enable();
          } else {
            mobileInputControl.disable();
          }
        }

        // Manage email input control enable/disable
        const oldEmailInputControl = this.myGroup.get('oldEmail');
        if (oldEmailInputControl) {
          if (this.isUserDetailEmail) {
            oldEmailInputControl.disable();
          } else if (this.verifyEmailBtn) {
            oldEmailInputControl.enable();
          } else {
            oldEmailInputControl.disable();
          }
        }

        // Patch values to the form
        this.myGroup.patchValue({
          firstname: this.user_details.firstname,
          lastname: this.user_details.lastname,
          gender: this.gender,
          mobileInput: this.mobileNum,
          oldEmail: this.user_details.email
        });

        // Handle profile image
        try {
          this.user_details.imageinfo = typeof this.user_details.imageinfo === 'string'
            ? JSON.parse(this.user_details.imageinfo)
            : this.user_details.imageinfo || [];

          if (Array.isArray(this.user_details.imageinfo) && this.user_details.imageinfo.length > 0) {
            const firstImageId = this.user_details.imageinfo[0].imageid;
            // Additional code to fetch and set the image can go here
            // You might want to call a separate function to handle the image fetching logic
          } else {
            this.profileImageUrl = "../../assets/images/profile-man.svg"; // Default profile image
          }
        } catch (e) {
          console.error('Failed to parse imageinfo:', e);
          this.profileImageUrl = "../../assets/images/profile-man.svg"; // Fallback to default
        }
      }
    });
  }

  GoBack() {

    if (this.showNewAddress) {
      // If the address form is open, go back to the map
      this.showNewAddress = false; // Hide the new address form
      this.isMapVisible = true;
      setTimeout(() => {
        this.initMap();
      }, 0);
    } else if (this.isMapVisible) {
      // If the map is visible, close it and hide the address card
      this.showCardAddressContent = false;
      this.isMapVisible = false;
      this.isEditMode = false;     // Reset edit mode
      this.UserAddressListApi();   // Reload or refresh the address list if needed
    }
  }

  // signout
  signout() {

    this.auth.logout();
    this.name = null;
    localStorage.clear();
    this.userId = "";
    this.cartService.resetCartCount();
    this.clearCookies();
    // this.router.navigate(['/home']).then(() => {
    // });
  }

  clearCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }

  UserAddressListApi(): void {
    const requestBody = {
      userId: this.userId,
    };

    this.http.post(environment.apiUrl + `/useraddress/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            if (Array.isArray(data.useraddress_output)) {
              this.UserAddressList = data.useraddress_output;

              // Find the default address and set selectedAddressId
              const defaultAddress = this.UserAddressList.find(address => address.isdefault === true);
              if (defaultAddress) {
                this.selectedAddressId = defaultAddress.id; // Set default address as selected
              } else if (this.UserAddressList.length > 0) {
                // If no default address, select the first address in the list
                this.selectedAddressId = this.UserAddressList[0].id;
              }
            } else {
              console.error("Expected an array for useraddress_output");
            }
          }
        },
        (error) => {
          console.error("UserAddressList API Error:", error);
        }
      );
  }
  UserEditAddress(id: any): void {
    this.selectedAddressId = id;
    this.isEditMode = true;
    // Make the GET request to retrieve the address by id
    this.http.get(environment.apiUrl + `/useraddress/get?id=${id}`, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            // Assuming the response contains the address data
            const addressData = response.useraddress;

            // Set the component variables to the retrieved address values
            this.firstName = addressData.firstname;
            this.lastName = addressData.lastname;
            this.phone = addressData.phone;
            this.selectedCountryISO = CountryISO.India;
            this.email = addressData.email;
            this.deliveryArea = addressData.deliveryarea;
            this.address = addressData.address;
            this.doorNo = addressData.doorno;
            this.landmark = addressData.landmark;
            this.postalCode = addressData.postalcode;
            this.latitude = addressData.latitude;
            this.longitude = addressData.longitude;
            this.selectedAddressType = addressData.type
            this.showNewAddress = true;
            this.showCardAddressContent = false;
          } else {
            console.error('Failed to retrieve address:', response);
          }
        },
        (error) => {
          console.error('Error retrieving address:', error);
        }
      );
  }
  deleteAddressConfirmation(address: any): void {
    this.addressToDelete = address;  // Store the address to delete
    this.showDeletePopup = true;     // Show the popup
  }

  confirmDeleteAddress(): void {


    if (this.addressToDelete) {
      const requestBody = {
        id: this.addressToDelete.id,
        updatedBy: this.userId,
      };

      this.http.post(
        `${environment.apiUrl}/useraddress/delete`,
        requestBody,
        { responseType: 'json' }
      ).subscribe(
        (response: any) => {

          if (response.status === 'SUCCESS') {

            // if (this.addressToDelete) {
            //   // Call your delete API here and pass this.addressToDelete.id
            //   this.http.delete(environment.apiUrl + `/useraddress/delete?id=${this.addressToDelete.id}`)
            //     .subscribe(
            //       (response: any) => {
            //         if (response.status === 'SUCCESS') {
            this.UserAddressList = this.UserAddressList.filter(address => address.id !== this.addressToDelete.id);

          } else {
            console.error('Failed to delete address');
          }
        },
        (error) => {
          console.error('Error deleting address:', error);
        }
      );
      this.showDeletePopup = false; // Close popup after deletion
    }
  }

  cancelDelete(): void {
    this.showDeletePopup = false;  // Close the popup without deleting
  }
  drafultAddress(id: any): void {
    const requestBody = {
      id: id,
      updatedBy: this.userId,
      userId: this.userId,
      isDefault: "true",
    };

    this.http.post(environment.apiUrl + '/useraddress/set-as-default', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.UserAddressList.forEach(address => {
              address.isdefault = (address.id === id); // Set isdefault based on the updated address
            });
            this.selectedAddressId = id; // Update selectedAddressId if needed
            // Handle success (e.g., show a success message, navigate, or update the UI)
            this.UserAddressListApi()
          } else {
            console.error('Failed to set-as-default', response);
          }
        },
        (error) => {
          console.error('Error set-as-default:', error);
        }
      );
  }

  enableEdit() {
    // this.selectedAddressId =id;
    this.isdeliveryArea = false;
    this.isEditable = true;
    this.isMapVisible = true;
    this.showCardAddressContent = true;

    setTimeout(() => {
      this.initMap();
    }, 0);
  }

  toogleSelectedPaymentDetailsMethod() {
    this.selectedPaymentDetailsMethod = !this.selectedPaymentDetailsMethod;
  }

  saveAddress(id: string) {
    if (this.isEditMode) {
      this.updateUserAddress(id);
    } else {
      this.createUserAddress();
    }
  }
  createUserAddress(): void {
    const requestBody = {
      id: this.selectedAddressId,  // Or you can dynamically generate an ID if necessary
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone?.e164Number, // Assuming this is a method to get the phone number
      deliveryArea: this.selectedAddress,
      address: this.address,
      doorNo: this.doorNo,
      landmark: this.landmark,
      postalCode: this.postalCode,
      latitude: this.latitude,
      longitude: this.longitude,
      createdBy: this.userId,
      userId: this.userId,
      type: this.selectedAddressType,
    };

    this.http.post(environment.apiUrl + '/useraddress/create', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.showNewAddress = false;
            this.isMapVisible = true;
            this.GoBack()
            // Handle success (e.g., show a success message, navigate, or update the UI)
          } else {
            console.error('Failed to create address', response);
          }
        },
        (error) => {
          console.error('Error creating address:', error);
        }
      );
  }

  // validation for name
  validateInput(event: KeyboardEvent): void {
    const pattern = /[A-Za-z]/; // Allow only alphabets
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault(); // Prevent input if it's not an alphabet
    }
  }

  updateUserAddress(id: any): void {
    this.selectedAddressId = id;
    const requestBody = {
      id: this.selectedAddressId,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone?.e164Number,
      deliveryArea: this.deliveryArea,
      address: this.address,
      doorNo: this.doorNo,
      landmark: this.landmark,
      postalCode: this.postalCode,
      latitude: this.latitude,
      longitude: this.longitude,
      updatedBy: this.userId,
      type: this.selectedAddressType,
    };

    // Send HTTP PUT request to update the address
    this.http.post(environment.apiUrl + '/useraddress/update', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.showNewAddress = false;
            this.isMapVisible = true;
            this.GoBack();
            // Handle success, such as showing a success message or updating UI
          } else {
            console.error('Failed to update address', response);
          }
        },
        (error) => {
          console.error('Error updating address:', error);
        }
      );
  }
  setAddressType(type: string): void {
    this.selectedAddressType = type;
  }
  resetAddressForm(): void {
    this.firstName = '';
    this.lastName = '';
    this.phone = '';
    this.email = '';
    this.deliveryArea = '';
    this.address = '';
    this.doorNo = '';
    this.landmark = '';
    this.postalCode = '';
    this.selectedAddressType = ''; // or 'home', 'office', etc. if you have a default
    this.latitude = null;
    this.longitude = null;
  }
  toggleCardAddressContent() {
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
    this.selectedAddress = '';
    this.showCardAddressContent = !this.showCardAddressContent; // Toggle showCardAddressContent
    if (this.showCardAddressContent) {
      this.isMapVisible = true; // Show map only if the card content is visible
      this.showNewAddress = false;
      this.isdeliveryArea = true;
      this.resetAddressForm();
      setTimeout(() => {
        this.initMap();
      }, 0);
    } else {
      this.isMapVisible = false; // Hide map if card content is hidden
    }
  }

  initMap() {
    this.isMapVisible = true

    const mapElement = document.getElementById("map") as HTMLElement;
    if (!mapElement) {

      // console.error("Map container not found.");
      return;
    }
    else {
      const input = document.getElementById("pac-input") as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);
      if (!input) {
        // console.error("Search input not found. Aborting map initialization.");
        return;
      }


      const defaultLat = -33.8688;
      const defaultLng = 151.2195;

      // Use the selected latitude and longitude if available, else default coordinates
      const centerLat = Number(this.latitude) || defaultLat;
      const centerLng = Number(this.longitude) || defaultLng;

      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          center: { lat: centerLat, lng: centerLng },
          zoom: 13,
          mapTypeId: "roadmap",
        }
      );

      // Add a marker at the center of the map
      let centerMarker = new google.maps.Marker({
        position: { lat: centerLat, lng: centerLng },
        map: map,
        title: "Selected Location", // Optional: You can set a title for the marker
      });

      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      // Bias the SearchBox results towards current map's viewport.
      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });

      let markers: google.maps.Marker[] = [];

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener("places_changed", () => {
        const places: google.maps.places.PlaceResult[] | undefined = searchBox.getPlaces();

        if (!places || places.length === 0) {
          return;
        }

        // Remove the center marker if a new place is selected
        if (centerMarker) {
          centerMarker.setMap(null); // Hide the center marker
        }

        // Clear out the old markers.
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            return;
          }

          if (place.formatted_address) {
            this.selectedAddress = place.formatted_address;
            this.cdr.detectChanges();// Set the selected address
            if (this.isEditMode) {
              this.deliveryArea = this.selectedAddress;
              this.cdr.detectChanges();
            }
            // Set the selected address

            // Initialize the geocoder
            const geocoder = new google.maps.Geocoder();

            // Geocode the address
            geocoder.geocode({ address: this.selectedAddress }, (results: {
              geometry: {
                location: {
                  lat(): number | null | undefined; lng: () => number | null | undefined;
                };
              };
            }[], status: string) => {
              if (status === google.maps.GeocoderStatus.OK) {
                // Get latitude and longitude from the result
                this.latitude = results[0].geometry.location.lat();
                this.longitude = results[0].geometry.location.lng();
              } else {
                console.error('Geocoding failed: ' + status);
              }
            });
          }

          const icon = {
            url: place.icon as string,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(25, 25),
          };

          // Create a marker for the selected place.
          markers.push(
            new google.maps.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            })
          );

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        map.fitBounds(bounds);
      });
    }

  }

  saveNameChanges() {
    this.saveChanges();
  }
  toggleShowPaymentDetails() {
    this.selectedPaymentMethod = !this.selectedPaymentMethod;
    this.UserCardList()
    setTimeout(() => {
      this.stripePayment();
    }, 0);

  }


  saveGenderChanges() {
    this.saveChanges();
  }

  saveChanges() {
    // this.showPhoneOtpVerifyPopup = true;
    const requestBody: any = {
      updatedBy: this.userId,
      userType: "user",
      id: this.userId
    }; // Base request

    // Retrieve updated phone value from the form control
    const formValue = this.myGroup.value;


    if (formValue.firstname === '') {
      alert("First name cannot be empty.");
      return;
    } else if (formValue.firstname) {
      requestBody.firstName = formValue.firstname;
    }
    if (formValue.lastname === '') {
      requestBody.lastName = null; // or use '' to indicate removal
    } else if (formValue.lastname) {
      requestBody.lastName = formValue.lastname;
    }
    if (formValue.gender) requestBody.gender = formValue.gender;
    if (formValue.email) requestBody.email = formValue.email;
    if (formValue.mobileInput) requestBody.phone = formValue.mobileInput;
    // Log the request body for debugging purposes
    this.http.post(environment.apiUrl + `/users/user-update`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (requestBody.firstName) {
          this.auth.userName.next(`${formValue.firstname} `);
        }

        this.getUserDetails();

        if (formValue.firstname || formValue.lastName) {
          this.isNameEditing = false;
          this.myGroup.get('firstname')?.disable();
          this.myGroup.get('lastname')?.disable();
        } else if (formValue.gender) {
          this.isGenderEditing = false;
          this.myGroup.get('gender')?.disable();
        } else if (formValue.email) {
          this.isEmailEditable = false;
        } else if (formValue.mobileInput) {
          this.isPhoneEditing = false;

        }



      }
    );
  }
  toggleEmailEdit() {
    const oldEmailInputControl = this.myGroup.get('oldEmail');

    if (oldEmailInputControl) {
      oldEmailInputControl.enable();
    }


    this.verifyEmailBtn = true;
    this.isEmailEditing = true; // Start editing mode
  }




  cancelGenderEdit() {
    this.isGenderEditing = false;
    this.myGroup.get('gender')?.setValue(this.originalGender);
    this.myGroup.get('gender')?.disable();
  }


  sendOtpForPhoneUpdate() {
    const newMobileInputValue = this.myGroup.get('newMobileInput')?.value;
    const oldMobileInputValue = this.myGroup.get('mobileInput')?.value;
    let phoneToUpdate: string = oldMobileInputValue?.e164Number ?? newMobileInputValue?.e164Number ?? this.user_details.phone ?? '';

    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      userType: 'user',
      phone: phoneToUpdate,  // Send either old or new phone number
    };

    // Make the API call to send the OTP
    this.http.post(
      environment.apiUrl + `/users/phone-send-code-userphone-update`,
      requestBody,
      { responseType: 'json' }
    ).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          // Show OTP popup and enable "Verify" button
          if (this.OldPasswordVerifyPopup) {
            this.showPhoneOtpVerifyPopup = false;
          } else {
            this.showPhoneOtpVerifyPopup = true;
          }
          this.isVerifyDisabled = false;  // Enable the "Verify" button after OTP is sent
          this.myGroup.get('otpCode')?.reset();
          // Set isOldPhone based on whether a new number was provided
          if (newMobileInputValue) {
            this.isOldPhone = false;  // Indicate that this is for the new number
            this.otpMessage = 'OTP is sent to your new number';
            this.phoneToVerify = newMobileInputValue.e164Number;  // Track the new phone number
          } else {
            this.isOldPhone = true;  // Indicate that this is for the old number
            this.otpMessage = 'OTP is sent to your old number';
            this.phoneToVerify = this.user_details.phone;  // Track the old phone number
          }
        } else {
          console.error('Failed to send OTP:', response);
        }
      },
      (error) => {
        console.error('API error:', error);

        // Check if the error response contains the specific error message
        if (error.error && error.error.errors && error.error.errors.phone && error.error.errors.phone.length > 0) {
          const errorMessage = error.error.errors.phone[0]; // Get the first error message
          // Show the specific error message in a snackbar
          this.snackBar.open(errorMessage, 'Close', {
            duration: 3000, // Duration in milliseconds
          });
        } else {
          // Optionally handle other errors
          this.snackBar.open('An error occurred. Please try again.', 'Close', {
            duration: 3000, // Duration in milliseconds
          });
        }
      }
    );
  }

  verifyOtpForPhoneUpdate() {
    // Get the OTP and phone values from the form and user details
    const otpCode = this.myGroup.get('otpCode')?.value;
    // Retrieve the phone value based on which input is active
    const phone = this.NewMobileInputOpen
      ? this.myGroup.get('newMobileInput')?.value?.e164Number
      : this.myGroup.get('mobileInput')?.value?.e164Number;


    // Check if OTP and phone values are present before making the API call
    if (!otpCode || !phone) {
      console.error('OTP or phone number is missing');
      this.snackBar.open('OTP or phone number is missing. Please try again.', 'Close', {
        duration: 3000,
      });
      return; // Exit if validation fails
    }

    // Prepare the request body for the API call
    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      phoneCode: otpCode,
      phone: phone
    };

    // Make the API call to validate OTP and update phone
    this.http.post(
      `${environment.apiUrl}/users/phone-code-validator-userphone-update`,
      requestBody,
      { responseType: 'json' }
    ).subscribe(
      (response: any) => {

        if (response.status === 'SUCCESS') {
          this.snackBar.open('Phone number verified successfully!', 'Close', {
            duration: 3000,

          });
          if (!this.isOldPhone || !this.isPhoneEditing) {
            this.NewMobileInputOpen = false;
            this.NewMobileSavebtn = false

          } else {
            this.NewMobileInputOpen = true;
          }

          this.getUserDetails();
          this.toggleVerifyPhoneOtpPopup(false); // Close the OTP popup
        } else if (response.status === 'ERROR') {
          if (response.errors && response.errors.action && response.errors.action.length > 0) {
            const errorMessage = response.errors.action[0];
            this.snackBar.open(errorMessage, 'Close', {
              duration: 3000,
            });
          } else if (response.errors && response.errors.phone && response.errors.phone.length > 0) {
            const phoneErrorMessage = response.errors.phone[0]; // Handle specific phone validation errors
            this.snackBar.open(phoneErrorMessage, 'Close', {
              duration: 3000,
            });
          } else {
            this.snackBar.open('An unknown error occurred. Please try again.', 'Close', {
              duration: 3000,
            });
          }
        }
      },
      (error) => {
        console.error('API error:', error); // Log the full error for debugging
        let errorMessage = 'An error occurred. Please try again.';

        // Handle specific HTTP status errors or API error responses
        if (error.status === 451) {
          errorMessage = 'The request could not be processed due to legal reasons.';
        } else if (error.error && error.error.message) {
          errorMessage = error.error.message; // Use the API's error message
        }

        this.snackBar.open(errorMessage, 'Close', {
          duration: 3000,
        });
      }
    );
  }

  increaseQuantity(productId: number, productVariationId: string) {
    const product = this.wishList.find(item => item.id === productId);

    if (product) {
      // Ensure productVariationId is defined
      productVariationId = productVariationId || product.productvariationid;
      productId = product.productid;

      // Increase the quantity
      product.cartitemsquantity = (Number(product.cartitemsquantity) || 0) + 1;

      // Add updated quantity to cart
      this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
    }
  }

  decreaseQuantity(productId: number, productVariationId: string) {
    const product = this.wishList.find(item => item.id === productId);

    if (product) {
      productId = product.productid;
      productVariationId = product.productvariationid;

      // Decrease the quantity or delete if it reaches 1
      if (product.cartitemsquantity > 1) {
        product.cartitemsquantity = Number(product.cartitemsquantity) - 1;
        this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
      } else if (product.cartitemsquantity === 1) {
        this.deleteCartItem(product.cartid);  // Delete item from cart if quantity is 1
        product.cartitemsquantity = null;  // Reset quantity in UI
        this.cdr.detectChanges();
      }
    }
  }

  deleteCartItem(cartId: any): void {
    const requestBody = {
      updatedBy: this.userId,
      cartitemIds: [cartId],
    };

    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          if (this.userId !== null && this.userId !== undefined) {
            this.cartService.updateCartCount(this.userId);
          } else {
            console.error('User ID is null or undefined. Cannot update cart count.');
          }
        }
      },
      (error: any) => {
        console.error('An error occurred:', error);
      }
    );
  }


  toggleVerifyPhoneOtpPopup(isOldPhone: boolean): void {
    this.showPhoneOtpVerifyPopup = !this.showPhoneOtpVerifyPopup;
    if (this.showPhoneOtpVerifyPopup) {
      this.myGroup.get('otpCode')?.reset();
    }
    this.isOldPhone = isOldPhone;
  }
  toggleCancel() {
    this.newMobileInput = false;
  }

  togglePhoneEdit() {
    const oldPhoneInputControl = this.myGroup.get('mobileInput');

    if (oldPhoneInputControl) {
      oldPhoneInputControl.enable();
    }

    this.NewMobileSavebtn = true;
  }




  // Function to cancel email editing
  toggleCancelEmail() {
    const oldEmailInputControl = this.myGroup.get('oldEmail');

    this.verifyEmailBtn = false;
    if (oldEmailInputControl) {
      oldEmailInputControl.disable();

    }

    this.isEmailEditing = false;
    this.myGroup.get('newEmail')?.reset();
  }

  // Function to send OTP for email verification
  sendOtpForEmailUpdate() {
    const newEmailInputValue = this.myGroup.get('newEmail')?.value;
    const oldEmailInputValue = this.myGroup.get('oldEmail')?.value;

    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      userType: 'user',
      email: newEmailInputValue || oldEmailInputValue, // Use new email if provided, else use old email
    };

    this.http.post(`${environment.apiUrl}/users/email-send-code-useremail-update`, requestBody)
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {

            if (this.OldPasswordVerifyPopup) {
              this.showEmailOtpVerifyPopup = false;
            } else {
              this.showEmailOtpVerifyPopup = true;
            }
            this.isOldEmail = !newEmailInputValue; // Set based on whether a new email was provided
            this.myGroup.get('otpCode')?.reset();

            if (newEmailInputValue) {
              this.isOldEmail = false;  // Indicate that this is for the new number
              this.otpMessage = 'OTP is sent to your newEmailInputValue';
            } else {
              this.isOldEmail = true;  // Indicate that this is for the old number
              this.otpMessage = 'OTP is sent to your oldEmailInputValue';
            }


          } else {
            console.error('Failed to send OTP:', response);
          }
        },
        (error) => {
          console.error('API error:', error);
          this.snackBar.open('An error occurred. Please try again.', 'Close', { duration: 3000 });
        }
      );
  }

  // Function to verify the OTP for email update
  verifyOtpForEmailUpdate() {
    const otpCode = this.myGroup.get('otpCode')?.value;
    const email = this.isOldEmail ? this.myGroup.get('oldEmail')?.value : this.myGroup.get('newEmail')?.value;

    if (!otpCode || !email) {
      this.snackBar.open('OTP or email address is missing. Please try again.', 'Close', { duration: 3000 });
      return;
    }

    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      emailCode: otpCode,
      email: email,
    };

    this.http.post(`${environment.apiUrl}/users/email-code-validator-useremail-update`, requestBody)
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.snackBar.open('Email verified successfully!', 'Close', { duration: 3000 });

            if (!this.isOldEmail || !this.isUserDetailEmail) {
              this.NewEmailInputOpen = false;
              this.verifyEmailBtn = false
              this.NewMobileSavebtn = false
            } else {
              this.NewEmailInputOpen = true;
            }
            this.getUserDetails(); // Refresh user details after update
            this.toggleVerifyEmailOtpPopup(); // Close OTP popup
          } else {
            this.snackBar.open('Verification failed. Please check your OTP and try again.', 'Close', { duration: 3000 });
          }
        },
        (error) => {
          console.error('API error:', error);
          this.snackBar.open('An error occurred. Please try again.', 'Close', { duration: 3000 });
        }
      );
  }

  // Function to toggle the OTP verification popup for email
  toggleVerifyEmailOtpPopup() {
    this.showEmailOtpVerifyPopup = !this.showEmailOtpVerifyPopup;
    if (this.showEmailOtpVerifyPopup) {
      this.myGroup.get('otpCode')?.reset();
    }
  }

  cancelEmailEdit() {
    this.isEmailEditable = false;
  }
  toggleShowSubscribePaymentDetails() {
    this.showSubscribePaymentDetails = !this.showSubscribePaymentDetails;
  }

  allowOnlyNumbers(event: KeyboardEvent) {
    const charCode = event.keyCode || event.which;
    // Allow only numbers (0-9), backspace (8), delete (46), and arrow keys
    if ((charCode < 48 || charCode > 57) && charCode !== 8 && charCode !== 46) {
      event.preventDefault();
    }
  }
  validateAmount() {
    const amount = Number(this.walletAmount);
    // If walletAmount is not a valid number, clear the input and show an alert
    if (isNaN(amount) || amount <= 0) {
      this.walletAmount = ''; // Reset the input
      // alert('Please enter a valid number greater than 0.');
      this.isAmountValid = false;
    } else {
      // If the amount is valid, enable the button
      this.isAmountValid = true;
    }
  }

  toggleVerifyOtpPopup() {
    this.showOtpVerifyPopup = !this.showOtpVerifyPopup;
  }






  highlightBorder() {
    const inputOtpElement = document.querySelector('.otp-input');
    inputOtpElement?.classList.add('highlighted');
    const inputPasswordElement = document.querySelector('.password-input');
    inputPasswordElement?.classList.add('highlighted');
    const inputPhoneElement = document.querySelector('.phone-otp-input');
    inputPhoneElement?.classList.add('highlighted');
  }

  resetBorder() {
    const inputElement = document.querySelector('.otp-input');
    inputElement?.classList.remove('highlighted');
    const inputPasswordElement = document.querySelector('.password-input');
    inputPasswordElement?.classList.remove('highlighted');
  }

  // change password
  editPassword() {
    this.isChangePassword = true;

  }
  forgotOldPassword() {
    this.router.navigate(['/login']);

  }

  cancelPasswordEdit() {
    this.isChangePassword = false;
  }



  savePassword() {
    // Add logic to save the new password
    // this.isChangePassword = false;
    this.OldPasswordVerifyPopup = true;
    // this.onChangePassword()
  }

  onChangePassword(): void {
    const newPassword = this.myGroup.get('newPassword')?.value;

    // const userId = localStorage.getItem('userId');

    if (!this.userId) {
      console.error('User ID not found in local storage'); // Debugging log
      this.snackBar.open('User ID not found', 'close', { duration: 3000, verticalPosition: 'top' });
      return;
    }
    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      newPassword: newPassword
    };

    this.http.post(`${environment.apiUrl}/users/change-password-update`, requestBody).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          this.isChangePassword = false
          this.snackBar.open('Password changed successfully', 'close', { duration: 3000, verticalPosition: 'top' });
          localStorage.removeItem('userId'); // Clear userId from local storage
        } else {
          this.snackBar.open('Failed to change password', 'close', { duration: 3000, verticalPosition: 'top' });
        }
      },
      error: () => {
        this.snackBar.open('Error occurred while changing password', 'close', { duration: 3000, verticalPosition: 'top' });
      }
    });
  }
  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }
  toggleVerifyPasswordPopup() {
    this.OldPasswordVerifyPopup = !this.OldPasswordVerifyPopup;
  }

  verifyPassword() {
    const newPassword = this.myGroup.get('newPassword')?.value;
    const oldPassword = this.myGroup.get('oldPassword')?.value;
    // const userId = localStorage.getItem('userId');

    if (!this.userId && !newPassword && !oldPassword) {
      console.error('User ID not found in local storage'); // Debugging log
      this.snackBar.open('User ID not found', 'close', { duration: 3000, verticalPosition: 'top' });
      return;
    }
    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      newPassword: newPassword,
      oldPassword: oldPassword
    };

    this.http.post(`${environment.apiUrl}/users/change-password-update`, requestBody).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          this.isChangePassword = false
          this.OldPasswordVerifyPopup = false
          this.snackBar.open('Password changed successfully', 'close', { duration: 3000, verticalPosition: 'top' });
        } else {
          this.snackBar.open('Failed to change password', 'close', { duration: 3000, verticalPosition: 'top' });
        }
      },
      error: () => {
        this.snackBar.open('Error occurred while changing password', 'close', { duration: 3000, verticalPosition: 'top' });
      }
    });
  }
  // end

  //  wallet
  UserWallet(): void {
    this.http.get(environment.apiUrl + `/userwallet/get-available-balance?userId=${this.userId}`, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.WalletBalanceAmount = response.availableBalance

          } else {
            console.error('Failed to retrieve wallet:', response);
          }
        },
        (error) => {
          console.error('Error retrieving wallet:', error);
        }
      );
  }
  UserWalletList() {
    const requestBody = {
      userId: this.userId,

      timeLine: this.selectedTimeline || 'old_transaction',
    }
    this.http.post(environment.apiUrl + `/userwallet/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.userWalletList = data.userwallet_output;

        }
      })
  }

  cancelNameEdit() {
    this.isNameEditing = false;
    this.myGroup.get('firstname')?.setValue(this.originalFirstName);
    this.myGroup.get('lastname')?.setValue(this.originalLastName);
    this.myGroup.get('firstname')?.disable();
    this.myGroup.get('lastname')?.disable();
  }


  UserCardList() {
    const requestBody = {
      userId: this.userId,
    }
    this.http.post(environment.apiUrl + `/usercarddetails/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.userCardsList = data.usercarddetails_output;

        }
      })
  }
  resetCardForm(): void {
    this.usercardid = '';
    this.cardHolderName = '';
    // this.cardNumber = '';
    // this.expiryDate = '';
    // this.cvv = '';
    // this.expiryMonth = '';
    // this.expiryYear = '';


  }

  toggleCardPaymentContent(): void {

    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }
    // if (!this.isEditCardMode) {
    //   this.resetCardForm()
    //   setTimeout(() => {
    //     this.stripePayment();
    //   }, 0);
    // }
    // if (card) {
    //   this.usercarddetails = card; // If editing, set selected card
    // } else {
    //   this.usercarddetails = null; // If adding, clear selected card
    // }
    setTimeout(() => {
      this.stripePayment();
    }, 1000);
    this.showCardPaymentContent = !this.showCardPaymentContent; // Toggle the form visibility
  }

  UserEditCard(id: any): void {
    this.isEditCardMode = true;
    this.http.get(environment.apiUrl + `/usercarddetails/get?id=${id}`, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {

            const cardData = response.usercarddetails;
            this.usercardid = cardData.id;
            this.cardHolderName = cardData.cardholdername;
            this.cardNumber = cardData.cardnumber;
            this.expiryMonth = cardData.expmonth
            this.expiryYear = cardData.expyear
            this.expiryDate = cardData.expirydate;
            this.cvv = cardData.cvv;



            this.toggleCardPaymentContent(); // Show the edit form with the card details
          } else {
            console.error('Failed to retrieve card details:', response);
          }
        },
        (error) => {
          console.error('Error retrieving card details:', error);
        }
      );
  }

  SaveCard(paymentMethodId: any, paymentMethodCard: any): void {
    this.createUserSaveCard(paymentMethodId, paymentMethodCard);

  }


  createUserSaveCard(paymentMethodId: any, paymentMethodCard: any): void {
    const requestBody = {
      createdBy: this.userId,
      userId: this.userId,
      cardHolderName: this.cardHolderName,
      cardNumber: paymentMethodCard.last4,
      expMonth: paymentMethodCard.exp_month,
      expYear: paymentMethodCard.exp_year,
      paymentMethodId: paymentMethodId,
      brand: paymentMethodCard.brand
    };

    this.http.post(environment.apiUrl + '/usercarddetails/create', requestBody, { responseType: 'json' })
      .subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS') {
            this.showCardPaymentContent = false;
            this.saveCardDetails = false;
            this.UserCardList();
            this.cardHolderName = '';
          } else {
            console.error('Failed to create card', response);
          }
        },
        (error) => {
          console.error('Error creating card:', error);
        }
      );
  }
  backArrow() {
    this.showCardPaymentContent = false;
    this.isEditCardMode = false;
  }

  deleteCardConfirmation(card: any): void {
    this.cardToDelete = card;  // Store the address to delete
    this.showCardDeletePopup = true;     // Show the popup
  }
  isFormValid(): boolean {
    return !!this.firstName && !!this.selectedAddress && !!this.address && !!this.postalCode && !!this.selectedAddressType;
  }


  confirmDeleteCard(): void {
    if (this.cardToDelete) {
      const requestBody = {
        id: this.cardToDelete.id,
        updatedBy: this.userId,
      };

      this.http.post(
        `${environment.apiUrl}/usercarddetails/delete`,
        requestBody,
        { responseType: 'json' }
      ).subscribe(
        (response: any) => {

          if (response.status === 'SUCCESS') {
            this.userCardsList = this.userCardsList.filter(card => card.id !== this.cardToDelete.id);

          } else {
            console.error('Failed to delete card');
          }
        },
        (error) => {
          console.error('Error deleting card:', error);
        }
      );
      this.showCardDeletePopup = false; // Close popup after deletion
    }
  }

  cancelDeleteCard(): void {
    this.showCardDeletePopup = false;  // Close the popup without deleting
  }


  onTimelineChange(timeline: string): void {
    this.selectedTimeline = timeline;
    this.UserWalletList();  // Call the function to update the wallet list with the selected timeline
  }



  showNewAddressContainer() {
    this.showNewAddress = true; // Show the "Add New Address" form
    this.isMapVisible = false;  // Hide the map
    this.isdeliveryArea = true;
  }



  toggleOrderDetails(): void {
    this.showOrderDetails = !this.showOrderDetails;
  }

  toggleSubscribeDetails() {
    this.showSubscribeDetails = !this.showSubscribeDetails;
  }
  togglePauseSubscribeDetails() {
    this.showPauseSubscribeDetails = !this.showPauseSubscribeDetails;
  }
  enableNewCard() {
    this.isCardDisabled = false;
    this.isCardValid = false
    this.selectedSavedCard = ''
  }

  tooglePayWithLink() {
    this.selectedPaymentMethod = false
  }

  toggleWalletDetails() {
    this.showWllaetDetails = !this.showWllaetDetails
    if (this.showWllaetDetails) {
      this.selectedPaymentMethod = false;
      this.isCardDisabled = false;
      this.selectedSavedCard = ''
    }
  }
  toggleSignoutPopup() {
    this.showSignoutPopup = !this.showSignoutPopup;
  }

  toggleSubscriptionPopup() {
    this.showsubscriptionPopup = !this.showsubscriptionPopup;
  }



  onSectionChange(section: string): void {
    this.activeSection = section;
    if (!section) {
      this.router.navigate(['/profile']);
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { section: section },
      queryParamsHandling: 'merge', // Preserve other query params if there are any
    });
    if (section === 'wallet') {
      this.UserWallet();
      this.UserWalletList()
    }
    if (section === 'wishlist') {
      this.wish_list();
    }
    if (section === 'cards') {
      this.UserCardList();
    }
    if (section === 'notifications') {
      this.NotificationList();
    }

  }

  removeWishList(productId: any, productVariationId: any) {
    const requestBody = {
      status: -1,
      createdBy: this.userId,
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId
    };

    this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        const index = this.wishlistitems_output.findIndex(
          (item: any) => item.productid === productId && item.productvariationid === productVariationId
        );

        if (index !== -1) {
          this.wishlistitems_output.splice(index, 1);
        }
        this.wishlistCount = this.wishlistitems_output.length;
      })
  }

  cartList() {
    const requestBody = { userId: this.userId };

    this.http.post(environment.apiUrl + `/cart/list`, requestBody, { responseType: 'json' }).pipe(
      switchMap((data: any) => {
        if (data.status === 'SUCCESS') {
          this.cartItems = data.cart_output;
          this.cartCount = data.count;

          const imageRequests = this.cartItems.map((item: any) => {
            return this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`).pipe(
              switchMap((datas: any) => {
                if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                  return this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`);
                } else {
                  return of({ status: 'ERROR' });
                }
              }),
              map((res: any) => {
                if (res.status === "SUCCESS") {
                  item.src = [res.uploads.base64data];
                } else {
                  item.src = [];
                }
                return item;
              })
            );
          });

          // Use forkJoin to wait for all image requests to complete
          return forkJoin(imageRequests).pipe(
            switchMap((itemsWithImages: any[]) => {
              // Get variations for all items
              const variationRequests = itemsWithImages.map((item: any) => {
                return this.variationApiCall(item.productid, item.productvariationid).pipe(
                  map((filteredVariations: any) => {
                    item.variations = filteredVariations;

                    const selectedVariation = item.variations.find((v: any) => v.id === item.productvariationid);
                    if (selectedVariation) {
                      item.selectedVariationId = selectedVariation.id;
                      item.selectedPrice = selectedVariation.sellingprice;
                      item.mrpPrice = selectedVariation.mrpprice;
                    }

                    return item;
                  })
                );
              });

              return forkJoin(variationRequests);
            })
          );
        } else {
          return of([]);
        }
      }),
      catchError(error => {
        console.error("Error in cartList API call:", error);
        return of([]);
      })
    ).subscribe(
      (updatedItems: any[]) => {
        this.cartItems = updatedItems;
        // this.getMrpPrice();
        // this.getSubTotalPrice();

      }
    );
  }

  variationApiCall(productid: any, productvariationid: any) {
    if (this.variationsCache.has(productid)) {
      return of(this.variationsCache.get(productid));
    }

    const requestBody = {
      productId: productid,
      productvariationId: productvariationid
    };

    return this.http.post(environment.apiUrl + `/products/list-productvariations`, requestBody, { responseType: 'json' }).pipe(
      map((data: any) => {
        if (data.status === 'SUCCESS') {

          const filteredVariations = data.productvariations_output.filter((variation: any) => variation.issale === true);
          this.variationsCache.set(productid, filteredVariations);
          this.variationsCacheSubject.next(this.variationsCache);
          return filteredVariations;
        } else {
          return [];
        }
      }),
      catchError(error => {
        console.error("Error in variationApiCall API call:", error);
        return of([]);
      })
    );
  }

  getPriceToShow(variation: any): number {
    if (variation.issubscribe && variation.issale) {
      if (variation.dataFromPreviousComponent === 'bestSelling') {
        this.priceToShow = variation.sellingprice; // Set priceToShow when dataFromPreviousComponent is 'bestSelling'
      } else if (variation.dataFromPreviousComponent === 'subscribeProduct') {
        this.priceToShow = variation.priceupto7days; // Set priceToShow when dataFromPreviousComponent is 'subscribe'
      } else {
        this.priceToShow = variation.priceupto7days; // Default to priceupto7days if both issubscribe and issale are true
      }
    } else if (variation.issubscribe) {
      this.priceToShow = variation.priceupto7days; // Set priceToShow if only issubscribe is true
    } else if (variation.issale) {
      this.priceToShow = variation.sellingprice; // Set priceToShow if only issale is true
    } else {
      this.priceToShow = variation.mrpprice; // Default to mrpPrice if neither issubscribe nor issale is true
    }
    return this.priceToShow; // Return the updated priceToShow
  }


  orderList(): void {
    const requestBody = {
      userId: this.userId,
    };
    this.http.post(environment.apiUrl + `/orders/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          if (this.userId) {
            this.UserOrderList = data.orders_output;
            this.UserOrderList.forEach((order: any) => {
              order.cartitems.forEach((item: any) => {
                item.src = [];

                this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`).subscribe((datas: any) => {
                  if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                    this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
                      if (res.status === "SUCCESS") {
                        item.src.push(res.uploads.base64data);
                      }
                    });
                  }
                });
              });
            });
          }
        }
      },
      (error: any) => {
        console.error("Error", error);
      }
    );
  }

  getFormattedDate(timestamp: string): string {
    const date = new Date(parseInt(timestamp));
    return date.toLocaleDateString();
  }

  getOrder(id: any): void {
    this.http.get(`${environment.apiUrl}/orders/get?id=${id}&userId=${this.userId}`, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            this.showOrderDetails = !this.showOrderDetails;
            this.orderDetails = data.orders;
           
            // Fetch images for each cart item
            this.orderDetails.cartitems.forEach((item: any) => {
              item.src = []; // Initialize src array for each item

              // Fetch image data
              this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${item.productid}`)
                .subscribe((datas: any) => {
                  if (datas.status === "SUCCESS" && datas.uploads.length > 0) {
                    this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`)
                      .subscribe((res: any) => {
                        if (res.status === "SUCCESS") {
                          // Push the base64 image data to the item
                          item.src.push(res.uploads.base64data);
                        }
                      });
                  }
                });
            });
          }
        },
        (error: any) => {
          console.error("Error:", error);
        }
      );
  }


  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }

    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity
    };

    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const wishlistItem = this.wishlistitems_output.find(item => item.productid === productId);
          if (wishlistItem) {
            wishlistItem.cartitemsquantity = quantity;
          }
          this.cdr.detectChanges();




        }
      },
      (error: any) => {
        console.error("Error in cartList API call:", error);
      }
    );
  }

  triggerUpload() {
    this.renderer.selectRootElement(this.fileInput?.nativeElement).click();
  }
  handleLicenceChange(event: any): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const maxWidth = 2400;
          const maxHeight = 1800;
          const thumbnailSize = 350;

          let width = img.width;
          let height = img.height;

          // Resize image if it exceeds maximum dimensions
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          // Draw and compress image
          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);
          const compressedBase64String = canvas.toDataURL('image/jpeg', 0.7);

          // Create thumbnail
          const thumbnailCanvas = document.createElement('canvas');
          const thumbnailCtx = thumbnailCanvas.getContext('2d');
          thumbnailCanvas.width = thumbnailSize;
          thumbnailCanvas.height = thumbnailSize;
          thumbnailCtx?.drawImage(img, 0, 0, thumbnailSize, thumbnailSize);
          const thumbnailBase64String = thumbnailCanvas.toDataURL('image/jpeg', 0.7);

          // Prepare request to upload image
          const request = {
            dataType: 'image',
            dataFor: 'users',
            dataForId: this.userId,
            base64Data: compressedBase64String,
            thumbnail: thumbnailBase64String,
            createdBy: this.userId,
          };

          // Upload image to server
          this.http.post(`${environment.apiUrl}/uploads/create`, request).subscribe((res: any) => {
            if (res.status === "SUCCESS") {
              const imageId = res.uploads.id;
              const imageInfoArray = [{ imageid: imageId, position: 0, visible: 'true' }];
              const formattedImageInfo = JSON.stringify(imageInfoArray);

              // Prepare update request with image info
              const updateRequest = {
                id: this.userId,
                updatedBy: this.userId,
                userType: this.user_details.usertype,
                imageids: [imageId],
                imageInfo: formattedImageInfo,
                firstname: this.user_details.firstname,
                lastname: this.user_details.lastname,
                gender: this.gender,
                profilepic: compressedBase64String,
              };

              // Update user with new image info
              this.http.post(`${environment.apiUrl}/users/user-update`, updateRequest).subscribe((data: any) => {
                if (data.status === "SUCCESS") {
                  this.uploadimages(data.users.id);
                  this.auth.fetchProfileImage(data.users.id);
                  this.snackBar.open('Image uploaded and updated successfully.', 'Close', {
                    duration: 3000,
                    verticalPosition: 'top'
                  });
                }
              });
            } else {
              this.snackBar.open('Image upload failed. Please try again.', 'Close', {
                duration: 3000,
                verticalPosition: 'top'
              });
            }
          });
        };
        img.src = base64String;
      };
      reader.readAsDataURL(file);
    }
  }


  uploadimages(id: any) {

    this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=users&dataForId=${id}`).subscribe((datas: any) => {
      if (datas.status === 'SUCCESS') {
        datas.uploads.forEach((upload: any, index: number) => {
          this.http.get(`${environment.apiUrl}/uploads/get?id=${upload.id}`).subscribe((res: any) => {
            if (res.status === 'SUCCESS') {
              if (res.uploads && res.uploads.base64data) {

                const base64Data = res.uploads.base64data.trim();
                const prefix = 'data:image/jpeg;base64,';
                this.profileImageUrl = base64Data.startsWith(prefix)
                  ? base64Data
                  : prefix + base64Data;
              } else {
                console.error(
                  'No base64 data found in the image response.'
                );
              }
            }
          });
        });
      }
    });
  }

  //notification
  NotificationList(): void {
    const requestBody = {
      userId: this.userId,
    };
    this.http.post(environment.apiUrl + `/notifications/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          if (this.userId) {
            this.UserNotificationList = data.notifications_output
          }
        }
      },
      (error: any) => {
        console.error("Error", error);
      }
    );
  }
  markAsRead(notificationId: string): void {
    const requestBody = {
      updatedBy: this.userId,
      id: notificationId,
    };

    this.http.post(environment.apiUrl + `/notifications/read`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.getUnreadCount();
          const notificationIndex = this.UserNotificationList.findIndex(n => n.id === notificationId);
          if (notificationIndex !== -1) {
            this.UserNotificationList[notificationIndex].isread = true;  // Mark the notification as read
          }
        }
      },
      (error: any) => {
        console.error("Error", error);
      }
    );
  }
  notificationView(notification: any) {
    console.log(notification,"notification")
    this.activeSection = 'orders'
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { section: 'orders' },
      queryParamsHandling: 'merge', // Preserve other query params if there are any
    });
    this.markAsRead(notification.id)
    this.getOrder(notification.refno)
  }


  getUnreadCount(): void {
    this.http.get(`${environment.apiUrl}/notifications/unread-count?userId=${this.userId}`, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            this.notificationCount = data.count;
          }
        },
        (error: any) => {
          console.error("Error:", error);
        }
      );
  }


}





