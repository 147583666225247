import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  forgotPasswordForm!: FormGroup;
  otpVerificationForm!: FormGroup;
  changePasswordForm!: FormGroup;
  userInfo: any
  currentSection: string = 'login';
  isSubmitting: boolean = false;
  passwordVisible: boolean = false;
  passwordVisibleConfirm: boolean = false;
  userId: any
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  user: string = "";
  isMobile: boolean = false;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    public auth: AuthService,
    private socialAuthService: SocialAuthService,
    private messageService: MessageService

  ) {
    this.auth.userInfo.subscribe(data => {
      if (data) {  // Check if userInfo is not null
        this.userId = data.id;
      }
    })


  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      mobileInput: [''],
      emailInput: [''],
      password: ['', Validators.required],
      rememberme: [false]
    });



    this.forgotPasswordForm = this.fb.group({
      mobileInput: ['', Validators.required],
      emailInput: ['', [Validators.required, Validators.email]],
    });

    this.otpVerificationForm = this.fb.group({
      otp: ['', Validators.required]
    });

    this.changePasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)]],
      confirmPassword: ['', Validators.required]
    });
    this.subscribeToInputChanges(this.loginForm);
    this.subscribeToInputChanges(this.forgotPasswordForm);

    if (!this.ifMobileApp()) {
      this.socialAuthService.authState.subscribe((user) => {
        if (user.provider === 'GOOGLE') {
          this.authService.validateGoogleUserSignIn({ idToken: user.idToken }).subscribe({
            next: (res) => {
              // this.signupDialogRef.close();
              const queryString = window.location.search;
              const searchParams: any = new URLSearchParams(queryString);
              const queryParams: any = Object.fromEntries(searchParams.entries());
              let currentUrl = this.router.url.split('?')[0];
              if (queryParams && queryParams.dialogue) {
                queryParams.dialogue = '0';
              }
              this.router.navigate([currentUrl], { queryParams: queryParams });
              this.messageService.clear();

              // this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
              this.router.navigate(['/home']);

            },
            error: (err) => {
              this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
            }
          })
        }
      });

      (window as any).handleCredentialResponse = (response: any) => {
        if (response && response.credential) {
          this.authService.validateGoogleUserSignIn({ idToken: response.credential }).subscribe({
            next: (res) => {
              // this.signupDialogRef.close();
              const queryString = window.location.search;
              const searchParams: any = new URLSearchParams(queryString);
              const queryParams: any = Object.fromEntries(searchParams.entries());
              let currentUrl = this.router.url.split('?')[0];
              if (queryParams && queryParams.dialogue) {
                queryParams.dialogue = '0';
              }
              this.router.navigate([currentUrl], { queryParams: queryParams });
              this.messageService.clear();

              // this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
              this.router.navigate(['/home']);

            },
            error: (err) => {
              this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
            }
          })
        }
      }
    } else {
      this.isMobile = true;
    }
  }


  loginGoogle() {
    if (this.isMobile) {
      (window as any).plugins.googleplus.login(
        {
          // 'scopes': '... ', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
          'webClientId': '138028886270-3a8pv7s0qf14fdcskl5b8cpn767ijk50.apps.googleusercontent.com', // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
          // 'offline': true // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
        },
        (obj: any) => {
          this.authService.validateGoogleUserSignIn({ mobileidToken: obj.idToken }).subscribe({
            next: (res) => {
              // this.signupDialogRef.close();
              const queryString = window.location.search;
              const searchParams: any = new URLSearchParams(queryString);
              const queryParams: any = Object.fromEntries(searchParams.entries());
              let currentUrl = this.router.url.split('?')[0];
              if (queryParams && queryParams.dialogue) {
                queryParams.dialogue = '0';
              }
              this.router.navigate([currentUrl], { queryParams: queryParams });
              this.messageService.clear();

              // this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' },);
              this.router.navigate(['/home']);

            },
            error: (err) => {
              this.snackBar.open('Some error occurs.' + JSON.stringify(err.error.errors), 'close', { duration: 3000, verticalPosition: 'top' },);
            }
          })
        },
        (msg: string) => {
          this.snackBar.open('Some error occurs.' + msg, 'close', { duration: 3000, verticalPosition: 'top' },);
        }
      );
    }
  }

  ifMobileApp() {
    if (this.user.includes('AdvenzoneMobileApp')) {
      return true;
    } else {
      return false;
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  subscribeToInputChanges(form: FormGroup): void {
    const mobileInputControl = form.get('mobileInput');
    const emailInputControl = form.get('emailInput');

    if (mobileInputControl && emailInputControl) {
      mobileInputControl.valueChanges.subscribe(value => {
        if (value) {
          // Disable email input if mobile input has value
          emailInputControl.disable({ emitEvent: false });
        } else {
          // Enable email input if mobile input is cleared
          emailInputControl.enable({ emitEvent: false });
        }
      });

      emailInputControl.valueChanges.subscribe(value => {
        if (value) {
          // Disable mobile input if email input has value
          mobileInputControl.disable({ emitEvent: false });
        } else {
          // Enable mobile input if email input is cleared
          mobileInputControl.enable({ emitEvent: false });
        }
      });
    }
  }
  showForgotPassword(): void {
    this.currentSection = 'forgetPassword';
  }

  showOtpVerification(): void {
    this.currentSection = 'otpVerification';
  }

  showChangePassword(): void {
    this.currentSection = 'changePassword';
  }

  onForgotPassword(): void {

    const mobileInputControl = this.forgotPasswordForm.get('mobileInput');
    const emailInputControl = this.forgotPasswordForm.get('emailInput');
    // Retrieve and trim inputs
    const mobileInput = mobileInputControl?.value || '';
    const emailInput = emailInputControl?.value || '';

    // Initialize the variable
    let emailOrphone: string | undefined;
    let userType = "user";
    // Prepare request body
    if (mobileInput && mobileInput.e164Number) {
      emailOrphone = mobileInput.e164Number.replace(/^\+/, '');
    }
    if (emailInput) {
      emailOrphone = emailInput;

    }
    if (emailOrphone) {

      this.http.post(`${environment.apiUrl}/users/forgotpassword-send-code`, { emailOrphone, userType }).subscribe({
        next: (data: any) => {
          if (data.status === 'SUCCESS') {

            this.userId = data.users.id

            this.snackBar.open('OTP sent successfully', 'close', { duration: 3000, verticalPosition: 'top' });
            // localStorage.setItem('userId', data.users.id);
            this.showOtpVerification();
          } else {
            this.snackBar.open('Failed to send OTP', 'close', { duration: 3000, verticalPosition: 'top' });
          }
        },
        error: () => {
          this.snackBar.open('Error occurred while sending OTP', 'close', { duration: 3000, verticalPosition: 'top' });
        }
      });
    }
  }

  onOtpVerification(): void {
    if (this.otpVerificationForm.invalid) {
      return;
    }
    // const userId = localStorage.getItem('userId');
    if (!this.userId) {
      console.error('User ID not found in local storage'); // Debugging log
      this.snackBar.open('User ID not found', 'close', { duration: 3000, verticalPosition: 'top' });
      return;
    }
    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      code: this.otpVerificationForm.get('otp')?.value
    };
    this.http.post(`${environment.apiUrl}/users/forgotpassword-code-validator`, requestBody).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          this.snackBar.open('OTP verified successfully', 'close', { duration: 3000, verticalPosition: 'top' });
          this.showChangePassword();
        } else {
          this.snackBar.open('Invalid OTP', 'close', { duration: 3000, verticalPosition: 'top' });
        }
      },
      error: (err) => {
        console.error('Error during OTP verification:', err); // Debugging log
        this.snackBar.open('Error occurred while verifying OTP', 'close', { duration: 3000, verticalPosition: 'top' });
      }
    });
  }

  onChangePassword(): void {

    if (this.changePasswordForm.get('newPassword')?.value !== this.changePasswordForm.get('confirmPassword')?.value) {
      this.snackBar.open('Passwords do not match', 'close', { duration: 3000, verticalPosition: 'top' });
      return;
    }
    // const userId = localStorage.getItem('userId');

    if (!this.userId) {
      console.error('User ID not found in local storage'); // Debugging log
      this.snackBar.open('User ID not found', 'close', { duration: 3000, verticalPosition: 'top' });
      return;
    }
    const requestBody = {
      updatedBy: this.userId,
      id: this.userId,
      newPassword: this.changePasswordForm.get('newPassword')?.value
    };

    this.http.post(`${environment.apiUrl}/users/change-password-update`, requestBody).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          this.snackBar.open('Password changed successfully', 'close', { duration: 3000, verticalPosition: 'top' });
          localStorage.removeItem('userId'); // Clear userId from local storage
          this.currentSection = 'login';
        } else {
          this.snackBar.open('Failed to change password', 'close', { duration: 3000, verticalPosition: 'top' });
        }
      },
      error: () => {
        this.snackBar.open('Error occurred while changing password', 'close', { duration: 3000, verticalPosition: 'top' });
      }
    });
  }
  isFormInvalid(): boolean {
    const mobileInputControl = this.loginForm.get('mobileInput');
    const emailInputControl = this.loginForm.get('emailInput');

    // Check if both inputs are empty or invalid
    return (
      (!mobileInputControl?.value?.e164Number && !emailInputControl?.value?.trim()) ||
      this.loginForm.invalid
    );
  }

  onLogin(): void {
    this.isSubmitting = true;

    // Validate the form before proceeding
    if (this.loginForm.invalid) {
      this.isSubmitting = false;
      return;
    }

    // Retrieve the form controls
    const mobileInputControl = this.loginForm.get('mobileInput');
    const emailInputControl = this.loginForm.get('emailInput');
    const passwordControl = this.loginForm.get('password');
    const rememberMeControl = this.loginForm.get('rememberme');

    // Retrieve and trim inputs
    const mobileInput = mobileInputControl?.value;
    const emailInput = emailInputControl?.value?.trim();
    const password = passwordControl?.value?.trim();
    const rememberMe = rememberMeControl?.value;

    // Initialize the variable
    let emailOrPhone: string | undefined;

    // Prepare the request body based on the available input
    if (mobileInput?.e164Number) {
      emailOrPhone = mobileInput.e164Number;
    } else if (emailInput) {
      emailOrPhone = emailInput;
    }

    // Ensure emailOrPhone and password are defined
    if (emailOrPhone && password) {
      const requestPayload = {
        emailOrphone: emailOrPhone,
        password: password,
        rememberme: rememberMe,
        userType: "user"
      };

      this.authService.login(requestPayload).subscribe({
        next: (res) => {
          this.authService.UserAction.subscribe(info => {
            if (info === "changepassword") {
              this.currentSection = 'changePassword';
            } else {
              this.isSubmitting = false;
              this.messageService.clear();

              // this.snackBar.open('Login Successfully', 'close', { duration: 3000, verticalPosition: 'top' });
              this.router.navigate(['/home']);
            }
          });
        },
        error: (err) => {
          this.isSubmitting = false;

          // Handle password mismatch error
          if (err.error.errors?.password?.[0]) {
            let Errors = err.error.errors.password[0]
            this.snackBar.open(Errors, 'close', { duration: 3000, verticalPosition: 'top' });
          }
          else if (err.error.errors?.LoginError?.[0]) {
            let Errors = err.error.errors.LoginError[0]
            this.snackBar.open(Errors, 'close', { duration: 3000, verticalPosition: 'top' });
          }
          else {
            const errorMessage = this.getErrorMessage(err);
            this.snackBar.open(errorMessage, 'close', { duration: 3000, verticalPosition: 'top' });
          }
        }
      });

    } else {
      this.isSubmitting = false;
      this.snackBar.open('Please fill in all required fields', 'close', { duration: 3000, verticalPosition: 'top' });
    }
  }

  // Centralized error message handling for other errors
  private getErrorMessage(err: any): string {
    if (err.error.errors?.email?.[0] === "Email not verified, Please Verify Email") {
      return 'Email not verified, Please Verify Email';
    } else if (err.error.errors?.LoginError?.[0] === "User doesn't exist.") {
      return 'User does not exist. Please contact the Administrator for assistance.';
    } else if (err.error.errors?.LoginError?.[0] === "Your account has been disabled. Please contact the Administrator for assistance.") {
      return 'Your account has been disabled. Please contact the Administrator for assistance.';
    } else {
      return 'Username or Password is Incorrect';
    }
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }
  togglePasswordVisibilityConfirm(): void {
    this.passwordVisibleConfirm = !this.passwordVisibleConfirm;
  }

}
