<div class="profile-container d-flex">
    <div class="profile-sidebar d-lg-block d-none">
        <div class="profile-header">
            <img [src]="profileImageUrl && profileImageUrl.trim() !== '' ? profileImageUrl : 'assets/images/profile-man.svg'"
                alt="Profile Picture" class="profile-man" alt="Profile Picture" class="profile-img">
            <div class="profile-info">
                <span class="greeting">Hello</span>
                <h6 class="username">{{user_details.firstname}}</h6>
            </div>
        </div>
        <div class="profile-section">
            <div class="menu-section">
                <h5 class="menu-header">Personal Information</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('profile')"
                        [class.active]="activeSection === 'profile'">
                        <img src="../../assets/icon/profile-icon-svg.svg" alt="" class="icon-profile">
                        Profile
                    </li>

                    <li class="menu-item" (click)="onSectionChange('wishlist')"
                        [class.active]="activeSection === 'wishlist'">
                        <img src="../../assets/icon/wish-icon.svg" alt="" class="icon-profile">
                        My Wishlist
                        <span class="badge-num text-center" *ngIf="wishlistCount>0">{{wishlistCount}}</span>


                    </li>
                    <li class="menu-item" (click)="onSectionChange('orders')"
                        [class.active]="activeSection === 'orders'">
                        <img src="../../assets/icon/my-orders-svg.svg" alt="" class="icon-profile">
                        My Orders
                    </li>
                    <li class="menu-item" (click)="onSectionChange('subscriptions')"
                        [class.active]="activeSection === 'subscriptions'">
                        <img src="../../assets/icon/subscribt-product-icon.svg" alt="" class="icon-profile">
                        Subscribe Products
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Payment</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('wallet')"
                        [class.active]="activeSection === 'wallet'">
                        <img src="../../assets/icon/mywallet-icon.svg" alt="" class="icon-profile">
                        My Wallet
                    </li>
                    <li class="menu-item" (click)="onSectionChange('cards')" [class.active]="activeSection === 'cards'">
                        <img src="../../assets/icon/saved-icon.svg" alt="" class="icon-profile">
                        Saved Card Details
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Location</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('address')"
                        [class.active]="activeSection === 'address'">
                        <img src="../../assets/icon/addrress-icon.svg" alt="" class="icon-profile">
                        Manage Address
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Notifications</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('notifications')"
                        [class.active]="activeSection === 'notifications'">
                        <img src="../../assets/icon/notify-icon.svg" alt="" class="icon-profile">
                        Notification
                        <span class="badge-num text-center" *ngIf="notificationCount>0">{{notificationCount}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="signout">
            <a href="javascript:void(0)" class="signout-link" (click)="toggleSignoutPopup()">
                <img src="../../assets/icon/signout-icon.svg" alt="" class="icon-profile">
                Sign out
            </a>
        </div>
    </div>
    <!-- mobile -->
    <div class="profile-sidebar d-lg-none d-block" *ngIf="!activeSection">
        <div class="profile-header">
            <img [src]="profileImageUrl && profileImageUrl.trim() !== '' ? profileImageUrl : 'assets/images/profile-man.svg'"
                alt="Profile Picture" class="profile-man" alt="Profile Picture" class="profile-img">
            <div class="profile-info">
                <span class="greeting">Hello</span>
                <h6 class="username">{{user_details.firstname}}</h6>
            </div>
        </div>
        <div class="profile-section">
            <div class="menu-section">
                <h5 class="menu-header">Personal Information</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('profile')"
                        [class.active]="activeSection === 'profile'">
                        <img src="../../assets/icon/profile-icon-svg.svg" alt="" class="icon-profile">
                        Profile
                    </li>

                    <li class="menu-item" (click)="onSectionChange('wishlist')"
                        [class.active]="activeSection === 'wishlist'">
                        <img src="../../assets/icon/wish-icon.svg" alt="" class="icon-profile">
                        My Wishlist
                        <span class="badge-num text-center" *ngIf="wishlistCount>0">{{wishlistCount}}</span>


                    </li>
                    <li class="menu-item" (click)="onSectionChange('orders')"
                        [class.active]="activeSection === 'orders'">
                        <img src="../../assets/icon/my-orders-svg.svg"
                        alt="" class="icon-profile">
                        My Orders
                    </li>
                    <li class="menu-item" (click)="onSectionChange('subscriptions')"
                        [class.active]="activeSection === 'subscriptions'">
                        <img src="../../assets/icon/subscribt-product-icon.svg" alt="" class="icon-profile">
                        Subscribe Products
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Payment</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('wallet')"
                        [class.active]="activeSection === 'wallet'">
                        <img src="../../assets/icon/mywallet-icon.svg" alt="" class="icon-profile">
                        My Wallet
                    </li>
                    <li class="menu-item" (click)="onSectionChange('cards')" [class.active]="activeSection === 'cards'">
                        <img src="../../assets/icon/saved-icon.svg" alt="" class="icon-profile">
                        Saved Card Details
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Location</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('address')"
                        [class.active]="activeSection === 'address'">
                        <img src="../../assets/icon/addrress-icon.svg" alt="" class="icon-profile">
                        Manage Address
                    </li>
                </ul>
            </div>
            <div class="menu-section">
                <h5 class="menu-header">Notifications</h5>
                <ul class="menu-list">
                    <li class="menu-item" (click)="onSectionChange('notifications')"
                        [class.active]="activeSection === 'notifications'">
                        <img src="../../assets/icon/notify-icon.svg" alt="" class="icon-profile">
                        Notification
                        <span class="badge-num text-center" *ngIf="notificationCount>0">{{notificationCount}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="signout">
            <a href="javascript:void(0)" class="signout-link" (click)="toggleSignoutPopup()">
                <img src="../../assets/icon/signout-icon.svg" alt="" class="icon-profile">
                Sign out
            </a>
        </div>
    </div>
    <!-- end -->

    <div class="profile-content" *ngIf="activeSection === 'profile'" [formGroup]="myGroup">
        <div class="profile-picture-container">
            <div class="profile-picture">
                <img [src]="profileImageUrl" alt="Profile Picture" class="profile-man">
                <input type="file" accept="image/*" (change)="handleLicenceChange($event)" #fileInput class="d-none">
                <div (click)="triggerUpload()">
                    <img src="../../assets/icon/profile-edit-icon.svg" alt="Edit Profile" class="edit-icon">
                </div>
            </div>
        </div>
        <div class="user_name d-lg-block d-none">
            <div class="d-flex justify-content-between align-items-center">
                <label for="">First name / Last name</label>
                <a href="javascript:void(0)" class="edit-click" *ngIf="!isNameEditing"
                    (click)="toggleNameEdit()">Edit</a>
                <a href="javascript:void(0)" class="cancel-click" *ngIf="isNameEditing"
                    (click)="cancelNameEdit()">Cancel</a>
            </div>
            <div class="row d-lg-flex d-none">
                <div class="col-lg-5">
                    <input type="text" class="form-control name" placeholder="Enter the first name"
                        [disabled]="!isNameEditing" formControlName="firstname">
                </div>
                <div class="col-lg-5">
                    <input type="text" class="form-control name" placeholder="Enter the last name"
                        [disabled]="!isNameEditing" formControlName="lastname">
                </div>
                <div class="col-lg-2">
                    <input class="btn btn-primary save-name" type="button" value="Save" *ngIf="isNameEditing"
                        (click)="saveNameChanges()">
                </div>
            </div>
        </div>
        <!-- for mobile -->
        <div class="user-name-card d-lg-none d-block">
            <div class="p-4">

                <div class="d-flex justify-content-between align-items-center">
                    <label for="">First name / Last name</label>
                    <a href="javascript:void(0)" class="mobile-edit" *ngIf="!isNameEditing"
                        (click)="toggleNameEdit()">Edit</a>
                    <a href="javascript:void(0)" class="mobile-cancel" *ngIf="isNameEditing"
                        (click)="cancelNameEdit()">Cancel</a>
                </div>
                <div class="d-flex flex-column gap-3 d-lg-none">
                    <input type="text" class=" form-control name" placeholder="Enter the first name"
                        formControlName="firstname" [disabled]="!isNameEditing">
                    <input type="text" class=" form-control name" placeholder="Enter the last name"
                        formControlName="lastname" [disabled]="!isNameEditing">
                    <input class="btn btn-primary save-name" type="button" value="Save" *ngIf="isNameEditing"
                        (click)="saveNameChanges()">
                </div>

            </div>
        </div>
        <!-- gender -->
        <div class="user_gender user_gender-card  p-4 p-md-0">
            <div class="row align-items-center">
                <label for="" class="col-lg-1 col-6">Gender</label>
                <a href="javascript:void(0)" class="edit-gender col-lg-2 col-6 ps-md-5" *ngIf="!isGenderEditing"
                    (click)="toggleGenderEdit()">Edit</a>
                <a href="javascript:void(0)" class="cancel-gender col-lg-2 col-6 ps-md-5 " *ngIf="isGenderEditing"
                    (click)="cancelGenderEdit()">Cancel</a>
            </div>
            <div class="row mt-2">
                <div class="col-6 col-lg-1 ps-md-2 me-lg-3">
                    <input type="radio" name="gender" class="me-2" id="male" [value]="'Male'" formControlName="gender">
                    <label for="male">Male</label>
                </div>
                <div class="col-6 col-lg-1 px-0 ps-md-1 me-lg-4">
                    <input type="radio" name="gender" class="me-2" id="female" [value]="'Female'"
                        formControlName="gender">
                    <label for="female">Female</label>
                </div>
                <div class="col-12 col-lg-3 save-btn-container">
                    <input class="btn btn-primary save-gender" type="button" value="Save" *ngIf="isGenderEditing"
                        (click)="saveGenderChanges()">
                </div>
            </div>
        </div>
        <!-- for mobile -->
        <!-- <div class="user_gender-card d-lg-none d-block">
            <div class="p-4">
                <div class="row">
                    <label for="" class="col">Gender</label>
                    <a href="javascript:void(0)" class="edit-gender col" *ngIf="!isGenderEditing"
                        (click)="toggleGenderEdit()">Edit</a>
                    <a href="javascript:void(0)" class="cancel-gender col" *ngIf="isGenderEditing"
                        (click)="cancelGenderEdit()">Cancel</a>
                </div>
                <div class="row mt-2">
                    <div class="col-4">
                        <input type="radio" name="gender" class="me-2" id="male" [value]="'male'"
                            formControlName="gender" [disabled]="!isGenderEditing">
                        <label for="male">Male</label>
                    </div>
                    <div class="col-8">
                        <input type="radio" name="gender" class="me-2" id="female" [value]="'female'"
                            formControlName="gender" [disabled]="!isGenderEditing">
                        <label for="female">Female</label>
                    </div>
 
                </div>
                <input class="btn btn-primary save-gender mt-3" type="button" value="Save" *ngIf="isGenderEditing"
                    (click)="saveGenderChanges()">
            </div>
        </div> -->
        <!-- mobile verify -->
        <div class="user_mobile d-lg-block d-none">
            <div class="row w-100">
                <div class="col-5 d-flex justify-content-between">
                    <label for="" *ngIf="!newMobileInput">Mobile number</label>
                    <label for="" *ngIf="newMobileInput">Old Mobile number</label>
                    <a href="javascript:void(0)" class="" *ngIf="isPhoneEditing" (click)="togglePhoneEdit()">Edit</a>
                    <a href="javascript:void(0)" class="" *ngIf="!isPhoneEditing" (click)="togglePhoneEdit()">Add </a>
                </div>
                <div class="col-5 d-flex justify-content-between new-mobile-label" *ngIf="newMobileInput">
                    <label for="">New Mobile number</label>
                    <a href="javascript:void(0)" (click)="toggleCancel()">Cancel</a>
                </div>
            </div>

            <div class="row mt-2">
                <!-- Old Mobile Number Input -->
                <div class="col-lg-5 d-flex">
                    <ngx-intl-tel-input class="d-block w-100 phone"
                        [preferredCountries]="[CountryISO.India,CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="selectedCountryISO" [maxLength]="15"
                        [phoneValidation]="true" name="phone" formControlName="mobileInput">
                    </ngx-intl-tel-input>
                </div>

                <!-- New Mobile Number Input -->
                <div class="col-lg-5" *ngIf="NewMobileInputOpen">
                    <ngx-intl-tel-input class="d-block w-100 phone"
                        [preferredCountries]="[CountryISO.India, CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="false" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                        [phoneValidation]="true" formControlName="newMobileInput" name="phone"></ngx-intl-tel-input>
                </div>
                <div class="col-lg-2" *ngIf="NewMobileSavebtn">
                    <input class="btn btn-primary save-number" type="button" value="Save"
                        (click)="sendOtpForPhoneUpdate()"
                        [disabled]="!(myGroup.get('newMobileInput')?.value?.number?.length >= 10 || myGroup.get('mobileInput')?.value?.number?.length >= 10)">
                </div>

            </div>

            <!-- OTP Verify Popup -->
            <div class="phone-otp-overlay" *ngIf="showPhoneOtpVerifyPopup">
                <div class="phone-otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyPhoneOtpPopup(isOldPhone)">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="phone-otp-verify-content">
                        <form [formGroup]="myGroup"> <!-- Bind the form group to the form -->
                            <div class="phone-otp">
                                <div class="popup-message">
                                    <!-- Show message based on whether it's for old or new mobile -->
                                    {{ isOldPhone ? 'OTP is sent to your old number' : 'OTP is sent to your new number'
                                    }}
                                </div>
                                <div>
                                    <!-- Bind the otpCode control using formControlName -->
                                    <input type="text" placeholder="Enter OTP" formControlName="otpCode"
                                        class="phone-otp-input">
                                </div>
                                <div class="">
                                    <button class="btn verify-btn" (click)="verifyOtpForPhoneUpdate()"
                                        [disabled]="!myGroup.get('otpCode')?.valid">
                                        Verify
                                    </button>
                                    <a class="resend-otp-btn d-block" (click)="sendOtpForPhoneUpdate()">Resend OTP</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <!-- for mobile -->
        <div class="user_mobile-card d-lg-none d-block p-4">
            <div class="d-flex justify-content-between align-items-center">
                <label for="" *ngIf="!newMobileInput">Mobile number</label>
                <label for="" *ngIf="newMobileInput">Old Mobile number</label>
                <a href="javascript:void(0)" class="edit-mobile" *ngIf="isPhoneEditing"
                    (click)="togglePhoneEdit()">Edit</a>
                <a href="javascript:void(0)" class="edit-mobile" *ngIf="!isPhoneEditing"
                    (click)="togglePhoneEdit()">Add</a>
            </div>
            <div class="row mt-2">
                <!-- Old Mobile Number Input -->
                <div class="col-12 d-flex">
                    <ngx-intl-tel-input class="d-block w-100 phone"
                        [preferredCountries]="[CountryISO.India, CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="false" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                        [phoneValidation]="true" name="phone" formControlName="mobileInput"></ngx-intl-tel-input>
                </div>
                <!-- New Mobile Number Input -->
                <div class="col-12 mt-3" *ngIf="NewMobileInputOpen">
                    <div class="d-flex justify-content-between">
                        <label for="">New Mobile number</label>
                        <a href="javascript:void(0)" class="cancel-click" (click)="toggleCancel()">Cancel</a>
                    </div>
                    <ngx-intl-tel-input class="d-block w-100 phone"
                        [preferredCountries]="[CountryISO.India, CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                        [enableAutoCountrySelect]="false" [enablePlaceholder]="false" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15"
                        [phoneValidation]="true" formControlName="newMobileInput" name="phone"></ngx-intl-tel-input>
                </div>
                <div class="col-12 mt-3" *ngIf="NewMobileSavebtn">
                    <input class="btn btn-primary save-number" type="button" value="Save"
                        (click)="sendOtpForPhoneUpdate()"
                        [disabled]="!(myGroup.get('newMobileInput')?.value?.number?.length >= 10 || myGroup.get('mobileInput')?.value?.number?.length >= 10)">
                </div>
            </div>

            <div class="phone-otp-overlay" *ngIf="showPhoneOtpVerifyPopup">
                <div class="phone-otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyPhoneOtpPopup(isOldPhone)">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="phone-otp-verify-content">
                        <form [formGroup]="myGroup"> <!-- Bind the form group to the form -->
                            <div class="phone-otp">
                                <div class="popup-message">
                                    {{ isOldPhone ? 'OTP is sent to your old number' : 'OTP is sent to your new number'
                                    }}
                                </div>
                                <div>
                                    <!-- Bind the otpCode control using formControlName -->
                                    <input type="text" placeholder="Enter OTP" formControlName="otpCode"
                                        class="phone-otp-input">
                                </div>
                                <div class="">
                                    <button class="btn verify-btn" (click)="verifyOtpForPhoneUpdate()"
                                        [disabled]="!myGroup.get('otpCode')?.valid">Verify</button>
                                    <a class="resend-otp-btn d-block" (click)="sendOtpForPhoneUpdate()">Resend OTP</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- email verify -->
        <div class="user_email d-lg-block d-none">
            <div class="d-flex justify-content-between align-items-center">
                <label for="email">Email id</label>
                <a class="edit-mobile" href="javascript:void(0)" (click)="toggleEmailEdit()"
                    *ngIf="!isUserDetailEmail && !isEmailEditing">Add Email</a>
                <a class="edit-mobile" href="javascript:void(0)" (click)="toggleEmailEdit()"
                    *ngIf="isUserDetailEmail && !isEmailEditing">Edit Email</a>
                <a class="edit-mobile" *ngIf="isEmailEditing" href="javascript:void(0)"
                    (click)="toggleCancelEmail()">Cancel</a>
            </div>

            <div class="row">
                <div class="mt-2 col-lg-5">
                    <input type="email" class="form-control" placeholder="example@email.com" formControlName="oldEmail">
                </div>
                <div class="mt-2 col-lg-5" *ngIf="NewEmailInputOpen">
                    <input type="email" class="form-control" placeholder="New Email" formControlName="newEmail">
                </div>
                <div class="mt-2 col-lg-2">
                    <button class="btn btn-primary verify-email verify-phn-btn" (click)="sendOtpForEmailUpdate()"
                        *ngIf="verifyEmailBtn">
                        Verify
                    </button>
                </div>
            </div>

            <!-- OTP Verify Popup -->
            <div class="otp-overlay" *ngIf="showEmailOtpVerifyPopup">
                <div class="otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyEmailOtpPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="otp-verify-content">
                        <form [formGroup]="myGroup">
                            <div class="otp-email-input">
                                <div class="popup-message">
                                    {{ isOldEmail ? 'OTP is sent to your old email' : 'OTP is sent to your new email' }}
                                </div>
                                <div>
                                    <input type="text" placeholder="Enter OTP" formControlName="otpCode"
                                    class="phone-otp-input" (focus)="highlightBorder()" (blur)="resetBorder()">
                                </div>
                                <div class="">
                                    <button class="btn verify-btn" (click)="verifyOtpForEmailUpdate()"
                                        [disabled]="!myGroup.get('otpCode')?.valid">
                                        Verify
                                    </button>
                                    <a class="resend-otp-btn d-block" (click)="sendOtpForEmailUpdate()">Resend OTP</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- for mobile -->
        <div class="user_email-card d-lg-none d-block p-4">
            <div class="d-flex justify-content-between align-items-center">
                <label for="email">Email id</label>
                <a class="edit-mobile email-mobile" href="javascript:void(0)" (click)="toggleEmailEdit()"
                    *ngIf=" !isUserDetailEmail && !isEmailEditing">Add
                    Email</a>
                <a class="edit-mobile email-mobile" href="javascript:void(0)"
                    *ngIf="isUserDetailEmail && !isEmailEditing" (click)="toggleEmailEdit()">Edit Email</a>
                <a *ngIf="isEmailEditing" class="edit-mobile email-mobile" href="javascript:void(0)"
                    (click)="toggleCancelEmail()">Cancel</a>



            </div>
            <div class="row">
                <div class="mt-2 col-lg-5">
                    <input type="email" class="form-control" placeholder="example@email.com"
                       formControlName="oldEmail">
                </div>
                <div class="mt-2 col-lg-5" *ngIf="NewEmailInputOpen">
                    <input type="email" class="form-control" placeholder="New Email " formControlName="newEmail">
                </div>
                <div class="mt-2 col-lg-2">
                    <button class="btn btn-primary verify-email verfiy-phn-btn" (click)="sendOtpForEmailUpdate()"
                        *ngIf="verifyEmailBtn">Verify</button>
                </div>
            </div>
            <div class="otp-overlay" *ngIf="showEmailOtpVerifyPopup">
                <div class="otp-verify-container">
                    <button class="close-btn" (click)="toggleVerifyEmailOtpPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>

                    <div class="otp-verify-content">
                        <form [formGroup]="myGroup">

                            <div class="otp">
                                <div class="popup-message">
                                    {{ isOldEmail ? 'OTP is sent to your old email' : 'OTP is sent to your new email' }}

                                </div>
                                <div>
                                    <input type="text" class="phone-otp-input" placeholder="Enter OTP"
                                        formControlName="otpCode" (focus)="highlightBorder()" (blur)="resetBorder()">
                                </div>
                            </div>
                            <div class="">
                                <button class="btn verify-btn" (click)="verifyOtpForEmailUpdate()"
                                    [disabled]="!myGroup.get('otpCode')?.valid ">Verify</button>
                                <a class="resend-otp-btn d-block" (click)="sendOtpForEmailUpdate()">Resend OTP</a>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </div>
        <div class="user_password d-lg-block d-none mt-4" *ngIf="logintype !== 'googlelogin' ">
            <div class="d-flex justify-content-between align-items-center">
                <label for="password">Password</label>
                <a *ngIf="!isChangePassword" class="edit-mobile" href="javascript:void(0)"
                    (click)="editPassword()">Change Password</a>
                <a *ngIf="isChangePassword" class="cancel-click" href="javascript:void(0)"
                    (click)="cancelPasswordEdit()">Cancel</a>
            </div>
 
            <div class="row">
                <!-- Disabled Input for Old Password -->
                <div class="mt-2 col-lg-5">
                    <input class="form-control" disabled placeholder="*********">
                </div>
 
                <!-- New Password Input when isChangePassword is true -->
                <div class="mt-2 col-lg-5" *ngIf="isChangePassword">
                    <form [formGroup]="myGroup" autocomplete="off">
                        <div class="password-container">
                            <input [type]="passwordPopupVisible ? 'text' : 'password'" class="form-control"
                                placeholder="Enter new Password" formControlName="newPassword" autocomplete="new-password"  maxlength="16">
                            <mat-icon class="pwd-eye" (click)="togglePasswordPopupVisibility()">
                                {{ passwordPopupVisible ? 'visibility' : 'visibility_off' }}
                            </mat-icon>
                        </div>
                        <small class="d-lg-block d-none  error-pswd"  *ngIf="myGroup.get('newPassword')?.hasError('required') && myGroup.get('newPassword')?.touched">
                            Password is required.
                          </small>
                          <small  class="d-lg-block d-none  error-pswd" *ngIf="myGroup.get('newPassword')?.hasError('pattern') && myGroup.get('newPassword')?.touched">
                            Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one number, and one special character.
                          </small>
                       
                    </form>
                </div>
 
                <!-- Save Button -->
                <div class="mt-2 col-lg-2" *ngIf="isChangePassword">
                    <button class="btn btn-primary save-password" (click)="savePassword()"
                        [disabled]="!myGroup.get('newPassword')?.valid || !myGroup.get('newPassword')?.value">
                        Save
                    </button>
                </div>
            </div>
 
            <!-- Old Password Verify Popup -->
            <div class="password-overlay" *ngIf="OldPasswordVerifyPopup">
                <div class="password-verify-container">
                    <button class="close-btn" (click)="toggleVerifyPasswordPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="password-verify-content">
                        <form [formGroup]="myGroup">
                            <!-- Make sure to wrap the inputs inside a form with the formGroup directive -->
                            <div class="passwords">
                                <div class="popup-message">Enter your old password</div>
                                <div>
                                    <input [type]="passwordVisible ? 'text' : 'password'" class="password-input" placeholder="Enter old password" autocomplete="new-password"
                                        formControlName="oldPassword" (focus)="highlightBorder()"
                                        (blur)="resetBorder()">
                                        <mat-icon class="pwd-eyes" (click)="togglePasswordVisibility()">
                                            {{ passwordVisible ? 'visibility' : 'visibility_off' }}
                                        </mat-icon>
                                </div>
                                <div class="forget-pswd" style="cursor: pointer;">
                                    <a class="forget-password-btn" (click)="forgotOldPassword()">Forget Password</a>
                                </div>
                            </div>
                            <div class="verify-pswd">
                                <button class="btn verify-btn"
                                    [disabled]="!myGroup.get('oldPassword')?.valid || !myGroup.get('oldPassword')?.value"
                                    (click)="verifyPassword()">Verify</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
 
        </div>
        <!-- for mobile -->
        <div class="user_password-card d-lg-none d-block p-4" *ngIf="logintype !== 'googlelogin' ">
            <div class="d-flex justify-content-between align-items-center">
                <label for="password">Password</label>
                <a *ngIf="!isChangePassword" class="edit-mobile email-mobile" href="javascript:void(0)"
                    (click)="editPassword()">Change Password</a>
                <a *ngIf="isChangePassword" class="cancel-click email-mobile" href="javascript:void(0)"
                    (click)="cancelPasswordEdit()">Cancel</a>
            </div>
            <div class="row">
                <div class="mt-2 col-lg-5">
                    <input class="form-control" disabled placeholder="********">
                </div>
                <div class="mt-2 col-lg-5" *ngIf="isChangePassword">
                    <form [formGroup]="myGroup" autocomplete="off">
                        <div class="password-container">
                            <input [type]="passwordPopupVisible ? 'text' : 'password'" class="form-control"
                                placeholder="Enter new Password" formControlName="newPassword" autocomplete="new-password"  maxlength="16">
                            <mat-icon class="pwd-eye" (click)="togglePasswordPopupVisibility()">
                                {{ passwordPopupVisible ? 'visibility' : 'visibility_off' }}
                            </mat-icon>
                        </div>
                       
                            <small class="d-lg-none d-block error-pswd" *ngIf="myGroup.get('newPassword')?.hasError('required') && myGroup.get('newPassword')?.touched">
                                Password is required.
                              </small>
                              <small  class="d-lg-none d-block  error-pswd" *ngIf="myGroup.get('newPassword')?.hasError('pattern') && myGroup.get('newPassword')?.touched">
                                Password must be at least 8 characters long and include one uppercase letter, one lowercase letter, one number, and one special character.
                              </small>
                    </form>
                </div>
 
                <div class="mt-2 col-lg-2" *ngIf="isChangePassword">
                    <button class="btn btn-primary save-password" (click)="savePassword()"
                        [disabled]="!myGroup.get('newPassword')?.valid || !myGroup.get('newPassword')?.value">Save</button>
                </div>
            </div>
            <div class="password-overlay" *ngIf="OldPasswordVerifyPopup">
                <div class="password-verify-container">
                    <button class="close-btn" (click)="toggleVerifyPasswordPopup()">
                        <img src="../../assets/icon/popup-close.svg" alt="Close">
                    </button>
                    <div class="password-verify-content">
                        <form [formGroup]="myGroup">
                            <div class="passwords">
                                <div class="popup-message">Enter your old password</div>
                                <div  class = "password-container"
                                >
                                    <input type="text"[type]="passwordVisible ? 'text' : 'password'" class="password-input" placeholder="Enter old password" autocomplete="new-password"
                                    formControlName="oldPassword" (focus)="highlightBorder()" (blur)="resetBorder()">
                                       
                                        <mat-icon class="pwd-eyes" (click)="togglePasswordVisibility()">
                                            {{ passwordVisible ? 'visibility' : 'visibility_off' }}
                                        </mat-icon>
                                </div>
                                <div class="forget-pswd" style="cursor: pointer;">
                                    <a class="forget-password-btn" (click)="forgotOldPassword()">Forget Password</a>
                                </div>
                            </div>
                            <div class="verify-pswd">
                                <button class="btn verify-btn"
                                    [disabled]="!myGroup.get('oldPassword')?.valid || !myGroup.get('oldPassword')?.value"
                                    (click)="verifyPassword()">Verify</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
 
 
    </div>
    <div class="wishlist-content" *ngIf="activeSection === 'wishlist'&& !orderEmpty">
        <div>
            <h6>My Wishlist({{wishlistCount}})</h6>
        </div>
        <hr>
        <div  class="ordr-list-contianer" (scroll)="onScroll()">
        <div class="row wishlist-item align-items-center" *ngFor="let wishlistitems of wishlistitems_output ">
            <div class="col-3 col-lg-1">
                <!-- <img src="../../assets/images/johnsonoil.svg" alt="" class="wishlist-img"> -->
                <img [src]="wishlistitems.src?.[0]" alt="img" class=" image-src">
            </div>
            <div class="col-5 col-lg-6">
                <h6 class="product-name text-truncate">{{wishlistitems.title}}</h6>
            </div>
            <div class="col-4 col-lg-2 text-end">
                <span class="product-price">  £ {{ (wishlistitems.cartitemquantity || 1) * wishlistitems.price }}</span>
            </div>
            <div class="col-6 col-lg-2 add-cart mt-2 mt-md-0 text-center"
                *ngIf="!isLoggedIn || wishlistitems.cartitemquantity === null || wishlistitems.cartitemquantity === 0 ">
                <button class="d-lg-block d-none w-100"
                    (click)="Add_to_cart(wishlistitems.productid,wishlistitems.productvariationid,1,'is_sale')"
                    *ngIf=" wishlistitems.type==='is_sale' ">Add to
                    cart</button>
                <button class="d-lg-none d-block w-100 add-card-btn"
                    (click)="Add_to_cart(wishlistitems.productid,wishlistitems.productvariationid,1,'is_sale')"
                    *ngIf=" wishlistitems.type==='is_sale' ">Add to
                    cart</button>
                <button class="d-lg-block d-none w-100"
                    *ngIf=" wishlistitems.type==='is_subscribe'" (click)="SubcribeClick(wishlistitems.productid,wishlistitems.productvariationid)">Subscribe</button>
                <button class="d-lg-none d-block w-100 add-card-btn"
                    *ngIf=" wishlistitems.type==='is_subscribe'" (click)="SubcribeClick(wishlistitems.productid,wishlistitems.productvariationid)">Subscribe</button>
                    <button class="d-lg-block d-none w-100"   (click)="Add_to_cart(wishlistitems.comboid,wishlistitems.cartproducts,1,'is_combo')"
                    *ngIf=" wishlistitems.type==='is_combo'">Add to
                    combo cart</button>
                <button class="d-lg-none d-block w-100 add-card-btn"  (click)="Add_to_cart(wishlistitems.comboid,wishlistitems.cartproducts,1,'is_combo')"
                    *ngIf=" wishlistitems.type==='is_combo'">Add to
                    combo cart</button>
            </div>
 
            <div class="col-6 col-lg-2 mt-2 mt-md-0 text-center"
            *ngIf="isLoggedIn && wishlistitems.cartitemquantity > 0">
         <div class="d-flex add-border w-100 align-items-center justify-content-around">
           <button class="btn p-0 border-0"
                   (click)="decreaseQuantity(wishlistitems.type === 'is_combo' ? wishlistitems.comboid : wishlistitems.productid, wishlistitems.productvariationid, wishlistitems.type)">
             <img src="../../assets/images/decrease_img.svg" alt="Decrease" style="width: 20px; height: 20px;">
           </button>
           <span class="px-2" style="font-size: 14px;">{{ wishlistitems.cartitemquantity }}</span>
           <button class="btn p-0 border-0"
                   (click)="increaseQuantity(wishlistitems.type === 'is_combo' ? wishlistitems.comboid : wishlistitems.productid, wishlistitems.productvariationid, wishlistitems.type)">
             <img src="../../assets/images/increase_img.svg" alt="Increase" style="width: 20px; height: 20px;">
           </button>
         </div>
       </div>
       
 
 
            <div class="col-6 col-lg-1 text-center mt-2 mt-md-0 close-btns">
                <button class="btn d-lg-block d-none"><img src="../../assets/images/close.svg" alt=""
                        (click)="removeWishList(wishlistitems.productid,wishlistitems.productvariationid)"></button>
                <button class="btn remove-icon d-lg-none d-block w-100" (click)="removeWishList(wishlistitems.productid,wishlistitems.productvariationid)"><img src="../../assets/icon/remove-icon.svg"
                        alt="" class="me-2"
                        >Remove</button>
            </div>
            <hr class="mt-1 d-lg-block d-none">
        </div>
    </div>
    </div>
 
    <div class="order-content" *ngIf="activeSection === 'orders'&& !orderEmpty">
        <div class="d-flex">
            <img src="../../assets/images/left_arrow.svg" alt="" class="me-2" *ngIf="showOrderDetails"
                (click)="toggleOrderDetails()" style="width: 15px; cursor: pointer;">
            <h6>My Orders</h6>
        </div>
        <hr>
        <div *ngIf="!showOrderDetails" class="ordr-list-contianer" (scroll)="onScroll()">
            <div *ngFor="let order of UserOrderList">
                <div class="order-item row align-items-center mb-4 mt-3" (click)="getOrder(order.id)">
                    <div class="col-2 col-md-1">
                        <div *ngIf="order.cartitems[0]?.src?.length > 0">
                            <img [src]="order.cartitems[0].src[0]" alt="Product" class="order-img">
                        </div>
                    </div>
                    <div class="col-7 col-md-5 d-none d-lg-block">
                        <h6 class="product-name">{{ order.cartitems[0].title }}</h6>
                        <span class="variations">
                    {{ order.cartitems[0].variation	}}
                  </span>
                        <!-- <span class="transaction-order" *ngIf="order.ordernumber !== null">Order ID : #GP 000{{
                            order.ordernumber}}</span> -->
                    </div>
                    <div class="col-7 d-block d-lg-none mt-2">
                        <h6 class="order-product-name text-truncate">{{ order.cartitems[0].title }}</h6>
                        <span class="product-amount">£{{ order.totalnetamount
                        }}</span>
                    </div>
                    <div class="col-3 d-block d-lg-none variation-tab" style="margin-top: -25px;">
                        <span class="variations">
                            {{order.cartitems[0].variation }}
                        </span>
                    </div>
                    <div class="col-2 col-md-2 text-start text-md-center mt-2 mt-md-0 d-none d-lg-block" style="margin-bottom: 25px;">
                        <span class="product-name">£{{ order.totalnetamount
                        }}</span>
                    </div>
                    <div class="d-block d-lg-none variation-align-content">
                        <div class="d-flex justify-content-end">
                            <span class="text-center delivery-card"><span
                                    [ngClass]="order.orderstatus === 'booked' ? 'status-dot yellow' : 'status-dot green'"></span>
                                Your item is {{ order.orderstatus }}</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 text-center mt-2 mt-md-0 d-lg-block d-none">
                        <h6 class="product-name text-start">
                            <span
                                [ngClass]="order.orderstatus === 'booked' ? 'status-dot yellow' : 'status-dot green'"></span>
                            Delivery on {{ getFormattedDate(order.deliveryscheduledate) }}
                        </h6>
                        <span class="m-auto d-block text-start delivery-card">Your item is {{ order.orderstatus
                            }}</span>
                    </div>
                </div>
                <hr class="d-lg-block d-none">
            </div>
        </div>
 
        <div *ngIf="showOrderDetails" class="order-details">
            <div class="orders-content">
                <div class="row w-100 d-lg-flex d-none">
                    <!-- Product Details Section -->
                    <span class="transaction-orders mb-1" *ngIf="orderDetails.ordernumber !== null">Order ID : #GP 000{{
                        orderDetails.ordernumber}}</span>
                    <div class="col-8">
 
                        <h6 class="mb-4">Product Details</h6>
                        <div class="row" *ngFor="let item of orderDetails.cartitems">
                            <div class="col-2 mb-4" *ngIf="item.src.length > 0">
                                <img [src]="item.src[0]" alt="Product Image" class="product-detail-img">
                            </div>
                            <div class="col-10">
                                <div class="order-info">
                                    <h6 class="product-title">{{ item.title	}}</h6>
                                    <p class="product-size">{{ item.variation}}</p>
                                    <span class="quantity-info">No of Quantity: {{ item.quantity }}</span>
                                </div>
                            </div>
                        </div>
                        <p class="product-price m-1">Total Price : £{{ orderDetails.totalnetamount }}</p>
 
                    </div>
                    <!-- Order Status Section -->
                    <div class="col-4">
                        <h6 class="mb-4">Order Status</h6>
                        <div class="order-status">
                            <p><span
                                    [ngClass]="orderDetails.orderstatus === 'booked' ? 'status-dot yellow' : 'status-dot green'"></span>
                                Ordered on {{ orderDetails.orderdate | date:'dd.MM.yyyy' }}</p>
                            <p><span
                                    [ngClass]="orderDetails.orderstatus === 'booked' ? 'status-dot yellow' : 'status-dot green'"></span>
                                Delivery on {{ orderDetails.deliveryscheduledate | date:'dd.MM.yyyy' }}
                                <small class="status-note text-center">You ordered item is waiting for delivery</small>
                            </p>
                            <!-- <button class="cancel-btn" (click)="toggleOrderPopup()">Cancel Product</button> -->
                        </div>
                    </div>
                </div>
                <div class="d-block d-lg-none mobile-order-content">
                    <h6 class="mb-4 d-lg-none d-block">Product Details</h6>
                    <div class="row mb-3" *ngFor="let item of orderDetails.cartitems">
                        <div class="col-3" *ngIf="item.src.length > 0">
                            <img [src]="item.src[0]" alt="" class="product-detail-mob-img">
                        </div>
                        <div class="col-9">
                            <div>
                                <div class="order-info">
                                    <span class="order-id">Order id: #GP 000{{ orderDetails.ordernumber }}</span>
                                    <h6 class="product-title text-truncate">{{ item.title }}</h6>
                                    <p class="product-size">{{ item.variation}}</p>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span class="quantity-info">No of Quantity: {{
                                            item.quantity }}</span>
                                            <p class="product-price">£{{ orderDetails.totalnetamount }}</p>
 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <!-- Order Status Section -->
                    <div class="d-block d-lg-none">
                        <h6 class="mb-4">Order Status</h6>
                        <div class="order-status">
                            <p><span
                                    [ngClass]="orderDetails.orderstatus === 'booked' ? 'status-dot yellow' : 'status-dot green'"></span>
                                Order on {{ orderDetails.orderdate | date:'dd.MM.yyyy' }}</p>
                            <p><span
                                    [ngClass]="orderDetails.orderstatus === 'booked' ? 'status-dot yellow' : 'status-dot green'"></span>
                                Delivery on {{ orderDetails.deliveryscheduledate | date:'dd.MM.yyyy' }}
                                <small class="status-note status-note-mobile text-center">You ordered item is waiting
                                    for delivery</small>
                            </p>
                            <!-- <button class="cancel-btn m-1" (click)="toggleOrderPopup()">Cancel Product</button> -->
                        </div>
                    </div>
                </div>
 
            </div>
            <hr>
            <!-- Delivery Address Section -->
            <div class="address-info row">
                <div class="col-lg-8 col-12 mb-3" style="padding-right: 0;">
                    <h6>Delivery Address</h6>
                    <div class="order_details">
                        <span>{{ orderDetails.firstname }} {{ orderDetails.lastname }} | {{ orderDetails.phone }} | {{
                            orderDetails.email }}</span>
                        <span>{{ orderDetails.doorno }}, {{ orderDetails.address }}, {{ orderDetails.postalcode
                            }}</span>
                    </div>
                </div>
                <hr class="d-lg-none d-block">
                <div class="col-lg-4 col-12" style="padding-left: 0;">
                    <h6>Payment</h6>
                    <div class="order_details" *ngFor="let item of orderDetails.orderpayments_details">
                        <span >
                            {{ item.method }} <small [ngClass]="{'text-danger': item.paymentstatus === 'pending', 'text-success': item.paymentstatus === 'success'}">(payment {{ item.paymentstatus }})</small>
                        </span>
                        <span class="order-payment-date">{{ item.date | date:'dd.MM.yyyy h:mm a' }}</span>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-12" style="padding-left: 0;">
                    <h6>Invoice</h6>
                    <button class="btn invoice-btn">Download invoice</button>
                </div> -->
                <hr>
                <div class="" style="padding-left: 0;" *ngIf="orderDetails.orderstatus !== 'booked'">
                    <h6>Invoice</h6>
                    <button class="btn invoice-btn">Download invoice</button>
                </div>
            </div>
 
        </div>
        <div class="order-overlay" *ngIf="showOrderPopup">
            <div class="order-popup-content">
                <div class="sign">
                    <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
                    <div class="popup-message">Do you want to cancel your product</div>
                </div>
                <hr class="hr-line">
                <div class="popup-actions">
                    <button class="btn no-confirm-btn" (click)="toggleOrderPopup()">No</button>
                    <div class="vr px-0 "></div>
                    <button class="btn yes-confirm-btn">Yes</button>
                </div>
            </div>
        </div>
    </div>
 
 
    <div class="subsciptions-content" *ngIf="activeSection === 'subscriptions'&& !orderEmpty">
        <div class="d-flex">
            <img src="../../assets/images/left_arrow.svg" alt="" class="me-2" *ngIf="showSubscribeDetails"
                (click)="toggleSubscribeDetails(subscriptions.id)" style="width: 15px; cursor: pointer;">
            <h6 *ngIf=" !showPauseSubscribeDetails">My Subscriptions</h6>
            <h6 *ngIf=" showPauseSubscribeDetails">Play/Pause Subscription</h6>
 
        </div>
        <hr>
        <div *ngIf="!showSubscribeDetails" class="ordr-list-contianer" (scroll)="onScroll()">
            <div class="row align-items-center subscription-item d-lg-flex d-none" *ngFor="let subscriptions of subscriptions_output" (click)="toggleSubscribeDetails(subscriptions.id)">
                <div class="col-lg-1">
                    <img [src]="subscriptions.src[0]" alt="" class="subsciption-img">
                </div>
                <div class="col-lg-5">
                    <h6 class="product-name">{{subscriptions.title}}</h6>
                    <span class="variations">{{subscriptions.variationvalue}} {{subscriptions.shortname}}</span>
                </div>
                <div class="col-lg-1 text-lg-center text-start p-0">
                    <span class="product-price">£ {{subscriptions.netamount}}</span>
                </div>
                <div class="col-lg-5">
                    <div class="d-flex align-items-center justify-content-lg-center justify-content-between gap-2">
                        <button
                        class="btn"
                        [ngClass]="{
                          'btn-success active-btn': subscriptions.subscriptionstatus === 'ACTIVE',
                          'pause-btn': subscriptions.subscriptionstatus === 'PAUSE',
                          'cancelled-btn': subscriptions.subscriptionstatus === 'CANCELLED'
                        }">
                        {{ subscriptions.subscriptionstatus }}
                      </button>
                     
                        <h6 class="product-name text-center">
                            {{ getFormattedSubscriptionDate(subscriptions.deliverystartdate || 0)}}
                             </h6>
                    </div>
                    <span class="m-auto d-block text-center delivery">Subsciption Starts</span>
                </div>
            <hr class="d-lg-block d-none mt-2">
 
            </div>
            <!-- for mobile -->
            <div class="subsciptions-content-card card d-lg-none d-block mb-4" *ngFor="let subscriptions of subscriptions_output">
                <div class="row subscription-item" (click)="toggleSubscribeDetails(subscriptions.id)">
                    <div class="col-2 mt-2">
                        <img [src]="subscriptions.src[0]" alt="" class="subsciption-img">
                    </div>
                    <div class="col-10 mt-2">
                        <div class="d-flex justify-content-around subscription-product-mobile-name">
                            <h6 class="subscription-product-name text-truncate">{{subscriptions.title}}</h6>
                            <span class="subscription-variations">{{subscriptions.variationvalue}} {{subscriptions.shortname}}</span>
                        </div>
 
                        <div class="d-flex justify-content-end m-2">
                            <button class="btn me-2"[ngClass]="{ 'btn-success subscription-active-btn': subscriptions.subscriptionstatus === 'ACTIVE',
                            ' pause-btn': subscriptions.subscriptionstatus === 'PAUSE' ,
                            'cancelled-btn': subscriptions.subscriptionstatus === 'CANCELLED' }">
                                {{ subscriptions.subscriptionstatus }}
                            </button>
                            <h6 class="subscription-product-date mb-0 mt-1">{{ getFormattedSubscriptionDate(subscriptions.deliverystartdate || 0)}}</h6>
                        </div>
                        <div class="d-flex justify-content-between align-items-center m-2">
                            <span class="subscription-product-price">£ {{subscriptions.netamount}}</span>
                            <span class=" delivery">Subscription Starts</span>
                           
                        </div>
                        <div>
 
                        </div>
                    </div>
                </div>
            </div>
         
        </div>
 
        <div *ngIf="showSubscribeDetails" class="subscribe-details">
            <div class="subscriptions-content">
                <div class="row w-100">
                    <!-- Product Details Section -->
                    <div class="col-12 col-lg-8">
                        <h6 class="mb-4">Product Details</h6>
                        <div class="row">
                            <div class="col-lg-2 col-3">
                                <img [src]="subscriptions?.src[0]" alt="Product Image"
                                    class="product-detail-img">
                            </div>
                            <div class="col-lg-10 col-9">
                                <div class="order-info">
                                    <span class="order-id">Order id: #GP 000{{ subscriptions?.subscriptionnumber }} </span>
                                    <h6 class="product-title"> {{subscriptions?.title}} </h6>
                                    <p class="product-size">{{subscriptions?.variationvalue}} {{subscriptions?.shortname}}</p>
                                    <span class="quantity-info d-lg-block d-none">No of Quantity: {{subscriptions?.quantity}}</span>
                                    <p class="product-price d-lg-block d-none">£ {{subscriptions?.netamount}} </p>
                                    <div class="d-lg-none d-flex align-items-center justify-content-between">
                                        <span class="quantity-info">No of Quantity: {{subscriptions?.quantity}} </span>
                                        <p class="product-price">£ {{subscriptions?.netamount}} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Subscription Status Section -->
                    <hr class="d-lg-none d-block">
                    <div class="col-lg-4 col-12">
                        <h6 class="mb-4">Subscription Status</h6>
                        <div class="order-status">
                            <div class="d-flex gap-3">
                                <button class="btn"[ngClass]="{
                                    'btn-success active-btn': subscriptions?.subscriptionstatus === 'ACTIVE',
                                    'pause-btn': subscriptions?.subscriptionstatus === 'PAUSE',
                                    'cancelled-btn': subscriptions?.subscriptionstatus === 'CANCELLED'
                                  }">
                                    {{ subscriptions?.subscriptionstatus }}
                                </button>
                            <div class="d-block">
 
                                <p>
                                    {{ getFormattedSubscriptionDate(subscriptions?.deliverystartdate || 0)}}
                                    <small class="status-note text-start">Subscription Starts</small>
                                </p>
                                <p>
                                    {{ getFormattedSubscriptionDate(subscriptions?.deliveryenddate || 0)}}
                                    <small class="status-note text-start">Subscription Ends</small>
                                </p>
                            </div>
                           
                        </div>
 
                            <button class="cancel-btn d-lg-block d-none" *ngIf="subscriptions?.subscriptionstatus !== 'CANCELLED' && subscriptions?.subscriptionstatus !== 'PAUSE'" (click)="togglePauseSubscribeDetails()">Pause
                                Subscription</button>
                            <button class="cancel-btn d-lg-block d-none" *ngIf="subscriptions?.subscriptionstatus !== 'CANCELLED' && subscriptions?.subscriptionstatus !== 'PAUSE'" (click)="toggleSubscriptionPopup()">Cancel
                                Subscription</button>
                            <button class="cancel-btn d-lg-block d-none" *ngIf="subscriptions?.subscriptionstatus === 'PAUSE'" (click)="pauseOrResumeSubscription('ACTIVE')">Resume
                                Subscription</button>
                            <div class="d-lg-none d-block">
                                <div class="row subscribe-btn-container">
                                    <button class="btn col-6 subscribe-btn"  *ngIf="subscriptions?.subscriptionstatus !== 'CANCELLED' && subscriptions?.subscriptionstatus !== 'PAUSE'" (click)="togglePauseSubscribeDetails()">
                                        Pause
                                        Subscription
                                    </button>
                                    <button class="btn col-6 subscribe-btn"  *ngIf="subscriptions?.subscriptionstatus !== 'CANCELLED' && subscriptions?.subscriptionstatus !== 'PAUSE'" (click)="toggleSubscriptionPopup()">Cancel
                                        Subscription</button>
                                    <button class="btn col-12 subscribe-btn" *ngIf="subscriptions?.subscriptionstatus === 'PAUSE'"  (click)="pauseOrResumeSubscription('ACTIVE')">
                                        Resume
                                        Subscription
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pause-subscription" *ngIf="showPauseSubscribeDetails">
                    <hr>
                    <div class="d-lg-flex d-block justify-content-center align-items-center gap-4">
                        <div class="d-flex align-items-center">
                            <input type="radio" class="radio-btn" name="pause" (click)="togglePauseSubscribe()">
                            <label for="" class="subscription-pause-label">Pause subscription till when i resume</label>
                        </div>
                        <div class="d-flex align-items-center">
                            <input type="radio" class="radio-btn" name="pause" (click)="togglePauseSubscribeDate()">
                            <label for="" class="subscription-pause-label">Pause subscription till the date</label>
                        </div>
 
                    </div>
                    <div class="mt-3" *ngIf="pauseSubscription">
                        <button class="btn subscribe-pause-btn d-block m-auto" (click)="pauseOrResumeSubscription('PAUSE')">
                            Pause
                            Subscription
                        </button>
                    </div>
 
                    <div class="d-lg-flex justify-content-center mt-3" *ngIf="pauseSubscriptionDate">
                        <div class="row">
                            <div class="col-lg-4 col-6">
                                <div>
                                    <label for="" class="form-label date-label">Starts on</label>
                                    <input type="date" class="form-control date-input" id="start-date" (change)="onStartDateChange($event)"
                                    [min]="formatDate(subscriptions.deliverystartdate)"
                                    [max]="formatDate(subscriptions.deliveryenddate)">
                                </div>
                            </div>
                            <div class="col-lg-4 col-6">
                                <div>
                                    <label for="" class="form-label date-label">Ends on</label>
                                    <input type="date" class="form-control date-input" id="end-date" (change)="onEndDateChange($event)"
                                    [min]="formatDate(subscriptions.deliverystartdate)"
                                    [max]="formatDate(subscriptions.deliveryenddate)">
                                </div>
                            </div>
                            <div class="col-lg-4 col-12 mt-4">
                                <button class="btn subscribe-pause-btn" (click)="pauseOrResumeSubscription('PAUSE')" [disabled]="!pauseStartDate || !pauseEndDate">
                                    Pause
                                    Subscription
                                </button>
                            </div>
                        </div>
 
                    </div>
                </div>
            </div>
            <hr>
            <!-- Delivery Address Section -->
            <div class="address-info row">
                <div class="col-lg-8 col-12 mb-3" style="padding-right: 0;">
                <h6>Delivery Address</h6>
                <div class="order_details">
                    <span> {{subscriptions?.firstname}} | {{subscriptions?.phone}} | {{subscriptions?.email}}</span>
                    <span> {{subscriptions?.deliveryarea}} </span>
                    </div>
                </div>
                <hr class="d-lg-none d-block">
                <div class="col-lg-4 col-12" >
                    <h6>Payment</h6>
                    <div class="order_details">
                        <span>{{subscriptions?.subscriptionpayments_details.method}} <small [ngClass]="{'text-danger': subscriptions.paymentstatus === 'pending', 'text-success': subscriptions.paymentstatus === 'success'}">(payment {{ subscriptions.paymentstatus }})</small> </span>
                        <span class="order-payment-date">{{ subscriptions?.subscriptionpayments_details.date | date:'dd.MM.yyyy h:mm a' }}</span>
                    </div>
                </div>
                <hr class="d-lg-none d-block">
                <div class="mt-2"  *ngIf="subscriptions?.subscriptionpayments_details.paymentstatus === 'success' && subscriptions?.subscriptionstatus === 'ACTIVE'">
                    <h6>Invoice</h6>
                    <button class="btn invoice-btn">Download invoice</button>
                </div>
            </div>
        </div>
        <div class="subscription-overlay" *ngIf="showsubscriptionPopup">
            <div class="subscription-popup-content">
                <div class="sign">
                    <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
                    <div class="popup-message">Do you want to cancel your subscription</div>
                </div>
                <hr class="hr-line">
                <div class="popup-actions">
                    <button class="btn no-confirm-btn" (click)="toggleSubscriptionPopup()">No</button>
                    <div class="vr px-0 "></div>
                    <button class="btn yes-confirm-btn" (click)="cancelSubscription('CANCELLED')">Yes</button>
                </div>
            </div>
        </div>
    </div>
 
 
    <div class="wallet-content" *ngIf="activeSection === 'wallet'">
        <div class="d-flex">
            <img src="../../assets/images/left_arrow.svg" alt="" class="me-2" style="width: 15px; cursor: pointer;"
                *ngIf="showWllaetDetails" (click)="toggleWalletDetails()">
            <h6 class="mb-0">My Wallet</h6>
        </div>
        <hr>
        <div class="row wallet-row" *ngIf="!showWllaetDetails">
            <div class="col-lg-6 col-md-12 mb-3">
                <div class="d-flex wallet-card-container">
                    <div class="card-container">
                    </div>
                    <div class="wallet-card p-3">
                        <div class="wallet-card-header">
                            <h6>Available Balance</h6>
                        </div>
                        <div class="wallet-card-body">
                            <div class="balance-section">
                                <div class="balance-details">
                                    <span class="currency-symbol">£</span>
                                    <span class="wallet-amount">{{ WalletBalanceAmount || '0.00' }}</span>
                                </div>
                                <div class="wallet-icon">
                                    <img src="../../assets/images/wallet-card-img.svg" alt="Wallet Icon">
                                </div>
                            </div>
 
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <!-- <hr class="wallet-line"> -->
                    <div>
                        <label for="" class="form-label">Add money to Wallet</label>
                        <div class="d-flex justify-content-between d-lg-flex d-none">
                            <input type="text" class="form-control w-75 add-money-wallet" placeholder="Enter amount"
                                [(ngModel)]="walletAmount" (keypress)="allowOnlyNumbers($event)"
                                (input)="validateAmount()">
                            <button (click)="toggleWalletDetails()" class="add-balance-btn btn me-3"
                                [disabled]="!isAmountValid"> <img src="../../assets/icon/wallet-add-plus-icon.svg"
                                    alt="Add Icon" class="add-icon me-2">Add</button>
                        </div>
                        <div class="d-lg-none d-block">
                            <input type="text" class="form-control w-100 add-money-wallet mb-3"
                                placeholder="Enter amount" [(ngModel)]="walletAmount"
                                (keypress)="allowOnlyNumbers($event)" (input)="validateAmount()">
                            <button (click)="toggleWalletDetails()" class="add-balance-btn btn w-75"
                                [disabled]="!isAmountValid"> <img src="../../assets/icon/wallet-add-plus-icon.svg"
                                    alt="Add Icon" class="add-icon me-2">Add Wallet Amount</button>
                        </div>
                    </div>
                    <!-- <a href="javascript:void(0)" class="add-balance-link"
                        (click)="toggleShowSubscribePaymentDetails()">
                        <img src="../../assets/images/wallet-add-btn.svg" alt="Add Icon" class="add-icon"> Add
                        wallet balance
                    </a> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12 mb-3">
                <div class="transactions-card p-3">
                    <div class="transactions-card-header d-flex justify-content-between align-items-center">
                        <h6>Your Transactions</h6>
                        <!-- Custom Dropdown with Radio Buttons -->
                        <div class="custom-dropdown">
                            <button class="dropdown-btn">Time Line <img src="../../assets/icon/down-arrow.svg" alt=""
                                    class="arrow-icon"></button>
                            <div class="dropdown-content">
                                <label class="mt-2">
                                    <input type="radio" name="timeline" value="last_1_month"
                                        (change)="onTimelineChange('last_1_month')" /> Last 1 month
                                </label>
                                <label class="mt-3">
                                    <input type="radio" name="timeline" value="last_2_months"
                                        (change)="onTimelineChange('last_2_months')" /> Last 2 months
                                </label>
                                <label class="mt-3">
                                    <input type="radio" name="timeline" value="last_3_months"
                                        (change)="onTimelineChange('last_3_months')" /> Last 3 months
                                </label>
                                <label class="mt-3">
                                    <input type="radio" name="timeline" value="old_transaction"
                                        (change)="onTimelineChange('old_transaction')" /> Old Transaction
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="overflow-auto">
                        <ul class="transactions-list transaction-body">
                            <li class="transaction-item" *ngFor="let transaction of userWalletList">
                                <div class="row ">
 
                                    <div class="col-md-5 col-6 transaction-amount align-items-center pe-0">
                                        <span class="amount-text text-success me-4" *ngIf="transaction.credit">£ {{
                                            transaction.credit }}</span>
                                        <span class="amount-text text-danger me-4" *ngIf="transaction.debit"> £ {{
                                            transaction.debit }}</span>
                                        <span class="transaction-date transaction-payment">{{
                                            transaction.transactiondate | date:'EEEE, dd.MM.yyyy'
                                            }}</span>
                                        <!-- <img src="../../assets/icon/right-arrow.svg" alt="Arrow" class="right-arrow-icon"
                                            (click)="toogleSelectedPaymentDetailsMethod()"> -->
                                    </div>
                                    <div class="col-md-7 col-6 transaction-details ps-0">
                                        <div class="amount-text text-success  text-end pe-2" *ngIf="transaction.credit">
                                            credit</div>
                                        <div class="amount-text text-danger text-end pe-2" *ngIf="transaction.debit">
                                            debit</div>
                                        <div class="d-md-flex">
                                            <div class="transaction-payment pe-1 mt-md-0 mt-2 text-end"
                                                [ngClass]="{'w-100': transaction.ordernumber === null}">
                                                {{ transaction.transactionmethod | titlecase }} payment
                                            </div>
                                            <ng-container
                                                *ngIf="transaction.ordernumber !== null; else fullWidthTransaction">
                                                <div class="transaction-order mt-md-0 mt-2 text-end"
                                                    (click)="orderWalletView(transaction)">
                                                    Order ID: #GP 000{{ transaction.ordernumber }}
                                                </div>
                                            </ng-container>
                                        </div>
                                        <ng-template #fullWidthTransaction>
                                            <!-- Empty template if needed, or additional elements to control the layout -->
                                        </ng-template>
                                    </div>
                                </div>
 
                               
                                <hr *ngIf="userWalletList.length>1">
                            </li>
 
                        </ul>
                    </div>
 
 
 
                </div>
            </div>
        </div>
        <div class="wallet-balance-container" *ngIf="showWllaetDetails">
            <div>
                <div>
                    <input type="radio" name="paymentRadio" (click)="setTransactionMethod('card')">
                    <label for="" class="card-payment">Card Payment</label>
                </div>
                <div class="card_payment_content m-4" *ngIf="selectedPaymentMethod">
                    <!-- Card Payment Content -->
                    <div class="card_details" *ngIf="userCardsList.length>0">
                        <label>Saved Card details</label>
                        <div class="card mt-2" *ngFor="let card of userCardsList">
                            <div class="card-body d-lg-flex d-none align-items-center justify-content-between">
                                <input type="radio" name="savedCard" class="me-3" [(ngModel)]="selectedSavedCard"
                                    [value]="card" (change)="onSavedCardSelect(card)">
                                <div class="d-block">
                                    <span>{{card.cardholdername}}</span>
 
                                </div>
                                <div>XXXX XXXX XXXX {{ card.cardnumber}}</div>
 
                                <div>
                                    <img src="../../assets/images/visa_bank.svg" alt="">
                                </div>
                            </div>
                            <div class="card-body d-lg-none d-block align-items-center justify-content-between"
                                style="padding: 10px;">
                                <div class="row">
                                    <div class=" col-2">
                                        <input type="radio" name="savedCard" class="me-3" [value]="card" (change)="onSavedCardSelect(card)">
                                    </div>
                                    <div class="col-7">
                                        <span class="card-user-name">{{card.cardholdername}}</span><br>
                                        <span class="card-number">XXXX XXXX XXXX {{ card.cardnumber}}</span>
 
 
                                    </div>
                                    <div class="col-3"><br>
                                        <span class="card-type text-end d-block">
                                            <!-- Debit card -->
                                            <img src="../../assets/images/visa_bank.svg" alt="">
 
                                        </span>
                                    </div>
                                </div>
 
                            </div>
                        </div>
                    </div>
 
                    <div class="newcard_details mt-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <input type="radio" name="savedCard" class="me-3" (click)="enableNewCard()">
 
                                <label>Pay with new card</label>
 
                            </div>
 
                            <div class="row d-lg-block d-none">
                                <img src="../../assets/images/visa_bank.svg" alt="" id="cardDetailsImg" class="col-4">
                                <img src="../../assets/images/mastercard-img.svg" alt="" class="col-4">
                                <img src="../../assets/images/discover-img.svg" alt="" class="col-4">
                            </div>
                        </div>
                        <div class="card mt-2" [ngClass]="{ 'disabled-card': isCardDisabled }">
                            <div class="card-body">
                                <div>
                                    <label for="cardHolderName" class="form-label card-holder-name">Card Holder
                                        name</label>
                                    <input type="text" id="cardHolderName" class="form-control content"
                                        placeholder="Card holder name" [(ngModel)]="cardHolderName">
                                </div>
 
                                <div class="input-with-icon mt-2">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <label for="cardDetails" class="form-label card-no">Card number</label>
                                        <div class="row d-lg-none d-block">
                                            <img src="../../assets/images/visa_bank.svg" alt="" id="cardDetailsImg"
                                                class="col-4">
                                            <img src="../../assets/images/mastercard-img.svg" alt="" class="col-4">
                                            <img src="../../assets/images/discover-img.svg" alt="" class="col-4">
                                        </div>
                                    </div>
                                    <div class="input-container">
                                        <div class="payment-container">
                                            <div id="card-element" class="form-control content"></div>
                                            <div *ngIf="cardErrors" class="error">{{ cardErrors }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-check mt-4" [ngClass]="{ 'disabled-card': isCardDisabled }">
                                    <input type="checkbox" id="saveCardDetails" class="form-check-input"
                                        [(ngModel)]="saveCardDetails">
                                    <label for="saveCardDetails" class="form-check-label">Save your card details for
                                        later
                                        payment</label>
                                </div>
                            </div>
                            <!-- <div class="col d-lg-block d-none">
                                <div class="mt-4 text-end">
                                    <button class="btn payment-btn"
                                        (click)="handlePayment(convertToNumber(walletAmount))"
                                        [disabled]="!selectedSavedCard && (!isCardValid || !isAmountValid || !cardHolderName) && transactionMethod === null">Make
                                        Payment</button>
                                </div>
                            </div> -->
                        </div>
 
                    </div>
 
                </div>
            </div>
            <hr>
            <div>
                <input type="radio" name="paymentRadio" (click)="setTransactionMethod('link')">
                <label for="" class="card-payment">Pay with link</label>
            </div>
            <div class="col d-lg-block d-none">
                <div class="mt-4 text-end">
                    <button class="btn payment-btn" [disabled]="(!selectedSavedCard && (!isCardValid || !isAmountValid || !cardHolderName)) && transactionMethod !== 'link'"
 
                        (click)="handlePayment(convertToNumber(walletAmount))"
                        >Make
                        Payment</button>
                </div>
            </div>
            <div class=" d-lg-none d-block">
                <div class="mt-4 text-center">
                    <button class="btn payment-mobile-btn"  [disabled]="(!selectedSavedCard && (!isCardValid || !isAmountValid || !cardHolderName)) && transactionMethod !== 'link'" (click)="handlePayment(convertToNumber(walletAmount))">Make Payment</button>
                </div>
            </div>
        </div>
 
    </div>
 
    <div class="card-content" *ngIf="activeSection === 'cards'">
        <div class="d-flex align-items-center justify-content-between" *ngIf="!showCardPaymentContent">
            <h6>Card details</h6>
            <button class="btn btn-outline-secondary add-address-btn" (click)="toggleCardPaymentContent()">+ Add Card
                details</button>
        </div>
        <div class="d-flex align-items-center justify-content-between" *ngIf="showCardPaymentContent">
            <h6><img src="../../assets/images/left_arrow.svg" alt="backIcon" (click)="backArrow()"> {{ isEditCardMode ?
                'Edit new card details' : 'Add new card details' }}</h6>
        </div>
        <hr>
        <div *ngIf="!showCardPaymentContent" class="ordr-list-contianer" (scroll)="onScroll()">
            <div class="row d-lg-flex d-none border-bottom text-center align-items-center p-4 justify-content-center" *ngFor="let card of userCardsList"  >
                <div class="col-2" >
                    <div>
                        <h6 class="card-detail-content">{{card.cardholdername}}</h6>
                    </div>
                </div>
                <div class="col-8">
                    <div>
                        <h6 class="card-detail-content">XXXX XXXX XXXX {{ card.cardnumber.slice(-4) }}</h6>
                    </div>
                </div>
                <div class="col-1">
                    <div>
                        <a href="javascript:void(0)" class="delete-link"
                            (click)="deleteCardConfirmation(card)">Delete</a>
                    </div>
                </div>
                <!-- <hr class="d-lg-block d-none" *ngIf="userCardsList.length>1"> -->
            </div>
            <!-- for mobile -->
            <div class="d-lg-none d-block" *ngFor="let card of userCardsList">
                <div class="card-details-mobile p-2 mb-4">
                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="d-flex">
                                <h6 class="card-detail-content me-2">{{card.cardholdername}}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <div>
                                <h6 class="card-detail-content">XXXX XXXX XXXX {{ card.cardnumber.slice(-4) }}</h6>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <!-- <div class="col-6">
                            <div>
                                <a href="javascript:void(0)" class="edit-link text-center d-block"
                                    (click)="UserEditCard(card.id)">Edit</a>
                            </div>
                        </div> -->
                        <div class="col">
                            <div>
                                <a href="javascript:void(0)" class="delete-link text-center d-block"
                                    (click)="deleteCardConfirmation(card)">Delete</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-delete-overlay" *ngIf="showCardDeletePopup">
                <div class="card-delete-content">
                    <div class="sign">
                        <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
                        <div class="popup-message">Do you want to delete your Card details</div>
                    </div>
                    <hr class="hr-line">
                    <div class="popup-actions">
                        <button class="btn no-confirm-btn" (click)="cancelDeleteCard()">No</button>
                        <div class="vr px-0 "></div>
                        <button class="btn yes-confirm-btn" (click)="confirmDeleteCard()">Yes</button>
                    </div>
                </div>
            </div>


        </div>
        <!-- add card btn click show content -->
        <div class="row align-items-center" *ngIf="showCardPaymentContent">
            <div class="card_payment_content">
                <div class="newcard_details">
                    <div class="mt-2">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <label for="cardHolderName" class="form-label card-holder-name">Card Holder
                                        name</label>
                                    <input type="text" id="cardHolderName" class="form-control content"
                                        placeholder="Card holder name" [(ngModel)]="cardHolderName">
                                </div>
                            </div>
                            <div class="input-with-icon mt-3">
                            </div>
                            <div class="payment-container">
                                <div id="card-element" class="form-control content"></div>
                                <div *ngIf="cardErrors" class="error">{{ cardErrors }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-check mt-4">
                        <!-- Save button enabled only if all fields are filled -->
                        <button class="btn save_btn d-lg-block d-none" (click)="handlePayment()">Save
                            card</button>
                        <button class="btn save_mob_btn d-lg-none d-block m-auto" (click)="handlePayment()">Save
                            card</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="address-content" *ngIf="activeSection === 'address'">
        <div class="d-flex align-items-center justify-content-between"
            *ngIf="!showCardAddressContent && !showNewAddress">
            <h6>Address</h6>
            <button class="btn btn-outline-secondary add-address-btn" (click)="toggleCardAddressContent()">+ Add
                Address</button>
        </div>
        <div class="d-flex align-items-center justify-content-between" *ngIf="showCardAddressContent || showNewAddress">
            <h6>
                <img src="../../assets/images/left_arrow.svg" (click)="GoBack()" alt="backIcon" style="cursor:pointer">
                {{ isEditMode ? 'Edit Address' : 'Add New Address' }}
            </h6>
        </div>

        <hr>
        <!-- for mobile -->
        <div class="delivery-card-mobile d-lg-none d-block" *ngIf="!showCardAddressContent && !showNewAddress">
            <ul class="card mt-2 p-0" *ngFor="let address of UserAddressList">
                <li class="list-unstyled border-bottom border-top p-2">
                    <div class="row">
                        <div class="col-10 pe-0">
                            <label class="delivery_details">
                                {{address.firstname}}
                                <!-- Conditionally display phone and email if they exist -->
                                <span *ngIf="address.phone"> | {{address.phone}}</span>
                                <span *ngIf="address.email"> | {{address.email}}</span>
                            </label>
                        </div>
                        <div class="col-2 ps-0 text-end">
                            <small class="work p-1 badge badge-secondary work-badge">{{address.type}}</small>
                            <small class="work p-1 badge badge-secondary work-badge d-lg-flex d-none"
                                *ngIf="address.isdefault ===true">Default</small>
                        </div>
                    </div>
                    <p class="delivery_address">{{address.deliveryarea}}</p>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" data-bs-toggle="tooltip"
                            data-bs-placement="bottom" title="Set as default" [(ngModel)]="address.isdefault"
                            (change)="drafultAddress(address)">
                        <label class="default-address">{{ address.isdefault ? 'Default address' : 'Set as Default address' }}</label>
                    </div>
                    <hr>
                    <div class="row ">
                        <!-- <a (click)="drafultAddress(address.id)" class="col text-center edit-link">Set as deafult</a> -->
                        <a (click)="UserEditAddress(address.id)" class="col text-center edit-link">Edit</a>
                        <div class="vr px-0 "></div>
                        <a class="col text-center delete-link" (click)="deleteAddressConfirmation(address)">Delete</a>
                    </div>
                </li>

            </ul>
        </div>
        <!-- for lap -->
        <div class="row align-items-center d-lg-flex d-none" *ngIf="!showCardAddressContent && !showNewAddress" class="ordr-list-contianer" (scroll)="onScroll()">
            <ul class="mt-2 p-0" *ngFor="let address of UserAddressList">
                <li class="list-unstyled border-bottom p-2">
                    <div class="d-flex justify-content-between">
                        <div>
                            <label class="delivery_details">
                                {{address.firstname}}
                                <span *ngIf="address.phone"> | {{address.phone}}</span>
                                <span *ngIf="address.email"> | {{address.email}}</span>
                            </label>
                            <small class="work p-1 badge-secondary badge work-badge">{{address.type}}</small>
                            <small class="work p-1 badge-secondary badge work-badge"
                                *ngIf="address.isdefault ===true">Default</small>
                        </div>
                        <div class="d-flex">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="{{ address.isdefault ? 'Default address' : 'Set as Default address' }}"
                                    [(ngModel)]="address.isdefault" (change)="drafultAddress(address)">
                            </div>
                            <a (click)="UserEditAddress(address.id)" class="me-2 edit-link">Edit</a>
                            <a class="delete-link" (click)="deleteAddressConfirmation(address)">Delete</a>
                        </div>

                    </div>
                    <p class="delivery_address">{{address.deliveryarea}} , {{address.postalcode}}</p>
                </li>
            </ul>
        </div>

        <!-- Delete Confirmation Popup -->
        <div class="card-delete-overlay" *ngIf="showDeletePopup">
            <div class="card-delete-content">
                <div class="sign">
                    <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
                    <div class="popup-message">Do you want to delete your address details?</div>
                </div>
                <hr class="hr-line">
                <div class="popup-actions">
                    <button class="btn no-confirm-btn" (click)="cancelDelete()">No</button> <!-- Cancel Delete -->
                    <div class="vr px-0 "></div>
                    <button class="btn yes-confirm-btn" (click)="confirmDeleteAddress()">Yes</button>
                    <!-- Confirm Delete -->
                </div>
            </div>
        </div>

        <div class="map-container" *ngIf="isMapVisible && showCardAddressContent">

            <div id="map-content" class=" position-relative">
                <input id="pac-input" type="text" placeholder="Search for places..."
                    class="controls pac-target-input  position-absolute" autocomplete="off" />
                <div id="map"></div>
            </div>

            <div>
                <label for="deliveryArea" class="form-label">Delivery area</label>
                <div class="input-with-link">
                    <input type="text" class="form-control" id="deliveryArea" placeholder="Delivery area"
                        [value]="selectedAddress" readonly>

                </div>
            </div>
            <div class="btn-container mt-3">
                <button class="btn next-btn" (click)="showNewAddressContainer()">Next <img
                        src="../../assets/images/next_rightarrow.svg" class="next-btn-img" alt="nextIcon"></button>
            </div>
        </div>
        <!-- Add new address content -->
        <div class="new-address-container" *ngIf="showNewAddress && isdeliveryArea">
            <div class="new-address-content">
                <div class="row mb-3">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">First name <span class="mandatory">*</span></label>

                        <input type="text" class="form-control" placeholder="Enter your First name"
                            [(ngModel)]="firstName" (keypress)="validateInput($event)">
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Last name</label>
                        <input type="text" class="form-control" placeholder="Enter your Last name"
                            [(ngModel)]="lastName" (keypress)="validateInput($event)">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Mobile number</label>
                        <ngx-intl-tel-input class="d-block w-100 phone"
                            [preferredCountries]="[CountryISO.India, CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectedCountryISO]="selectedCountryISO" [(ngModel)]="phone">
                        </ngx-intl-tel-input>


                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Email id</label>
                        <input type="text" class="form-control" placeholder="Enter your Email id" [(ngModel)]="email">
                    </div>
                </div>
                <div class="mb-3">
                    <label for="deliveryArea" class="form-label">Delivery area</label>
                   
                    <div class="position-relative textarea-container">
                        <textarea
                          #textarea
                          maxlength="200"
                          class="auto-expand"
                          id="deliveryArea"
                          placeholder="Delivery area"
                          [ngModel]=" selectedAddress"
                          (ngModelChange)="isEditMode ? deliveryArea = $event : selectedAddress = $event"
                          readOnly appAutoResize>
                        </textarea>
                        <a href="javascript:void(0)" class="absolute-link" (click)="enableEdit()">Change</a>
                    </div>

                </div>

                <div class="mb-3">
                    <label for="" class="form-label">Address <span class="mandatory">*</span>
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="address">
                </div>
                <div class="row mb-3">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Floor/Door no (optional)</label>
                        <input type="text" class="form-control" [(ngModel)]="doorNo">
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Landmark</label>
                        <input type="text" class="form-control" [(ngModel)]="landmark">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Postal code <span class="mandatory">*</span></label>
                        <input type="text" class="form-control" placeholder="Enter your Postal code"
                            [(ngModel)]="postalCode">
                    </div>
                    <div class="col-md-6 col-12">
                        <label for="" class="form-label">Address type <span class="mandatory">*</span></label>
                        <div class="row">
                            <!-- Home -->
                            <div class="col-md-4 col-sm-4 col-4">
                                <div class="card home-cards" (click)="setAddressType('home')"
                                    [ngClass]="{'selected-address': selectedAddressType === 'home'}">
                                    <div class="card-body home-card-body">Home</div>
                                </div>
                            </div>

                            <!-- Office -->
                            <div class="col-md-4 col-sm-4 col-4">
                                <div class="card home-cards" (click)="setAddressType('office')"
                                    [ngClass]="{'selected-address': selectedAddressType === 'office'}">
                                    <div class="card-body home-card-body">Office</div>
                                </div>
                            </div>

                            <!-- Others -->
                            <div class="col-md-4 col-sm-4 col-4">
                                <div class="card home-cards" (click)="setAddressType('others')"
                                    [ngClass]="{'selected-address': selectedAddressType === 'others'}">
                                    <div class="card-body home-card-body">Others</div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="mt-3">
                    <button class="btn next-save-btn" [disabled]="!isFormValid()"
                        (click)="saveAddress(selectedAddressId)">Save Address</button>
                </div>
            </div>
        </div>

    </div>
    <div class="notification-content" *ngIf="activeSection === 'notifications'">
        <h6>Notifications</h6>
        <hr><div class="ordr-list-contianer" (scroll)="onScroll()">
        <div class="d-lg-block d-none" *ngFor="let notification of UserNotificationList">
            <div class="notification-item d-flex align-items-center" (click)="markAsRead(notification.id)">
                <div>
                    <img src="../../assets/images/notification-delivered.svg" alt="Order Image" class="order-img"
                        *ngIf="notification.notifytype === 'ORDER_RECEIVED' || notification.notifytype ==='SUB_RECEIVED'">
                    <img src="../../assets/images/notification-waiting.svg" alt="Order Image" class="order-img"
                        *ngIf="notification.notifytype === 'ORDER_BOOKED' || notification.notifytype === 'SUB_BOOKED'">
                        <img src="../../assets/images/notification-cancel.svg" alt="Order Image" class="order-img"
                        *ngIf="notification.notifytype === 'ORDER_CANCELLED' || notification.notifytype === 'SUB_CANCELLED'">            
                </div>
                <div class="d-block flex-grow-1 mx-3">
                    <p class="mb-1 notification-text" [ngClass]="{'font-weight-bold': !notification.isread}">{{
                        notification.message }}</p>
                    <p class="mb-0 notification-date" *ngIf="notification.notifydate != null">{{
                        getFormattedDate(notification.notifydate || ' date:dd.MM.yyyy')}}</p>
                </div>
                <div>
                    <a href="javascript:void(0)" class="view-details-link"
                        (click)="markAsRead(notification.id)">View
                        details</a>
                </div>
 
            </div>
            <hr *ngIf="UserNotificationList.length > 1">
 
 
        </div></div>
        <!-- for mobile -->
        <div class="d-lg-none d-block">
            <div *ngFor="let notification of UserNotificationList" class="notification-item row">
                <div class="col-2">
                    <div>
                        <img src="../../assets/images/notification-delivered.svg" alt="Order Image" class="order-img"
                            *ngIf="notification.notifytype === 'ORDER_RECEIVED' || notification.notifytype ==='SUB_RECEIVED'">
                        <img src="../../assets/images/notification-waiting.svg" alt="Order Image" class="order-img"
                            *ngIf="notification.notifytype === 'ORDER_BOOKED' || notification.notifytype === 'SUB_BOOKED'">
                        <img src="../../assets/images/notification-cancel.svg" alt="Order Image" class="order-img"
                            *ngIf="notification.notifytype === 'ORDER_CANCELLED' || notification.notifytype === 'SUB_CANCELLED'">    
                    </div>
                </div>
                <div class="col-10">
                    <p class="mb-1 notification-mobile-text" [ngClass]="{'font-weight-bold': !notification.isread}">{{
                        notification.message }}</p>
                    <div class="d-flex align-items-center justify-content-between">
                        <p class="mb-0 notification-date" *ngIf="notification.notifydate != null">{{
                            getFormattedDate(notification.notifydate || ' date:dd.MM.yyyy') }}</p>
                        <a href="javascript:void(0)" class="view-details-mobile-link"
                        (click)="markAsRead(notification.id)">View details</a>
                    </div>
                </div>
                <hr *ngIf="UserNotificationList.length > 1">
 
            </div>
       
        </div>
    </div>
 
  <!-- Empty card -->
  <div class="profile-content" *ngIf="orderEmpty && activeSection !== 'wallet' && activeSection !== 'profile'&& activeSection !== 'cards'&& activeSection !== 'address'&& activeSection !== 'notifications'">
    <div class="empty-cart-content">
        <img src="../../assets/images/empty_cart.svg" alt="" class="img-fluid d-block mx-auto">
        <h5 class="text-center mt-3" *ngIf="activeSection === 'orders' || activeSection === 'subscriptions'">Your Cart is Empty!</h5>
        <h5 class="text-center mt-3"  *ngIf="activeSection === 'wishlist'" >Your Wishlist is Empty!</h5>

        <div class="shopping text-center">
            <a href="/home">Continue Shopping</a>
        </div>
    </div>
</div>
    <div class="signout-overlay" *ngIf="showSignoutPopup">
        <div class="signout-content">
            <div class="sign">
                <img src="../../assets/images/signout-img.svg" alt="warningIcon" class="popup-icon">
                <div class="popup-message">Do you want to logout your account</div>
            </div>
            <hr class="hr-line">
            <div class="popup-actions">
                <button class="btn no-confirm-btn" (click)="toggleSignoutPopup()">No</button>
                <div class="vr px-0 "></div>
                <button class="btn yes-confirm-btn" (click)="signout()">Yes</button>
            </div>
        </div>
    </div>
</div>
<app-play-store></app-play-store>