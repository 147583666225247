import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  pagesOutput: any[] = [];
  mainCategories: any[] = [];
  subCategories: any[] = [];
  groceryCategoryId: string | null = null;
  homeData: any;
  activeCategoryId: any;
  mailId: string;
  constructor(public router: Router, private http: HttpClient, private productService: ProductService) {
    this.pageType()
    this.categoriesListLevel_0()
    this.mailId = 'sajisv8@gmail.com';
  }
  pageType(): void {
    const requestBody = {
      sort:"ASC",
      sortColumn:"pages.title"
    };

    this.http.post(environment.apiUrl + `/pages/list`, requestBody, { responseType: 'json' })
  .subscribe({
    next: (data: any) => {
      if (data.status === 'SUCCESS') {
        this.pagesOutput = data.pages_output;
      } else {
        console.error('Failed to fetch pages list:', data.message);
      }
    },
    error: (error) => {
      console.error("Pages List API Error:", error);
    }
  });

  }

  click_pageType(page: any): void{
  
        // Handle the response data here
        if (page.pagetype === 'about-us') {
          this.router.navigate(['/about-us'], { state: { pageData: page.pagetype } });
        } else if (page.pagetype === 'cancellation-policy') {
          this.router.navigate(['/cancellation-policy'], { state: { pageData: page.pagetype  } });
        } else if (page.pagetype === 'privacy-policy') {
          this.router.navigate(['/privacy-policy'], { state: { pageData: page.pagetype  } });
        } else if (page.pagetype === 'terms-conditions') {
          this.router.navigate(['/terms-conditions'], { state: { pageData: page.pagetype  } });
        } else {
          console.error('Unknown pagetype:', page.pagetype);
        }
      
    }


    categoriesListLevel_0(): void {
      const requestBody = {
        status: 1,
        parentId: '',
        level: '0',
      };
   
      this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            this.mainCategories = data.categories_output;
   
            //variations
            this.mainCategories.forEach((categories: any) => {
              if (categories.categorykey && categories.categorykey.length > 0) {
                categories.categorykey = categories.categorykey;
              }
            })
   
            // if (this.homeData) {
            //   const parentId = this.homeData.parentId;
   
            //   this.categoriesListLevel_1(parentId)
            // }
            const groceryCategory = this.mainCategories.find(category => category.parentname.toLowerCase() === 'grocery');
            if (groceryCategory) {
              this.groceryCategoryId = groceryCategory.id;
            }
          }
        },
      );
    }
    categorykey_click(card: any): void {
      // this.SubscribeOpen=false;
      
  //  this.router.navigate(['/product-list-pages'], {
  //   queryParams: { category: card.categorykey },
  //   state: { dataToPass: { category_Id: card.id ,datafrom_page:'header'} }
  // });
  const From_Main_Category="From_Main_Category"
this.router.navigate(['/product-list-pages'], { queryParams: { category: card.categorykey } ,state: { dataToPass: { datafrom: From_Main_Category,category_Id: card.id,datafrom_page:'header'} }});
     
    }
   
    categoriesListLevel_1(parentId: string): void {
      const requestBody = {
        status: 1,
        parentId: parentId,
        level: '1',
      };
   
      this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            this.subCategories = data.categories_output;
            if (this.homeData) {
   
              this.activeCategoryId = this.homeData.parentId;
   
            }
          }
        },
      )
      this.activeCategoryId = ''
    }
  //  footer function 
  openEmail(event: Event) {
    event.preventDefault(); // Prevent default mailto behavior
    window.open("mailto:sajisv8@gmail.com", "_blank");
  }
  detectedAddress: string = '';

  detectLocation() {
    const address = "Harlow, Edinburgh Way, Temple Fields, Harlow CM20 2DG";
    const encodedAddress = encodeURIComponent(address); // Encode address for URL
    window.open(`https://www.google.com/maps?q=${encodedAddress}`, "_blank");
  }
  openLink(platform: string) {
    switch (platform) {
      case 'facebook':
        window.open('https://www.facebook.com/AdvenzoneApp', '_blank');
        break;
      case 'twitter':
        window.open('https://twitter.com/advenzone', '_blank'); // Replace with the actual Twitter link
        break;
      case 'instagram':
        window.open('https://www.instagram.com/advenzone/', '_blank');
        break;
      case 'whatsapp':
        window.open('https://wa.me/7812838749', '_blank');
        break;
      default:
        console.error('Unknown platform');
    }
  }
    
  
}