<div class="text-center p-5">
    <h2 class="text-success">Payment Completed!</h2>
    <p>You have successfully added  to your wallet. Your wallet balance has been updated. Thank you for using our wallet service!</p>

  
    <!-- Icon or image to represent success -->
    <div class="success-icon">
        <img src="success-icon.png" alt="Success" width="100" height="100" />
    </div>
  
    <!-- Button to go back to home or next step -->
    <div class="mt-4">
        <button class="btn btn-primary me-1" (click)="goHome()">Go to Home</button>
        <button class="btn btn-secondary" (click)="viewOrder()">View Order</button>
    </div>
  </div>
  
  