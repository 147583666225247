import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { Clipboard } from '@angular/cdk/clipboard';


declare var $: any;
@Component({
  selector: 'app-product-pages',
  templateUrl: './product-pages.component.html',
  styleUrls: ['./product-pages.component.css']
})
export class ProductPagesComponent implements OnInit {
  iscomboavailable = false;
  ishalalfood = false;
  isSale: boolean | undefined;
  isSubscribe: boolean | undefined;
  mrpPrice: number | undefined;
  priceToShow!: number;
  variationDisplay: string | undefined;
  selectedVariation: any;
  cart_Id: any

  CategoryId: any[] = [];
  productId: any;
  productDetails: any = {};
  productComboDetails: any = {};
  comboProducts: any = {}
  isComboOfferProduct = false;
  ComboOfferList: any = {};
  productVariations: any[] = [];
  flavorList: string[] = [];
  selectedFlavor: string = '';
  category: string | undefined;
  variations: any[] = [];
  dataFromPreviousComponent: string | undefined;
  mainImage: string = '';
  selectedIndex: number = 0;
  selectedSize = '50 ML';
  isExpanded = false;
  isDeliverySchedule: boolean = false;
  productImages: { src: string[] } = { src: [] };
  sanitizedDescription: SafeHtml = '';
  sanitizedTitleAndDescription: SafeHtml = '';
  sanitizedTitleAndBrand: SafeHtml = '';
  activeTab = 'description';
  product_list_Id: any;
  additionaldetails: any;
  isLoggedIn: boolean = false;
  showMore: boolean = false;
  FoodDeliverySlot: boolean = false;
  responsiveOptions: any | undefined;
  numVisible: number = 1;
  variationid: string | null = null;
  userId: string | null = null;
  add_cart: boolean = true;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
  startIndex: number = 0;
  endIndex: number = 5;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  hostName: any = "";
  showCopiedMessage = false;
  showCopiedOptions = false;
  type: any;
  isMobileView = false;
  downArrow: boolean = false;
  upArrow: boolean = false;
  rightArrow: boolean = false;
  productListCount: any;
  isLoadingRecentlyViewed: boolean = false;
  loadMoreRecentlyViewed: boolean = false;
  page: number = 1;
  count: number = 3;

  // numVisible: number = 3;

  // Image sources for controls
  prevImageSrc: string = '../../assets/icon/carousel-down.svg';
  nextImageSrc: string = '../../assets/icon/carousel-down.svg';


  @ViewChild('tabSection', { static: false }) tabSection!: ElementRef;
  @ViewChild('relatedelemt', { static: false }) parentElement!: ElementRef;

  @ViewChild('relatedelemt') elemt!: ElementRef;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public auth: AuthService,
    private cartService: CartService,
    private elementRef: ElementRef,
    private clipboard: Clipboard,
    private cdr: ChangeDetectorRef

  ) {
    this.hostName = window.location.host;
    const navigation = this.router.getCurrentNavigation();
    const dataToPass = navigation?.extras?.state?.['dataToPass'];

    if (dataToPass) {
      
      if (dataToPass.datafrom === 'bestSelling') {
        this.type = 'is_sale'
      }
      else if (dataToPass.datafrom === 'subscribeProduct') {
        this.type = 'is_subscribe'
      } else if (dataToPass.datafrom === 'ComboOffer') {
        this.type = 'is_combo'
      } else {
        this.type = ''
      }
      this.dataFromPreviousComponent = dataToPass.datafrom || '';
      this.variationid = dataToPass.variationId || '';
      this.category = dataToPass.name || '';

      // Store in local storage with fallback values
      localStorage.setItem('dataFromPreviousComponent', this.dataFromPreviousComponent || '');
      localStorage.setItem('variationId', this.variationid || '');

      localStorage.setItem('category', this.category || '');
    } else {
      // Retrieve from local storage or use an empty string as fallback
      this.dataFromPreviousComponent = localStorage.getItem('dataFromPreviousComponent') || '';

      this.variationid = localStorage.getItem('variationId') || '';

      this.category = localStorage.getItem('category') || '';
    }

    this.isDeliverySchedule = this.dataFromPreviousComponent === 'subscribeProduct';


    // Subscribing to auth userInfo
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {
      if (data) {
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.product_list_Id = params.get('id');
      const navigation = this.router.getCurrentNavigation();
      const dataToPass = navigation?.extras?.state?.['dataToPass'];

      if (dataToPass) {
        this.dataFromPreviousComponent = dataToPass.datafrom || '';
        this.variationid = dataToPass.variationId || '';
        this.category = dataToPass.name || '';

        // Store in local storage with fallback values
        localStorage.setItem('dataFromPreviousComponent', this.dataFromPreviousComponent || '');
        localStorage.setItem('variationId', this.variationid || '');
        localStorage.setItem('category', this.category || '');
      } else {

        // Retrieve from local storage or use an empty string as fallback
        this.dataFromPreviousComponent = localStorage.getItem('dataFromPreviousComponent') || '';
        this.variationid = localStorage.getItem('variationId') || '';

        this.category = localStorage.getItem('category') || '';
      }

      if ((this.dataFromPreviousComponent ?? '').trim() === 'ComboOffer') {
        this.productDetails = ''
        this.ComboOfferProductGet(this.product_list_Id);
      } else {
        this.ProductListCall(this.product_list_Id);
      }


    })

    this.responsiveOptions = [
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '810px',
        numVisible: 2,
        numScroll: 1
      },

    ];

    this.updateVisibleItems();
    // this.wish_list();

    this.isMobileView = window.innerWidth < 993;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItems();
    this.isMobileView = window.innerWidth < 993;

  }



  updateVisibleItems() {

    const width = window.innerWidth;
    if (width > 1199) {
      this.numVisible = 1;
    }
  }

  // @ViewChild('myCarousel', { static: false }) myCarousel!: ElementRef;

  ngAfterViewInit() {
    // Initialize the carousel using jQuery
    // $(this.myCarousel.nativeElement).carousel({
    //   interval: 3000, // Adjust the interval as needed
    //   wrap: true
    // });
  }


  prevImage(): void {

    if (this.startIndex > 0) {
      this.startIndex--;
      this.endIndex--;
    }
    // if (this.startIndex > 0) {
    //   this.startIndex -= this.numVisible;
    // }
  }

  nextImage() {
    if (this.endIndex < this.productImages.src.length) {
      this.startIndex++;
      this.endIndex++;

    }
    // if (this.startIndex + this.numVisible < this.productImages.src.length) {
    //   this.startIndex += this.numVisible;
    // }
  }

  get visibleImages() {
    return this.productImages.src.slice(this.startIndex, this.startIndex + this.numVisible);
  }

  hoverImage(control: string, isHover: boolean) {
    if (control === 'prev') {
      this.prevImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    } else if (control === 'next') {
      this.nextImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    }
  }

  getKeys(obj: any): string[] {

    return obj ? Object.keys(obj) : [];
  }

  selectImage(index: number): void {
    this.mainImage = this.productImages.src[index];
    this.selectedIndex = index;
  }

  startDragging(e: any, flag: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, type: string, child: any, spot: string) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.cursorDown.x = e.pageX;
    this.cursorDown.y = e.pageY;
    if (type === 'mouse') {
      this.startX = e.pageX - el.offsetLeft;
    } else {
      // this.startX = e.touches[0].pageX - el.offsetLeft;
      // this.startY = e.touches[0].pageY - el.offsetTop;
    }



    this.scrollLeft = el.scrollLeft;
    // setTimeout(() => { this.isDragging = true }, 300);
  }

  stopDragging(e: any, flag: any, type: string) {
    this.cursorUp.x = e.pageX;
    this.cursorUp.y = e.pageY;
    // setTimeout(() => { this.isDragging = false });
    this.mouseDown = false;
  }

  moveEvent(e: any, el: { offsetLeft: number; scrollLeft: number; offsetTop: number }, type: string, child: any) {
    // e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    let x = 0, y = 0;
    if (type === 'mouse') {
      x = e.pageX - el.offsetLeft;
    } else {
      // x = e.touches[0].pageX - el.offsetLeft;
      // y = e.touches[0].pageY - el.offsetTop;
    }
    const scrollX = x - this.startX;
    const scrollY = y - this.startY;
    if (Math.abs(scrollY) > Math.abs(scrollX)) {
      document.body.scrollTo(window.scrollX, window.scrollY - scrollY);
    } else {
      el.scrollLeft = this.scrollLeft - scrollX;
    }
  }


  onScroll(e: any, el: { offsetLeft: number; scrollLeft: any; clientWidth: any, offsetTop: number }, child: any) {
    if ((child.clientWidth - el.scrollLeft) <= el.clientWidth + 1) {
      // this.loadmore();

      // if (this.showMoreReview && !this.reviewLoading) {
      //   this.reviewLoading = true;
      //   this.loadmore();
      // }
    }
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
    this.setActiveTab('description');
    const tabSectionPosition = this.tabSection.nativeElement.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: tabSectionPosition - 200, // Adjust this value (-100) to control how far up you want to scroll
      behavior: 'smooth'
    });
  }


  addToCart(): void {
    // alert(Added ${this.selectedSize} of ${this.productDetails.name} to the cart!);
  }


  selectSize(variation: any): void {
    if (!variation) {
      console.error("Invalid variation passed to selectSize:", variation);
      return;
    }

    const product = this.productDetails;

    // Ensure product exists and has variations
    if (product && product.productvariations && Array.isArray(product.productvariations)) {
      this.selectedVariation = variation;
      // Set selected variation ID
      product.selectedVariation = variation.productvariationid;

      // Set price and MRP to display
      this.priceToShow = this.getPriceToShow(variation);
      this.mrpPrice = variation.mrpprice ?? 0;

      // Update cart items quantity for the selected variation
      if (variation.cartitemquantity != null) {
        product.cartitemquantity = variation.cartitemquantity;
      } else {
        // Fallback to find the selected variation from the product variations
        const selectedVariation = product.productvariations.find(
          (v: any) => v.productvariationid === variation.productvariationid
        );
        product.cartitemquantity = selectedVariation?.cartitemquantity ?? 0;
        this.selectedFlavor=selectedVariation?.cartitemflavor||''
        
      }

      // Update combo availability
      this.iscomboavailable = variation.iscomboavailable === true;
      // if (this.selectedVariation.variationid && this.selectedVariation.iscomboavailable === true) {
      //   this.iscomboavailable = true;
      // }
      // else {
      //   this.iscomboavailable = false;

      // }
      // Update delivery slot logic based on the variation name
      this.FoodDeliverySlot = variation.name === 'Food';
      // Update wishlist icon status
      product.wishlistitemstatus =
        variation?.wishlistitemstatus ??
        false; // Default to false if null or undefined

      product.wishlistIcon =
        variation?.wishlistitemstatus === 1 ||
        variation?.wishlistIcon === true; // Use wishlistIcon directly if available

      
    } else {
      console.error("Invalid productDetails or missing productvariations:", this.productDetails);
    }
  }
 

  getPriceToShow(variation: any): number {
    if (variation.issubscribe && variation.issale) {
      if (variation.dataFromPreviousComponent === 'bestSelling') {
        this.FoodDeliverySlot = true
        this.priceToShow = variation.sellingprice; // Set priceToShow when dataFromPreviousComponent is 'bestSelling'
        if (variation.name === 'Food') {
          this.FoodDeliverySlot = true;
        } else if (variation.name === 'Grocery') {
          this.FoodDeliverySlot = false;
        }

      } else if (variation.dataFromPreviousComponent === 'subscribeProduct') {
        this.priceToShow = variation.priceupto7days; // Set priceToShow when dataFromPreviousComponent is 'subscribe'
      } else {
        this.priceToShow = variation.priceupto7days; // Default to priceupto7days if both issubscribe and issale are true
      }
    } else if (variation.issubscribe) {
      this.priceToShow = variation.priceupto7days; // Set priceToShow if only issubscribe is true
    } else if (variation.issale) {
      this.priceToShow = variation.sellingprice; // Set priceToShow if only issale is true
    } else {
      this.priceToShow = variation.mrpprice; // Default to mrpPrice if neither issubscribe nor issale is true
    }
    return this.priceToShow; // Return the updated priceToShow
  }


  ProductListCall(subcategoryId?: string): void {
    const id = subcategoryId || '';
    const userIdParam = this.userId ? `&userId=${this.userId}` : '';
    let type = this.type
    this.http.get(`${environment.apiUrl}/products/user-view-get?id=${id}${userIdParam}&type=${type}`).subscribe({
      next: (data: any) => {

        if (data.status === 'SUCCESS') {

          const CategoryIds = data.products.categoryid || [];
          this.isComboOfferProduct = false;
          let productId = data.products.id;
          this.productId
          if (productId) {
            this.productId = productId;
          }
          if (CategoryIds) {
            this.CategoryId = [CategoryIds];
          }
          this.flavorList = data.products.flavor || null;

          if (data.products && data.products.flavor && data.products.flavor.length > 0) {
            // this.selectedFlavor = data.products.flavor;
            this.FoodDeliverySlot = true;
          }
          const element = this.elementRef.nativeElement.querySelector('.scroll_top');
          if (element) {
            window.scroll(0, 0);
          }
          this.productDetails = data.products;




          if (data.products.ishalalfood === true) {
            this.ishalalfood = true;
          }
          else {
            this.ishalalfood = false;
          }
          this.productDetails.variations = [];

          let matchingVariation;

          if (this.variationid) {
            // If variationid is not empty, find the matching variation
            matchingVariation = this.productDetails.productvariations.find(
              (variation: any) => variation.productvariationid === this.variationid
            );
          }

          if (!matchingVariation) {
            // If no matching variation is found, default to the first variation
            matchingVariation = this.productDetails.productvariations[0];
            this.variationid = matchingVariation.productvariationid
          }




          if (matchingVariation) {
            this.productDetails.cartitemquantity = matchingVariation.cartitemquantity;
            this.productDetails.cartitemcartid = matchingVariation.cartitemcartid;
            this.productDetails.selectedVariation = matchingVariation.productvariationid;
            this.productDetails.wishlistIcon = matchingVariation.wishlistitemstatus;
            this.productDetails.cartitemFlavor = matchingVariation.cartitemflavor;
            this.selectedFlavor = this.productDetails.cartitemFlavor || "";
          }
          
          this.cart_Id = this.productDetails.cartitemcartid
          if (this.productDetails.productvariations && this.productDetails.productvariations.length > 0) {

            // this.productDetails.productvariations.sort((a: any, b: any) => a.sellingprice - b.sellingprice);
            const uniqueVariations = this.productDetails.productvariations.filter(
              (variation: any, index: number, self: any[]) =>
                index === self.findIndex(v => v.productvariationid === variation.productvariationid)
            );
            uniqueVariations.sort((a: any, b: any) => a.sellingprice - b.sellingprice);
            uniqueVariations.forEach((x: any) => {

              if (x.productvariationid && x.iscomboavailable === true) {
                this.iscomboavailable = true;
              }
              else {
                this.iscomboavailable = false;

              }
              if (this.dataFromPreviousComponent === 'subscribeProduct' && x.issubscribe === true) {
                // Filter for subscribeProduct
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice, sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale: x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  name: this.category,
                  iscomboavailable: x.iscomboavailable,
                  wishlistitemstatus: x.wishlistitemstatus,
                  wishlistIcon: this.productDetails.wishlistitemstatus
                });
                this.productDetails.variations.sort((a: any, b: any) => {
                  if (a.variationvalue < b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return -1;
                  }
                  if (a.variationvalue > b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return 1;
                  }
                  return a.sellingprice - b.sellingprice;
                  // return 0;
                });
              } else if (this.dataFromPreviousComponent === 'bestSelling' && x.issale === true) {
                // Filter for bestSelling
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice,
                  sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale: x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  name: this.category,
                  iscomboavailable: x.iscomboavailable,
                  wishlistitemstatus: x.wishlistitemstatus,
                  wishlistIcon: this.productDetails.wishlistitemstatus

                });
                // Sort variations by variationvalue in ascending order
                this.productDetails.variations.sort((a: any, b: any) => {
                  if (a.variationvalue < b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return -1;
                  }
                  if (a.variationvalue > b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return 1;
                  }
                  return a.sellingprice - b.sellingprice;
                  // return 0;
                });

              } else if (this.dataFromPreviousComponent === '' && x.issale === true) {
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice,
                  sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale: x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  name: this.category,
                  iscomboavailable: x.iscomboavailable,
                  wishlistitemstatus: x.wishlistitemstatus,
                  wishlistIcon: this.productDetails.wishlistitemstatus

                });
                // Sort variations by variationvalue in ascending order
                this.productDetails.variations.sort((a: any, b: any) => {
                  if (a.variationvalue < b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return -1;
                  }
                  if (a.variationvalue > b.variationvalue) {
                    return a.sellingprice - b.sellingprice;
                    // return 1;
                  }
                  return a.sellingprice - b.sellingprice;
                  // return 0;
                });

              }
            })

            if (this.variationid) {
              const mathProductvariationId = this.productDetails.variations.find(
                (variation: any) => variation.productvariationid === this.variationid
              );
              this.selectedVariation = mathProductvariationId;


            } else if (this.productDetails.variations.length > 0) {
              this.selectedVariation = this.productDetails.variations[0]; // Set the first variation as default


            }
            else {
              this.selectedVariation = this.productDetails.productvariations[0];

            }
            // this.productDetails.wishlistIcon= this.productDetails.wishlistitemstatus

            // Set the default variation (first one)
            // this.selectedVariation = this.productDetails.productvariations[0];

            // Calculate the default price to show based on the first variation
            this.getPriceToShow(this.selectedVariation);
            this.mrpPrice = this.selectedVariation.mrpprice;
          }

          // Other code for description and image handling
          if (this.productDetails.description) {
            this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.productDetails.description);
          } else {
            this.sanitizedDescription = '';
          }

          let x = data.products.additionaldetails;

          try {
            if (x && x.trim() !== '') {
              this.additionaldetails = JSON.parse(x);
            } else {
              this.additionaldetails = {}; // or any default value
            }
          } catch (e) {
            console.error("Error parsing additionaldetails:", e);
            this.additionaldetails = {}; // Handle the error by assigning a default value
          }
          // image uploads
          if (this.productDetails.imageinfo) {
            try {
              const imageInfoArray = JSON.parse(this.productDetails.imageinfo); // Parse the `imageinfo` JSON string
              this.productImages.src = []; // Initialize the image array

              imageInfoArray.forEach((image: any, index: number) => {
                if (image.imageid) {
                  // Fetch the image using the image ID
                  this.http.get(`${environment.apiUrl}/uploads/get?id=${image.imageid}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      this.productImages.src.push(res.uploads.base64data);

                      // Set the first image as the default main image
                      if (index === 0) {
                        this.mainImage = this.productImages.src[0];
                        this.selectedIndex = 0;
                      }
                    }
                  });
                }
              });
            } catch (err) {
              console.error("Error parsing imageinfo JSON:", err);
            }
          } else {
            console.warn("No imageinfo field found in productDetails.");
          }

          // if (this.userId) {
          //   this.wish_list();

          // }
          this.combo_offer(productId, this.variationid);
        }
      },
      error: (error) => {
        console.error("API Error:", error);
      }
    });
  }
  ComboOfferProductGet(comboId?: string): void {
    if (!comboId) {
      console.error("Combo ID is required");
      return;
    }
    let url = `${environment.apiUrl}/combos/product-get?id=${comboId}`;
    if (this.userId) {
      url += `&userId=${this.userId}`;
    }

    this.http.get(url).subscribe({
      next: (data: any) => {

        if (data.status === 'SUCCESS') {
          this.productComboDetails = data.combos;
          this.cart_Id = this.productComboDetails.cartitemcartid
          this.productComboDetails.cartitemquantity =
          this.productComboDetails.cartitemquantity ?? 0;
          this.isComboOfferProduct = true;
          this.isDeliverySchedule=false
          this.comboProducts = this.productComboDetails?.comboproducts?.map((product: any) => ({
            ...product,
            availableFlavors: product.productflavor || [], // Add flavors here
            selectedFlavor: product.cartitemflavor || "" 
          })) || [];

          this.priceToShow = this.productComboDetails?.total_price || 0;
          const productIds = this.comboProducts
            .map((product: any) => product.productid)
            .filter((id: string) => id); // Remove null or undefined
            // this.selectedFlavor=product.productflavor

          const productVariationIds = this.comboProducts
            .map((product: any) => product.productvariationid)
            .filter((id: string) => id); // Remove null or undefined

          this.combo_offer(productIds, productVariationIds);

          // Combine product descriptions
          const combinedDescriptions = this.comboProducts
            ?.map((product: { productdescription: any; }) => product.productdescription)
            .join('<br><br>') || '';

          // titile and descriptions
          const combinedTitleAndDescriptions = this.comboProducts
            ?.map((product: { producttitle: any; productdescription: any; }) =>
              `<span style="font-weight: bold; color: black; font-size: 20px;">Product: ${product.producttitle}</span><br> ${product.productdescription}`
            )
            .join('<br><br>') || '';

          this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(combinedDescriptions);
          this.sanitizedTitleAndDescription = this.sanitizer.bypassSecurityTrustHtml(combinedTitleAndDescriptions);
          // product brand details
          const combinedTitleAndDetails = this.comboProducts
            .map((product: any) => {
              const productTitle = product?.producttitle || "No Title";
              let productDetails = "";

              // Parse additional details if present and valid
              if (product?.productadditionaldetails && product.productadditionaldetails.trim() !== "") {
                try {
                  const additionalDetails = JSON.parse(product.productadditionaldetails);
                  productDetails = Object.entries(additionalDetails)
                    .map(
                      ([key, value]) => `
                      <div class="row">
                        <div class="col-6" style="font-weight: bold; color: black;">${key}</div>
                        <div class="col-6" style="color: gray;">${value}</div>
                      </div>
                    `
                    )
                    .join("");

                  // Return the title and details only if valid details are present
                  return `
                  <div style="margin-bottom: 20px;">
                    <span style="font-weight: bold; color: black; font-size: 20px;">Product: ${productTitle}</span><br>
                    ${productDetails}
                  </div>
                `;
                } catch (e) {
                  console.error(`Invalid JSON in productadditionaldetails for ${productTitle}`, e);
                }
              }

              // Return an empty string if no valid details
              return "";
            })
            .filter(Boolean) // Remove empty strings (products with no details)
            .join(""); // Combine all products into one string

          // Sanitize the combined HTML for display
          this.sanitizedTitleAndBrand = this.sanitizer.bypassSecurityTrustHtml(combinedTitleAndDetails);

          // upload images
          if (this.productComboDetails.imageinfo) {
            try {
              const imageInfoArray = JSON.parse(this.productComboDetails.imageinfo); // Parse the `imageinfo` JSON string
              this.productImages.src = []; // Initialize the image array
          
              imageInfoArray.forEach((image: any, index: number) => {
                if (image.imageid) {
                  // Fetch the image using the image ID
                  this.http.get(`${environment.apiUrl}/uploads/get?id=${image.imageid}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      this.productImages.src.push(res.uploads.base64data);
          
                      // Set the first visible image as the default main image
                      if (index === 0 || image.visible === "true") {
                        this.mainImage = this.productImages.src[0];
                        this.selectedIndex = 0;
                      }
                    }
                  });
                }
              });
            } catch (err) {
              console.error("Error parsing imageinfo JSON:", err);
            }
          } else {
            console.warn("No imageinfo field found in productComboDetails.");
          }
          
        }
      },
      error: (error) => {
        console.error("API Error:", error);
      }
    });
  }
  // update Flavor
  onFlavorChange(event: Event, productDetails: any): void {
    let selectedFlavor = (event.target as HTMLSelectElement).value;

    this.selectedFlavor = selectedFlavor;

    const matchingVariation = productDetails.productvariations.find(
      (variation: any) =>
        variation.productvariationid === this.selectedVariation.productvariationid
    );

    if (matchingVariation && matchingVariation.cartitemcartid && matchingVariation.cartitemid !== null) {

      this.updateFlavor({
        cartItemId: matchingVariation.cartitemid,
        cartId: matchingVariation.cartitemcartid,
        flavor: selectedFlavor
      });
    } else {

    }
  }
  onComboFlavorChange(event: Event, product: any) {
    const selectedFlavor = (event.target as HTMLSelectElement).value;


    // Create an object with comboproductid and selected flavor
    const updatedProduct = {
      comboId: this.productComboDetails.id,
      flavor: selectedFlavor,
      productId: product?.productid,
      cartId: this.productComboDetails.cartitemcartid
    };

    if (updatedProduct.comboId) {
      this.updateFlavor(updatedProduct)
      // Add your logic for flavor update here, such as API calls or other operations
    } else {
    }
  }



  updateFlavor(product: any): void {

    const requestBody: any = {
      updatedBy: this.userId,
      cartId: product.cartId,
      flavor: product.flavor
    };
    // Include optional fields if present
    if (product.cartItemId) {
      requestBody.cartitemId = product.cartItemId;
    }
    if (product.comboId) {
      requestBody.comboId = product.comboId;
    }
    if (product.productId) {
      requestBody.productId = product.productId;
    }


    this.http.post(environment.apiUrl + `/cart/update-flavor`, requestBody, { responseType: 'json' }).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
        } else {
          console.warn('Flavor update returned with a non-success status:', data);
        }
      },
      error: (error: any) => {
        console.error('An error occurred while updating the flavor:', error);
      }
    });
  }


  // combo list cart
  addComboToCart(combo: any, quantity: number) {
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
      return;
    }

    if (!combo || !combo.comboproducts || combo.comboproducts.length === 0) {
      console.error("Error: Combo or combo products are not defined or empty.");
      return;
    }

    // Prepare the combo products payload
    const comboProductsPayload = combo.comboproducts.map((product: any) => ({
      productid: product.productid,
      productvariationid: product.productvariationid || null,
    }));

    const requestBody = {
      type: 'is_combo',
      createdBy: this.userId,
      userId: this.userId,
      comboId: combo.id,
      comboProducts: comboProductsPayload,
      quantity: quantity,
    };

    // API call
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          // Update cart quantity and ID
          combo.cartitemquantity = data.cartitems?.quantity || quantity;
          combo.comboid = data.cartitems?.comboid || null;
          combo.cartid = data.cartitems?.cartid || null;  // Ensure cartid is correctly set
          this.cartService.updateCartCount(data.newCartCount);
          this.cdr.detectChanges();
        } else {
          console.error("Error adding combo to cart:", data.message);
        }
      },
      error: (error: any) => {
        console.error("Error in add-to-cart API call:", error);
      }
    });
  }

  // Increasing combo quantity
  increaseComboQuantity(combo: any) {
    let cartId = combo.cartid || combo.cartitemcartid;
    let comboId = combo.comboid || combo.id;

    if (!cartId || !comboId) {
      console.error("Error: Cart ID or Combo ID is missing.");
      return;
    }
    if (!comboId) {
      this.addComboToCart(combo, (combo.cartitemquantity || 0) + 1);
    } else {
      combo.cartitemquantity = (combo.cartitemquantity || 0) + 1;
      this.addComboToCart(combo, combo.cartitemquantity);
    }
  }

  // Decreasing combo quantity or removing from cart
  decreaseComboQuantity(combo: any) {
    let cartId = combo.cartid || combo.cartitemcartid;
    let comboId = combo.comboid || combo.id;
    if (!cartId || !comboId) {
      console.error(combo, "Error: Cart ID or Combo ID is missing.", `${combo.comboid} ${combo.cartid}`);
      return;
    }

    if (combo.cartitemquantity > 1) {
      combo.cartitemquantity -= 1;
      this.addComboToCart(combo, combo.cartitemquantity);
    } else if (combo.cartitemquantity === 1) {
      this.deleteComboFromCart(combo);
    }
  }


  // Deleting combo from cart
  deleteComboFromCart(combo: any): void {
    let cartId = combo.cartid || combo.cartitemcartid;
    let comboId = combo.comboid || combo.id;
    if (!cartId || !comboId) {
      console.error(combo, "Error: Cart ID or Combo ID is missing.", `${combo.comboid} ${combo.cartid}`);
      return;
    }
    const requestBody = {
      updatedBy: this.userId,
      comboIds: [comboId],
      cartId: cartId
    };

    this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          // Reset cart quantity and ID
          combo.cartitemquantity = null;
          comboId = null;

          this.cartService.updateCartCount(data.newCartCount);
          this.cdr.detectChanges();
        } else {
          console.error("Error removing combo from cart:", data.message);
        }
      },
      error: (error: any) => {
        console.error("Error in delete API call:", error);
      }
    });
  }


  // product get cart
  Add_to_cart(productId: string | number, productVariationId: string, quantity: number, type: string) {

    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
      return;
    }

    let requestBody;

    if (type === 'is_combo') {
      if (!this.comboProducts) {
        console.error("Error: comboProducts is undefined");
        return;
      }
      const comboProducts = this.comboProducts.map((product: any) => ({
        productid: product.productid,
        productvariationid: product.productvariationid,
        flavor: product.selectedFlavor || null,
      }));
      requestBody = {
        type: 'is_combo',
        createdBy: this.userId,
        userId: this.userId,
        comboId: productId,
        comboProducts: comboProducts,
        quantity: quantity,
      };
    } else if (type === 'is_sale') {
      requestBody = {
        type: 'is_sale',
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId,
        quantity: quantity,
        flavor: this.selectedFlavor,
      };
    }

    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          const product = this.productDetails.productvariations?.find(
            (variation: any) => variation.productvariationid === productVariationId
          );
          this.productComboDetails.cartitemquantity = data.cartitems?.quantity || quantity;
          this.productComboDetails.cartitemcartid = data.cartitems?.id || null;
          this.cart_Id = data.cart?.id || null;

          this.cartService.updateCartCount(data.newCartCount);
          this.cdr.detectChanges();
          if (product) {
            product.cartitemquantity = quantity;
            product.cartitemid = data.cartitems?.id;
            this.productDetails.cartitemquantity = quantity;
            this.productDetails.cartitemid = data.cartitems?.id;
            this.cart_Id = data.cart?.id

            this.cartService.updateCartCount(data.newCartCount);
            this.cdr.detectChanges();
            if (data.cartitems && data.cartitems.flavor) {
              this.selectedFlavor = data.cartitems.flavor;
            }
          } else {
            console.error(`ProductVariation ${productVariationId} not found`);
            // Handle undefined productVariation
          }

          if (type === 'is_combo') {
            const combo = this.comboProducts.find(
              (c: any) => c.productvariationid === productVariationId
            );
            if (combo) {
              combo.cartitemquantity = data.cartitems.quantity;
              combo.cartitemid = data.cartitems?.id;
              this.cartService.updateCartCount(data.newCartCount);
              this.cdr.detectChanges();
              if (data.cartitems && data.cartitems.flavor) {
                this.selectedFlavor = data.cartitems.flavor;
              }
            }
          }
        }
      },
      error: (error: any) => {
        console.error("Error in add-to-cart API call:", error);
      }
    });
  }

  increaseQuantity(productId: number, productVariationId: string) {


    if (this.productComboDetails && this.productComboDetails.id === productId) {
      const combo = this.productComboDetails;
      const transformedComboProducts = combo.comboproducts.map((item: any) => ({
        productid: item.productid,
        productvariationid: item.productvariationid,
      }));

      if (combo) {
        combo.cartitemquantity = (combo.cartitemquantity || 0) + 1;
        this.Add_to_cart(productId, transformedComboProducts, combo.cartitemquantity, 'is_combo');
        this.cdr.detectChanges();
      }
    } else if (this.type === 'is_sale' && this.productDetails) {
      const product = this.productDetails.productvariations.find(
        (variation: any) => variation.productvariationid === productVariationId
      );
      if (product) {
        product.cartitemquantity = (product.cartitemquantity || 0) + 1;
        this.Add_to_cart(productId, productVariationId, product.cartitemquantity, 'is_sale');
        this.productDetails.cartitemquantity = product.cartitemquantity;
        this.cdr.detectChanges();
      }
    }
  }


  decreaseQuantity(productId: number, productVariationId: string) {


    if (this.productComboDetails && this.productComboDetails.id === productId) {
      const combo = this.productComboDetails;
      const transformedComboProducts = combo.comboproducts.map((item: any) => ({
        productid: item.productid,
        productvariationid: item.productvariationid,
      }));
      if (combo.cartitemquantity > 1) {

        combo.cartitemquantity -= 1;
        this.Add_to_cart(productId, transformedComboProducts, combo.cartitemquantity, 'is_combo');
      } else {

        combo.cartitemquantity = 0;
        this.deleteCartItem(combo); // Call delete function for combo
      }
      this.cdr.detectChanges();
    } else if (this.type === 'is_sale' && this.productDetails) {

      const product = this.productDetails.productvariations.find(
        (variation: any) => variation.productvariationid === productVariationId
      );
      if (product) {
        if (product.cartitemquantity > 1) {
          product.cartitemquantity -= 1;
          this.Add_to_cart(productId, productVariationId, product.cartitemquantity, 'is_sale');
        } else {
          product.cartitemquantity = 0;
          this.deleteCartItem(product); // Call delete function for individual product
        }
        this.cdr.detectChanges();
      }
    }

  }
  deleteCartItem(product: any): void {

    const requestBody: any = {
      updatedBy: this.userId,
      cartId: this.cart_Id,
    };

    // Add `cartitemIds` only if `product.cartitemid` is not null
    if (product.cartitemid !== null && product.cartitemid !== undefined) {
      requestBody.cartitemIds = [product.cartitemid];
    }

    // Add `comboId` only if `product.id` is not null
    if (product.id !== null && product.id !== undefined) {
      requestBody.comboIds = [product.id];
    }




    this.http.post(`${environment.apiUrl}/cart/delete`, requestBody, { responseType: 'json' }).subscribe({
      next: (data: any) => {
        if (data.status === 'SUCCESS') {
          this.cartService.updateCartCount(data.newCartCount);

          if (this.type === 'is_combo') {
            const combo = this.productComboDetails.find((item: any) => item.id === product.comboid);

            if (combo) {
              combo.cartitemquantity = 0;
              combo.cartitemid = null;
            }
          } else if (this.type === 'is_sale') {
            this.productDetails.cartitemquantity = 0;
          }

          this.cdr.detectChanges();
        }
      },
      error: (error: any) => {
        console.error('An error occurred while deleting the cart item:', error);
      }
    });
  }
  selectedSchedule: string = '';
  // startDate: string = '';
  // endDate: string = '';
  startDate: string | null = null;
  endDate: string | null = null;
  minEndDate: string | null = null;
  currentDate: string | undefined;
  selectedDates: Date[] = [];
  SubcribeClick(product_id: any, selectedVariationId: any) {
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const variationId = selectedVariationId;

    const subcribe = "SubcribeProduct"
    this.router.navigate(['/add-cart'], {
      state: {
        dataToPass: {
          datafrom: subcribe,
          product_id: product_id,
          variation_id: variationId,
          schedule: this.selectedSchedule,
          startDate: this.startDate,
          endDate: this.endDate
        }
      }
    });

  }
  onDateChange() {
    this.selectedDates = [];
    if (this.startDate && this.endDate) {
      let start = new Date(this.startDate);
      let end = new Date(this.endDate);

      let current = new Date(start);
      while (current.getTime() <= end.getTime()) {
        this.selectedDates.push(new Date(current));
        current.setDate(current.getDate() + 1);
      }
    }
  }

dateClass = (d: Date) => {
  if (!this.startDate) return '';

  const start = new Date(this.startDate); // Convert startDate to Date object
  const dateString = d.toISOString().split('T')[0];
  const startString = start.toISOString().split('T')[0];

  // If endDate exists, convert it to Date object
  const end = this.endDate ? new Date(this.endDate) : null;
  const endString = end ? end.toISOString().split('T')[0] : null;

  if (dateString === startString || dateString === endString) {
    return 'highlight-start-end'; // Start & End Date color
  } else if (this.endDate && d > start && d < end!) { 
    return 'highlight-between'; // Dates in between
  }
  return '';
};
  toggleWishList(event: Event, item: any): void {
    event.stopPropagation();

    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
      return;
    }

    const requestBody = {
      type: 'is_combo',
      status: item.wishlistitemstatus === null ? 1 : -1,
      createdBy: this.userId,
      userId: this.userId,
      comboId: item.id,
      comboProducts: item.comboproducts.map((product: any) => ({
        productid: product.productid,
        productvariationid: product.productvariationid,
      })),
    }


    this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
      .subscribe({
        next: (data: any) => {
          if (data.status === 'SUCCESS') {
            if (item.type = 'is_combo') {
              item.wishlistitemstatus = item.wishlistitemstatus === null ? 1 : null;
            } else {
              item.wishlistIcon = !item.wishlistIcon;
            }
          }
        },
        error: (error) => {
          console.error('API Error:', error);
        }
      });
  }

  // product get wishlist
  toggleWishListOrCombo(event: Event, item: any): void {
    event.stopPropagation();

    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
      return;
    }
    const requestBody = item.comboproducts ? {
      type: 'is_combo',
      status: item.wishlistitemstatus === null ? 1 : -1,
      createdBy: this.userId,
      userId: this.userId,
      comboId: item.id,
      comboProducts: item.comboproducts.map((product: any) => ({
        productid: product.productid,
        productvariationid: product.productvariationid,
      })),
    }
      : {
        status: !item.wishlistIcon ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: item.id,
        productVariationId: item.selectedVariation,
        type: this.type,
      };

    this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
      .subscribe({
        next: (data: any) => {
          if (data.status === 'SUCCESS') {
            if (item?.comboproducts) {
              item.wishlistitemstatus = item.wishlistitemstatus === null ? 1 : null;
            } else {
              item.wishlistIcon = !item.wishlistIcon;
            }
          }
        },
        error: (error) => {
          console.error('API Error:', error);
        }
      });
  }


  copyTextToClipboard(params: any,) {
    this.clipboard.copy(params);

    this.showCopiedMessage = true;
    //  this.showCopiedOptions = true;
    // Hide the message after 3 seconds
    setTimeout(() => {
      this.showCopiedMessage = false;
      // this.showCopiedOptions = false;
    }, 3000);

  }

  combo_offer(productId: any, variationId: any): void {
    const requestBody: any = {
      productIds: productId,
      productVariationIds: variationId
    };

    if (this.userId) {
      requestBody.userId = this.userId;
    }

    this.http.post(environment.apiUrl + `/combos/list`, requestBody, { responseType: 'json' })
      .subscribe({
        next: (data: any) => {
          if (data.status === 'SUCCESS') {
            this.rightArrow = true;
            this.ComboOfferList = data.combos_output;
            // Loop through each combo to fetch images
            this.ComboOfferList.forEach((combo: any) => {
              const imageInfoArray = JSON.parse(combo.imageinfo); // Parse imageinfo JSON string
              combo.images = []; // Initialize an empty array to store image data

              // Loop through parsed image info array and fetch each image
              imageInfoArray.forEach((imageInfo: any) => {
                this.http.get(`${environment.apiUrl}/uploads/get?id=${imageInfo.imageid}`).subscribe(
                  (res: any) => {
                    if (res.status === "SUCCESS") {
                      combo.images.push(res.uploads.base64data);

                      if (combo.images.length === 1) { // Set the first image as the main image
                        combo.mainImage = combo.images[0];
                      }
                    }
                  },
                  (error) => console.error("Image Fetch Error:", error)
                );
              });

            });
            this.productListCount = data.count
          }
        },
        error: (error) => {
          console.error("Combo Offer API Error:", error);
        }
      });
  }
  comboProductListClick(comboId?: string) {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    })

    this.router.navigate(['/product-pages', comboId], {
      state: { dataToPass: { datafrom: "ComboOffer", comboId: comboId, } }
    });
  }
 
  onLeftScrollOrClick(): void {
    this.downArrow = false;
    this.rightArrow = true;

    const leftBtn = document.querySelector('#left-relatedProduct') as HTMLElement;
    const content = document.querySelector('#relatedProduct-list') as HTMLElement;

    const scrollLeft = (event: Event) => {
      const TOLERANCE = content.offsetLeft;
      const scrollLeftMax = content.scrollWidth - content.clientWidth;
      // Check if we are at the start of the scrollable content
      if (content.scrollLeft <= 0) {
        this.downArrow = false; // No more space to scroll left
        this.rightArrow = true;
        this.upArrow = true;
        this.upArrow = false;
      } else {
        this.upArrow = content.scrollLeft > 370 - TOLERANCE;
      }

      content.scrollBy({
        left: -370,
        behavior: 'smooth'
      });

      event.preventDefault();
    };

    leftBtn.removeEventListener("click", scrollLeft);
    leftBtn.addEventListener("click", scrollLeft);
    scrollLeft(new MouseEvent('click'));
  }

  onRightScrollOrClick(): void {
    this.rightArrow = true;
    this.upArrow = true;
    const rightBtn = document.querySelector('#right-relatedProduct') as HTMLElement;
    const content = document.querySelector('#relatedProduct-list') as HTMLElement;

    const scrollRight = (event: Event) => {
      const maxScrollLeft = content.scrollWidth - content.clientWidth;
      setTimeout(() => {
        if (this.productListCount === this.ComboOfferList.length) {
          this.downArrow = false;
        }
      }, 0);
      // Check if we can scroll further
      if (content.scrollLeft + 380 >= maxScrollLeft) {
        this.rightArrow = false; // No more space to scroll
        if (this.ComboOfferList.length > 0) {
          this.downArrow = true;  // Enable down arrow
        }

        else {
          this.downArrow = false;
          // Enable down arrow
        }
      }

      content.scrollBy({
        left: +380,
        behavior: 'smooth'
      });
      event.preventDefault();
    };

    rightBtn.removeEventListener("click", scrollRight);
    rightBtn.addEventListener("click", scrollRight);
    scrollRight(new MouseEvent('click'));
  }


  onStartDateChange() {
    if (this.startDate) {
      // Set the minimum end date to the selected start date
      this.minEndDate = this.startDate;
      // Clear the end date if it is before the new start date
      if (this.endDate && this.endDate < this.startDate) {
        this.endDate = null;
      }
    }
  }


}

