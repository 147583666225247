
<p-toast></p-toast>
<app-header *ngIf="showHeaderFooter"></app-header>
<router-outlet></router-outlet>
<!-- <app-footer *ngIf="showHeaderFooter"></app-footer> -->
<app-footer  *ngIf="showFooter"></app-footer>
 
<!-- <div *ngIf="!popupClosed" class="overlay"></div> -->


