<div class="container-fluid" style="height: 100%; width: 100%;">
    <div class="row justify-content-center align-items-center" style="height: 100%;">
        <div class="col-lg-6 col-md-12 mb-4" id="border">
            <div class="text-center mb-5 mt-1">
                <img src="/assets/gpstore-logo.svg" class="w-30" alt="logo">
            </div>
            <div class="container registerSection">
                <!-- New Registration Section -->
                <div class="m-4 newRegister" *ngIf="currentSection === 'newRegister'">
                    <div class="m-4">
                        <h5 class="text-start mb-4">New user registration</h5>
                        <form class="m-auto " [formGroup]="SignInForm" (ngSubmit)="onSubmit()">
                            <div class="mb-3 row">
                                <div class="col-12 col-md-6 col-lg-6">
                                    <label for="fname" class="form-label fnameLabel">First name</label>
                                    <input type="text" class="form-control" id="inputfname"
                                        aria-describedby="fnameinput" formControlName="firstName"
                                        placeholder="Enter your first name">
                                    <div *ngIf="SignInForm.controls['firstName'].invalid && (SignInForm.controls['firstName'].dirty || SignInForm.controls['firstName'].touched)"
                                        class="text-danger">
                                        First name is required.
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <label for="lname" class="form-label lnameLabel">Last name</label>
                                    <input type="text" class="form-control" id="inputlname"
                                        aria-describedby="lnameinput" formControlName="lastName"
                                        placeholder="Enter your last name">
                                    <div *ngIf="SignInForm.controls['lastName'].invalid && (SignInForm.controls['lastName'].dirty || SignInForm.controls['lastName'].touched)"
                                        class="text-danger">
                                        Last name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 row position-relative">
                                <div class="col-12 col-md-6 col-lg-12">
                                    <label for="inputPassword" class="form-label passwordLabel">Password</label>
                                    <input [type]="passwordVisible ? 'text' : 'password'" class="form-control" maxlength="8"
                                        id="inputPassword" formControlName="password" placeholder="Enter your Password">
                                    <mat-icon class="pwd-eye" (click)="togglePasswordVisibility()">
                                        {{ passwordVisible ? 'visibility' : 'visibility_off' }}
                                    </mat-icon>
                                </div>
                            </div>
                            <div *ngIf="SignInForm.controls['password'].invalid && (SignInForm.controls['password'].dirty || SignInForm.controls['password'].touched)"
                                class="text-danger">
                                Password is required and must be at least 8 characters.
                            </div>
                            <div class="mb-3 row position-relative">
                                <div class="col-12 ">
                                    <label for="inputConfirmPassword" class="form-label confirmPasswordLabel">Confirm
                                        password</label>
                                    <input [type]="confirmPasswordVisible  ? 'text' : 'password'" class="form-control"
                                        id="inputConfirmPassword" formControlName="confirmPassword"
                                       maxlength="8" placeholder="Confirm your password">
                                    
                                    <mat-icon class="cmdpwd-eye" (click)="toggleConfirmPasswordVisibility()">
                                        {{ confirmPasswordVisible  ? 'visibility' : 'visibility_off' }}
                                    </mat-icon>
                                </div>
                            </div>
                            <div *ngIf="SignInForm.errors?.['passwordMismatch'] && (SignInForm.controls['confirmPassword'].dirty || SignInForm.controls['confirmPassword'].touched)"
                                        class="text-danger">
                                        Passwords do not match.
                                    </div>
                            <div class="mb-3 row">
                                <div class="col-12">
                                    <button type="submit" class="btn signinBtn w-100"
                                        [disabled]="SignInForm.invalid">Sign
                                        In</button>
                                </div>
                            </div>
                            <!-- <div class="form-group text-center mb-3">
                                <span class="guest-border"><a href="/home" class="Guest">Continue as a Guest <img
                                            src="../../../assets/icon/arrow-left.svg" alt="arrow"></a></span>
                            </div> -->
                        </form>
                    </div>
                </div>

                <!-- Mobile Verification Section -->
                <div class="m-4 mobileVerification" *ngIf="currentSection === 'mobileVerification'">
                    <div class="">
                        <h5 class="text-start mb-4">Mobile number/Email id</h5>
                        <form class="m-auto" [formGroup]="mobileVerificationForm">
                            <div class="mb-3 row">
                                <label for="inputMobile" class="form-label mobileLabel">Mobile number</label>
                                <div class="d-flex">
                                    <ngx-intl-tel-input class="d-block w-100 phone" formControlName="mobileInput"
                                        [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                        [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedKingdom"
                                        [maxLength]="15" [phoneValidation]="true">
                                    </ngx-intl-tel-input>

                                </div>
                                <div *ngIf="mobileError" class="text-danger">
                                    {{ mobileError }}
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <label for="inputEmail" class="form-label emailLabel">Email id</label>
                                <div>
                                    <input type="email" class="form-control" id="inputEmail"
                                        placeholder="Enter your email id" formControlName="emailInput"
                                        (focus)="enableVerifyButton()" (focus)="onFocus('emailInput')"
                                        (blur)="onBlur('emailInput')">
                                </div>

                                <div *ngIf="emailError" class="text-danger">
                                    {{ emailError }}
                                </div>
                            </div>
                            <div class="mt-4 mb-3">
                                <button type="button" class="btn btnVerify w-50 d-block m-auto"
                                    [disabled]="!isVerifyEnabled" (click)="verifyInput()">Verify</button>
                            </div>
                        </form>
                    </div>
                </div>


                <!-- OTP Verification Section -->
                <div class="m-4 otpVerification" *ngIf="currentSection === 'otpVerification'">
                    <div class="m-4 mobileOtpVerify">
                        <h5 class="text-start mb-4">OTP Verification</h5>
                        <form class="m-auto" [formGroup]="otpForm" (ngSubmit)="onOtpSubmit()">
                            <div class="mb-3 row" *ngIf="showMobileOtpVerification">
                                <label for="inputOtp" class="form-label mobileLabel">Mobile OTP</label>
                                <div>
                                    <input type="text" class="form-control" id="inputOtp" formControlName="mobileOTP"
                                        placeholder="Enter your mobile OTP" (input)="enableOTPVerifyButton()">
                                </div>
                            </div>
                            <div class="mb-3 row" *ngIf="showEmailOtpVerification">
                                <label for="inputOtpEmail" class="form-label emailLabel">Email OTP</label>
                                <div>
                                    <input type="text" class="form-control" id="inputOtpEmail"
                                        formControlName="emailOTP" placeholder="Enter your email OTP" required
                                        (input)="enableOTPVerifyButton()">
                                </div>
                            </div>
                            <div class="mb-3 text-center">
                                <button type="submit" class="btn btnMobileOtpVerify w-100"
                                    [disabled]="!isVerifyOTPEnabled">Verify</button>
                                <div class="mt-3"><a class="cursor resend" (click)="onResendOtp()">Resend OTP</a></div>
                            </div>
                            <!-- Display error message -->
                            <div *ngIf="otpError" class="text-danger mb-3">
                                {{ otpError }}
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <!-- Guest Border -->
            <div class="form-group text-center email-container d-lg-none d-none" >
                <button type="button" class="email btn">
                    <span class="gmail-btn text-center"><img src="../../../assets/icon/g-signin.svg" alt="Gmail Logo"
                            class="gmail-logo"></span>
                    <span class="text-center">Sign in</span>
                </button>
            </div>

            <div class="guest-border-container">
                <span class="guest-border">
                    <a href="/home" class="Guest">Continue as a Guest <img src="../../../assets/icon/arrow-left.svg"
                            alt="arrow"></a>
                </span>
            </div>
        </div>
        <!-- Image Carousel Section -->
        <div class="col-lg-6 col-md-12 carouselImage d-none d-lg-block">
            <div class="container">
                <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="img-wrapper">
                                <img src="../../../assets/carousel-image/login-carousel.svg" class="d-block w-100"
                                    alt="...">
                            </div>
                            <div class="carousel-caption d-none d-md-block text-start">
                                <h1>GP STORE</h1>
                                <h2>“Shop smart, eat well with GP Store your online grocery and food Platform”</h2>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="img-wrapper">
                                <img src="../../../assets/carousel-image/carousel-login.svg" class="d-block w-100"
                                    alt="...">
                            </div>
                            <div class="carousel-caption d-none d-md-block text-start">
                                <h1>GP STORE</h1>
                                <h2>“Shop smart, eat well with GP Store your online grocery and food Platform”</h2>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="0" class="active"
                            aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>