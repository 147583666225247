 <div class="m-lg-5 m-md-4 m-4 cancellation-page">
    <!-- <div class="img-container">
        <div class="bg-img">
            <img src="../../assets/images/privacypolicy.svg" alt="img" class="privacy-policy-img">
            <div class="img-overlay"></div>
            <div class="img-content">
                <span class="cancel-policy-condition-heading">Cancellation Policy</span>
            </div>
        </div>
    </div>
    <div class="cancel-policy-container">
        <div class="mt-4 cancel-policy-details-content">
            <p>At Gp Store, we strive to provide a seamless shopping experience. If you need to cancel your order, please review our cancellation policy below:</p>
        </div>
        <div class="process-content">
        <div class="mt-4">
            <h4>Order Cancellation Timeline</h4>
        </div>
        <div class="cancel-policy-details">
            <div class="mt-4 cancel-policy-details-content">
                    <ul>
                        <li>You may cancel your order within 24 hours of placing it for a full refund.</li>
                        <li> After 24 hours, cancellation requests will be handled on a case-by-case basis and may incur a restocking fee.</li>
                    </ul>
                    <p>How to Cancel To cancel your order, please contact our customer service team at [Customer Service Email] or call us at [Customer Service Phone Number]. Be sure to provide your order number and the reason for cancellation.</p>
            </div>
        </div>
    </div>
    <div class="process-content">
        <div class="mt-4">
            <h4>Refund Process</h4>
        </div>
        <div class="cancel-policy-details">
            <div class="mt-4 cancel-policy-details-content">
                <ul>
                    <li>If your cancellation request is approved, a full refund will be processed to your original payment method within 5-7 business days.</li>
                    <li>Please note that processing times may vary based on your bank or payment provider.</li>
                </ul>
                <p> Non-Cancelable Orders Certain items are non-cancelable once they have been processed or shipped. These may include:</p>
                <ul>
                    <li> Customized or personalized products</li>
                    <li> Digital downloads</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="process-content">
        <div class="cancel-policy-details">
            <div class="mt-4 cancel-policy-details-content">
                <p>Changes to This Policy We reserve the right to update or modify this cancellation policy at any time. Any changes will be reflected on this page.</p>
                <p>If you have any questions about our cancellation policy, please feel free to reach out.</p>
                <p>Thank you for shopping with us!</p>
            </div>
        </div>
    </div>
    </div> -->
    <div *ngIf="pageType">
        <div [innerHTML]="pageType.description"></div>
<app-play-store></app-play-store>
 
</div> 
 

