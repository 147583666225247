import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { ProductPopupDialogComponent } from '../product-popup-dialog/product-popup-dialog.component';
import { CartService } from '../services/cart-service.service';
import { ProductService } from '../services/product.service';
 
@Component({
  selector: 'app-subscribe-list-product',
  templateUrl: './subscribe-list-product.component.html',
  styleUrls: ['./subscribe-list-product.component.css']
})
export class SubscribeListProductComponent {
 
  productList: any[] = [];
  page: number = 1; // Initial page number
  count: number = 9;
  productsPerPage: number = 9;
  totalPages: number = 0;
  totalProducts: any;
  selectedVariation: any;
  userId: string | null = null;
  add_cart: boolean = true;
  isLoggedIn: boolean = false;
  quantities = new Map<number, number>();
  addedToCart = new Map<number, boolean>();
 
  constructor(public auth: AuthService, public router: Router, private route: ActivatedRoute, private http: HttpClient, private productService: ProductService, private dialog: MatDialog, private elementRef: ElementRef,private cartService: CartService) {
    this.auth.accessToken.subscribe((token: string | null) => {
      // Set isLoggedIn to true if token exists, otherwise false
      this.isLoggedIn = !!token;
    });
    this.auth.userInfo.subscribe((data: any) => {
      if (data) {
        this.userId = data?.id;
        this.isLoggedIn = !!data;
      } else {
        this.isLoggedIn = false;  
      }
    });
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const searchQuery = params['q'];
      if (searchQuery) {
        this.ProductListCall(searchQuery);
 
 
      }
    });
    this.ProductListCall()
  }
 
  previousPage(): void {
    if (this.page > 1) {
      this.page--;
      this.ProductListCall();
    }
  }
 
  nextPage(): void {
    if (this.page < this.totalPages) {
      this.page++;
      this.ProductListCall();
    }
  }
 
  goToPage(page: number): void {
    if (page !== this.page) {
      this.page = page;
      this.ProductListCall();
    }
  }
 
  getProductsForPage(page: number): any[] {
    // Mock function to get products. In real implementation, this should be fetched from the server.
    // Calculate start and end index based on the current page
    const start = (page - 1) * this.productsPerPage;
    const end = start + this.productsPerPage;
 
    // Here, you would return the actual products based on the start and end indexes
    return []; // Return actual products array based on start and end
  }
  openProductDialog(product: any,selectedVariationId: string): void {
   
    const variationId = this.selectedVariation|| selectedVariationId;
   
    this.dialog.open(ProductPopupDialogComponent, {
      data: {
        id: product.id,
        datafrom: '',
        variationId: variationId
      },
      width: '90%',
      height: '90%',
      disableClose: true
    });
    this.selectedVariation=''
  }
 
  selectSize(id: any, variation: any): void {
    let productIndex = this.productList.findIndex((x: any) => x.id == id);
 
    if (productIndex != -1) {
      this.productList[productIndex].price = variation.priceToShow;
      this.productList[productIndex].originalPrice = variation.originalPrice;
      this.selectedVariation = variation.productvariationid;
     
    }
 
  }
  ProductListCall(searchQuery?: string): void {
    // Define requestBody with a flexible type using an index signature
    const requestBody: { [key: string]: any } = {
      status: 1,
      isSubscribe: true
    };
 
    // Add the searchQuery to requestBody if it exists
    if (searchQuery) {
      requestBody['q'] = searchQuery;
    }
   
    this.http.post(environment.apiUrl + `/products/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          this.productList = data.products_output;
          this.totalProducts = data.count;  // Fetch total product count from API response
 
          // this.totalPages = Math.ceil(this.totalProducts / this.count);  // Calculate total pages
          this.totalPages = this.totalProducts > 9 ? Math.ceil(this.totalProducts / this.productsPerPage) : 0;
          // variations
 
          this.productList.forEach((product: any) => {
            if (product.productvariations && product.productvariations.length > 0) {
              product.productvariations.sort((a: any, b: any) => a.sellingprice - b.sellingprice);
 
              product.variations = [];
              product.productvariations.forEach((variation: any) => {
 
                if (product.productvariations[0]) {
                  // Set the first variation as default
 
                  product.originalPrice = product.productvariations[0].mrpprice;
                  product.variationvalue = product.productvariations[0].variationvalue;
                  product.price = product.productvariations[0].priceupto7days;
                  product.originalPrice = product.productvariations[0].mrpprice;
                  product.variationvalue = product.productvariations[0].variationvalue;
                  product.variationshortname = product.productvariations[0].variationshortname;
                  product.selectedVariation = product.productvariations[0].productvariationid
                  product.cartquantity = product.productvariations[0].cartquantity
                  product.cartid = product.productvariations[0].cartid
                  product.variations.push({
                    originalPrice: variation.mrpprice,
                    priceToShow: variation.priceupto7days,
                    variationvalue: variation.variationvalue,
                    variationshortname: variation.variationshortname,
                    productvariationid: variation.productvariationid
                  });
 
                }
              });
            }
            // Sort variations by variationvalue in ascending order
            product.variations.sort((a: any, b: any) => {
              if (a.variationvalue < b.variationvalue) {
                return a.sellingprice - b.sellingprice;
                // return -1;
              }
              if (a.variationvalue > b.variationvalue) {
                return a.sellingprice - b.sellingprice;
                // return 1;
              }
              return a.sellingprice - b.sellingprice;
              // return 0;
            });
          });
 
 
          data.products_output.forEach((x: any) => {
 
            x.src = [];
            this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=products&dataForId=${x.id}`).subscribe((datas: any) => {
 
              if (datas.status === "SUCCESS") {
                if (datas.uploads.length > 0) {
 
                  this.http.get(environment.apiUrl + `/uploads/get?id=${datas.uploads[0].id}`).subscribe((res: any) => {
                    if (res.status === "SUCCESS") {
                      x.src.push(res.uploads.base64data);
                    }
                  });
                }
              }
            })
          })
          if(this.userId){
          this.wish_list()
          }
        }
      },
    );
  }
  SubcribeClick(){
    if (!this.userId || !this.isLoggedIn) {
      this.router.navigate(['/login']);
    }else{
    const subcribe = "SubcribeProduct"
    this.router.navigate(['/add-cart'], {
      state: { dataToPass: { datafrom: subcribe } }
    });
  }
   }
 
  productList_page(productId: string, selectedVariation: string): void {
   
    const subscribeProduct = "subscribeProduct";
    const variationId = this.selectedVariation||selectedVariation;
   
    this.router.navigate(['/product-pages', productId], {
      state: { dataToPass: { datafrom: subscribeProduct , variationId: variationId } }
    });
 
  }
 
  toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
    event.stopPropagation(); // Prevent triggering parent events
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to the login page if the user is not logged in
      this.router.navigate(['/login']);
      return; // Stop further execution
    }
    const newStatus = !product.wishlistIcon;
 
    product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
    if (this.userId) {
      const requestBody = {
        status: newStatus ? 1 : -1,
        createdBy: this.userId,
        userId: this.userId,
        productId: productId,
        productVariationId: productVariationId
      };
 
      this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
        .subscribe(
          (data: any) => {
            // Handle the success response here if needed
          },
          (error) => {
            console.error("API Error:", error);
          }
        );
    } else {
      // If user is not logged in, navigate to the login page
      this.router.navigate(['/login']);
    }
  }
 
  wish_list(): void {
    // if (!this.userId) {
    //   this.router.navigate(['/login']);
    //   return;
    // }
 
    const requestBody = {
      userId: this.userId,
    };
 
    this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
            const wish_list_product = data.wishlistitems_output;
 
            // Loop through productList and check if product is in the wishlist
            this.productList.forEach((product: any) => {
              const isInWishlist = wish_list_product.some((item: any) => item.productid === product.id);
              product.wishlistIcon = isInWishlist; // Set wishlist icon to true if in wishlist
             
            });
          }
        },
        (error) => {
          console.error("Wishlist API Error:", error);
        }
      );
  }
}
 
 
 