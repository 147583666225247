import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  pagesOutput: any[] = [];
  constructor(public router: Router, private http: HttpClient, private productService: ProductService) {
    this.pageType()
  }
  pageType(): void {
    const requestBody = {
      sort:"ASC",
      sortColumn:"pages.title"
    };

    this.http.post(environment.apiUrl + `/pages/list`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          if (data.status === 'SUCCESS') {
  
            this.pagesOutput = data.pages_output;
          }
        },
        (error) => {
          console.error("Wishlist API Error:", error);
        }
      );
  }

  click_pageType(page: any): void{
  
        // Handle the response data here
        if (page.pagetype === 'about-us') {
          this.router.navigate(['/about-us'], { state: { pageData: page.pagetype } });
        } else if (page.pagetype === 'cancellation-policy') {
          this.router.navigate(['/cancellation-policy'], { state: { pageData: page.pagetype  } });
        } else if (page.pagetype === 'privacy-policy') {
          this.router.navigate(['/privacy-policy'], { state: { pageData: page.pagetype  } });
        } else if (page.pagetype === 'terms-conditions') {
          this.router.navigate(['/terms-conditions'], { state: { pageData: page.pagetype  } });
        } else {
          console.error('Unknown pagetype:', page.pagetype);
        }
      
    }
}