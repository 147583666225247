import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef  } from '@angular/material/dialog';
 import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CartService } from '../services/cart-service.service';
import { FormsModule } from '@angular/forms';
 
@Component({
  selector: 'app-product-popup-dialog',
  templateUrl: './product-popup-dialog.component.html',
  styleUrls: ['./product-popup-dialog.component.css']
})
export class ProductPopupDialogComponent {
  flavorList: string[] = [];
  iscomboavailable = false;
 
  isSale: boolean | undefined;
  isSubscribe: boolean | undefined;
  mrpPrice: number | undefined;
  priceToShow!: number;
  variationDisplay: string | undefined;
  selectedVariation: any;
  variations: any[] = [];
  userId: string | null = null;
  productDetails: any = {};
  productVariations: any[] = [];
 dataFromPreviousComponent: string | undefined;
  mainImage: string = '';
  selectedIndex: number = 0;
  selectedSize = '50 ML';
  isExpanded = false;
  variationid:any;
  productImages: { src: string[] } = { src: [] };
  sanitizedDescription: SafeHtml = '';
  activeTab = 'description';
  product_list_Id: any;
  additionaldetails: any;
  showMore: boolean = false;
  isLoggedIn: boolean = false;
  startIndex: number = 0;
  endIndex: number = 5;
  cursorDown = { x: 0, y: 0 };
  cursorUp = { x: 0, y: 0 };
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  numVisible: number = 1;
  selectedFlavor: string = '';
  FoodDeliverySlot: boolean = false;
  isDeliverySchedule: boolean = false;
  type:any
     // Image sources for controls
     prevImageSrc: string = '../../assets/icon/carousel-down.svg';
     nextImageSrc: string = '../../assets/icon/carousel-down.svg';
 
  @ViewChild('tabSection', { static: false }) tabSection!: ElementRef;
 
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private elementRef:ElementRef,
    public auth: AuthService,
    private cartService: CartService,
    private dialogRef: MatDialogRef<ProductPopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  )
  {
    this.ProductListCall(data.id);
    this.dataFromPreviousComponent =data.datafrom
    this.variationid  = data.variationId;
 
   
    // Check if dataToPass exists and access its properties safely
   
 
      if (this.dataFromPreviousComponent === 'subscribeProduct') {
        this.isDeliverySchedule = true;
        this.type = 'is_subscribe'
      } else {
        this.isDeliverySchedule = false;
        this.type = 'is_sale'
      }
      this.auth.accessToken.subscribe((token: string | null) => {
        // Set isLoggedIn to true if token exists, otherwise false
        this.isLoggedIn = !!token;
      });
      this.auth.userInfo.subscribe((data: any) => {
        if (data) {
          this.userId = data?.id;
          this.isLoggedIn = !!data;
        } else {
          this.isLoggedIn = false;  
        }
      });
 
  }
 
  ngOnInit(): void {
  }
 
  getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }
 
  selectImage(index: number): void {
    this.mainImage = this.productImages.src[index];
    this.selectedIndex = index;
  }
 
  prevImage(): void {
   
    if (this.startIndex > 0) {
      this.startIndex--;
      this.endIndex--;
    }
    // if (this.startIndex > 0) {
    //   this.startIndex -= this.numVisible;
    // }
  }
 
  nextImage() {
    if (this.endIndex < this.productImages.src.length) {
      this.startIndex++;
      this.endIndex++;
   
    }
    // if (this.startIndex + this.numVisible < this.productImages.src.length) {
    //   this.startIndex += this.numVisible;
    // }
  }
  get visibleImages() {
    return this.productImages.src.slice(this.startIndex, this.startIndex + this.numVisible);
  }
 
  hoverImage(control: string, isHover: boolean) {
    if (control === 'prev') {
      this.prevImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    } else if (control === 'next') {
      this.nextImageSrc = isHover ? '../../assets/icon/color-carousel-down.svg' : '../../assets/icon/carousel-down.svg';
    }
  }
 
 
 
 
 
 
 
 
  selectSize(variation: any): void {
 
 
    this.selectedVariation = variation;
   
    this.productDetails.selectedVariation = variation.productvariationid;
   
    this.priceToShow = this.getPriceToShow(variation);
  this.mrpPrice = variation.mrpprice;
  if (this.selectedVariation.variationid && this.selectedVariation.iscomboavailable === true) {
    this.iscomboavailable = true;
  }
  else {
    this.iscomboavailable = false;
 
  }
 
    }
 
  getPriceToShow(variation: any): number {
    if (variation.issubscribe && variation.issale) {
      if (variation.dataFromPreviousComponent === 'bestSelling') {
        this.FoodDeliverySlot=true
        this.priceToShow = variation.sellingprice; // Set priceToShow when dataFromPreviousComponent is 'bestSelling'
      } else if (variation.dataFromPreviousComponent === 'subscribeProduct') {
        this.priceToShow = variation.priceupto7days; // Set priceToShow when dataFromPreviousComponent is 'subscribe'
      } else {
        this.priceToShow = variation.priceupto7days; // Default to priceupto7days if both issubscribe and issale are true
      }
    } else if (variation.issubscribe) {
      this.priceToShow = variation.priceupto7days; // Set priceToShow if only issubscribe is true
    } else if (variation.issale ) {
      this.priceToShow = variation.sellingprice; // Set priceToShow if only issale is true
    } else {
      this.priceToShow = variation.mrpprice; // Default to mrpPrice if neither issubscribe nor issale is true
    }
    return this.priceToShow; // Return the updated priceToShow
  }
 
 
 
 
 
  ProductListCall(subcategoryId?: string): void {
    const id = subcategoryId || '';
    const userIdParam = this.userId ? `&userId=${this.userId}` : '';
    this.http.get(`${environment.apiUrl}/products/user-view-get?id=${id}${userIdParam}`).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const element = this.elementRef.nativeElement.querySelector('.scroll_top');
          if (element) {
            window.scroll(0, 0);
          }
          this.flavorList = data.products.flavor || null;
 
          if (data.cartitems && data.cartitems.flavor && data.cartitems.flavor.length > 0) {
            this.selectedFlavor = data.cartitems.flavor[0];
          }
          this.productDetails = data.products;
          this.productDetails.variations = [];  
           const matchingVariation = this.productDetails.productvariations.find(
            (variation:any) => variation.productvariationid === this.variationid
          );
         
          if (matchingVariation) {
            this.productDetails.cartitemsquantity = matchingVariation.cartitemsquantity;
            this.productDetails.cartid = matchingVariation.cartid;
            this.productDetails.selectedVariation = matchingVariation.productvariationid;
           
          }
         
          if (this.productDetails.productvariations && this.productDetails.productvariations.length > 0) {
 
 
            this.productDetails.productvariations.forEach((x: any) => {
              if (x.productvariationid && x.iscomboavailable === true) {
                this.iscomboavailable = true;
              }
              else {
                this.iscomboavailable = false;
 
              }
              if (this.dataFromPreviousComponent === 'subscribeProduct' && x.issubscribe === true) {
                // Filter for subscribeProduct
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice, sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale:x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  iscomboavailable: x.iscomboavailable
 
                });
                 // Sort variations by variationvalue in ascending order
               this.productDetails.variations.sort((a: any, b: any) => {
                if (a.variationvalue < b.variationvalue) {
                  return -1;
                }
                if (a.variationvalue > b.variationvalue) {
                  return 1;
                }
                return 0;
              });
              } else if (this.dataFromPreviousComponent === 'bestSelling' && x.issale === true) {
                // Filter for bestSelling
                this.productDetails.variations.push({
                  variationvalue: x.variationvalue,
                  variationshortname: x.variationshortname,
                  productvariationid: x.productvariationid,
                  variationid: x.variationid,
                  mrpprice: x.mrpprice,
                  sellingprice: x.sellingprice,
                  priceupto7days: x.priceupto7days,
                  issale:x.issale,
                  issubscribe: x.issubscribe,
                  dataFromPreviousComponent: this.dataFromPreviousComponent,
                  iscomboavailable: x.iscomboavailable
 
 
                });
                 // Sort variations by variationvalue in ascending order
               this.productDetails.variations.sort((a: any, b: any) => {
                if (a.variationvalue < b.variationvalue) {
                  return -1;
                }
                if (a.variationvalue > b.variationvalue) {
                  return 1;
                }
                return 0;
              });
              }else if(this.dataFromPreviousComponent === '' && x.issale === true){
              this.productDetails.variations.push({
                variationvalue: x.variationvalue,
                variationshortname: x.variationshortname,
                productvariationid: x.productvariationid,
                variationid: x.variationid,
                mrpprice: x.mrpprice,
                sellingprice: x.sellingprice,
                priceupto7days: x.priceupto7days,
                issale:x.issale,
                issubscribe: x.issubscribe,
                dataFromPreviousComponent: this.dataFromPreviousComponent,
                iscomboavailable: x.iscomboavailable
 
 
              });
               // Sort variations by variationvalue in ascending order
               this.productDetails.variations.sort((a: any, b: any) => {
                if (a.variationvalue < b.variationvalue) {
                  return -1;
                }
                if (a.variationvalue > b.variationvalue) {
                  return 1;
                }
                return 0;
              });
            }
            })
           
            if (this.variationid) {
              const mathProductvariationId =  this.productDetails.variations.find(
                (variation: any) => variation.productvariationid === this.variationid
              );
              this.selectedVariation = mathProductvariationId;
 
 
            }else if ( this.productDetails.variations.length > 0) {
              this.selectedVariation =  this.productDetails.variations[0]; // Set the first variation as default
 
 
            }
             else {
              this.selectedVariation = this.productDetails.productvariations[0];
 
            }
            // Set the default variation (first one)
            // this.selectedVariation = this.productDetails.productvariations[0];
 
            // Calculate the default price to show based on the first variation
            this.getPriceToShow(this.selectedVariation);
            this.mrpPrice = this.selectedVariation.mrpprice;
          }
 
          // Other code for description and image handling
          if (this.productDetails.description) {
            this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(this.productDetails.description);
          } else {
            this.sanitizedDescription = '';
          }
 
          let x = data.products.additionaldetails;
 
          try {
            if (x && x.trim() !== '') {
              this.additionaldetails = JSON.parse(x);
            } else {
              this.additionaldetails = {}; // or any default value
            }
          } catch (e) {
            console.error("Error parsing additionaldetails:", e);
            this.additionaldetails = {}; // Handle the error by assigning a default value
          }
 
          this.http.get(`${environment.apiUrl}/uploads/list-by-datafor?dataFor=products&dataForId=${this.productDetails.id}`).subscribe((datas: any) => {
            if (datas.status === "SUCCESS") {
              this.productImages.src = [];
 
              datas.uploads.forEach((upload: any, index: number) => {
                this.http.get(`${environment.apiUrl}/uploads/get?id=${upload.id}`).subscribe((res: any) => {
                  if (res.status === "SUCCESS") {
                    this.productImages.src.push(res.uploads.base64data);
                    if (index === 0) { // Set the first image as the default main image
                      this.mainImage = this.productImages.src[0];
                      this.selectedIndex = 0;
                    }
                  }
                });
              });
            }
          });
           if(this.userId){
            this.wish_list();
 
          }
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
 
 
  Add_to_cart(productId: number, productVariationId: string, quantity: number) {
    if (!this.userId || !this.isLoggedIn) {
      // Redirect to login page if user is not logged in
      this.router.navigate(['/login']);
      return;
    }
    const requestBody = {
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      quantity: quantity,
      flavor: this.selectedFlavor ? [this.selectedFlavor] : []
    };
 
    this.http.post(environment.apiUrl + `/cart/add-to-cart`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
 
          this.productDetails.cartitemsquantity = quantity;
         
 
          // const product = this.productDetails.productvariations.find(
          //   (variation: any) => variation.productvariationid === data.productvariationid
          // );
          // if (product) {
          //   product.cartitemsquantity = quantity;
           
          // }
 
          this.cartService.updateCartCount(data.newCartCount);  // Ensure cart count is updated correctly
          if (data.cartitems && data.cartitems.flavor && data.cartitems.flavor.length > 0) {
            this.selectedFlavor = data.cartitems.flavor[0];
          }
        }
      },
      (error: any) => {
        console.error("Error in add-to-cart API call:", error);
      }
    );
  }
 
 increaseQuantity(productId: number, productVariationId: string) {
  const product = this.productDetails.productvariations.find(
    (variation: any) => variation.productvariationid === productVariationId
  );
  if (product) {
    product.cartitemsquantity += 1;
 
    // this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
    this.productDetails.cartitemsquantity = product.cartitemsquantity; // Update the main product cart quantity
  }
}
 
decreaseQuantity(productId: number, productVariationId: string, cartId: any) {
  const product = this.productDetails.productvariations.find(
    (variation: any) => variation.productvariationid === productVariationId
  );
  if (product) {
    if (product.cartitemsquantity > 1) {
      product.cartitemsquantity -= 1;
      this.Add_to_cart(productId, productVariationId, product.cartitemsquantity);
    } else if (product.cartitemsquantity === 1) {
      product.cartitemsquantity = 0;
      this.productDetails.cartitemsquantity = 0;  // Also set the main product cart quantity to 0
      this.deleteCartItem(cartId);  // Call delete function when quantity reaches zero
    }
  }
}
 
deleteCartItem(cartId: any): void {
  const requestBody = {
    updatedBy: this.userId,
    cartIds: [cartId],
  };
 
  this.http.post(environment.apiUrl + `/cart/delete`, requestBody, { responseType: 'json' }).subscribe(
    (data: any) => {
      if (data.status === 'SUCCESS') {
        // Update cart count after deletion
        this.cartService.updateCartCount(data.newCartCount);
 
        // Find the product variation associated with the deleted cart item
        const product = this.productDetails.productvariations.find(
          (variation: any) => variation.cartid === cartId
        );
 
        if (product) {
          // Reset the cart quantity to 0 for the deleted product variation
          product.cartitemsquantity = 0;
        }
 
        // Reset main product cartitemsquantity if it was the last item in the cart
        this.productDetails.cartitemsquantity = 0;
 
      }
    },
    (error: any) => {
      console.error('An error occurred while deleting the cart item:', error);
    }
  );
}
 
Subscribe_click(){
    const subcribe = "SubcribeProduct"
    this.router.navigate(['/add-cart'], {
      state: { dataToPass: { datafrom: subcribe } }
    });
    this.dialogRef.close();
}
 toggleWishList(event: Event, product: any, productId: any, productVariationId: any): void {
  event.stopPropagation(); // Prevent triggering parent events
  if (!this.userId || !this.isLoggedIn) {
    // Redirect to the login page if the user is not logged in
    this.router.navigate(['/login']);
    return; // Stop further execution
  }
  const newStatus = !product.wishlistIcon;
 
  product.wishlistIcon = newStatus; // Update the wishlist icon status in UI
  if (this.userId) {
    const requestBody = {
      status: newStatus ? 1 : -1,
      createdBy: this.userId,
      userId: this.userId,
      productId: productId,
      productVariationId: productVariationId,
      type:this.type
    };
 
    this.http.post(environment.apiUrl + `/wishlistitems/status-update`, requestBody, { responseType: 'json' })
      .subscribe(
        (data: any) => {
          // Handle the success response here if needed
        },
        (error) => {
          console.error("API Error:", error);
        }
      );
  } else {
    // If user is not logged in, navigate to the login page
    this.router.navigate(['/login']);
  }
}
 
wish_list(): void {
  const requestBody = {
    userId: this.userId,
  };
 
  this.http.post(environment.apiUrl + `/wishlistitems/list`, requestBody, { responseType: 'json' })
    .subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const wish_list_product = data.wishlistitems_output;
 
          // Check if the product is in the wishlist
          const isInWishlist = wish_list_product.some((item: any) => item.productid === this.productDetails.id);
 
          // Set wishlistIcon property based on the result
          this.productDetails.wishlistIcon = isInWishlist;
        }
      },
      (error) => {
        console.error("Wishlist API Error:", error);
      }
    );
}
 
navigateToProductPage(selectedVariation:any): void {
 
  this.router.navigate(['/product-pages', this.data.id],{
    state: { dataToPass: { datafrom: this.dataFromPreviousComponent , variationId: this.data.variationId } }
  });
  this.dialogRef.close();
 
}
 
 
}
 
 
 