import { Directive, ElementRef, HostListener, Input } from '@angular/core';
 
@Directive({
  selector: '[appAutoResize]'
})
export class AutosizeDirective {
  @Input() readonly: boolean = false;
  private resizeListener: any | undefined;
  constructor(private el: ElementRef) {}
 
  ngOnInit(): void {
    // Adjust height on initialization
    this.adjustHeight();
 
    // Add window resize listener
    this.resizeListener = this.onResize.bind(this);
    window.addEventListener('resize', this.resizeListener);
  }
  ngOnDestroy(): void {
    // Remove the listener on destroy to avoid memory leaks
    if (this.resizeListener) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustHeight()
  }
 
  private adjustHeight(): void {
  //   console.log("Adjusting height...");
   
  //   const textarea = this.el.nativeElement as HTMLTextAreaElement;
  //   let contentHeight = textarea.scrollHeight;
  //    let isMobile  = window.innerWidth < 768;
  // // Add conditions based on screen size
  // if (!isMobile) { // For larger screens
  //       console.log("window");
       
  //   textarea.style.height = `${contentHeight}px`;
   
  // }else{
  //   // Temporarily reset height to auto to calculate correct scrollHeight
  //   textarea.style.height = 'auto';
 
  //   // Calculate the full height including borders and paddings
  //   const style = window.getComputedStyle(textarea);
  //   const paddingTop = parseFloat(style.paddingTop || '0');
  //   const paddingBottom = parseFloat(style.paddingBottom || '0');
  //   const borderTop = parseFloat(style.borderTopWidth || '0');
  //   const borderBottom = parseFloat(style.borderBottomWidth || '0');
 
  //   // Set height to content height plus paddings and borders
  // const t =  textarea.scrollHeight;
  // console.log(t);
 
  //   const totalHeight =
  //     textarea.scrollHeight + paddingTop + borderTop + borderBottom + paddingBottom + textarea.clientHeight;
  //   console.log(totalHeight,"test",borderBottom,"borderBottom",borderTop,"borderTop",paddingBottom,"paddingBottom",paddingTop,"paddingTop",style,"style",);
   
  //   textarea.style.height = `${totalHeight}px`;
  // }
 
 
 
    const textarea = this.el.nativeElement as HTMLTextAreaElement;
 
    // Temporarily reset the height to auto for recalculation
    textarea.style.height = 'auto';
 
    // Calculate the new height
    const style = window.getComputedStyle(textarea);
    const isMobile = window.innerWidth < 768;
    const paddingTop = parseFloat(style.paddingTop || '0');
    const paddingBottom = parseFloat(style.paddingBottom || '0');
    const borderTop = parseFloat(style.borderTopWidth || '0');
    const borderBottom = parseFloat(style.borderBottomWidth || '0');
   
    let totalHeight = textarea.scrollHeight;
    if (isMobile) {
      totalHeight += paddingTop + paddingBottom + borderTop + borderBottom;
    }
 
 
    // Apply the calculated height
    textarea.style.height = `${totalHeight}px`;
 
  }
 
}
 
 