import { HttpClient } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
interface Offer {
  image: string;
  backgroundColor: string;
  text1: string;
  text2: string;
  offerDetails: string;
  textColor: string;
  highlightColor: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  responsiveOptions: any[] | undefined;
  showArrow:boolean= false;
  userId: string | null = null;
  offers: Offer[] = [
    {
      image: '../../assets/carousel-image/chinese-food.svg',
      backgroundColor: '#F7E7DC',
      text1: '50% off',
      text2: 'Chinese Food',
      offerDetails: '* Offer till July 20',
      textColor: '#C95200',
      highlightColor: '#000000',
    },
    {
      image: '../../assets/carousel-image/indian-food.svg',
      backgroundColor: '#96C9F4',
      text1: '30% off',
      text2: 'Indian Food',
      offerDetails: '* Offer till July 20',
      textColor: '#004B8A',
      highlightColor: '#000000',
    },
    {
      image: '../../assets/carousel-image/oil-products.svg',
      backgroundColor: '#102C57',
      text1: '30% off',
      text2: 'Oil Products',
      offerDetails: '* Offer till July 20',
      textColor: '#FFC533',
      highlightColor: '#FFFFFF',
    },
    {
      image: '../../assets/carousel-image/chinese-food.svg',
      backgroundColor: '#F7E7DC',
      text1: '50% off',
      text2: 'Chinese Food',
      offerDetails: '* Offer till July 20',
      textColor: '#C95200',
      highlightColor: '#000000',
    },
    {
      image: '../../assets/carousel-image/indian-food.svg',
      backgroundColor: '#96C9F4',
      text1: '30% off',
      text2: 'Indian Food',
      offerDetails: '* Offer till July 20',
      textColor: '#004B8A',
      highlightColor: '#000000',
    },
    {
      image: '../../assets/carousel-image/oil-products.svg',
      backgroundColor: '#102C57',
      text1: '30% off',
      text2: 'Oil Products',
      offerDetails: '* Offer till July 20',
      textColor: '#FFC533',
      highlightColor: '#FFFFFF',
    },{
      image: '../../assets/carousel-image/chinese-food.svg',
      backgroundColor: '#F7E7DC',
      text1: '50% off',
      text2: 'Chinese Food',
      offerDetails: '* Offer till July 20',
      textColor: '#C95200',
      highlightColor: '#000000',
    },
    {
      image: '../../assets/carousel-image/indian-food.svg',
      backgroundColor: '#96C9F4',
      text1: '30% off',
      text2: 'Indian Food',
      offerDetails: '* Offer till July 20',
      textColor: '#004B8A',
      highlightColor: '#000000',
    },
    {
      image: '../../assets/carousel-image/oil-products.svg',
      backgroundColor: '#102C57',
      text1: '30% off',
      text2: 'Oil Products',
      offerDetails: '* Offer till July 20',
      textColor: '#FFC533',
      highlightColor: '#FFFFFF',
    }
  ];
  numVisible: number = 3; // default visible items
  mainCategories: any = [];
  constructor(public router: Router,private http: HttpClient,public auth: AuthService) {
    this.categoriesListLevel_0();
    this.auth.userInfo.subscribe(data => {
 
      if (data) {  // Check if userInfo is not null
        this.userId = data.id;
      }
    })
   }
 
 
   
  ngOnInit(): void {this.responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1
    },
 
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];
 
  this.updateVisibleItems();
  }
 
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItems();
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const threshold =  window.innerHeight * 0.30;
    const bottomOffset = 20;
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const windowHeight = window.innerHeight;
 
    // Show the arrow when the scroll position is greater than the threshold
    if (scrollPosition > threshold) {
      this.showArrow = true;
    } else {
      this.showArrow = false;
    }
 
    // Ensure the arrow remains visible even at the bottom of the page
    if (windowHeight + scrollPosition >= document.body.scrollHeight - bottomOffset) {
      this.showArrow = true;
    }
  }
 
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
 
  updateVisibleItems() {
    const width = window.innerWidth;
    if (width > 1199) {
      this.numVisible = 3;
    } else if (width > 991) {
      this.numVisible = 2;
    }  else {
      this.numVisible = 1;
    }
  }
 
  get showPrevArrow(): boolean {
    return this.offers.length > this.numVisible;
  }
 
  get showNextArrow(): boolean {
    return this.offers.length > this.numVisible;
  }
 
  groceryOpen(category: any): void {
   const From_Main_Category="From_Main_Category"
    this.router.navigate(['/product-list-pages'], { queryParams: { category: category.categorykey,category_Id:category.id } ,state: { dataToPass: { datafrom: From_Main_Category} }});
  }
 
  foodOpen(category: any): void {
    const From_Main_Category="From_Main_Category"
    this.router.navigate(['/product-list-pages'], { queryParams: { category: category.categorykey,category_Id:category.id } ,state: { dataToPass: { datafrom: From_Main_Category} }});
  }
 
  categoriesListLevel_0(): void {
    const requestBody = {
      status: 1,
      parentId: '',
      level: '0',
      showInBanner: true
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
       
        if (data.status === 'SUCCESS') {
 
          this.mainCategories = data.categories_output;
       
          // if (this.homeData) {
          //   const parentId = this.homeData.parentId;
 
          //   this.categoriesListLevel_1(parentId)
          // }
          // const groceryCategory = this.mainCategories.find(category => category.parentname.toLowerCase() === 'grocery');
          // if (groceryCategory) {
          //   this.groceryCategoryId = groceryCategory.id;
          // }
        }
      },
    );
  }
}
 
 