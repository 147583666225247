import { HttpClient } from '@angular/common/http';
import { Component, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
interface Offer {
src: any;
  image: string;
  backgroundColor: string;
  text1: string;
  text2: string;
  offerDetails: string;
  textColor: string;
  highlightColor: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  responsiveOptions: any[] | undefined;
  showArrow:boolean= false;
  userId: string | null = null;
  offers: Offer[] = [];
  offerBanner:any = [];
  numVisible: number = 3; // default visible items
  currentStartIndex: number = 0; // Track the current start index for slicing the offers
  mainCategories: any = [];
  constructor(public router: Router,private http: HttpClient,public auth: AuthService,private cdr: ChangeDetectorRef) {
    this.categoriesListLevel_0();
    this.auth.userInfo.subscribe(data => {
 
      if (data) {  // Check if userInfo is not null
        this.userId = data.id;
      }
    })
   }
 
 
   
  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
          })
 
    this.responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1
    },
 
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];
 this.offerList();
  this.updateVisibleItems();
  }
 
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateVisibleItems();
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const threshold =  window.innerHeight * 0.30;
    const bottomOffset = 20;
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const windowHeight = window.innerHeight;
 
    // Show the arrow when the scroll position is greater than the threshold
    if (scrollPosition > threshold) {
      this.showArrow = true;
    } else {
      this.showArrow = false;
    }
 
    // Ensure the arrow remains visible even at the bottom of the page
    if (windowHeight + scrollPosition >= document.body.scrollHeight - bottomOffset) {
      this.showArrow = true;
    }
  }
 
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
 
  updateVisibleItems() {
    const width = window.innerWidth;
   
    if (width <= 575) {
      this.numVisible = 1;
    } else if (width <= 991) {
      this.numVisible = 2;
    } else {
      this.numVisible = 3;
    }
  }
 
  get showPrevArrow(): boolean {
    return this.currentStartIndex > 0;
  }
 
  get showNextArrow(): boolean {
    return this.currentStartIndex + this.numVisible < this.offers.length;
  }
 
  navigateToPrev(): void {
    if (this.showPrevArrow) {
      this.currentStartIndex -= this.numVisible;
    }
  }
  navigateToNext(): void {
    if (this.showNextArrow) {
      this.currentStartIndex += this.numVisible;
    }
  }
  groceryOpen(category: any): void {
   
   const From_Main_Category="From_Main_Category"
    this.router.navigate(['/product-list-pages'], { queryParams: { category: category.categorykey } ,state: { dataToPass: { datafrom: From_Main_Category} }});
  }
 
  foodOpen(category: any): void {
    const From_Main_Category="From_Main_Category"
    this.router.navigate(['/product-list-pages'], { queryParams: { category: category.categorykey } ,state: { dataToPass: { datafrom: From_Main_Category} }});
  }
 
  categoriesListLevel_0(): void {
    const requestBody = {
      status: 1,
      parentId: '',
      level: '0',
      showInBanner: true
    };
 
    this.http.post(environment.apiUrl + `/categories/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
       
        if (data.status === 'SUCCESS') {
 
          this.mainCategories = data.categories_output;
       
          // if (this.homeData) {
          //   const parentId = this.homeData.parentId;
 
          //   this.categoriesListLevel_1(parentId)
          // }
          // const groceryCategory = this.mainCategories.find(category => category.parentname.toLowerCase() === 'grocery');
          // if (groceryCategory) {
          //   this.groceryCategoryId = groceryCategory.id;
          // }
        }
      },
    );
  }
  
  offerList(): void {
    const requestBody = {
      status: 1,
    };
  
    this.http.post(environment.apiUrl + `/offerbanners/list`, requestBody, { responseType: 'json' }).subscribe(
      (data: any) => {
        if (data.status === 'SUCCESS') {
          const colorList = ['#96C9F4', '#102C57', '#F7E7DC'];
          const predefinedTextColors = ['#004B8A', '#FFC533', '#C95200'];
          const highlighterList = ['#000000', '#FFFFFF', '#000000'];
  
          // Map offer data
          this.offers = data.offerbanners_output.map((item: any, index: number) => {
            const expiryDate = new Date(+item.expirydate);
            const formattedDate = expiryDate.toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
            });
  
            return {
              id: item.id, // Use item.id as dataForId for uploads API
              src: '', // Placeholder for the image source
              backgroundColor: colorList[index % colorList.length],
              text1: `${item.percentage}% off`,
              text2: item.categoryname,
              offerDetails: `* Offer till ${formattedDate}`,
              textColor: predefinedTextColors[index % predefinedTextColors.length],
              highlightColor: highlighterList[index % highlighterList.length],
            };
          });
  
          // Fetch images for all offers
          this.offers.forEach((offer: any) => {
            if (offer.id) {
              this.http.get(environment.apiUrl + `/uploads/list-by-datafor?dataFor=offerbanners&dataForId=${offer.id}`).subscribe((listData: any) => {
                if (listData.status === 'SUCCESS' && listData.uploads.length > 0) {
                  const uploadId = listData.uploads[0].id;
                  this.http.get(environment.apiUrl + `/uploads/get?id=${uploadId}`).subscribe((uploadData: any) => {
                    if (uploadData.status === 'SUCCESS') {
                      let base64data = uploadData.uploads.base64data;
                      if (base64data.startsWith('data:image')) {
                        base64data = base64data.split('base64,')[1]; // Strip redundant prefix
                      }
                      offer.src = `data:image/jpeg;base64,${base64data}`;
                      this.cdr.detectChanges();
                    }
                  });
                } 
              });
            }
          });
        }
      }
    );
  }
  
  
}
 
 
 
 
 